import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';

import type { PerformanceFlatFields, PerformanceRegisterFields } from './types';

export const usePerformanceLabelledFields = (
  records: PerformanceFlatFields[] | undefined
) => {
  const { t } = useTranslation(['common']);
  const effectiveness = useRating('effectiveness');
  const testTypeLookup = t('testTypes');

  return useMemo<PerformanceRegisterFields[]>(() => {
    return (
      records?.map((d) => {
        const parent = d.assessmentParents.filter((a) => a.assessment)[0]
          ?.assessment;

        return {
          ...d,
          SubmitterName: d.submitter?.FriendlyName || '-',
          FriendlyID: getFriendlyId(
            Parent_Type_Enum.TestResult,
            d.SequentialId
          ),
          OverallEffectivenessLabelled: effectiveness.getLabel(
            d.OverallEffectiveness
          ),
          TestTypeLabelled:
            testTypeLookup[d.TestType as keyof typeof testTypeLookup] ?? '-',
          NextTestDate: parent?.NextTestDate,
          AssessmentTitle: parent?.Title ?? '-',
        };
      }) || []
    );
  }, [effectiveness, records, testTypeLookup]);
};
