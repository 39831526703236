import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import { handleError } from '../utils/errorUtils';

export type StorageKeys =
  | 'ControlRegisterTable-PreferencesV2'
  | 'RiskRegisterTable-PreferencesV2'
  | 'AppetiteRegisterTable-PreferencesV2'
  | 'AcceptanceRegisterTable-PreferencesV1'
  | 'ActionsRegisterTable-PreferencesV1'
  | 'IssuesRegisterTable-PreferencesV1'
  | 'Temp-PreferencesV1'
  | 'ControlGroupRegisterTable-PreferencesV1'
  | 'ObligationRegisterTable-PreferencesV1'
  | 'ControlTestRegisterTable-PreferencesV1'
  | 'Dashboard-Preferences'
  | 'Dashboard-PreferencesV3'
  | 'NavMenu-Preferences'
  | 'PolicyRegisterTable-Preferences'
  | 'ObligationAssessmentsRegisterTable-PreferencesV1'
  | 'DocumentAssessmentsRegisterTable-PreferencesV1'
  | 'IndicatorsRegisterTable-PreferencesV1'
  | 'MyItems-PreferencesV1'
  | 'AuditLogRegisterTable-PreferencesV2'
  | 'MyPolicies-Preferences'
  | 'AssessmentRegister-Preferences'
  | 'AssessmentResults-Preferences'
  | 'AssessmentResultRegister-Preferences'
  | 'ImpactRegisterTable-Preferences'
  | 'ImpactRatingRegisterTable-Preferences'
  | 'TagsSettingsTable-Preferences'
  | 'DepartmentsSettingsTable-Preferences'
  | 'GroupsSettingsTable-Preferences'
  | 'AssessmentActivities-Preferences'
  | 'ThirdPartyResponseTable-Preferences';

export function useStorage<T>(
  initialValue: T,
  { localStorageKey }: { localStorageKey?: StorageKeys }
): [T, Dispatch<SetStateAction<T>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined' || !localStorageKey) {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(localStorageKey);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      handleError(error);
      // If error also return initialValue
      console.log(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((arg0: T) => T)): void => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== 'undefined' && localStorageKey) {
          window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(valueToStore)
          );
        }
      } catch (error) {
        handleError(error);
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [localStorageKey, storedValue]
  );

  return [storedValue, setValue];
}
