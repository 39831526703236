import type {
  PreferenceCategory,
  PreferencesChannel,
  PreferencesSet,
} from '@risksmart-app/shared/knock/schemas';
import { groupBy } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CategorySwitchesSection } from '@/components/NotificationSettingsModal/CategorySwitchesSection';
import NotificationChannelSwitchesRow from '@/components/NotificationSettingsModal/NotificationChannelSwitchesRow';
import type { WorkflowTemplate } from '@/components/NotificationSettingsModal/util';
import { useIsFeatureVisibleToOrgLazy } from '@/utils/featureFlags';

import style from './style.module.scss';

type Props = {
  enabledChannels: readonly PreferencesChannel[];
  defaultDisabledChannels: readonly PreferencesChannel[];
  workflows: WorkflowTemplate[];
};

const NotificationSettingsForm = (props: Props) => {
  const { watch } = useFormContext<PreferencesSet>();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'notification_settings',
  });
  const checkFeatureFlag = useIsFeatureVisibleToOrgLazy();

  const channelTypeMap = t('channel_types', { returnObjects: true });
  const categories = groupBy(
    props.workflows.filter(
      (w) => !w.featureFlagKey || checkFeatureFlag(w.featureFlagKey)
    ),
    'category'
  );
  const allChannelTypes = watch('channel_types');

  const disabledChannels = [
    ...props.enabledChannels.filter((channel) => !allChannelTypes[channel]),
    ...props.defaultDisabledChannels,
  ];

  return (
    <div>
      <div className={style.notificationTableRow}>
        <div></div>
        {props.enabledChannels.map((channel) => (
          <div className={'font-bold'} key={channel}>
            {channelTypeMap[channel]}
          </div>
        ))}
      </div>

      <NotificationChannelSwitchesRow
        label={'All'}
        variant={'header'}
        channelTypes={props.enabledChannels}
        name={'channel_types'}
        disabledChannels={props.defaultDisabledChannels} // Disable slack notifications for now
      />

      {Object.entries(categories).map(([category, workflows]) => (
        <CategorySwitchesSection
          key={category}
          category={category as PreferenceCategory}
          workflows={workflows}
          channels={props.enabledChannels}
          disabledChannels={disabledChannels}
        />
      ))}
    </div>
  );
};

export default NotificationSettingsForm;
