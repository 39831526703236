import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  GetInternalAuditByIdDocument,
  GetInternalAuditsDocument,
  Parent_Type_Enum,
  useDeleteInternalAuditsMutation,
  useGetInternalAuditByIdQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useGetDetailParentPath } from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import { useTabs } from './useTabs';

type Props = {
  activeTabId: 'details' | 'reports' | 'issues' | 'actions' | 'risks';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const internalAuditId = useGetGuidParam('internalAuditId');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const parentUrl = useGetDetailParentPath(internalAuditId);
  const [mutate, deleteResult] = useDeleteInternalAuditsMutation({
    update: (cache) => {
      evictField(cache, 'internal_audit_entity');
      evictField(cache, 'internal_audit_entity_aggregate');
    },
    refetchQueries: [GetInternalAuditByIdDocument, GetInternalAuditsDocument],
    fetchPolicy: 'no-cache',
  });

  const { t: st } = useTranslation(['common'], { keyPrefix: 'internalAudits' });
  const { data, loading, error } = useGetInternalAuditByIdQuery({
    variables: {
      id: internalAuditId,
    },
  });
  if (error) {
    throw error;
  }

  const internalAudit = data?.internal_audit_entity[0];
  const tabs = useTabs(internalAudit);
  const onDelete = useDeleteResultNotification({
    entityName: st('entity'),
    asyncAction: async () => {
      if (!internalAudit) {
        return false;
      }
      await mutate({
        variables: {
          Ids: [internalAudit.Id],
        },
      });
      await navigate(parentUrl);

      return true;
    },
  });

  if (deleteResult.data?.delete_internal_audit_entity?.affected_rows === 1) {
    return <></>;
  }

  if (!loading && !internalAudit?.Id) {
    throw new PageNotFound(
      `Internal audit with id ${internalAuditId} not found`
    );
  }

  const counter =
    internalAudit &&
    `(${getFriendlyId(
      Parent_Type_Enum.InternalAuditEntity,
      internalAudit.SequentialId
    )})`;
  const fallbackTitle = st('fallback_title');

  return (
    <PageLayout
      actions={
        <Permission
          permission={'delete:internal_audit_entity'}
          parentObject={internalAudit}
        >
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button')}
            </Button>
          </SpaceBetween>
        </Permission>
      }
      meta={{
        title: fallbackTitle,
      }}
      title={internalAudit?.Title}
      counter={counter}
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={activeTabId}
        variant="container"
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
