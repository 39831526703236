import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';

import { AnalyticsContext } from './AnalyticsContext';

interface AnalyticsProviderProps {
  writeKey: string;
  children: React.ReactNode;
}
export const AnalyticsProvider = (
  props: AnalyticsProviderProps
): JSX.Element => {
  const analytics = React.useMemo(
    () => AnalyticsBrowser.load({ writeKey: props.writeKey }),
    [props.writeKey]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};
