import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import type {
  GetAssessmentByIdQuery,
  GetComplianceMonitoringAssessmentByIdQuery,
  GetInternalAuditReportByIdQuery,
} from 'src/generated/graphql';
import {
  GetAssessmentByIdDocument,
  GetAssessmentsDocument,
  useGetAssessmentByIdQuery,
  useGetComplianceMonitoringAssessmentByIdQuery,
  useGetInternalAuditReportByIdQuery,
  useUpdateAssessmentMutation,
  useUpdateComplianceMonitoringAssessmentMutation,
  useUpdateInternalAuditReportMutation,
} from 'src/generated/graphql';
import AssessmentForm from 'src/pages/assessments/forms/AssessmentForm';
import type { AssessmentFormDataFields } from 'src/pages/assessments/forms/AssessmentForm/assessmentSchema';
import { defaultValues } from 'src/pages/assessments/forms/AssessmentForm/assessmentSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import {
  contributorUpdateFields,
  departmentInsertInputBuilder,
  ownerUpdateFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { evictField } from '@/utils/graphqlUtils';

import AssessmentFindingPreview from '../../../forms/AssessmentFindingsPreview/AssessmentFindingPreview';
import AssessmentStatusPreview from '../../../forms/AssessmentStatusPreview/AssessmentStatusPreview';
import type { AssessmentTypeEnum } from '../../../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../../../useAssessmentTypeConfig';

interface Props {
  assessmentMode: AssessmentTypeEnum;
}

const getUpdateMutation = (assessmentMode: AssessmentTypeEnum) => {
  switch (assessmentMode) {
    case 'rating':
      return useUpdateAssessmentMutation;
    case 'compliance_monitoring_assessment':
      return useUpdateComplianceMonitoringAssessmentMutation;
    case 'internal_audit_report':
      return useUpdateInternalAuditReportMutation;
  }
};

const getGetMutation = (assessmentMode: AssessmentTypeEnum) => {
  switch (assessmentMode) {
    case 'rating':
      return useGetAssessmentByIdQuery;
    case 'compliance_monitoring_assessment':
      return useGetComplianceMonitoringAssessmentByIdQuery;
    case 'internal_audit_report':
      return useGetInternalAuditReportByIdQuery;
  }
};

const mapData = (
  data:
    | GetAssessmentByIdQuery
    | GetComplianceMonitoringAssessmentByIdQuery
    | GetInternalAuditReportByIdQuery
    | undefined
) => {
  if ((data as GetAssessmentByIdQuery)?.assessment) {
    return (data as GetAssessmentByIdQuery).assessment[0];
  }
  if (
    (data as GetComplianceMonitoringAssessmentByIdQuery)
      ?.compliance_monitoring_assessment
  ) {
    return (data as GetComplianceMonitoringAssessmentByIdQuery)
      .compliance_monitoring_assessment[0];
  }
  if ((data as GetInternalAuditReportByIdQuery)?.internal_audit_report) {
    return (data as GetInternalAuditReportByIdQuery).internal_audit_report[0];
  }
};

const Tab: FC<Props> = ({ assessmentMode }) => {
  const assessmentId = useGetGuidParam('assessmentId');
  const navigate = useNavigate();
  const query = getGetMutation(assessmentMode);
  const { data, error } = query({
    variables: { Id: assessmentId },
  });
  if (error) {
    throw error;
  }

  const assessment = mapData(data);
  const {
    permissions: { update },
  } = useAssessmentTypeConfig(assessmentMode);
  const canEdit = useHasPermission(update, assessment);
  const mutation = getUpdateMutation(assessmentMode);
  const [updateMutation] = mutation({
    update: (cache) => {
      evictField(cache, 'risk_assessment_result');
      evictField(cache, 'document_assessment_result');
      evictField(cache, 'obligation_assessment_result');
      evictField(cache, 'assessment');
      evictField(cache, 'internal_audit_entity');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
    },
    refetchQueries: [GetAssessmentByIdDocument, GetAssessmentsDocument],
  });

  const onSave = async (data: AssessmentFormDataFields) => {
    if (!assessment) {
      throw new Error('Missing assessment');
    }
    await updateMutation({
      variables: {
        ...data,
        Id: assessment.Id,
        OriginatingItemId: assessment.OriginatingItemId,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...contributorUpdateFields(data, assessment.Id),
        ...ownerUpdateFields(data, assessment.Id),
        tags: tagInsertInputBuilder(data.TagTypeIds, assessment.Id),
        departments: departmentInsertInputBuilder(
          data.DepartmentTypeIds,
          assessment.Id
        ),
        CompletedByUser: data.CompletedByUser?.value,
      },
    });
  };
  const onDismiss = () => navigate(-1);

  return (
    <AssessmentForm
      readOnly={!canEdit}
      values={{
        ...defaultValues,
        ...assessment,
        Summary: assessment?.Summary ?? '',
        Owners: getOwners(assessment),
        Contributors: getContributors(assessment),
        ActualCompletionDate: assessment?.ActualCompletionDate ?? null,
        NextTestDate: assessment?.NextTestDate ?? null,
        TargetCompletionDate: assessment?.TargetCompletionDate ?? null,
        StartDate: assessment?.StartDate ?? null,
        CompletedByUser: assessment?.CompletedByUser
          ? { value: assessment.CompletedByUser, type: 'user' }
          : null,
        ancestorContributors: assessment?.ancestorContributors ?? [],
        Status: assessment?.Status ?? 'notstarted',
      }}
      onSave={onSave}
      onDismiss={onDismiss}
      aside={
        <>
          <AssessmentStatusPreview
            status={assessment?.Status}
            targetCompletionDate={assessment?.TargetCompletionDate}
            actualCompletionDate={assessment?.ActualCompletionDate}
          />
          <AssessmentFindingPreview
            assessmentMode={assessmentMode}
            assessmentId={assessment?.Id}
            outcome={assessment?.Outcome}
          />
        </>
      }
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      assessmentMode={assessmentMode}
    />
  );
};

export default Tab;
