import type { PropertyFilterOperator } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import type { PropertyFilterProps } from '@cloudscape-design/components/property-filter';
import { useMemo } from 'react';

import type { TableRecord } from '../types';
import type { TableFieldsWithCustomAttributes } from './useAddCustomAttributeFieldData';

const DEFAULT_OPERATORS: readonly PropertyFilterOperator[] = [
  ':',
  '!:',
  '=',
  '!=',
] as const;

export const useCreateFilterProperties = <T extends TableRecord>(
  tableFields: TableFieldsWithCustomAttributes<T>
) => {
  return useMemo((): PropertyFilterProps.FilteringProperty[] => {
    return Object.entries(tableFields).map(([fieldName, fieldConfig]) => {
      const { filteringProperties } = fieldConfig.filterOptions ?? {};

      return {
        key: fieldName,
        groupValuesLabel:
          filteringProperties?.defaultOperator ?? fieldConfig.header,
        propertyLabel: fieldConfig.header,
        operators: filteringProperties?.operators ?? DEFAULT_OPERATORS,
        ...filteringProperties,
      };
    });
  }, [tableFields]);
};
