import { Forbidden } from '@risksmart-app/components/errors/errors';
import type { FC, ReactNode } from 'react';

import type { ObjectAccess } from './Permission';
import { useHasPermission } from './useHasPermission';

type Props = {
  children: ReactNode;
  permission: ObjectAccess | ObjectAccess[];
  fallback?: ReactNode;
  canHaveAccessAsContributor?: true;
};

const ProtectedRoute: FC<Props> = ({
  children,
  permission,
  fallback,
  canHaveAccessAsContributor,
}) => {
  const hasAccess = useHasPermission(
    permission,
    undefined,
    canHaveAccessAsContributor
  );

  if (hasAccess === null) {
    return <></>;
  }
  if (!hasAccess) {
    if (fallback) {
      return fallback;
    }
    throw new Forbidden(`Access to ${permission} denied`);
  }

  return children;
};

export default ProtectedRoute;
