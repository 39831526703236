import TextContent from '@cloudscape-design/components/text-content';
import Button from '@risksmart-app/components/Button';
import parse from 'html-react-parser';
import type { Ref } from 'react';
import { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { useDashboardWidgetSettings } from '../../../../context/useDashboardWidgetSettings';
import type { WidgetRef } from '../../types';
import { EditorModal } from './EditorModal';
import type { WidgetSettings } from './widgetSettingsSchema';

export const RichTextWidget = (_props: unknown, ref: Ref<WidgetRef>) => {
  const [settings, setSettings] = useDashboardWidgetSettings<WidgetSettings>();
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation('common', {
    keyPrefix: 'dashboard.widgets.richText',
  });

  useImperativeHandle(ref, () => ({
    openSettings: () => setShowModal(true),
  }));

  const handleSave = async (data: WidgetSettings) => {
    setSettings({ ...data, content: sanitizeHtml(data.content) });
  };

  return (
    <>
      {showModal && (
        <EditorModal
          onDismiss={() => setShowModal(false)}
          onSave={handleSave}
        />
      )}
      <div className={'rich-text-widget'}>
        {!settings ? (
          <div className={'flex flex-col justify-center items-center'}>
            <h3 className={'m-0'}>{t('not_configured_title')}</h3>
            <p>{t('not_configured')}</p>
            <Button onClick={() => setShowModal(true)}>
              {t('configure_button')}
            </Button>
          </div>
        ) : (
          <TextContent>
            {parse(sanitizeHtml(settings?.content ?? ''))}
          </TextContent>
        )}
      </div>
    </>
  );
};
