import type { TableProps } from '@cloudscape-design/components/table';
import { useMemo } from 'react';
import { MAX_COL_WIDTH } from 'src/App.config';

import { EMPTY_CELL } from '../../collectionUtils';
import type { TableRecord, TableRecordColumnWidths } from '../types';
import type { TableFieldsWithCustomAttributes } from './useAddCustomAttributeFieldData';

type Options<T extends TableRecord> = {
  tableFields: TableFieldsWithCustomAttributes<T>;
  columnWidths?: TableRecordColumnWidths<T>;
};

/**
 * Creates cloudscape compatible table column definitions based on RiskSmarts
 * definitions, as well as ensuring column widths are appropriately set. Why
 * does Risksmart have a different format for field definitions? this was
 * implemented in an attempt to reduce duplications, and mistakes between column
 * definitions, filter definitions and table preferences
 *
 * @param param0
 * @returns
 */
export const useCreateColumnDefinitions = <T extends TableRecord>({
  tableFields,
  columnWidths,
}: Options<T>): TableProps.ColumnDefinition<T>[] => {
  return useMemo((): TableProps.ColumnDefinition<T>[] => {
    return Object.entries(tableFields).map(([fieldName, fieldConfig]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { filterOptions, ...field } = fieldConfig;

      return {
        ...field,
        id: fieldName,
        width: columnWidths?.[fieldName],
        maxWidth: field.maxWidth ?? MAX_COL_WIDTH,
        sortingField: !field.sortingDisabled
          ? (field.sortingField ?? fieldName)
          : undefined,
        cell:
          field.cell ??
          ((item) => <>{(item[fieldName] as string) ?? EMPTY_CELL}</>),
      };
    });
  }, [columnWidths, tableFields]);
};
