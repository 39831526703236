import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledDocumentMultiselect from '@/components/Form/ControlledDocumentMultiselect';
import ControlledDocumentSelect from '@/components/Form/ControlledDocumentSelect';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import TagSelector from '@/components/Form/TagSelector';
import type { Version_Status_Enum } from '@/generated/graphql';
import { Contributor_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { AttestationFormFields } from './AttestationFormFields';
import type { DocumentFormFieldData } from './documentSchema';

type Props = {
  readOnly?: boolean;
  documentId?: string;
  docVersionStatus?: Version_Status_Enum;
};

const DocumentFormFields: FC<Props> = ({ readOnly, documentId }) => {
  const { control } = useFormContext<DocumentFormFieldData>();
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy' });
  const types = st('types', { returnObjects: true });
  const typeOptions = Object.keys(types).map((key) => ({
    value: key,
    label: types[key as keyof typeof types],
  }));

  const { t } = useTranslation(['common']);
  const attestationsEnabled = useIsFeatureVisibleToOrg('attestations');

  const frequency = t('frequency');
  const frequencyOptions = Object.keys(frequency).map((key) => ({
    value: key,
    label: frequency[key as keyof typeof frequency],
  }));

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key="title"
        forceRequired={true}
        name="Title"
        label={st('fields.Title')}
        description={st('fields.Title_help')}
        control={control}
        placeholder={st('fields.Title_placeholder') ?? ''}
        disabled={readOnly}
      />

      <ControlledTextarea
        key="purpose"
        name="Purpose"
        label={st('fields.Purpose')}
        description={st('fields.Purpose_help')}
        placeholder={st('fields.Purpose_placeholder') ?? ''}
        control={control}
        disabled={readOnly}
      />

      <ControlledDocumentSelect
        key="parentDocument"
        name="ParentDocument"
        description={st('fields.Parent_help')}
        label={st('fields.Parent')}
        control={control}
        excludedIds={documentId ? [documentId] : []}
        disabled={readOnly}
      />

      <ControlledRadioGroup
        key="documentType"
        forceRequired={true}
        description={st('fields.DocumentType_help')}
        label={st('fields.DocumentType')}
        name="DocumentType"
        control={control}
        items={typeOptions}
        transform={noTransform}
        disabled={readOnly}
        testId="type"
      />

      <ControlledDocumentMultiselect
        key="linkedDocuments"
        description={st('fields.LinkedDocuments_help')}
        label={st('fields.LinkedDocuments')}
        name="linkedDocuments"
        control={control}
        excludedIds={documentId ? [documentId] : []}
        disabled={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key="owners"
        control={control}
        description={st('fields.Owner_help')}
        includeGroups={true}
        inheritedContributorsName="ancestorContributors"
        contributorType={Contributor_Type_Enum.Owner}
        label={st('fields.Owner')}
        name="Owners"
        forceRequired={true}
        placeholder={t('fields.Owner_placeholder')}
        disabled={readOnly}
        testId="owners"
      />

      <ControlledGroupAndUserContributorMultiSelect
        key="contributors"
        control={control}
        includeGroups={true}
        contributorType={Contributor_Type_Enum.Contributor}
        inheritedContributorsName="ancestorContributors"
        label={t('fields.Contributor')}
        name="Contributors"
        placeholder={t('fields.Contributor_placeholder')}
        description={st('fields.Contributor_help')}
        disabled={readOnly}
      />

      <ControlledSelect
        key="testFrequency"
        filteringType="auto"
        label={st('fields.TestFrequency')}
        description={st('fields.TestFrequency_help')}
        name="TestFrequency"
        placeholder="Select"
        control={control}
        addEmptyOption={true}
        options={frequencyOptions}
        disabled={readOnly}
      />

      <ControlledDatePicker
        key="nextTestDate"
        control={control}
        name="NextTestDate"
        label={st('fields.NextTestDate')}
        description={st('fields.NextTestDate_help')}
        disabled={readOnly}
      />

      <TagSelector
        key="tags"
        name="tags"
        control={control}
        disabled={readOnly}
      />
      <DepartmentSelector
        key="departments"
        name="departments"
        control={control}
        disabled={readOnly}
      />

      {attestationsEnabled && (
        <AttestationFormFields key={'attestationFields'} />
      )}
    </CustomisableForm>
  );
};

export default DocumentFormFields;
