
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_1kosq_sym1j_145",
  "breadcrumb": "awsui_breadcrumb_1kosq_sym1j_149",
  "ghost-breadcrumb": "awsui_ghost-breadcrumb_1kosq_sym1j_150",
  "icon": "awsui_icon_1kosq_sym1j_153",
  "anchor": "awsui_anchor_1kosq_sym1j_159",
  "text": "awsui_text_1kosq_sym1j_206",
  "last": "awsui_last_1kosq_sym1j_225"
};
  