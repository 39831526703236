
    import './styles.scoped.css';
    export default {
  "sticky-scrollbar": "awsui_sticky-scrollbar_faqt8_x23gf_181",
  "sticky-scrollbar-content": "awsui_sticky-scrollbar-content_faqt8_x23gf_193",
  "sticky-scrollbar-visible": "awsui_sticky-scrollbar-visible_faqt8_x23gf_196",
  "sticky-scrollbar-native-invisible": "awsui_sticky-scrollbar-native-invisible_faqt8_x23gf_199",
  "sticky-scrollbar-offset": "awsui_sticky-scrollbar-offset_faqt8_x23gf_202",
  "is-visual-refresh": "awsui_is-visual-refresh_faqt8_x23gf_205"
};
  