import type { FC } from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import { useHasPermission } from 'src/rbac/useHasPermission';
import type { UserOrGroup } from 'src/schemas/global';

import {
  Parent_Type_Enum,
  useGetAcceptanceByIdQuery,
  useInsertAcceptanceMutation,
  useUpdateAcceptanceMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import AcceptanceForm from '../forms/AcceptanceForm';
import type { AcceptanceFormDataFields } from '../forms/acceptanceSchema';
import { defaultValues } from '../forms/acceptanceSchema';

type Props = {
  Id?: string;
  ParentId?: string;
};

const getUserAndGroup = (field: UserOrGroup | null | undefined) => {
  let user: string | null = null;
  let userGroup: string | null = null;
  if (field) {
    switch (field.type) {
      case 'user':
        user = field.value;
        break;
      case 'userGroup':
        userGroup = field.value;
        break;
      default:
        console.error(`Unsupported type ${field.type}`);
    }
  }

  return { user, userGroup };
};

const getUserOrGroup = (
  user?: string | null,
  userGroup?: string | null
): UserOrGroup | null => {
  if (user) {
    return {
      type: 'user',
      value: user,
    };
  }
  if (userGroup) {
    return {
      type: 'userGroup',
      value: userGroup,
    };
  }

  return null;
};

const Tab: FC<Props> = ({ Id, ParentId }) => {
  const { updateFiles } = useFileUpdate();
  const navigate = useNavigate();

  const onDismiss = () => {
    navigate(-1);
  };

  const { data, loading, error } = useGetAcceptanceByIdQuery({
    variables: {
      _eq: Id!,
    },
    skip: !Id,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const acceptance = data?.acceptance[0];

  const canUpdatedAcceptance = useHasPermission(
    'update:acceptance',
    acceptance
  );

  const canModify = canUpdatedAcceptance || !acceptance;

  const values = {
    ...defaultValues,
    ...acceptance,
  };

  const acceptanceFormFields: AcceptanceFormDataFields | undefined = useMemo<
    AcceptanceFormDataFields | undefined
  >(() => {
    if (!acceptance) {
      return undefined;
    }
    const approvedBy = getUserOrGroup(
      acceptance.ApprovedByUser,
      acceptance.ApprovedByUserGroup
    );
    const requestedBy = getUserOrGroup(
      acceptance.RequestedByUser,
      acceptance.RequestedByUserGroup
    );

    const fields: AcceptanceFormDataFields = {
      ...acceptance,
      approvedBy,
      requestedBy,
    };

    return fields;
  }, [acceptance]);

  const [insert] = useInsertAcceptanceMutation({
    update: (cache) => {
      evictField(cache, 'acceptance');
      evictField(cache, 'acceptance_aggregate');
      evictField(cache, 'change_request');
    },
  });
  const [update] = useUpdateAcceptanceMutation({
    update: (cache) => {
      evictField(cache, 'acceptance');
      evictField(cache, 'change_request');
    },
  });

  const onSave = async (data: AcceptanceFormDataFields) => {
    const { files, newFiles, approvedBy, requestedBy, ...rest } = data;
    const { user: approvedByUser, userGroup: approvedByUserGroup } =
      getUserAndGroup(approvedBy);
    const { user: requestedByUser, userGroup: requestedByUserGroup } =
      getUserAndGroup(requestedBy);
    if (acceptance) {
      const result = await update({
        variables: {
          ...rest,
          OriginalTimestamp: acceptance.ModifiedAtTimestamp,
          Id: acceptance.Id,
          ApprovedByUser: approvedByUser,
          ApprovedByUserGroup: approvedByUserGroup,
          RequestedByUser: requestedByUser,
          RequestedByUserGroup: requestedByUserGroup,
          CustomAttributeData: rest.CustomAttributeData || undefined,
        },
      });
      if (result.data?.updateChildAcceptance?.affected_rows !== 1) {
        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      if (!ParentId) {
        throw new Error('Cannot insert acceptance without a parent');
      }
      const result = await insert({
        variables: {
          ...rest,
          ParentId,
          ApprovedByUser: approvedByUser,
          ApprovedByUserGroup: approvedByUserGroup,
          RequestedByUser: requestedByUser,
          RequestedByUserGroup: requestedByUserGroup,
          CustomAttributeData: rest.CustomAttributeData || undefined,
        },
      });
      Id = result.data?.insertChildAcceptance?.Id;
    }
    if (!Id) {
      throw new Error('Id must be set');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Acceptance,
      parentId: Id,
      newFiles: newFiles,
      originalFiles: values?.files,
      selectedFiles: files,
    });
  };

  if (loading) {
    return null;
  }

  return (
    <AcceptanceForm
      onSave={onSave}
      readOnly={!canModify}
      values={acceptanceFormFields}
      onDismiss={onDismiss}
      approvalConfig={{ object: acceptance }}
    />
  );
};

export default Tab;
