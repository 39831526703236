
    import './styles.scoped.css';
    export default {
  "marker": "awsui_marker_1kjc7_n1yds_153",
  "root": "awsui_root_1kjc7_n1yds_167",
  "title": "awsui_title_1kjc7_n1yds_202",
  "list": "awsui_list_1kjc7_n1yds_206",
  "marker--dimmed": "awsui_marker--dimmed_1kjc7_n1yds_255",
  "marker--highlighted": "awsui_marker--highlighted_1kjc7_n1yds_258"
};
  