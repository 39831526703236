import type {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { getCurrentDayDate } from '@/utils/dateUtils';

import { AssessmentStatus } from './config';
import type { AssessmentRegisterFields } from './types';
import type { AssessmentTypeEnum } from './useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from './useAssessmentTypeConfig';

type AssessmentSummaryProps = {
  items: readonly AssessmentRegisterFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
  assessmentMode: AssessmentTypeEnum;
};

const dueDaysOffset = 30;

export const AssessmentSummary: FC<AssessmentSummaryProps> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
  assessmentMode,
}) => {
  const { taxonomyKey } = useAssessmentTypeConfig(assessmentMode);
  const { t } = useTranslation(['common'], {
    keyPrefix: `${taxonomyKey}.summary_category_titles`,
  });
  const { getLabel: getStatusLabel } = useRating('assessment_status');
  const currentDate = getCurrentDayDate();
  const currentDateWithOffset = getCurrentDayDate(dueDaysOffset);
  const inprogressEnumValue = AssessmentStatus.Inprogress;
  const completeEnumValue = AssessmentStatus.Complete;

  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'TargetCompletionDate',
              value: currentDateWithOffset.toISOString(),
              operator: '<',
            },
            {
              propertyKey: 'TargetCompletionDate',
              value: currentDate.toISOString(),
              operator: '>',
            },
            {
              propertyKey: 'StatusLabelled',
              value: getStatusLabel(completeEnumValue),
              operator: '!=',
            },
          ],
          operation: 'and',
        }}
        title={t('due')}
        filteringProperties={filteringProperties}
        items={items ?? []}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'TargetCompletionDate',
              value: currentDate.toISOString(),
              operator: '<',
            },
            {
              propertyKey: 'StatusLabelled',
              value: getStatusLabel(completeEnumValue),
              operator: '!=',
            },
          ],
          operation: 'and',
        }}
        title={t('overdue')}
        filteringProperties={filteringProperties}
        items={items ?? []}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'StatusLabelled',
              value: getStatusLabel(inprogressEnumValue) || '',
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('assessment_in_progress')}
        filteringProperties={filteringProperties}
        items={items ?? []}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('total')}
        filteringProperties={filteringProperties}
        items={items ?? []}
      />
    </RegisterDashboard>
  );
};
