import type {
  ControlElement,
  JsonSchema7,
  VerticalLayout,
} from '@jsonforms/core';

import type { JsonSchemaField } from './EditFields/NewFieldSchema';

export interface CustomAttributeFields {
  Schema: JsonSchema7;
  UiSchema: VerticalLayout;
  Title?: string;
  Id?: string;
}

interface CustomAttributeData {
  schema: JsonSchema7;
  uiSchema: VerticalLayout;
}

const defaultUiSchema: VerticalLayout = {
  type: 'VerticalLayout',
  elements: [],
};

const addUpdateRemoveUiControl = (
  control: ControlElement,
  uiSchema: VerticalLayout,
  removeField = false
) => {
  const elements = [...uiSchema.elements];
  const index = uiSchema.elements.findIndex((item) => {
    return ((item as ControlElement).scope || '').indexOf(control.scope) !== -1;
  });
  if (index === -1 && !removeField) {
    elements.push(control);
  } else {
    const additions = removeField === false ? [control] : [];
    elements.splice(index, 1, ...additions);
  }

  return elements;
};

export const removeFieldFromSchemaData = (
  field: JsonSchemaField,
  data?: CustomAttributeData
): CustomAttributeData => {
  const { control, attributeName } = field;
  const { schema = {}, uiSchema = defaultUiSchema } = data || {};
  const updatedSchemaProps = { ...(schema.properties || {}) };
  delete updatedSchemaProps[attributeName];

  return {
    schema: {
      ...schema,
      properties: updatedSchemaProps,
      // allows for properties to be flagged as required.
      required: [],
    },
    uiSchema: {
      ...uiSchema,
      elements: addUpdateRemoveUiControl(control, uiSchema, true),
    },
  };
};

export const addFieldToSchemaData = (
  field: JsonSchemaField,
  data?: CustomAttributeData
): CustomAttributeData => {
  const { schema: controlSchema, control, attributeName } = field;
  const { schema = {}, uiSchema = defaultUiSchema } = data || {};

  return {
    schema: {
      ...schema,
      properties: {
        ...(schema.properties || {}),
        [attributeName]: controlSchema,
      },
    },
    uiSchema: {
      ...uiSchema,
      elements: addUpdateRemoveUiControl(control, uiSchema),
    },
  };
};
