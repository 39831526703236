
    import './styles.scoped.css';
    export default {
  "option": "awsui_option_1p2cx_1oqxr_153",
  "disabled": "awsui_disabled_1p2cx_1oqxr_190",
  "parent": "awsui_parent_1p2cx_1oqxr_193",
  "highlighted": "awsui_highlighted_1p2cx_1oqxr_196",
  "content": "awsui_content_1p2cx_1oqxr_200",
  "label-content": "awsui_label-content_1p2cx_1oqxr_207",
  "label": "awsui_label_1p2cx_1oqxr_207",
  "tag": "awsui_tag_1p2cx_1oqxr_214",
  "label-tag": "awsui_label-tag_1p2cx_1oqxr_215",
  "label-prefix": "awsui_label-prefix_1p2cx_1oqxr_225",
  "tags": "awsui_tags_1p2cx_1oqxr_238",
  "description": "awsui_description_1p2cx_1oqxr_239",
  "selected": "awsui_selected_1p2cx_1oqxr_250",
  "icon": "awsui_icon_1p2cx_1oqxr_264",
  "icon-size-big": "awsui_icon-size-big_1p2cx_1oqxr_271",
  "filtering-match-highlight": "awsui_filtering-match-highlight_1p2cx_1oqxr_275",
  "trigger-variant": "awsui_trigger-variant_1p2cx_1oqxr_290"
};
  