import { useCollection } from '@cloudscape-design/collection-hooks';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import type { IndicatorFormDataFields } from 'src/pages/indicators/forms/indicatorSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { ownerAndContributorIds } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import type { Query_Root } from '@/generated/graphql';
import {
  Parent_Type_Enum,
  useDeleteIndicatorsMutation,
  useGetIndicatorsByParentIdQuery,
  useInsertIndicatorMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { latestResultValueFromData } from '../latestResultValueFromData';
import CreateIndicatorModal from '../modals/CreateIndicatorModal';
import type { IndicatorTableFields } from './config';
import { useIndicatorColumnDefinitions } from './config';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('indicators.tabHelp');
  const { updateFiles } = useFileUpdate();
  const { addNotification } = useNotifications();
  const { t: st } = useTranslation(['common'], { keyPrefix: 'indicators' });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const canDeleteIndicators = useHasPermission('delete:indicator', parent);
  const [selected, setSelected] = useState<IndicatorTableFields[]>([]);
  const { data, loading } = useGetIndicatorsByParentIdQuery({
    variables: {
      parentId: parent.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [deleteAction, deleteResult] = useDeleteIndicatorsMutation({
    update: (cache) => {
      const fields = ['indicator'];
      fields.forEach((field) => evictField(cache, field as keyof Query_Root));
    },
  });
  const handleCreateOpen = () => {
    setIsCreateOpen(true);
  };
  const handleCreateClose = () => {
    setIsCreateOpen(false);
  };

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      await deleteAction({
        variables: { ids: selected.map((s) => s.Id) },
      });
      setSelected([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    entityName: st('entity_name'),
  });

  const [mutate] = useInsertIndicatorMutation({
    update: (cache) => {
      evictField(cache, 'control');
      evictField(cache, 'risk');
      evictField(cache, 'indicator');
    },
  });

  const save = async (data: IndicatorFormDataFields) => {
    const { files, newFiles, ...rest } = data;
    const result = await mutate({
      variables: {
        ...rest,
        ParentId: parent.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
      },
    });
    const indicatorId = result.data?.insertChildIndicator?.Id;
    if (!indicatorId) {
      throw new Error('Missing indicator id');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Indicator,
      parentId: indicatorId,
      newFiles: newFiles,
      originalFiles: [],
      selectedFiles: files,
    });
  };

  const labelledFields = useMemo<IndicatorTableFields[]>(
    () =>
      data?.indicator.map((indicator) => ({
        ...indicator,
        LatestResultDate: indicator?.latestResults[0]?.ResultDate ?? null,
        LatestResultValue: latestResultValueFromData(indicator),
      })) || [],
    [data?.indicator]
  );

  const columnDefinitions = useIndicatorColumnDefinitions();

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:indicator" parentObject={parent}>
              <Button formAction="none" onClick={handleCreateOpen}>
                {st('create_new_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions[4],
        isDescending: true,
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteIndicators ? 'multi' : undefined}
        selectedItems={selected}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelected(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:indicator"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selected.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {st('delete_button')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:indicator"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleCreateOpen}
                    >
                      {st('create_new_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />

      {isCreateOpen && (
        <CreateIndicatorModal onSave={save} onDismiss={handleCreateClose} />
      )}

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
