
    import './styles.scoped.css';
    export default {
  "code-editor-refresh": "awsui_code-editor-refresh_1gl9c_6uado_158",
  "code-editor": "awsui_code-editor_1gl9c_6uado_158",
  "pane": "awsui_pane_1gl9c_6uado_492",
  "pane__close-container": "awsui_pane__close-container_1gl9c_6uado_503",
  "pane__list": "awsui_pane__list_1gl9c_6uado_508",
  "pane__table": "awsui_pane__table_1gl9c_6uado_515",
  "pane__item": "awsui_pane__item_1gl9c_6uado_521",
  "pane__cell": "awsui_pane__cell_1gl9c_6uado_521",
  "pane__item--highlighted": "awsui_pane__item--highlighted_1gl9c_6uado_535",
  "pane__location": "awsui_pane__location_1gl9c_6uado_556",
  "pane__description": "awsui_pane__description_1gl9c_6uado_556",
  "focus-lock": "awsui_focus-lock_1gl9c_6uado_571",
  "editor": "awsui_editor_1gl9c_6uado_615",
  "editor-refresh": "awsui_editor-refresh_1gl9c_6uado_643",
  "status-bar": "awsui_status-bar_1gl9c_6uado_648",
  "status-bar-with-hidden-pane": "awsui_status-bar-with-hidden-pane_1gl9c_6uado_657",
  "status-bar__left": "awsui_status-bar__left_1gl9c_6uado_661",
  "status-bar__right": "awsui_status-bar__right_1gl9c_6uado_668",
  "status-bar__language-mode": "awsui_status-bar__language-mode_1gl9c_6uado_672",
  "status-bar__cursor-position": "awsui_status-bar__cursor-position_1gl9c_6uado_672",
  "status-bar__cog-button": "awsui_status-bar__cog-button_1gl9c_6uado_678",
  "tab-list": "awsui_tab-list_1gl9c_6uado_683",
  "tab-button": "awsui_tab-button_1gl9c_6uado_688",
  "tab-button--refresh": "awsui_tab-button--refresh_1gl9c_6uado_721",
  "tab-button--warnings": "awsui_tab-button--warnings_1gl9c_6uado_724",
  "tab-button--active": "awsui_tab-button--active_1gl9c_6uado_733",
  "tab-button--disabled": "awsui_tab-button--disabled_1gl9c_6uado_749",
  "tab-button--divider": "awsui_tab-button--divider_1gl9c_6uado_782",
  "tab-button--errors": "awsui_tab-button--errors_1gl9c_6uado_789",
  "count": "awsui_count_1gl9c_6uado_798",
  "text": "awsui_text_1gl9c_6uado_801",
  "loading-screen": "awsui_loading-screen_1gl9c_6uado_823",
  "error-screen": "awsui_error-screen_1gl9c_6uado_824"
};
  