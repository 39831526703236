import type { UserOrGroup } from 'src/schemas/global';

import type {
  Contributor_Group_Insert_Input,
  Contributor_Insert_Input,
  Department_Insert_Input,
  Owner_Group_Insert_Input,
  Owner_Insert_Input,
  Tag_Insert_Input,
} from '@/generated/graphql';

export const tagInsertInputBuilder = (
  tagTypeIds: string[],
  parentId: string | undefined = undefined
): Tag_Insert_Input[] =>
  tagTypeIds.map((tagId) => ({
    TagTypeId: tagId,
    ParentId: parentId,
  }));

export const ownerAndContributorInsertFields = (
  data: {
    Owners: UserOrGroup[];
    Contributors: UserOrGroup[];
  },
  parentId: string | undefined = undefined
) => ({
  Contributors: contributorUserInsertInputBuilder(data.Contributors, parentId),
  ContributorGroups: contributorGroupInsertInputBuilder(
    data.Contributors,
    parentId
  ),
  Owners: ownerUserInsertInputBuilder(data.Owners, parentId),
  OwnerGroups: ownerGroupInsertInputBuilder(data.Owners, parentId),
});

export const ownerAndContributorIds = (data: {
  Owners: UserOrGroup[];
  Contributors: UserOrGroup[];
}) => ({
  ...contributorIds(data),
  OwnerUserIds: data.Owners.filter((c) => c.type === 'user').map(
    (c) => c.value
  ),
  OwnerGroupIds: data.Owners.filter((c) => c.type === 'userGroup').map(
    (c) => c.value
  ),
});

export const contributorIds = (data: { Contributors: UserOrGroup[] }) => ({
  ContributorUserIds: data.Contributors.filter((c) => c.type === 'user').map(
    (c) => c.value
  ),
  ContributorGroupIds: data.Contributors.filter(
    (c) => c.type === 'userGroup'
  ).map((c) => c.value),
});

export const ownerUpdateFields = (
  data: {
    Owners: UserOrGroup[];
  },
  parentId: string
) => ({
  owners: ownerUserInsertInputBuilder(data.Owners, parentId),
  ownerIds: data.Owners.filter((c) => c.type === 'user').map((c) => c.value),
  ownerGroupIds: data.Owners.filter((c) => c.type === 'userGroup').map(
    (c) => c.value
  ),
  ownerGroups: ownerGroupInsertInputBuilder(data.Owners, parentId),
});

export const contributorUpdateFields = (
  data: {
    Contributors: UserOrGroup[];
  },
  parentId: string
) => ({
  contributorIds: data.Contributors.filter((c) => c.type === 'user').map(
    (c) => c.value
  ),
  contributorGroupIds: data.Contributors.filter(
    (c) => c.type === 'userGroup'
  ).map((c) => c.value),
  contributors: contributorUserInsertInputBuilder(data.Contributors, parentId),
  contributorGroups: contributorGroupInsertInputBuilder(
    data.Contributors,
    parentId
  ),
});

const ownerUserInsertInputBuilder = (
  users: UserOrGroup[],
  parentId: string | undefined = undefined
): Owner_Insert_Input[] =>
  users
    .filter((u) => u.type === 'user')
    .map(({ value }) => ({
      UserId: value,
      ParentId: parentId,
    }));

const ownerGroupInsertInputBuilder = (
  users: UserOrGroup[],
  parentId: string | undefined = undefined
): Owner_Group_Insert_Input[] =>
  users
    .filter((u) => u.type === 'userGroup')
    .map(({ value }) => ({
      UserGroupId: value,
      ParentId: parentId,
    }));

const contributorUserInsertInputBuilder = (
  users: UserOrGroup[],
  parentId: string | undefined = undefined
): Contributor_Insert_Input[] =>
  users
    .filter((u) => u.type === 'user')
    .map(({ value }) => ({
      UserId: value,
      ParentId: parentId,
    }));

const contributorGroupInsertInputBuilder = (
  users: UserOrGroup[],
  parentId: string | undefined = undefined
): Contributor_Group_Insert_Input[] =>
  users
    .filter((u) => u.type === 'userGroup')
    .map(({ value }) => ({
      UserGroupId: value,
      ParentId: parentId,
    }));

export const departmentInsertInputBuilder = (
  departmentTypeIds: string[],
  parentId: string | undefined = undefined
): Department_Insert_Input[] =>
  departmentTypeIds.map((deptId) => ({
    DepartmentTypeId: deptId,
    ParentId: parentId,
  }));

export type Requiredish<T> = {
  [K in keyof Required<T>]: T[K];
};
