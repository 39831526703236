import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import Table from '@risksmart-app/components/Table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetChangeRequestsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useRequestsTableProps } from './config';
import { RequestsSummary } from './RequestsSummary';
import type { ChangeRequestRegisterFields } from './types';

const Page = () => {
  const { t } = useTranslation('common', { keyPrefix: 'requests' });
  const { user } = useRisksmartUser();
  const { data, loading } = useGetChangeRequestsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      currentUserId: user?.userId ?? '',
    },
    skip: !user?.userId,
  });
  const [selectedItems, setSelectedItems] = useState<
    ChangeRequestRegisterFields[]
  >([]);
  const tableProps = useRequestsTableProps(data);
  const counter = getCounter(tableProps.totalItemsCount, loading);

  useEffect(() => {
    tableProps.actions.setPropertyFiltering({
      tokens: [
        {
          propertyKey: 'RequiresAction',
          value: 'true',
          operator: '=',
        },
      ],
      operation: 'and',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <PageLayout
      helpTranslationKey="requests.registerHelp"
      title={t('register_title')}
      counter={counter}
    >
      <RequestsSummary
        items={tableProps.allItems}
        propertyFilterQuery={tableProps.propertyFilterQuery}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table
        {...tableProps}
        selectionType={'multi'}
        selectedItems={selectedItems}
        onSelectionChange={(e) => setSelectedItems(e.detail.selectedItems)}
        trackBy={'Id'}
      />
    </PageLayout>
  );
};

export default Page;
