import type { FC } from 'react';

import { Parent_Type_Enum, useGetAppetiteByIdQuery } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

type Props = {
  appetiteId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ appetiteId }) => {
  const { data } = useGetAppetiteByIdQuery({
    variables: {
      _eq: appetiteId!,
    },
    skip: !appetiteId,
  });

  return (
    <>
      {getFriendlyId(
        Parent_Type_Enum.Appetite,
        data?.appetite?.[0]?.SequentialId
      ) || ''}
    </>
  );
};

export default Breadcrumb;
