import {
  CreateInternalAuditResultPage,
  InternalAuditReportUpdateBreadcrumb,
  InternalAuditReportUpdatePage,
  UpdateInternalAuditResultPage,
} from '@pages';
import i18n from '@risksmart-app/components/providers/i18n';
import type { RouteObject } from 'react-router';

export const internalAuditReportsRoute: RouteObject = {
  path: ':assessmentId',
  handle: {
    breadcrumb: InternalAuditReportUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: <InternalAuditReportUpdatePage activeTabId="details" />,
    },
    {
      path: 'activities',
      handle: {
        title: () => i18n.format(i18n.t('activity_other'), 'capitalizeAll'),
      },
      children: [
        {
          path: '',
          element: (
            <InternalAuditReportUpdatePage
              activeTabId="activities"
              activityMode="list"
            />
          ),
        },
        {
          path: 'add',
          handle: {
            title: () => i18n.format(i18n.t('activity_one'), 'capitalizeAll'),
          },
          element: (
            <InternalAuditReportUpdatePage
              activeTabId="activities"
              activityMode="add"
            />
          ),
        },
        {
          path: ':activityId',
          handle: {
            title: () => i18n.format(i18n.t('activity_one'), 'capitalizeAll'),
          },
          element: (
            <InternalAuditReportUpdatePage
              activeTabId="activities"
              activityMode="update"
            />
          ),
        },
      ],
    },

    {
      path: 'findings',
      handle: {
        title: () => i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
      },
      children: [
        {
          path: '',
          element: <InternalAuditReportUpdatePage activeTabId="findings" />,
        },
        {
          path: ':findingId',
          handle: {
            title: () => i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
          },
          element: <UpdateInternalAuditResultPage />,
        },
        {
          path: 'add',
          element: <CreateInternalAuditResultPage />,
          handle: {
            title: () => i18n.t('assessmentResults.create_title'),
          },
        },
      ],
    },

    {
      path: 'linked-items',
      children: [
        {
          path: '',
          element: <InternalAuditReportUpdatePage activeTabId="linkedItems" />,
        },
      ],
    },
  ],
};
