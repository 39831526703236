import { z } from 'zod';

const RecallSchema = z.object({
  Reason: z.string().optional(),
  ShareWithRespondents: z.boolean().default(false),
});

export type RecallSchemaFields = z.infer<typeof RecallSchema>;

export default RecallSchema;
