import type { FC, ReactNode } from 'react';

import { useStorage } from '../state/useStorage';
import { NavMenuContext } from './useNavMenu';

type Props = {
  children: ReactNode;
};

export const NavMenuProvider: FC<Props> = ({ children }) => {
  const state = useStorage<boolean>(true, {
    localStorageKey: 'NavMenu-Preferences',
  });

  return (
    <NavMenuContext.Provider value={state}>{children}</NavMenuContext.Provider>
  );
};
