
    import './styles.scoped.css';
    export default {
  "placeholder": "awsui_placeholder_dwuol_1f9hy_145",
  "item": "awsui_item_dwuol_1f9hy_150",
  "checkbox": "awsui_checkbox_dwuol_1f9hy_154",
  "filter": "awsui_filter_dwuol_1f9hy_163",
  "trigger": "awsui_trigger_dwuol_1f9hy_168",
  "layout-strut": "awsui_layout-strut_dwuol_1f9hy_174",
  "list-bottom": "awsui_list-bottom_dwuol_1f9hy_180",
  "selected-icon": "awsui_selected-icon_dwuol_1f9hy_184",
  "show-label-tag": "awsui_show-label-tag_dwuol_1f9hy_188",
  "inline-token-trigger": "awsui_inline-token-trigger_dwuol_1f9hy_192",
  "inline-token-list": "awsui_inline-token-list_dwuol_1f9hy_199",
  "inline-token": "awsui_inline-token_dwuol_1f9hy_192",
  "visual-refresh": "awsui_visual-refresh_dwuol_1f9hy_229",
  "inline-token-hidden-placeholder": "awsui_inline-token-hidden-placeholder_dwuol_1f9hy_236",
  "inline-token-counter": "awsui_inline-token-counter_dwuol_1f9hy_242",
  "inline-token-trigger--disabled": "awsui_inline-token-trigger--disabled_dwuol_1f9hy_246",
  "inline-label-trigger-wrapper": "awsui_inline-label-trigger-wrapper_dwuol_1f9hy_252",
  "inline-label-wrapper": "awsui_inline-label-wrapper_dwuol_1f9hy_256",
  "inline-label": "awsui_inline-label_dwuol_1f9hy_252",
  "inline-label-disabled": "awsui_inline-label-disabled_dwuol_1f9hy_278",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_dwuol_1f9hy_286"
};
  