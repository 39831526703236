import type { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import type { SortingState } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import type {
  TypedPropertyFilterQuery,
  TypedPropertyFilterToken,
} from '@risksmart-app/components/Table/tableUtils';
import {
  BarChart01,
  BarChart10,
  Grid01,
  GridDotsOuter,
  PieChart02,
  PieChart03,
  Star01,
} from '@untitled-ui/icons-react';
import type { QUnitType } from 'dayjs';

import type { TablePreferences } from '@/utils/table/types';

import type { DashboardFilter } from '../../../context/DashboardFilter';
import type {
  AggregationType,
  DataSourceItem,
  WidgetDataSource,
} from '../Gigawidget/types';
import { UNRATED } from '../Gigawidget/types';
import { defaultClickthroughFilterWithUnrated } from './dataSourceHelpers';
import type { dataSources } from './dataSources';
import type { SettingsSchema } from './settingsSchema';

export type GigawidgetDataSources = typeof dataSources;
type ChartType =
  | 'bar'
  | 'stacked-bar'
  | 'pie'
  | 'donut'
  | 'table'
  | 'kpi'
  | 'placemat';

export type FilterSettings<
  T extends keyof GigawidgetDataSources = keyof GigawidgetDataSources,
> = {
  filtering?: TypedPropertyFilterQuery<
    DataSourceItem<GigawidgetDataSources[T]>
  >;
};

export type GigawidgetSettings<
  T extends keyof GigawidgetDataSources = keyof GigawidgetDataSources,
  K extends ChartType = ChartType,
> = FilterSettings<T> & {
  title: string;
  dataSource: T;
  chartType: K;
  precision?: 'day' | 'month' | 'year';
  categoryGetter:
    | GigawidgetDataSources[T]['categoryGetters'][number]['id']
    | `custom:${string}`;
  subCategoryGetter?:
    | GigawidgetDataSources[T]['categoryGetters'][number]['id']
    | `custom:${string}`;
  sorting?: SortingState<DataSourceItem<GigawidgetDataSources[T]>>;
  customTitle?: boolean;
  showFilters: boolean;
  ignoreDashboardDateFilter: boolean;
  aggregationType?: AggregationType;
  aggregationField?: keyof DataSourceItem<GigawidgetDataSources[T]>;
  preferences?: TablePreferences<DataSourceItem<GigawidgetDataSources[T]>>;
  customUnit?: boolean;
  unit: string;
};

export const dateFormats: Record<
  NonNullable<SettingsSchema['precision']>,
  string
> = {
  day: 'DD MMM',
  month: 'MMM YYYY',
  year: 'YYYY',
};

export const chartTypeLabels: Record<
  NonNullable<SettingsSchema['chartType']>,
  Pick<OptionDefinition, 'label' | 'iconSvg'>
> = {
  bar: {
    label: 'Bar Chart',
    iconSvg: <BarChart01 viewBox={'0 0 24 24'} width={24} height={24} />,
  },
  'stacked-bar': {
    label: 'Stacked Bar Chart',
    iconSvg: <BarChart10 viewBox={'0 0 24 24'} width={24} height={24} />,
  },
  pie: {
    label: 'Pie Chart',
    iconSvg: <PieChart03 viewBox={'0 0 24 24'} width={24} height={24} />,
  },
  donut: {
    label: 'Donut Chart',
    iconSvg: <PieChart02 viewBox={'0 0 24 24'} width={24} height={24} />,
  },
  table: {
    label: 'Table',
    iconSvg: <Grid01 viewBox={'0 0 24 24'} width={24} height={24} />,
  },
  kpi: {
    label: 'Tile',
    iconSvg: <Star01 viewBox={'0 0 24 24'} width={24} height={24} />,
  },
  placemat: {
    label: 'Placemat',
    iconSvg: <GridDotsOuter viewBox={'0 0 24 24'} width={24} height={24} />,
  },
};

export const datePrecisionLabels: Record<
  NonNullable<SettingsSchema['precision']>,
  string
> = {
  day: 'Day',
  month: 'Month',
  year: 'Year',
};

export const getString = (value: string | (() => string)) =>
  typeof value === 'string' ? value : value();

export const dashboardFilterToQuery = (
  filter: DashboardFilter,
  precision: QUnitType,
  dateFilter?: (
    dateRange: DashboardFilter['dateRange'],
    precision: QUnitType
  ) => PropertyFilterToken[],
  includeGlobalFilters: {
    departments?: boolean;
    tags?: boolean;
  } = {}
): TypedPropertyFilterQuery<{
  departments: unknown;
  tags: unknown;
  CreatedAtTimestamp: unknown;
}> => ({
  operation: 'and',
  tokens: [
    ...(includeGlobalFilters.tags
      ? filter.tags.map(
          (tag) =>
            ({
              operator: '=',
              propertyKey: 'tags',
              value: tag,
            }) as TypedPropertyFilterToken<{ tags: unknown }>
        )
      : []),
    ...(includeGlobalFilters.departments
      ? filter.departments.map(
          (department) =>
            ({
              operator: '=',
              propertyKey: 'departments',
              value: department,
            }) as TypedPropertyFilterToken<{ departments: unknown }>
        )
      : []),
    ...((dateFilter
      ? dateFilter(filter.dateRange, precision)
      : []) as unknown as TypedPropertyFilterToken<Record<string, unknown>>[]),
  ],
});

export const getCategoryGetter = <TDataSource extends WidgetDataSource>(
  id:
    | GigawidgetSettings['categoryGetter']
    | GigawidgetSettings['subCategoryGetter'],
  categoryGetters: TDataSource['categoryGetters']
): TDataSource['categoryGetters'][number] | null | undefined => {
  if (!id) {
    return null;
  }
  if (id.startsWith('custom:')) {
    const fieldId = id.split(':')[1];

    return {
      id: 'customAttributeGetter',
      name: () => 'Custom Attribute',
      categoryGetter: (item) =>
        item.CustomAttributeData?.[fieldId] ?? {
          key: UNRATED,
          label: UNRATED,
        },
      clickthroughFilter: defaultClickthroughFilterWithUnrated(fieldId),
    };
  }

  return categoryGetters.find((cg) => cg.id === id);
};
