import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteAssessmentActivitiesMutation,
  useGetAssessmentActivitiesByParentIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../../../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../../../useAssessmentTypeConfig';
import { useGetCollectionTableProps } from './config';
import type { AssessmentActivityFields } from './types';

const translationKeyPrefix = 'assessmentActivities';
type ItemType = AssessmentActivityFields;

interface Props {
  parent: ObjectWithContributors;
  assessmentMode: AssessmentTypeEnum;
}

const Tab: FC<Props> = ({ parent, assessmentMode }) => {
  const {
    routing: { activityAddUrl },
  } = useAssessmentTypeConfig(assessmentMode);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: translationKeyPrefix,
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const assessmentId = useGetGuidParam('assessmentId');
  const { addNotification } = useNotifications();
  const [selectedActivities, setSelectedActivities] = useState<ItemType[]>([]);
  const canDeleteAssessmentActivities = useHasPermission(
    `delete:assessment_activity`,
    parent
  );
  const { data, loading } = useGetAssessmentActivitiesByParentIdQuery({
    variables: {
      AssessmentId: assessmentId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [deleteAcceptances, deleteResult] =
    useDeleteAssessmentActivitiesMutation({
      update: (cache) => {
        evictField(cache, 'assessment_activity');
      },
    });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteAcceptances({
        variables: { Ids: selectedActivities.map((s) => s.Id) },
      });
      setSelectedActivities([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const tableProps = useGetCollectionTableProps(
    assessmentMode,
    data?.assessment_activity,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <>
      <Table
        {...tableProps}
        selectionType={canDeleteAssessmentActivities ? 'multi' : undefined}
        selectedItems={selectedActivities}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedActivities(detail.selectedItems);
        }}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:assessment_activity"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedActivities.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:assessment_activity"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      href={activityAddUrl(assessmentId)}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
