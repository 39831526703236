import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';
import type { IssueUpdatesFields } from 'src/schemas/issueUpdates';
import { defaultValues, IssueUpdatesSchema } from 'src/schemas/issueUpdates';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  Parent_Type_Enum,
  useGetIssueUpdateByIdQuery,
  useInsertIssueUpdateMutation,
  useUpdateIssueUpdateMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import IssueUpdateForm from './forms/IssueUpdateForm';

type Props = {
  onDismiss: () => void;
  issueUpdateId?: string;
  parent: ObjectWithContributors;
};

const IssueUpdateModal: FC<Props> = ({ onDismiss, issueUpdateId, parent }) => {
  const issueId = useGetGuidParam('issueId');

  const { updateFiles } = useFileUpdate();
  const [insertIssueUpdate] = useInsertIssueUpdateMutation({
    update: (cache) => evictField(cache, 'issue_update'),
  });
  const canCreateIssueUpdate = useHasPermission('insert:issue_update', parent);
  const canEditIssueUpdate = useHasPermission('update:issue_update', parent);
  const [updateIssueUpdate] = useUpdateIssueUpdateMutation({
    update: (cache) => evictField(cache, 'issue_update'),
  });
  const { t } = useTranslation('common');
  const { data, loading, error } = useGetIssueUpdateByIdQuery({
    variables: {
      _eq: issueUpdateId!,
    },
    skip: !issueUpdateId,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const issueUpdate = data?.issue_update[0];
  const canModify = issueUpdate ? canEditIssueUpdate : canCreateIssueUpdate;
  const onSave = async (data: IssueUpdatesFields) => {
    const { newFiles, files } = data;
    if (issueUpdate) {
      const result = await updateIssueUpdate({
        variables: {
          ...data,
          CustomAttributeData: data.CustomAttributeData,
          ParentIssueId: issueId,
          Id: issueUpdate.Id,
          OriginalTimestamp: issueUpdate?.ModifiedAtTimestamp,
        },
      });
      if (result.data?.update_issue_update?.affected_rows !== 1) {
        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      const result = await insertIssueUpdate({
        variables: {
          ...data,
          CustomAttributeData: data.CustomAttributeData,
          ParentIssueId: issueId,
        },
      });
      issueUpdateId = result.data?.insert_issue_update_one?.Id;
    }

    if (!issueUpdateId) {
      throw new Error('Missing result');
    }

    await updateFiles({
      parentType: Parent_Type_Enum.IssueUpdate,
      parentId: issueUpdateId,
      newFiles: newFiles,
      originalFiles: issueUpdate?.files,
      selectedFiles: files,
    });
  };

  if (loading) {
    return null;
  }

  return (
    <ModalForm<IssueUpdatesFields>
      i18n={t('actionUpdates')}
      values={issueUpdate}
      defaultValues={defaultValues}
      schema={IssueUpdatesSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      formId={'issue-update-form'}
      visible={true}
      readOnly={!canModify}
      parentType={Parent_Type_Enum.IssueUpdate}
    >
      <IssueUpdateForm readOnly={!canModify} />
    </ModalForm>
  );
};

export default IssueUpdateModal;
