import Input from '@cloudscape-design/components/input';
import type { InputProps } from '@cloudscape-design/components/input/interfaces';
import type { ControlProps } from '@jsonforms/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  CustomSchema,
  CustomUISchemaElement,
  FieldType,
} from '../../FormBuilder/useFormBuilderContext';
import CustomisableControl from './CustomisableControl';
import { getFieldLabel } from './utils';

type TextControlProps = ControlProps & {
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
};

type TextFieldTypes = Extract<FieldType, 'text' | 'number' | 'url'>;

export const TextControlUnwrapped: FC<TextControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  id,
  path,
  config,
}) => {
  const [_, setIsFieldDirty] = useState(false);
  const [label, setLabel] = useState<string>(uischema?.label || '');
  const fieldType: TextFieldTypes = uischema?.options?.fieldType || 'text';

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  const defaultPlaceholders = {
    text: t('text'),
    number: t('number'),
    url: t('url'),
  };

  const inputMode: Record<TextFieldTypes, InputProps.InputMode> = {
    text: 'text',
    number: 'numeric',
    url: 'url',
  };

  useEffect(() => {
    setLabel(getFieldLabel(uischema?.label, schema?.minLength));
  }, [uischema, schema]);

  return (
    <CustomisableControl
      label={label}
      uischema={uischema}
      errors={errors}
      schema={schema}
    >
      <Input
        {...{ className: 'grow' }}
        type={fieldType || 'text'}
        inputMode={inputMode[fieldType] || 'text'}
        value={data || ''}
        onChange={(event) => {
          setIsFieldDirty(true);
          handleChange(path, event.detail.value);
        }}
        onBlur={() => setIsFieldDirty(true)}
        name={id}
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={
          appliedUiSchemaOptions?.placeholder ||
          (fieldType && defaultPlaceholders[fieldType]) ||
          defaultPlaceholders['text']
        }
      />
    </CustomisableControl>
  );
};
