import type { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import type { FC } from 'react';
import { NavLink, useLocation, useMatches } from 'react-router';

import type { CountRenderer } from './Count';

export interface LinkProps {
  item: (
    | SideNavigationProps.Link
    | SideNavigationProps.LinkGroup
    | SideNavigationProps.ExpandableLinkGroup
  ) & {
    icon?: JSX.Element;
    count?: string;
  };
  renderCount?: CountRenderer;
}

import { useTools } from '../Tools/useTools';

const Link: FC<LinkProps> = ({ item, renderCount }) => {
  const matches = useMatches();
  const location = useLocation();
  const hasMatch =
    matches
      .map((match) => match.pathname)
      .filter((match) => {
        if (location.pathname === '/' && match === '/') {
          return true;
        }
        if (match !== '/') {
          return true;
        }
      })
      .filter((path) => path === item.href).length > 0;

  const [toolsContent, setToolsContent] = useTools();

  const onClick =
    item.href === '#notifications'
      ? () => {
          setToolsContent(
            toolsContent === 'notifications' ? undefined : 'notifications'
          );

          return false;
        }
      : undefined;

  return (
    <li className="list-none px-4 py-1">
      <NavLink
        to={item.href || '#'}
        onClick={onClick}
        className={({ isActive }) =>
          `transition duration-200 group no-underline px-[10px] py-3 rounded-md flex items-center space-x-4 hover:bg-navy_light ${
            (isActive && item.href !== '#notifications') ||
            (item.href === '#notifications' && toolsContent === 'notifications')
              ? 'text-navy bg-teal hover:text-navy hover:bg-teal'
              : 'text-white'
          }`
        }
        // eslint-disable-next-line react/no-children-prop
        children={({ isActive }) => (
          <>
            {item.icon && (
              <span
                className={`w-[24px] h-[24px] relative ${
                  (isActive && item.href !== '#notifications') ||
                  (item.href === '#notifications' &&
                    toolsContent === 'notifications')
                    ? 'text-navy'
                    : 'text-teal'
                }`}
              >
                {item.icon}
                {item.count === 'notification' &&
                  renderCount?.({
                    isActive: hasMatch,
                    countName: item.count,
                    badge: true,
                  })}
              </span>
            )}
            <span className="grow">{item.text}</span>
            {item.count &&
              renderCount?.({
                isActive: hasMatch,
                countName: item.count,
              })}
          </>
        )}
      />
    </li>
  );
};

export default Link;
