import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import { UNRATED } from 'src/pages/controls/lookupData';

import {
  Risk_Assessment_Result_Control_Type_Enum,
  useGetInternalAuditReportRiskAssessmentResultsByRiskIdLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useInternalAuditsExportTable = (
  riskId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getStatusLabel } = useRating('assessment_status');
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const { getLabel: getImpactLabel } = useRating('impact');
  const { getLabel: getUncontrolledLabel } = useRating('risk_uncontrolled');
  const { getLabel: getControlledLabel } = useRating('risk_controlled');
  const [getAssessments, getAssessmentsResult] =
    useGetInternalAuditReportRiskAssessmentResultsByRiskIdLazyQuery({
      variables: {
        RiskId: riskId,
      },
    });

  const { t: assessmentsColumns } = useTranslation(['common']);
  const createExportTable = async () => {
    const { data: assessmentsData } = await getAssessments();
    const assessmentsTableData = (
      assessmentsData?.risk_assessment_result ?? []
    ).map((au) => [
      au.parents?.[0]?.internalAuditReport?.Title ?? '-',
      getStatusLabel(au.parents?.[0]?.internalAuditReport?.Status) ?? '-',
      au.ControlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
        ? getControlledLabel(au.Rating)
        : (getUncontrolledLabel(au.Rating) ?? UNRATED.label),
      getImpactLabel(au.Impact ?? UNRATED.value),
      getLikelihoodLabel(au.Likelihood ?? UNRATED.value),
      toLocalDate(au.parents?.[0]?.internalAuditReport?.StartDate) ?? '-',
      toLocalDate(au.parents?.[0]?.internalAuditReport?.ActualCompletionDate) ??
        '-',
      au.parents?.[0]?.internalAuditReport?.completedByUser?.FriendlyName ??
        '-',
    ]);

    return createTable({
      widths: ['*', 50, 50, 50, 50, 50, 70, 70],
      body: [
        tableHeaders([
          assessmentsColumns('assessments.columns.Title'),
          assessmentsColumns('assessments.columns.Status'),
          assessmentsColumns('assessmentResults.fields.Rating'),
          assessmentsColumns('assessmentResults.fields.Impact'),
          assessmentsColumns('assessmentResults.fields.Likelihood'),
          assessmentsColumns('assessments.columns.StartDate'),
          assessmentsColumns('assessments.columns.CompletionDate'),
          assessmentsColumns('assessments.columns.CompletionBy'),
        ]),
        ...assessmentsTableData,
      ],
    });
  };

  return [createExportTable, getAssessmentsResult.loading];
};

export default useInternalAuditsExportTable;
