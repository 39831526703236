import FormField from '@cloudscape-design/components/form-field';
import type {
  HorizontalLayout,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  VerticalLayout,
} from '@jsonforms/core';
import type { FC } from 'react';
import { useState } from 'react';

import {
  Header,
  SpaceBetween,
} from '../../../theme/risksmart-themed/components';
import type { SectionData } from '../../FormBuilder/form-configs/section';
import { FormBuilderAddQuestion } from '../../FormBuilder/FormBuilderAddQuestion';
import { FormSectionModal } from '../../FormBuilder/FormSectionModal';
import type { CustomUISchemaElement } from '../../FormBuilder/useFormBuilderContext';
import { useFormBuilderContext } from '../../FormBuilder/useFormBuilderContext';
import FormEditButton from '../../FormEditButton/FormEditButton';
import { renderChildren } from './utils';

type GroupLayoutRendererProps = {
  layout: VerticalLayout | HorizontalLayout;
  schema: JsonSchema;
  uischema: CustomUISchemaElement;
  path: string;
  renderers: JsonFormsRendererRegistryEntry[];
  cells: JsonFormsCellRendererRegistryEntry[] | undefined;
  enabled: boolean;
};

export const GroupLayoutRenderer: FC<GroupLayoutRendererProps> = ({
  schema,
  uischema, // uischema is only a partial ui schema for this specific group
  path,
  renderers,
  cells,
  enabled,
}) => {
  const label = uischema?.label ? uischema.label : '';
  const initialSectionData = {
    sectionTitle: label,
  };

  const [isEditingSection, setIsEditingSection] = useState(false);
  const [sectionData, setSectionData] =
    useState<SectionData>(initialSectionData);

  const { isFormCustomisable } = useFormBuilderContext();

  const conditionalStyling = isFormCustomisable
    ? 'border border-solid border-grey px-4 py-3'
    : '';

  return (
    <div className={'w-full mb-5'}>
      <div className={'w-full justify-start'}>
        <FormField stretch={false}>
          <div
            className={
              'flex flex-col grow md:basis-2/3 rounded-md gap-y-3 ' +
              conditionalStyling
            }
          >
            <div className={'flex items-start'}>
              <SpaceBetween direction={'horizontal'} size={'xs'}>
                <Header variant={'h2'}>{label}</Header>
                {isFormCustomisable ? (
                  <FormEditButton onClick={() => setIsEditingSection(true)} />
                ) : null}
              </SpaceBetween>
            </div>

            {renderChildren({
              layout: uischema,
              schema,
              path,
              renderers,
              cells,
              enabled,
            })}

            {isFormCustomisable ? (
              <>
                <FormBuilderAddQuestion parentId={uischema.id || ''} />

                <FormSectionModal
                  action={'edit'}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  isEditingSection={isEditingSection}
                  setIsEditingSection={setIsEditingSection}
                  initialSectionData={initialSectionData}
                  uiSchema={uischema}
                />
              </>
            ) : null}
          </div>
        </FormField>
      </div>
    </div>
  );
};
