import type { ApolloCache } from '@apollo/client';
import { isApolloError } from '@apollo/client';

import type { Query_Root } from '@/generated/graphql';

export const evictField = <T>(
  cache: ApolloCache<T>,
  fieldName: keyof Query_Root
) => {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: fieldName,
  });
  cache.gc();
};

export const isPermissionError = (e: unknown) => {
  return (
    e instanceof Error &&
    isApolloError(e) &&
    e.graphQLErrors.length > 0 &&
    e.graphQLErrors[0].extensions?.code === 'permission-error'
  );
};

export enum HasuraErrorCodes {
  PermissionError = 'permission-error',
  UnexpectedError = 'unexpected',
  ValidationFailed = 'validation-failed',
  ConstraintError = 'constraint-violation',
}
