import type { FC } from 'react';

import { useGetDocumentByIdQuery } from '@/generated/graphql';

type Props = {
  documentId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ documentId }) => {
  const { data } = useGetDocumentByIdQuery({
    variables: {
      id: documentId!,
    },
    skip: !documentId,
  });

  return <>{data?.document?.[0]?.Title}</>;
};

export default Breadcrumb;
