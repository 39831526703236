import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledGroupAndUserMultiSelect from '@/components/Form/ControlledGroupAndUserMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import Editor from '@/components/Form/Editor/Editor';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';

import type { ImpactFormFieldData } from './impactFormSchema';
import styles from './style.module.scss';

type Props = { readOnly?: boolean };

const ImpactFormFields: FC<Props> = (props) => {
  const { control } = useFormContext<ImpactFormFieldData>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impacts',
  });
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.form}>
      <CustomisableForm readOnly={props.readOnly}>
        <ControlledInput
          key="name"
          forceRequired={true}
          testId="Name"
          name="Name"
          label={st('fields.Name')}
          description={st('fields.Name_help')}
          control={control}
          placeholder={st('placeholders.Name')}
          disabled={props.readOnly}
        />

        <ControlledTextarea
          key="rationale"
          name="Rationale"
          label={st('fields.Rationale')}
          description={st('fields.Rationale_help')}
          placeholder={st('placeholders.Rationale') ?? ''}
          control={control}
          disabled={props.readOnly}
        />

        <Editor
          key="ratingGuidance"
          name="RatingGuidance"
          label={st('fields.RatingGuidance')}
          description={st('fields.RatingGuidance_help')}
          control={control}
          disabled={props.readOnly}
          height={240}
        />

        <ControlledGroupAndUserMultiSelect
          key="owners"
          control={control}
          label={t('fields.Owner')}
          includeGroups={true}
          description={st('fields.Owner_help')}
          name="Owners"
          placeholder={t('fields.Owner_placeholder')}
          disabled={props.readOnly}
        />
        <ControlledRating
          key="likelihoodAppetite"
          control={control}
          name="LikelihoodAppetite"
          type="likelihood_appetite"
          label={st('fields.LikelihoodAppetite')}
          description={st('fields.LikelihoodAppetite_help')}
          disabled={props.readOnly}
          showValue={true}
        />
      </CustomisableForm>
    </div>
  );
};

export default ImpactFormFields;
