import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { Editor as TinyEditor } from 'tinymce';

import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { PageForm } from '@/components/Form/Form/PageForm';
import {
  Document_File_Type_Enum,
  namedOperations,
  Parent_Type_Enum,
  useGetDocumentByIdQuery,
  useGetDocumentFileByIdQuery,
  useInsertDocumentVersionMutation,
  useUpdateDocumentVersionMutation,
  Version_Status_Enum,
} from '@/generated/graphql';
import { useChangeRequests } from '@/hooks/useChangeRequests';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';

import { useFileUpload } from '../../../../../../../../data/rest/useFileUpload';
import { useHasPermission } from '../../../../../../../../rbac/useHasPermission';
import type { DocumentVersionFormFieldData } from '../../../forms/documentFileSchema';
import {
  defaultValues,
  DocumentFileFormSchema,
} from '../../../forms/documentFileSchema';
import DocumentVersionForm from '../../../forms/DocumentVersionForm';
import DocumentVersionFormFields from '../../../forms/DocumentVersionFormFields';
import { useNextFileVersion } from '../../../useNextFileVersion';

type TabProps = {
  parentDocumentId: string;
  documentFileId: string;
};

const Tab = ({ parentDocumentId, documentFileId }: TabProps) => {
  const approversEnabled = useIsFeatureVisibleToOrg('approvers');
  const { t } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.confirm_close_modal',
  });
  const { t: st } = useTranslation(['common']);
  const editorRef = useRef<TinyEditor | null>(null);
  const uploadFile = useFileUpload();
  const [confirmCloseVisible, setConfirmCloseVisible] = useState(false);
  const [insert] = useInsertDocumentVersionMutation({
    update: (cache) => {
      evictField(cache, 'document');
    },
  });
  const [update] = useUpdateDocumentVersionMutation({
    update: (cache) => {
      evictField(cache, 'document');
      evictField(cache, 'change_request');
    },
  });
  const { data: parentData } = useGetDocumentByIdQuery({
    variables: {
      id: parentDocumentId,
    },
  });
  const parent = parentData?.document[0];

  const [
    { nextVersion, content, type, link, customAttributeData },
    { loading: loadingNextFileVersion },
  ] = useNextFileVersion(parentDocumentId);
  const navigate = useNavigate();
  const canEditPermission = useHasPermission('update:document_file', parent);
  const canCreatePermission = useHasPermission('insert:document_file', parent);

  const {
    data: documentFileData,
    loading,
    error,
  } = useGetDocumentFileByIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: documentFileId!,
    },
    skip: !documentFileId,
  });
  if (error) {
    throw error;
  }

  const documentFile = documentFileData?.document_file[0];

  const canModify = documentFile ? canEditPermission : canCreatePermission;
  const isDocumentFileDraft = documentFile?.Status
    ? documentFile?.Status === Version_Status_Enum.Draft
    : true;
  const onSave = async (data: DocumentVersionFormFieldData) => {
    let content: string | null = null;
    let link: string | null = null;
    let fileId: string | null = null;
    switch (data.Type) {
      case Document_File_Type_Enum.Link:
        link = data.Link;
        break;
      case Document_File_Type_Enum.Html:
        content = editorRef.current?.getContent() ?? null;
        break;
      case Document_File_Type_Enum.File:
        if (data.newFiles.length === 1) {
          const uploadResponse = await uploadFile(
            Parent_Type_Enum.Document,
            [parentDocumentId],
            data.newFiles
          );
          fileId = uploadResponse.fileIds[0];
        } else if (documentFile?.FileId) {
          fileId = documentFile?.FileId;
        }
        break;
    }
    if (documentFile) {
      await update({
        variables: {
          ...data,
          FileId: fileId,
          Id: documentFile.Id,
          Content: content,
          Link: link,
          LatestModifiedAtTimestamp: documentFile.ModifiedAtTimestamp,
          ReviewedBy: data.ReviewedBy?.value,
        },
        refetchQueries: [namedOperations.Query.getDocumentFileById],
        context: {
          headers: {
            'x-confirm-change-request': 'true',
          },
        },
      });
    } else {
      const { Status: _, ...dataToInsert } = data;
      await insert({
        variables: {
          ...dataToInsert,
          FileId: fileId,
          ParentDocumentId: parentDocumentId,
          Content: content,
          Link: link,
          ReviewedBy: dataToInsert.ReviewedBy?.value,
        },
        refetchQueries: [namedOperations.Query.getDocumentFileById],
      });
    }
  };

  const approvalData = documentFile
    ? {
        ...documentFile,
        owners: documentFile.parent?.owners ?? [],
        ownerGroups: documentFile.parent?.ownerGroups ?? [],
      }
    : undefined;

  const { pendingChangeRequests } = useChangeRequests(approvalData);

  const values: DocumentVersionFormFieldData | undefined = documentFile
    ? ({
        ...defaultValues,
        ...documentFile,
        files: documentFile.file ? [{ file: documentFile.file }] : [],
        ReviewedBy: documentFile.ReviewedBy
          ? { value: documentFile.ReviewedBy, type: 'user' }
          : null,
      } as DocumentVersionFormFieldData)
    : undefined;
  const defaultValuesWithVersion: DocumentVersionFormFieldData = {
    ...defaultValues,
    Version: nextVersion,
    Content: content,
    Type: type,
    Link: link,
    CustomAttributeData: customAttributeData,
  } as DocumentVersionFormFieldData;
  const readOnly = !canModify;

  if (loading || loadingNextFileVersion) {
    return null;
  }

  const onDismiss = () => {
    navigate(`/policy/${parentDocumentId}/files`);
  };

  return approversEnabled && documentFile?.Id ? (
    <>
      <DocumentVersionForm
        // Disable status field as we have buttons which allow correct status transitions
        disableStatus={true}
        defaultValues={defaultValuesWithVersion}
        values={values}
        onSave={onSave}
        onDismiss={(saved) => {
          if (saved || readOnly || !isDocumentFileDraft) {
            onDismiss();
          }
          setConfirmCloseVisible(true);
        }}
        readOnly={readOnly}
        approvalConfig={{
          object: approvalData,
        }}
        hasPendingChangeRequests={pendingChangeRequests.length >= 1}
        isCreatingNewEntity={!!documentFile}
        editorRef={editorRef}
        readonly={readOnly}
        savedStatus={documentFile?.Status}
        parentId={parentDocumentId}
      />

      {confirmCloseVisible && (
        <ConfirmModal
          isVisible={true}
          onConfirm={onDismiss}
          onDismiss={() => setConfirmCloseVisible(false)}
          header={t('title')}
        >
          {t('message')}
        </ConfirmModal>
      )}
    </>
  ) : (
    <>
      <PageForm
        // TODO: Add render template?
        header={st('details')}
        i18n={st('documentFiles')}
        defaultValues={defaultValuesWithVersion}
        parentType={Parent_Type_Enum.DocumentFile}
        values={values}
        schema={DocumentFileFormSchema}
        onSave={onSave}
        onDismiss={(saved) => {
          if (saved || readOnly) {
            onDismiss();
          }
          setConfirmCloseVisible(true);
        }}
        formId={'document-file-form'}
        readOnly={readOnly}
      >
        <DocumentVersionFormFields
          disableStatus={false}
          isCreatingNewEntity={!!documentFile}
          editorRef={editorRef}
          readonly={readOnly}
          savedStatus={documentFile?.Status || Version_Status_Enum.Draft}
          parentId={parentDocumentId}
        />
      </PageForm>
      <ConfirmModal
        isVisible={confirmCloseVisible}
        onConfirm={onDismiss}
        onDismiss={() => setConfirmCloseVisible(false)}
        header={t('title')}
      >
        {t('message')}
      </ConfirmModal>
    </>
  );
};

export default Tab;
