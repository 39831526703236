import { merge } from 'ts-deepmerge';

import { emptyFilterQuery } from '@/utils/table/types';

import { shouldDisableClickThrough } from '../../../context/shouldDisableClickThrough';
import { useDashboardFilter } from '../../../context/useDashboardFilter';
import { Gigawidget } from '../Gigawidget';
import type {
  Category,
  CategoryType,
  DataSourceItem,
  DateFilterOptions,
  GigawidgetCommonProps,
  WidgetDataSource,
} from '../Gigawidget/types';
import type { GigawidgetSettings } from './util';
import { dashboardFilterToQuery, dateFormats, getCategoryGetter } from './util';

type Props<TDataSource extends WidgetDataSource> = {
  dataSource: TDataSource;
  settings: GigawidgetSettings;
};

export const UniversalChart = <TDataSource extends WidgetDataSource>({
  dataSource,
  settings,
}: Props<TDataSource>) => {
  const { filters } = useDashboardFilter();
  const datePrecision = settings.precision ?? 'month';

  const categoryConfig = getCategoryGetter(
    settings.categoryGetter,
    dataSource.categoryGetters
  );

  const subCategoryConfig = getCategoryGetter(
    settings.subCategoryGetter,
    dataSource.categoryGetters
  );

  const dateFilterOptions: DateFilterOptions<TDataSource> = {
    dateFilter: categoryConfig?.date
      ? categoryConfig.dateFilter
      : dataSource.dashboardFilterConfig.dateFilter,
    precision: categoryConfig?.date ? datePrecision : 'day',
    dateFormat: dateFormats[datePrecision],
  };

  const clickThroughUrl = (
    item: Category<DataSourceItem<TDataSource>, CategoryType>
  ) => {
    if (shouldDisableClickThrough(filters)) {
      // Registers use "AND" filtering on departments and tags, whilst the dashboard used "OR" filtering.
      // To avoid the mismatch, disable click through when more than 1 department or tag is selected.
      return undefined;
    }

    return (
      dataSource.clickThroughUrl?.(
        merge(
          settings.filtering ?? emptyFilterQuery,

          dashboardFilterToQuery(
            filters,
            datePrecision,
            !categoryConfig?.date
              ? dataSource.dashboardFilterConfig.dateClickthroughFilter
              : undefined,
            {
              departments: !!dataSource.dashboardFilterConfig.departmentsFilter,
              tags: !!dataSource.dashboardFilterConfig.tagsFilter,
            }
          ),
          {
            tokens:
              categoryConfig?.clickthroughFilter?.(item, datePrecision) ?? [],
          }
        )
      ) ?? ''
    );
  };

  const onClickNumberHandler = () => {
    if (shouldDisableClickThrough(filters)) {
      // Registers use "AND" filtering on departments and tags, whilst the dashboard used "OR" filtering.
      // To avoid the mismatch, disable click through when more then 1 department or tag is selected.
      return undefined;
    }

    return (
      dataSource.clickThroughUrl?.(
        merge(
          settings.filtering ?? emptyFilterQuery,
          dashboardFilterToQuery(
            filters,
            'day',
            settings.ignoreDashboardDateFilter
              ? undefined
              : dataSource.dashboardFilterConfig.dateClickthroughFilter,
            {
              departments: !!dataSource.dashboardFilterConfig.departmentsFilter,
              tags: !!dataSource.dashboardFilterConfig.tagsFilter,
            }
          )
        ),
        settings.sorting ?? undefined
      ) ?? ''
    );
  };

  const commonProps: GigawidgetCommonProps<TDataSource> = {
    dataSource,
    dateFilterOptions,
    propertyFilterQuery: settings.filtering ?? emptyFilterQuery,
  };

  //categorical charts
  if (categoryConfig) {
    switch (settings.chartType) {
      case 'pie':
      case 'donut':
        return (
          <Gigawidget
            {...commonProps}
            type={settings.chartType}
            aggregationType={settings.aggregationType}
            aggregationField={settings.aggregationField}
            categoryGetter={categoryConfig.categoryGetter}
            categoryRatingTranslationKey={categoryConfig.ratingColourKey}
            categoryOverrideFunction={categoryConfig.categoryOverrideFunction}
            onClickUrl={clickThroughUrl}
          />
        );
      case 'bar':
      case 'stacked-bar':
        return (
          <Gigawidget
            {...commonProps}
            aggregationType={settings.aggregationType}
            aggregationField={settings.aggregationField}
            type={settings.chartType}
            categoryGetter={categoryConfig.categoryGetter}
            categoryRatingTranslationKey={categoryConfig.ratingColourKey}
            categoryOverrideFunction={categoryConfig.categoryOverrideFunction}
            subCategoryGetter={subCategoryConfig?.categoryGetter}
            subCategoryRatingTranslationKey={subCategoryConfig?.ratingColourKey}
            subCategoryOverrideFunction={
              subCategoryConfig?.categoryOverrideFunction
            }
            orientation={'vertical'}
            onClickUrl={clickThroughUrl}
          />
        );
    }
  }

  // non-categorical charts
  switch (settings.chartType) {
    case 'table':
      return (
        <Gigawidget
          {...commonProps}
          type={'table'}
          aggregationField={settings.aggregationField}
          aggregationType={settings.aggregationType}
        />
      );
    case 'kpi':
      if (!settings.aggregationType) {
        return null;
      }

      return (
        <Gigawidget
          {...commonProps}
          type={'kpi'}
          onClickUrl={onClickNumberHandler}
          aggregationField={settings.aggregationField}
          aggregationType={settings.aggregationType}
          unit={settings.customUnit ? settings.unit : undefined}
        />
      );
    case 'placemat':
      return <Gigawidget {...commonProps} type={'placemat'} />;
  }
};
