import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import type { IndicatorResultFormFields } from 'src/pages/indicators/forms/indicatorResultSchema';
import {
  defaultValues,
  indicatorResultSchema,
} from 'src/pages/indicators/forms/indicatorResultSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import type { Indicator_Type_Enum } from '@/generated/graphql';
import {
  Parent_Type_Enum,
  useGetIndicatorResultByIdQuery,
  useInsertIndicatorResultMutation,
  useUpdateIndicatorResultMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import IndicatorResultsForm from '../forms/IndicatorResultsForm';

type Props = {
  onDismiss: () => void;
  parentIndicatorId: string;
  parentIndicatorType: Indicator_Type_Enum;
  Id?: string;
  indicator: ObjectWithContributors;
};

const IndicatorResultModel: FC<Props> = ({
  onDismiss,
  parentIndicatorId,
  Id,
  parentIndicatorType,
  indicator,
}) => {
  const { t } = useTranslation('common');
  const { updateFiles } = useFileUpdate();
  const [insert] = useInsertIndicatorResultMutation({
    update: (cache) => {
      evictField(cache, 'indicator_result');
      evictField(cache, 'indicator');
    },
  });
  const [update] = useUpdateIndicatorResultMutation({
    update: (cache) => {
      evictField(cache, 'indicator_result');
      evictField(cache, 'indicator');
    },
  });
  const { data, loading, error } = useGetIndicatorResultByIdQuery({
    variables: { id: Id! },
    skip: !Id,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const userCanEdit = useHasPermission('update:indicator_result', indicator);
  const userCanCreate = useHasPermission('insert:indicator_result', indicator);
  const indicatorResult = data?.indicator_result[0];
  const userCanModify = indicatorResult ? userCanEdit : userCanCreate;

  const onSave = async (data: IndicatorResultFormFields) => {
    const { files, newFiles, ...rest } = data;
    let indicatorResultId: string | undefined = indicatorResult?.Id;
    if (indicatorResult) {
      await update({
        variables: {
          ...rest,
          id: indicatorResult.Id,
          CustomAttributeData: data.CustomAttributeData || undefined,
        },
      });
    } else {
      const result = await insert({
        variables: {
          ...rest,
          IndicatorId: parentIndicatorId,
          CustomAttributeData: data.CustomAttributeData || undefined,
        },
      });
      indicatorResultId = result.data?.insert_indicator_result_one?.Id;
    }
    if (!indicatorResultId) {
      throw new Error('indicatorResultId is missing');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.IndicatorResult,
      parentId: indicatorResultId,
      newFiles: newFiles,
      originalFiles: indicatorResult?.files ?? [],
      selectedFiles: files,
    });
  };

  if (loading) {
    return null;
  }
  const formId = 'indicator-result-form';

  return (
    <ModalForm
      i18n={t('indicator_results')}
      values={indicatorResult}
      defaultValues={defaultValues}
      schema={indicatorResultSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      formId={formId}
      visible={true}
      readOnly={!userCanModify}
      parentType={Parent_Type_Enum.IndicatorResult}
    >
      <IndicatorResultsForm
        indicatorType={parentIndicatorType}
        readOnly={!userCanModify}
      />
    </ModalForm>
  );
};

export default IndicatorResultModel;
