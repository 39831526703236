import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';
import type { RecursivePartial } from 'src/testing/stub';

import type { Document_Assessment_Result } from '@/generated/graphql';
import { Approval_Status_Enum, Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';

import type { DocumentFields, PolicyRegisterFields } from './types';

export const useLabelledFields = (
  records: DocumentFields[] | undefined,
  documentAssessmentResults:
    | Array<RecursivePartial<Document_Assessment_Result> | undefined | null>
    | undefined
): PolicyRegisterFields[] | undefined => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy' });
  const { t: tc } = useTranslation(['common']);
  const types = useMemo(() => st('types', { returnObjects: true }), [st]);
  const { getLabel: getPerformanceResultLabel } =
    useRating('performance_result');
  const { getLabel: getDocumentFileStatusLabel } = useRating(
    'document_file_status'
  );
  const frequency = useMemo(() => tc('frequency'), [tc]);

  return useMemo<PolicyRegisterFields[] | undefined>(() => {
    return records?.map((d) => {
      const latestAssessment = documentAssessmentResults?.find((dar) =>
        dar?.parents?.find((p) => p?.ParentId === d.Id)
      );
      const latestFile =
        d.documentFiles.length > 0 ? d.documentFiles[0] : undefined;
      const status = latestFile?.changeRequests.some(
        (cr) => cr.ChangeRequestStatus === Approval_Status_Enum.Pending
      )
        ? 'pending_approval'
        : latestFile?.Status;

      const lastApprovedDate = _.orderBy(
        latestFile?.changeRequests ?? [],
        ['ModifiedAtTimestamp'],
        ['desc']
      ).filter(
        (cr) => cr.ChangeRequestStatus === Approval_Status_Enum.Approved
      )[0]?.ModifiedAtTimestamp;

      return {
        Id: d.Id,
        Title: d.Title,
        Parent: d.parent?.Title ?? null,
        DocumentType: types[d.DocumentType as keyof typeof types] ?? '-',
        OwnerName: d.owners,
        tags: d.tags,
        departments: d.departments,
        CreatedByUserName: d.createdByUser?.FriendlyName ?? null,
        ModifiedByUserName: d.modifiedByUser?.FriendlyName ?? null,
        CreatedByUserId: d.CreatedByUser,
        ModifiedByUserId: d.ModifiedByUser,
        CreatedAtTimestamp: d.CreatedAtTimestamp,
        ModifiedAtTimestamp: d.ModifiedAtTimestamp,
        PerformanceResultValue: latestAssessment?.Rating ?? null,
        PerformanceResult: getPerformanceResultLabel(
          latestAssessment?.Rating ?? null
        ),
        Status: getDocumentFileStatusLabel(status ?? null) || '-',
        StatusValue: status ?? null,
        ReviewDate: latestFile?.ReviewDate ?? null,
        NextReviewDate: latestFile?.NextReviewDate ?? null,
        CustomAttributeData: d.CustomAttributeData ?? null,
        owners: d.owners,
        SequentialIdLabel: d.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Document, d.SequentialId)
          : '',
        SequentialId: d.SequentialId,
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        Download: latestFile?.Status === 'published',
        LatestRatingDate: d.LatestRatingDate ?? '-',
        NextTestDate: d.NextTestDate ?? '-',
        TestFrequency: d.TestFrequency ? frequency[d.TestFrequency] : null,
        LastApprovedDate: lastApprovedDate,
      };
    });
  }, [
    getDocumentFileStatusLabel,
    getPerformanceResultLabel,
    records,
    types,
    documentAssessmentResults,
    frequency,
  ]);
};
