
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2rhyz_1nsm8_145",
  "input": "awsui_input_2rhyz_1nsm8_149",
  "input-readonly": "awsui_input-readonly_2rhyz_1nsm8_196",
  "input-invalid": "awsui_input-invalid_2rhyz_1nsm8_263",
  "input-has-icon-left": "awsui_input-has-icon-left_2rhyz_1nsm8_272",
  "input-warning": "awsui_input-warning_2rhyz_1nsm8_275",
  "input-type-search": "awsui_input-type-search_2rhyz_1nsm8_287",
  "input-has-icon-right": "awsui_input-has-icon-right_2rhyz_1nsm8_300",
  "input-has-no-border-radius": "awsui_input-has-no-border-radius_2rhyz_1nsm8_303",
  "input-container": "awsui_input-container_2rhyz_1nsm8_310",
  "input-icon-left": "awsui_input-icon-left_2rhyz_1nsm8_315",
  "input-icon-right": "awsui_input-icon-right_2rhyz_1nsm8_322",
  "input-button-right": "awsui_input-button-right_2rhyz_1nsm8_328"
};
  