import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { Toast } from 'react-hot-toast';

import { NotificationBanner } from '../NotificationBanner/NotificationBanner';

export type PromiseNotificationBannerProps = {
  type: 'promise';
  promise: Promise<unknown>;
  successMessage: React.ReactNode;
  errorMessage: React.ReactNode;
  toast: Toast;
};

export const PromiseNotificationBanner = (
  props: PromiseNotificationBannerProps
) => {
  const [state, setState] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );

  useEffect(() => {
    setState('loading');
    props.promise
      .then(() => {
        setState('success');
      })
      .catch(() => {
        setState('error');
      });
  }, [props.promise]);

  const content = useMemo(() => {
    switch (state) {
      case 'loading':
        return 'Saving changes...';
      case 'success':
        return props.successMessage;
      case 'error':
        return props.errorMessage;
    }
  }, [state, props.errorMessage, props.successMessage]);

  return (
    <NotificationBanner
      toast={props.toast}
      type={state}
      content={content}
      durationMs={state === 'success' ? 3500 : Infinity}
      dismissable={state === 'error'}
    />
  );
};
