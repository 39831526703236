import type { PreferencesChannel } from '@risksmart-app/shared/knock/schemas';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Switch } from '@/components/Form/ControlledSwitch/ControlledSwitch';

import style from './style.module.scss';

type Props = {
  name: string;
  label: string;
  variant?: 'normal' | 'header';
  channelTypes: readonly PreferencesChannel[];
  disabledChannels: readonly PreferencesChannel[];
};

const NotificationChannelSwitchesRow = ({
  name,
  label,
  variant = 'normal',
  channelTypes,
  disabledChannels,
}: Props) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ name, control });

  const handleChangeChannel = (
    channel: PreferencesChannel,
    checked: boolean
  ) => {
    onChange({
      ...value,
      [channel]: checked,
    });
  };

  const currentChannelState = (channel: string) =>
    Object.hasOwn(value ?? {}, channel) ? value[channel] : true;

  useEffect(() => {
    if (value == null) {
      onChange(
        channelTypes.reduce(
          (acc, channel) => {
            acc[channel] = true;

            return acc;
          },
          {} as Record<PreferencesChannel, boolean>
        )
      );
    }
  }, [value, onChange, channelTypes]);

  const className =
    variant === 'header'
      ? `${style.notificationTableRow} ${style.headerRow}`
      : `${style.notificationTableRow}`;

  return (
    <div className={className}>
      <div>{label}</div>
      {channelTypes.map((channel) => (
        <div key={channel} className={'grid place-items-center'}>
          <Switch
            data-testid={`${name}.${channel}`}
            checked={currentChannelState(channel)}
            onChange={() => {
              handleChangeChannel(channel, !currentChannelState(channel));
            }}
            disabled={disabledChannels.includes(channel)}
          />
        </div>
      ))}
    </div>
  );
};

export default NotificationChannelSwitchesRow;
