import type { ButtonProps } from '@cloudscape-design/components/button';
import Button from '@risksmart-app/components/Button';
import type { FC } from 'react';

import styles from './style.module.scss';

const DeleteButton: FC<Omit<ButtonProps, 'variant' | 'formAction'>> = (
  props
) => {
  return (
    <Button
      variant="primary"
      formAction="none"
      {...props}
      {...{ className: styles.button }}
    />
  );
};

export default DeleteButton;
