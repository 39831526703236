import FormField from '@cloudscape-design/components/form-field';
import type { FC } from 'react';
import { useState } from 'react';

import type { SectionData } from './form-configs/section';
import { initialSectionData } from './form-configs/section';
import { FormSectionModal } from './FormSectionModal';
import type {
  CustomSchema,
  CustomUISchema,
  CustomUISchemaElement,
} from './useFormBuilderContext';

type FormBuilderAddSectionProps = {
  schema: CustomSchema;
  uiSchema: CustomUISchema | CustomUISchemaElement;
  setUISchema: (uiSchema: CustomUISchema) => void;
};

export const FormBuilderAddSection: FC<FormBuilderAddSectionProps> = ({
  uiSchema,
}) => {
  const [isAddingSection, setIsAddingSection] = useState<boolean>(false);
  const [sectionData, setSectionData] =
    useState<SectionData>(initialSectionData);

  return (
    <>
      <FormField stretch={false}>
        <div
          className={
            'flex flex-col basis-2/3 rounded-md ' +
            'border-2 border-dashed border-grey ' +
            'text-slate-700 items-center gap-y-4 py-[48px] ' +
            'transition hover:cursor-pointer hover:bg-grey150'
          }
          onClick={() => setIsAddingSection(true)}
        >
          <h1 className={'text-grey m-0 font-normal'}>+</h1>
          <h1 className={'text-grey m-0 font-semibold'}>Add Section</h1>
        </div>
      </FormField>

      <FormSectionModal
        action={'add'}
        sectionData={sectionData}
        setSectionData={setSectionData}
        isEditingSection={isAddingSection}
        setIsEditingSection={setIsAddingSection}
        initialSectionData={initialSectionData}
        uiSchema={uiSchema}
      />
    </>
  );
};
