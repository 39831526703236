export const enum TestIds {
  Tier = 'tier',
  Description = 'description',
  Owners = 'owners',
  Contributors = 'contributors',
  ParentRiskId = 'parentRiskId',
  Name = 'name',
  AssessmentFrequency = 'assessmentFrequency',
  NextTestDate = 'nextTestDate',
  Tags = 'tags',
  Departments = 'departments',
  Treatment = 'treatment',
  Status = 'status',
}
