import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { FieldValues } from 'react-hook-form';

import { useGetInternalAuditReportsQuery } from '@/generated/graphql';
import { Parent_Type_Enum } from '@/generated/graphql.typed';
import { getFriendlyId } from '@/utils/friendlyId';

import ControlledSelect from '../ControlledSelect';
import type { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  testId?: string;
}

export const ControlledInternalAuditReportSelector = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();
  const { data, loading } = useGetInternalAuditReportsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const options =
    data?.internal_audit_report.map((internalAuditReport) => ({
      value: internalAuditReport.Id,
      label:
        internalAuditReport?.Title ??
        getFriendlyId(
          Parent_Type_Enum.InternalAuditReport,
          internalAuditReport.SequentialId
        ),
    })) ?? [];

  return (
    <ControlledSelect
      statusType={loading ? 'loading' : 'finished'}
      options={options}
      filteringType="auto"
      {...props}
    />
  );
};

export default ControlledInternalAuditReportSelector;
