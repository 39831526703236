import type { FC } from 'react';
import { useNavigate } from 'react-router';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import { useInsertQuestionnaireTemplateMutation } from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';
import { questionnaireTemplateVersionCreateUrl } from '@/utils/urls';

import { QuestionnaireTemplateForm } from '../../forms/QuestionnaireTemplateForm';
import type { QuestionnaireTemplateFormData } from '../../forms/questionnaireTemplateSchema';

const QuestionnaireTemplateCreateTab: FC = () => {
  useI18NSummaryHelpContent('questionnaire_templates.help');
  const navigate = useNavigate();
  const [mutate] = useInsertQuestionnaireTemplateMutation({
    update: (cache) => {
      evictField(cache, 'questionnaire_template');
    },
  });

  const onSave = async (data: QuestionnaireTemplateFormData) => {
    const { data: result } = await mutate({
      variables: {
        ...data,
        CustomAttributeData: data.CustomAttributeData || undefined,
        Tags: tagInsertInputBuilder(data.TagTypeIds),
        Departments: departmentInsertInputBuilder(data.DepartmentTypeIds),
        ...ownerAndContributorInsertFields(data),
      },
    });

    if (result?.insert_questionnaire_template_one?.Id) {
      navigate(
        questionnaireTemplateVersionCreateUrl(
          result?.insert_questionnaire_template_one?.Id
        ),
        {
          replace: true,
        }
      );
    }
  };

  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return <QuestionnaireTemplateForm onSave={onSave} onDismiss={onDismiss} />;
};

export default QuestionnaireTemplateCreateTab;
