import {
  DataImportUpdatePage,
  GroupUpdateBreadcrumb,
  GroupUpdatePage,
  SettingsPage,
} from '@pages';
import i18n from '@risksmart-app/components/providers/i18n';
import type { RouteObject } from 'react-router';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

export const settingsRoute: RouteObject = {
  path: '',
  children: [
    {
      index: true,
      path: '',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'taxonomy',
      element: (
        <ProtectedRoute permission="update:taxonomy">
          <SettingsPage activeTabId="taxonomy" />
        </ProtectedRoute>
      ),
    },
    {
      path: 'users',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="users" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'approvals',
      handle: {
        title: () => i18n.t('approvals.page_title'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="approvers">
              <ProtectedRoute permission="read:settings">
                <SettingsPage activeTabId="approvals" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
      ],
    },
    {
      path: 'groups',
      handle: {
        title: () => i18n.t('userGroups.page_title'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="settings">
              <ProtectedRoute permission="read:settings">
                <SettingsPage activeTabId="groups" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        {
          path: ':groupId',
          handle: {
            title: () => i18n.t('userGroups.group_page_title'),
            breadcrumb: GroupUpdateBreadcrumb,
          },
          children: [
            {
              path: 'details',
              element: (
                <OrgFeatureFlaggedRoute featureFlag="settings">
                  <ProtectedRoute permission="read:settings">
                    <GroupUpdatePage activeTabId="details" />
                  </ProtectedRoute>
                </OrgFeatureFlaggedRoute>
              ),
            },
            {
              path: 'members',
              element: (
                <OrgFeatureFlaggedRoute featureFlag="settings">
                  <ProtectedRoute permission="read:settings">
                    <GroupUpdatePage activeTabId="members" />
                  </ProtectedRoute>
                </OrgFeatureFlaggedRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'tags',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="tags" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'departments',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="departments" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'audit',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="audit" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'data-import',
      handle: {
        title: () => 'Data Import',
      },
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute permission="insert:data_import">
              <SettingsPage activeTabId="dataImport" />
            </ProtectedRoute>
          ),
        },
        {
          path: 'add',
          handle: {
            title: () => 'Add',
          },
          element: (
            <ProtectedRoute permission="insert:data_import">
              <DataImportUpdatePage selectedTabId="details" />
            </ProtectedRoute>
          ),
        },
        {
          path: ':dataImportId',
          handle: {
            title: () => 'Details',
          },
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute permission="insert:data_import">
                  <DataImportUpdatePage selectedTabId="details" />
                </ProtectedRoute>
              ),
            },
            {
              path: 'results',
              handle: {
                title: () => 'Results',
              },
              element: (
                <ProtectedRoute permission="insert:data_import">
                  <DataImportUpdatePage selectedTabId="results" />
                </ProtectedRoute>
              ),
            },
          ],
        },
      ],
    },
  ],
};
