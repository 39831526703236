import type { JsonFormsRendererRegistryEntry } from '@jsonforms/core';

import {
  BooleanControl,
  DropdownSelectControl,
  TextAreaControl,
  TextControl,
} from './controls';
import { GroupLayoutRenderer } from './layouts/GroupLayout';
import { VerticalLayoutRenderer } from './layouts/VerticalLayout';
import {
  booleanControlTester,
  dropdownSelectControlTester,
  groupLayoutTester,
  textAreaControlTester,
  textControlTester,
  verticalLayoutTester,
} from './testers';

const rendererRegistry: JsonFormsRendererRegistryEntry[] = [
  {
    tester: verticalLayoutTester,
    renderer: VerticalLayoutRenderer,
  },
  {
    tester: groupLayoutTester,
    renderer: GroupLayoutRenderer,
  },
  {
    tester: textControlTester,
    renderer: TextControl,
  },
  {
    tester: textAreaControlTester,
    renderer: TextAreaControl,
  },
  {
    tester: booleanControlTester,
    renderer: BooleanControl,
  },
  {
    tester: dropdownSelectControlTester,
    renderer: DropdownSelectControl,
  },
];

export default rendererRegistry;
