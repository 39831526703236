import SpaceBetween from '@cloudscape-design/components/space-between';
import Spinner from '@cloudscape-design/components/spinner';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { useGetDetailPath } from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal';
import {
  useDeleteUserGroupsMutation,
  useGetUserGroupByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';
import { settingsGroupsUrl } from '@/utils/urls';

import { useTabs } from './config';

type Props = {
  activeTabId: 'details' | 'members';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common'], { keyPrefix: 'userGroups' });
  const userGroupId = useGetGuidParam('groupId');
  const detailPath = useGetDetailPath(userGroupId);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();

  const { data, loading } = useGetUserGroupByIdQuery({
    variables: {
      Id: userGroupId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [deleteUserGroups, deleteResult] = useDeleteUserGroupsMutation({
    update: (cache) => {
      evictField(cache, 'user_group');
    },
  });

  const userGroup = data?.user_group[0];
  const tabs = useTabs(userGroup, detailPath);

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteUserGroups({
        variables: {
          UserGroupIds: [userGroupId],
        },
      });
      setIsDeleteModalVisible(false);
      navigate(settingsGroupsUrl());

      return true;
    },
  });

  return (
    <PageLayout
      title={userGroup?.Name}
      meta={{
        title: 'User Group',
      }}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            variant="normal"
            formAction="none"
            onClick={() => {
              setIsDeleteModalVisible(true);
            }}
          >
            {t('delete')}
          </Button>
        </SpaceBetween>
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <ControlledTabs
          activeTabId={activeTabId}
          tabs={tabs}
          variant="container"
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setIsDeleteModalVisible(false);
        }}
      >
        {t('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
