import type {
  PropertyFilterOperator,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import BadgeList from '@/components/BadgeList';
import type { DepartmentPartsFragment } from '@/generated/graphql';
import { useGetDepartmentsQuery } from '@/generated/graphql';

import type { FieldConfig } from '../types';

export function useGetDepartmentFieldConfig<
  T extends {
    Id: string;
    departments: DepartmentPartsFragment[];
  },
>(): FieldConfig<T> {
  const { data: departments } = useGetDepartmentsQuery();
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });

  return useMemo(
    () => ({
      header: t('departments'),
      cell: (item) => (
        <BadgeList
          badges={item.departments
            ?.map((department) => department.type?.Name)
            .filter((e) => e != null)}
        />
      ),
      filterOptions: {
        filteringProperties: createDepartmentsFieldPropertyFilter(
          departments?.department_type || []
        ),
      },
      exportVal: (item) =>
        item.departments
          .map((department) => department.type?.Name || '')
          .join(','),
    }),
    [departments?.department_type, t]
  );
}

const createDepartmentsFieldPropertyFilter = (
  departments: { Name: string | undefined; DepartmentTypeId: string }[]
): Partial<PropertyFilterProperty> => {
  const getDepartmentById = (departmentTypeId: string) =>
    departments.find((t) => t.DepartmentTypeId === departmentTypeId)?.Name ||
    '-';
  const hasDepartment = (departments: unknown, departmentTypeId: string) =>
    !!(
      (departments || []) as { Name: string; DepartmentTypeId: string }[]
    ).find((t) => t.DepartmentTypeId === departmentTypeId);

  const doesNotHaveDepartment = (
    departments: unknown,
    departmentTypeId: string
  ) => !hasDepartment(departments, departmentTypeId);

  return {
    operators: [
      ...(['=', ':'] as PropertyFilterOperator[]).map((operator) => ({
        operator,
        format: getDepartmentById,
        match: hasDepartment,
      })),
      ...(['!=', '!:'] as PropertyFilterOperator[]).map((operator) => ({
        operator,
        format: getDepartmentById,
        match: doesNotHaveDepartment,
      })),
      {
        operator: '<',
        format: (num: string): string => {
          const parsed = z.coerce.number().default(0).safeParse(num);
          if (parsed.success) {
            return parsed.data.toString();
          }

          return '';
        },
        match: (departments: unknown, num: string) => {
          const parsed = z.coerce.number().default(0).safeParse(num);
          if (parsed.success) {
            return ((departments || []) as unknown[]).length < parsed.data;
          }

          return false;
        },
      },
    ],
  };
};
