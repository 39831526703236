import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import RiskFormFields from './RiskFormFields';
import type { RiskFormDataFields } from './riskSchema';
import { defaultValues, RiskFormSchema } from './riskSchema';

export type Props = Omit<
  FormContextProps<RiskFormDataFields>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'defaultValues'
  | 'renderTemplate'
  | 'i18n'
  | 'mapPreviewedChanges'
> & {
  initialTier?: number;
  riskId?: string;
  parentRiskNode?: {
    Id: string;
    SequentialId?: number | null | undefined;
    ObjectType: Parent_Type_Enum;
  } | null;
};

const RiskForm: FC<Props> = (props) => {
  const { user } = useRisksmartUser();
  const canInsertTier1Risks = useHasPermission('insert:risk_tier_1');
  const defaultTier =
    props.initialTier || (canInsertTier1Risks || props.riskId ? 1 : 2);
  const { t } = useTranslation(['common']);
  const defaultData: RiskFormDataFields = {
    ...defaultValues,
    Tier: defaultTier as RiskFormDataFields['Tier'],
    Owners: [
      {
        type: 'user',
        value: user!.userId!,
      },
    ],
  };

  return (
    <CustomisableFormContext
      {...props}
      header={t('details')}
      schema={RiskFormSchema}
      defaultValues={defaultData}
      i18n={t('risks')}
      formId={'risk-form'}
      parentType={Parent_Type_Enum.Risk}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      approvalConfig={{ object: { Id: props.riskId ?? '' } }}
      mapPreviewedChanges={(
        current: RiskFormDataFields | undefined,
        incoming: RiskFormDataFields & {
          Owners: { UserId: string }[];
          OwnerGroups: { UserGroupId: string }[];
          Contributors: { UserId: string }[];
          ContributorGroups: { UserGroupId: string }[];
        }
      ): RiskFormDataFields => {
        return {
          ...defaultValues,
          ...current,
          ...incoming,
          Owners: incoming
            ? getOwners({
                owners: incoming.Owners,
                ownerGroups: incoming.OwnerGroups,
              })
            : (current?.Owners ?? []),
          Contributors: incoming
            ? getContributors({
                contributors: incoming.Contributors,
                contributorGroups: incoming.ContributorGroups,
              })
            : (current?.Contributors ?? []),
        };
      }}
    >
      <RiskFormFields
        readOnly={props.readOnly}
        riskId={props.riskId}
        parentRiskNode={props.parentRiskNode}
      />
    </CustomisableFormContext>
  );
};

export default RiskForm;
