
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_19bso_1jx5p_153",
  "inner-root": "awsui_inner-root_19bso_1jx5p_154",
  "has-groups": "awsui_has-groups_19bso_1jx5p_187",
  "compact-mode": "awsui_compact-mode_19bso_1jx5p_191",
  "token": "awsui_token_19bso_1jx5p_200",
  "inner-token": "awsui_inner-token_19bso_1jx5p_201",
  "grouped": "awsui_grouped_19bso_1jx5p_214",
  "list": "awsui_list_19bso_1jx5p_226",
  "show-operation": "awsui_show-operation_19bso_1jx5p_237",
  "select": "awsui_select_19bso_1jx5p_243",
  "inner-select": "awsui_inner-select_19bso_1jx5p_244",
  "token-content": "awsui_token-content_19bso_1jx5p_248",
  "token-content-grouped": "awsui_token-content-grouped_19bso_1jx5p_254",
  "inner-token-content": "awsui_inner-token-content_19bso_1jx5p_259",
  "edit-button": "awsui_edit-button_19bso_1jx5p_264",
  "dismiss-button": "awsui_dismiss-button_19bso_1jx5p_265",
  "inner-dismiss-button": "awsui_inner-dismiss-button_19bso_1jx5p_266",
  "token-disabled": "awsui_token-disabled_19bso_1jx5p_324"
};
  