import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledAssessmentSelector from '@/components/Form/ControlledAssessmentSelector';
import ControlledComplianceMonitoringAssessmentSelector from '@/components/Form/ControlledComplianceMonitoringAssessmentSelector';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledDocumentMultiselect from '@/components/Form/ControlledDocumentMultiselect';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledInternalAuditReportSelector from '@/components/Form/ControlledInternalAuditReportSelector';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import type { DocumentAssessmentResultFormDataFields } from './documentAssessmentResultSchema';
import { RiskAssessmentResultTestIds } from './RiskAssessmentResultTestIds';

type Props = {
  readOnly?: boolean;
  showSelector?: AssessmentTypeEnum;
  disableDocumentSelector: boolean;
};

const DocumentAssessmentResultForm: FC<Props> = ({
  readOnly,
  showSelector,
  disableDocumentSelector,
}) => {
  const { control } = useFormContext<DocumentAssessmentResultFormDataFields>();
  const { t } = useTranslation('common', {
    keyPrefix: 'assessmentResults.fields',
  });

  return (
    <CustomisableForm readOnly={readOnly}>
      {showSelector === 'rating' && (
        <ControlledAssessmentSelector
          defaultRequired={false}
          readOnly={readOnly}
          disabled={readOnly || disableDocumentSelector}
          key="AssessmentId"
          name="AssessmentId"
          label={t('Assessment')}
          description={t('Assessment_help')}
          control={control}
        />
      )}
      {showSelector === 'compliance_monitoring_assessment' && (
        <ControlledComplianceMonitoringAssessmentSelector
          defaultRequired={false}
          readOnly={readOnly}
          disabled={readOnly || disableDocumentSelector}
          key="ComplianceMonitoringAssessmentId"
          name="ComplianceMonitoringAssessmentId"
          label={t('ComplianceMonitoringAssessment')}
          description={t('ComplianceMonitoringAssessment_help')}
          control={control}
          testId={RiskAssessmentResultTestIds.ComplianceMonitoringAssessment}
        />
      )}
      {showSelector === 'internal_audit_report' && (
        <ControlledInternalAuditReportSelector
          defaultRequired={false}
          readOnly={readOnly}
          disabled={readOnly || disableDocumentSelector}
          key="InternalAuditReportId"
          name="InternalAuditReportId"
          label={t('InternalAuditReport')}
          description={t('InternalAuditReport_help')}
          control={control}
          testId={RiskAssessmentResultTestIds.InternalAuditReport}
        />
      )}
      <ControlledDocumentMultiselect
        defaultRequired={true}
        key="DocumentIds"
        control={control}
        label={t('Document')}
        description={t('Document_help')}
        disabled={readOnly || disableDocumentSelector}
        name="DocumentIds"
        placeholder={t('Document_placeholder')}
      />
      <ControlledRating
        defaultRequired={true}
        key="dar_rating"
        name="Rating"
        label={t('Rating')}
        description={t('Rating_help')}
        placeholder={t('Rating_placeholder')}
        type="performance_result"
        control={control}
        disabled={readOnly}
      />
      <ControlledTextarea
        defaultRequired={false}
        key="dar_rationale"
        name="Rationale"
        label={t('Rationale')}
        description={t('Rationale_help')}
        placeholder={t('Rationale_placeholder')}
        control={control}
        disabled={readOnly}
      />
      <ControlledDatePicker
        defaultRequired={true}
        name="TestDate"
        key="dar_testDate"
        label={t('TestDate')}
        description={t('TestDate_help')}
        control={control}
        disabled={readOnly}
      />
      <ControlledFileUpload
        key="newFiles"
        label={t('newFiles')}
        description={t('newFiles_help')}
        control={control}
        name="newFiles"
        saveFilesName="files"
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default DocumentAssessmentResultForm;
