
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_39ths_ueroh_209",
  "file-loading-overlay": "awsui_file-loading-overlay_39ths_ueroh_241",
  "file-loading-overlay-single-row": "awsui_file-loading-overlay-single-row_39ths_ueroh_246",
  "file-option-name": "awsui_file-option-name_39ths_ueroh_250",
  "file-option-size": "awsui_file-option-size_39ths_ueroh_251",
  "file-option-last-modified": "awsui_file-option-last-modified_39ths_ueroh_252",
  "file-option": "awsui_file-option_39ths_ueroh_250",
  "file-option-thumbnail": "awsui_file-option-thumbnail_39ths_ueroh_265",
  "file-option-thumbnail-image": "awsui_file-option-thumbnail-image_39ths_ueroh_269",
  "file-option-metadata": "awsui_file-option-metadata_39ths_ueroh_282",
  "with-image": "awsui_with-image_39ths_ueroh_285",
  "single-row-loading": "awsui_single-row-loading_39ths_ueroh_288",
  "token": "awsui_token_39ths_ueroh_292",
  "token-grid": "awsui_token-grid_39ths_ueroh_299",
  "token-contains-image": "awsui_token-contains-image_39ths_ueroh_308",
  "token-box": "awsui_token-box_39ths_ueroh_312",
  "horizontal": "awsui_horizontal_39ths_ueroh_331",
  "error": "awsui_error_39ths_ueroh_339",
  "dismiss-button": "awsui_dismiss-button_39ths_ueroh_343",
  "warning": "awsui_warning_39ths_ueroh_349",
  "read-only": "awsui_read-only_39ths_ueroh_359",
  "loading": "awsui_loading_39ths_ueroh_372"
};
  