import type { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import type { SortingState } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type {
  TypedPropertyFilterQuery,
  TypedPropertyFilterToken,
} from '@risksmart-app/components/Table/tableUtils';
import type { QUnitType } from 'dayjs';
import type { KeyPrefix } from 'i18next';
import type { ParseKeys } from 'i18next/typescript/t';
import type { HasPermission } from 'src/rbac/Permission';

import type { Parent_Type_Enum } from '@/generated/graphql';
import type { UseRatingResponse } from '@/hooks/use-rating';
import type { UseRiskScoreFormattersResponse } from '@/hooks/useRiskScore';
import type { IsFeatureVisibleToOrg } from '@/utils/featureFlags';
import type { StatefulTableOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import type { TablePropsWithActions, TableRecord } from '@/utils/table/types';

import type { DashboardFilter } from '../../../context/DashboardFilter';

export type AggregationType = 'count' | 'sum' | 'mean' | 'max' | 'min';

export const UNRATED = 'Unrated';

/** Category type along with the records within this category */
export type Category<
  T,
  K extends CategoryType,
  S extends CategoryType | never = never,
> = {
  key: K | UnratedCategoryType;
  label: string;
  sortKey?: string;
  aggregatedValue: number;
  data: T[];
  subCategories?: Category<T, S | UnratedCategoryType>[];
};

export type CategoryGetter<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
> = (
  data: DataSourceItem<TDataSource>
) => CategoryResult<TCategory> | CategoryResult<TCategory>[];

export type DataSourceItem<T> = T extends WidgetDataSource<infer K> ? K : never;

export type DataSourceVariables<T> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends WidgetDataSource<any, infer K> ? K : never;

export type DataSourceData<T> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends WidgetDataSource<any, any, infer K> ? K : never;

type CategoryGetterDefinition<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
> = {
  id: string;
  name: () => string;
  categoryGetter: CategoryGetter<TDataSource, TCategory>;
  clickthroughFilter?: (
    category: Category<
      DataSourceItem<TDataSource>,
      TCategory | UnratedCategoryType
    >,
    datePrecision: QUnitType
  ) => TypedPropertyFilterToken<DataSourceItem<TDataSource>>[];
  categoryOverrideFunction?: (
    category: Category<
      DataSourceItem<TDataSource>,
      TCategory | UnratedCategoryType
    >,
    ratingFns: UseRatingResponse,
    riskFormatters: UseRiskScoreFormattersResponse
  ) => Partial<{
    color: string;
    title: string;
    category: Category<
      DataSourceItem<TDataSource>,
      TCategory | UnratedCategoryType
    >;
    value: number;
  }>;
  ratingColourKey?: KeyPrefix<'ratings'>;
} & (
  | {
      date?: false;
    }
  | {
      date: true;
      /** Choose how the global date filtering will work */
      dateFilter: DateFilterOptions<TDataSource>['dateFilter'];
    }
);

export type WidgetDataSource<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TItem extends TableRecord = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TVariables = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
> = {
  /**
   * Required to determine which custom attributes to display in the category selector
   */
  parentTypes: Parent_Type_Enum[];
  /**
   * Whether or not the user has access to view the data source
   *
   * @param hasPermission Function to retrieve user permissions
   * @param isFeatureVisibleToOrg Function to retrieve org features
   * @returns
   */
  hasAccess: (
    hasPermission: HasPermission,
    isFeatureVisibleToOrg: IsFeatureVisibleToOrg
  ) => boolean;
  documentNode: TypedDocumentNode<TData, TVariables>;
  defaultVariables: TVariables;
  useTablePropsHook: (
    data: TData | undefined,
    options: StatefulTableOptions<TItem>
  ) => TablePropsWithActions<TItem>;
  fields: ParseKeys<'common'>;
  entityNamePlural: ParseKeys<'common'>;
  entityNameSingular: ParseKeys<'common'>;
  dashboardFilterConfig: {
    tagsFilter?: (filters: DashboardFilter['tags']) => Partial<TVariables>;
    departmentsFilter?: (
      filters: DashboardFilter['departments']
    ) => Partial<TVariables>;
    dateFilter?: DateFilterOptions<
      WidgetDataSource<TItem, TVariables, TData>
    >['dateFilter'];
    dateClickthroughFilter?: (
      filter: DashboardFilter['dateRange'],
      datePrecision: QUnitType
    ) => TypedPropertyFilterToken<TItem>[];
  };
  categoryGetters: CategoryGetterDefinition<
    WidgetDataSource<TItem, TVariables, TData>,
    CategoryType
  >[];
  clickThroughUrl?: (
    propertyFilter: TypedPropertyFilterQuery<TItem>,
    sortingState?: SortingState<TItem>
  ) => string;
  useRelativeCustomAttributeDates?: boolean;
};

export type UnratedCategoryType = typeof UNRATED;
export type CategoryType = string | number | Date | UnratedCategoryType;
type DetailedCategoryType<T extends CategoryType> = {
  key: T | undefined | null | UnratedCategoryType;
  label: string;
  sortKey?: string;
  count?: number;
};
export type DateFilterOptions<TDataSource> = {
  dateFilter?: (
    filters: DashboardFilter['dateRange'],
    precision: QUnitType
  ) => Partial<DataSourceVariables<TDataSource>>;
  precision?: QUnitType;
  dateFormat?: string;
};

export type CategoryResult<TCategory extends CategoryType> =
  | TCategory
  | DetailedCategoryType<TCategory>
  | UnratedCategoryType
  | null;

export type GigawidgetCommonProps<TDataSource extends WidgetDataSource> = {
  dataSource: TDataSource;
  variables?: Partial<DataSourceVariables<TDataSource>>;
  dateFilterOptions?: DateFilterOptions<TDataSource>;
  propertyFilterQuery?: PropertyFilterQuery;
  aggregationType?: AggregationType;
  aggregationField?: keyof DataSourceItem<TDataSource>;
};

export type CategoricalGigawidgetCommonProps<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
> = {
  categoryGetter: CategoryGetter<TDataSource, TCategory>;
  categoryRatingTranslationKey?: KeyPrefix<'ratings'>;
  categoryOverrideFunction?: (
    catgory: Category<
      DataSourceItem<TDataSource>,
      TCategory | UnratedCategoryType
    >,
    ratingFns: UseRatingResponse,
    riskFormatters: UseRiskScoreFormattersResponse
  ) => Partial<{
    color: string;
    title: string;
    category: Category<
      DataSourceItem<TDataSource>,
      TCategory | UnratedCategoryType
    >;
    value: number;
  }>;
  onClickUrl?: (
    item: Category<
      DataSourceItem<TDataSource>,
      TCategory | UnratedCategoryType
    >,
    filters: DashboardFilter
  ) => string | undefined;
};
