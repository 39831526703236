import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { getEnv } from '@risksmart-app/components/utils/environment';
import axios from 'axios';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';

import { handleError } from '@/utils/errorUtils';

import { AxiosContext } from './AxiosContext';

const instance = axios.create({
  baseURL: getEnv('REACT_APP_REST_API_URL'),
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);

    return Promise.reject(error);
  }
);

type Props = {
  children: ReactNode;
};

export const AxiosProvider: FC<Props> = ({ children }) => {
  const { getAccessTokenSilently } = useRisksmartUser();
  useEffect(() => {
    instance.interceptors.request.use(async function (config) {
      const token = await getAccessTokenSilently();
      config.headers['Authorization'] = `Bearer ${token}`;

      return config;
    });
  }, [getAccessTokenSilently]);

  return (
    <AxiosContext.Provider value={instance}>{children}</AxiosContext.Provider>
  );
};
