import SpaceBetween from '@cloudscape-design/components/space-between';
import Toggle from '@cloudscape-design/components/toggle';
import type { ControlProps } from '@jsonforms/core';
import type { FC } from 'react';

import style from './style.module.scss';

export const BooleanControlUnwrapped: FC<ControlProps> = (props) => {
  const { data, handleChange, path, id, enabled } = props;

  return (
    <SpaceBetween size={'xs'} direction={'horizontal'}>
      <div className={style.booleanCell}>
        <Toggle
          checked={!!data}
          onChange={(ev) => handleChange(path, ev.detail.checked)}
          name={id}
          disabled={!enabled}
        >
          {props.label}
        </Toggle>
      </div>
    </SpaceBetween>
  );
};
