import type { RendererProps, VerticalLayout } from '@jsonforms/core';
import { useJsonForms } from '@jsonforms/react';
import type { FunctionComponent } from 'react';
import React from 'react';

import { renderChildren } from './utils';

export const VerticalLayoutRenderer = (props: RendererProps) => {
  const { data: _data, ...otherProps } = props;

  // We don't hand over data to the layout renderer to avoid re-rendering it with every data change
  return <VerticalLayoutRendererComponent {...otherProps} />;
};

const VerticalLayoutRendererComponent: FunctionComponent<RendererProps> =
  React.memo(function VerticalLayoutRendererComponent({
    schema,
    uischema,
    path,
    visible,
    enabled,
  }: RendererProps) {
    const { renderers, cells } = useJsonForms();

    const layout = uischema as VerticalLayout;

    return (
      <div
        hidden={visible === undefined || visible === null ? false : !visible}
      >
        {renderChildren({
          layout,
          renderers,
          cells,
          schema,
          path,
          enabled,
        })}
      </div>
    );
  });
