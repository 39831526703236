import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { MAX_COL_WIDTH } from 'src/App.config';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import type { AssessmentTypeEnum } from '../../../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../../../useAssessmentTypeConfig';
import type {
  AssessmentActivityFields,
  AssessmentActivityRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  assessmentMode: AssessmentTypeEnum
): TableFields<AssessmentActivityRegisterFields> => {
  const {
    routing: { activityEditUrl },
  } = useAssessmentTypeConfig(assessmentMode);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'assessmentActivities.columns',
  });
  const { getByValue: statusGetByValue } = useRating(
    'assessment_activity_status'
  );
  const navigate = useNavigate();

  return {
    Title: {
      header: st('Title'),
      cell: (item) => (
        <Link
          variant="secondary"
          href="#"
          onFollow={() => {
            navigate(activityEditUrl(item.ParentId, item.Id));
          }}
        >
          {item.Title}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    StatusLabelled: {
      header: st('Status'),
      sortingField: 'StatusLabelled',
      cell: (item) => {
        return <SimpleRatingBadge rating={statusGetByValue(item.Status)} />;
      },
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    ActivityTypeLabelled: {
      header: st('Type'),
      sortingField: 'ActivityTypeLabelled',
      cell: (item) => item.ActivityTypeLabelled,
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    CompletionDate: dateColumn(st('CompletionDate'), 'CompletionDate'),
    AssignedUser: {
      header: st('AssignedUser'),
      cell: (item) => item.assignedUser?.FriendlyName ?? '-',
    },
    ModifiedAtTimestamp: dateColumn(st('UpdatedOn'), 'ModifiedAtTimestamp'),
    CreatedById: {
      header: st('CreatedById'),
      cell: (item) => item.CreatedById,
    },
    CreatedByUsername: {
      header: st('CreatedByUsername'),
      cell: (item) => item.CreatedByUsername,
    },
    CreatedOn: dateColumn(st('CreatedOn'), 'CreatedAtTimestamp'),
    UpdatedById: {
      header: st('UpdatedById'),
      cell: (item) => item.UpdatedById,
    },
    UpdatedByUsername: {
      header: st('UpdatedByUsername'),
      cell: (item) => item.UpdatedByUsername,
    },
  };
};

export const useGetCollectionTableProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentActivityFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<AssessmentActivityRegisterFields> => {
  const { t } = useTranslation(['taxonomy']);
  const fields = useGetFieldConfig(assessmentMode);
  const labelledFields = useLabelledFields(records);

  return useGetTableProps({
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: t('activity'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'AssessmentActivitiesRegister-Preferences',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'ActivityTypeLabelled',
      'StatusLabelled',
      'CompletionDate',
    ],
    fields,
    defaultSortingState: {
      sortingColumn: 'CompletionDate',
      sortingDirection: 'desc',
    },
  });
};
