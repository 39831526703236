import type { DefaultValues } from 'react-hook-form';
import { z } from 'zod';

import {
  Third_Party_Status_Enum,
  Third_Party_Type_Enum,
} from '@/generated/graphql';

import {
  CustomAttributeDataSchema,
  FilesSchema,
  InheritedContributorSchema,
  TagsAndDepartmentsSchema,
  UserOrGroupsSchema,
} from '../../../schemas/global';

export const thirdPartyFormSchema = z
  .object({
    title: z.string().min(1, 'Required'),
    description: z.string().nullish(),
    companyName: z.string().min(1, 'Required'),
    companiesHouseNumber: z.string().nullish(),
    address: z.string().nullish(),
    cityTown: z.string().nullish(),
    postcode: z.string().nullish(),
    country: z.string().nullish(),
    primaryContactName: z.string().nullish(),
    contactName: z.string().nullish(),
    contactEmail: z.string().nullish(),
    companyDomain: z.string().nullish(),
    type: z.nativeEnum(Third_Party_Type_Enum, { required_error: 'Required' }),
    status: z.nativeEnum(Third_Party_Status_Enum, {
      required_error: 'Required',
    }),
    criticality: z
      .string()
      // Pipe used to fix coercion validation issue:
      // https://zod.dev/?id=you-can-use-pipe-to-fix-common-issues-with-zcoerce
      .pipe(z.coerce.number({ required_error: 'Required' }).min(1).max(4)),
    Contributors: UserOrGroupsSchema,
    Owners: UserOrGroupsSchema.min(1, { message: 'Required' }),
    ancestorContributors: InheritedContributorSchema,
  })
  .and(TagsAndDepartmentsSchema)
  .and(FilesSchema)
  .and(CustomAttributeDataSchema);

export type ThirdPartyFormData = z.infer<typeof thirdPartyFormSchema>;

export const defaultValues: DefaultValues<ThirdPartyFormData> = {
  title: '',
  description: '',
  companyName: '',
  companiesHouseNumber: '',
  address: '',
  cityTown: '',
  postcode: '',
  country: '',
  primaryContactName: '',
  contactName: '',
  contactEmail: '',
  companyDomain: '',
  Owners: [],
  Contributors: [],
  ancestorContributors: [],
  tags: [],
  departments: [],
  TagTypeIds: [],
  DepartmentTypeIds: [],
  CustomAttributeData: null,
  newFiles: [],
  files: [],
};
