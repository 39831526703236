import type {
  JsonSchema,
  Labelable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';

export interface SectionData {
  sectionTitle: string;
}

export const initialSectionData: SectionData = {
  sectionTitle: '',
};

export const sectionSchema: JsonSchema = {
  type: 'object',
  properties: {
    sectionTitle: { type: 'string', minLength: 1 },
  },
  required: ['sectionTitle'],
};

interface VerticalLayoutWithLabelables extends VerticalLayout {
  elements: UISchemaElement[] & Labelable[];
}

export const sectionUISchema: VerticalLayoutWithLabelables = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      label: 'Section Title',
      scope: '#/properties/sectionTitle',
      options: {
        placeholder: 'Enter section title here...',
      },
    },
  ],
};
