import type { AutosuggestProps } from '@cloudscape-design/components/autosuggest';
import Autosuggest from '@cloudscape-design/components/autosuggest';
import type { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';
import type { FieldValues } from 'react-hook-form';

import { FormField } from '@/components/Form/Form/FormField';
import HelpLink from '@/components/HelpPanel/HelpLink';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import type { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  options: AutosuggestProps.Options | undefined;
  onSelect?: NonCancelableEventHandler<AutosuggestProps.SelectDetail>;
  enableVirtualScroll?: boolean;
  disabled?: boolean;
}

export const ControlledAutosuggest = <T extends FieldValues>({
  name,
  label,
  control,
  options,
  onSelect,
  forceRequired,
  enableVirtualScroll = false,
  defaultRequired,
  description,
  allowDefaultValue,
  testId,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      forceRequired={forceRequired}
      defaultRequired={defaultRequired}
      allowDefaultValue={allowDefaultValue}
      name={name}
      control={control}
      render={({ field: { ref, onChange, onBlur, value } }) => (
        <FormField
          label={label}
          testId={testId}
          errorText={error?.message}
          stretch
          info={
            description && (
              <HelpLink id={label} title={label} content={description} />
            )
          }
        >
          <Autosuggest
            ref={ref}
            name={name}
            value={value ?? ''} // fallback to empty string as undefined/null causes an error
            onBlur={onBlur}
            onSelect={onSelect}
            onChange={(e) => onChange(e.detail.value)}
            options={options}
            virtualScroll={enableVirtualScroll}
            enteredTextLabel={(value) => `Use: "${value}"`}
            ariaLabel="Enter value"
            placeholder="Enter value"
            empty="No matches found"
            {...props}
            disabled={readOnly || props.disabled}
          />
        </FormField>
      )}
    />
  );
};
