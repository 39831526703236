import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import {
  addQuestionnaireTemplateUrl,
  questionnaireTemplateDetailsUrl,
} from '@/utils/urls';

import { Permission } from '../../rbac/Permission';
import type {
  QuestionnaireTemplateFields,
  QuestionnaireTemplateRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

export const useGetCollectionTableProps = (
  records: QuestionnaireTemplateFields[] | undefined
): TablePropsWithActions<QuestionnaireTemplateRegisterFields> => {
  const props = useGetQuestionnaireTemplatesTableProps(records);

  return useGetTableProps(props);
};

const useGetFieldConfig =
  (): TableFields<QuestionnaireTemplateRegisterFields> => {
    const { t: ct } = useTranslation(['common'], {
      keyPrefix: 'questionnaire_templates.columns',
    });
    const getStatus = useRating('questionnaire_template_version_status');

    return {
      Title: {
        header: ct('title'),
        cell: (item) => (
          <Link
            variant="secondary"
            href={questionnaireTemplateDetailsUrl(item.Id)}
          >
            {item.Title}
          </Link>
        ),
      },
      Description: {
        header: ct('description'),
      },
      LatestStatus: {
        id: 'latestStatus',
        header: ct('status'),
        cell: (item) => {
          return (
            <SimpleRatingBadge
              rating={getStatus.getByValue(item.LatestStatus)}
            />
          );
        },
        sortingField: 'Status',
      },
      CreatedByFriendlyName: {
        header: ct('created_by'),
      },
      ModifiedByFriendlyName: {
        header: ct('updated_by'),
      },
    };
  };

export const useGetQuestionnaireTemplatesTableProps = (
  records: QuestionnaireTemplateFields[] | undefined
): UseGetTablePropsOptions<QuestionnaireTemplateRegisterFields> => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_templates',
  });
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(() => {
    return {
      data,
      fields,
      customAttributeSchema: null,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:questionnaire_template">
          <Button href={addQuestionnaireTemplateUrl()}>
            {st('create_new_button')}
          </Button>
        </Permission>
      ),
      enableFiltering: true,
      initialColumns: [
        'Title',
        'Description',
        'LatestStatus',
        'CreatedByFriendlyName',
        'CreatedAtTimestamp',
        'ModifiedAtTimestamp',
        'ModifiedByFriendlyName',
      ],
      preferencesStorageKey: 'QuestionnaireTemplatesTable-PreferencesV1',
    };
  }, [st, data, fields]);
};
