import _ from 'lodash';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';

import Tokens from '@/components/Tokens';
import { useGetControlsBasicQuery } from '@/generated/graphql';
import { controlDetailsUrl } from '@/utils/urls';

import ControlledMultiselect from '../ControlledMultiselect';
import type { ControlledBaseProps } from '../types';
import { getOptions } from './multiselectUtils';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  disabled?: boolean;
  excludedIds?: string[];
  renderTokens?: boolean;
}

export const ControlledControlMultiSelect = <T extends FieldValues>({
  excludedIds,
  ...props
}: Props<T>) => {
  const { data: controls, loading } = useGetControlsBasicQuery({});

  const orderedOptions = useMemo(() => {
    const defaultValues: { value: string; label: string }[] =
      props.control._defaultValues[props.name] ?? [];

    return _.sortBy(
      getOptions(controls, defaultValues).filter(
        (control) => !excludedIds?.includes(control.value)
      ),
      'label'
    );
  }, [controls, excludedIds, props.control._defaultValues, props.name]);

  return (
    <ControlledMultiselect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      hideTokens={true}
      renderTokens={true}
      filteringType="auto"
      options={orderedOptions}
      customTokenRender={(options, actions) => (
        <Tokens
          onRemove={actions.removeToken}
          disabled={props.disabled}
          tokens={options.map((o) => ({
            value: o.value!,
            url: controlDetailsUrl(o.value!),
            label: o.label!,
          }))}
        />
      )}
    />
  );
};
