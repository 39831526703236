export const getFieldLabel = (
  label: string | undefined,
  minLength?: number | undefined
) => {
  if (!label) {
    return '';
  }

  if (minLength && minLength > 0) {
    return `${label}`;
  }

  return `${label} (optional)`;
};
