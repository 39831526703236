import { createContext } from 'react';

export type HelpContent = FieldContent[];

export type Content = string | { title: string; content: string }[];

export type FieldContent = {
  title: string;
  content: Content;
};

export type FormFieldHelpContent = {
  [fieldId: string]: FieldContent;
};

type HelpContext = {
  addFieldHelp: (fieldId: string, helpContent: FieldContent) => void;
  removeFieldHelp: (fieldId: string) => void;
  formFieldHelpContent: FormFieldHelpContent;
  summaryHelpContent: HelpContent | null;
  setSummaryHelpContent: (content: HelpContent | null) => void;
  contentId: string | null;
  setContentId: (contentId: string | null) => void;
  hasHelpContent: boolean;
  translationKey: string | null;
  setTranslationKey: (translationKey: string) => void;
  showFieldHelpInPopover: boolean;
  setShowFieldHelpInPopover: (showFieldHelpInPopover: boolean) => void;
};

export const HelpContext = createContext<HelpContext | null>(null);
