import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { complianceMonitoringAssessmentDetailsUrl } from '@/utils/urls';

import { UNRATED } from '../../../../../controls/lookupData';
import type {
  ComplianceObligationAssessmentResultFlatFields,
  ComplianceObligationAssessmentResultRegisterFields,
} from './types';
import { useComplianceMonitoringObligationRatingLabelledFields } from './useComplianceMonitoringObligationRatingLabelledFields';

const useGetFieldConfig = (
  onOpenResult: (id: string) => void
): TableFields<ComplianceObligationAssessmentResultRegisterFields> => {
  const { t: at } = useTranslation(['common'], {
    keyPrefix: 'complianceMonitoringAssessment',
  });
  const { t: rt } = useTranslation(['common'], {
    keyPrefix: 'ratings.columns',
  });
  const { getByValue: getResultByValue } = useRating('performance_result');
  const { getByValue: statusGetByValue } = useRating('assessment_status');

  return useMemo(
    () => ({
      TestDate: dateColumn(rt('TestDate'), 'TestDate', (item) =>
        onOpenResult(item.Id)
      ),
      Title: {
        header: rt('ComplianceMonitoringAssessmentTitle'),
        cell: (item) =>
          item.parents.find((p) => p.complianceMonitoringAssessment)
            ?.complianceMonitoringAssessment ? (
            <Link
              variant="secondary"
              href={complianceMonitoringAssessmentDetailsUrl(
                item.parents.find((p) => p.complianceMonitoringAssessment)!
                  .complianceMonitoringAssessment!.Id
              )}
            >
              {
                item.parents.find((p) => p.complianceMonitoringAssessment)
                  ?.complianceMonitoringAssessment?.Title
              }
            </Link>
          ) : (
            '-'
          ),
        sortingField: 'Title',
      },
      Result: {
        header: rt('Rating'),
        cell: (item) => {
          const result = getResultByValue(item.Rating);

          return <SimpleRatingBadge rating={result ?? UNRATED} />;
        },
        sortingField: 'ResultValue',
      },
      Status: {
        header: at('columns.Status'),
        cell: (item) => {
          return <SimpleRatingBadge rating={statusGetByValue(item.Status)} />;
        },
        sortingField: 'Status',
      },
      CompletionDate: dateColumn(rt('CompletionDate'), 'CompletionDate'),
      NextTestDate: dateColumn(at('columns.NextTestDate'), 'NextTestDate'),
    }),
    [at, getResultByValue, onOpenResult, rt, statusGetByValue]
  );
};

const useGetObligationRatingTableProps = (
  records: ComplianceObligationAssessmentResultFlatFields[] | undefined,
  onOpenResult: (id: string) => void,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ComplianceObligationAssessmentResultRegisterFields> => {
  const labelledFields =
    useComplianceMonitoringObligationRatingLabelledFields(records);
  const fields = useGetFieldConfig(onOpenResult);

  return {
    customAttributeSchema: customAttributeSchema,
    data: labelledFields,
    enableFiltering: true,
    entityLabel: 'rating',
    defaultSortingState: {
      sortingColumn: 'TestDate',
      sortingDirection: 'desc',
    },
    initialColumns: [
      'TestDate',
      'Title',
      'Result',
      'Status',
      'CompletionDate',
      'NextTestDate',
    ],
    preferencesStorageKey:
      'ComplianceMonitoringObligationRatingRegisterTable-Preferences',
    fields,
  };
};

export const useGetCollectionTableProps = (
  records: ComplianceObligationAssessmentResultFlatFields[] | undefined,
  onOpenResult: (id: string) => void,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ComplianceObligationAssessmentResultRegisterFields> => {
  const props = useGetObligationRatingTableProps(
    records,
    onOpenResult,
    customAttributeSchema
  );

  return useGetTablePropsWithoutUrlHash(props);
};
