import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import RecallModal from 'src/pages/third-party-responses/modals/RecallModal';
import type { RecallSchemaFields } from 'src/pages/third-party-responses/modals/schema';

import TabHeader from '@/components/TabHeader';
import {
  useGetThirdPartyResponsesQuery,
  useRecallQuestionnaireInvitesMutation,
} from '@/generated/graphql';

import { useGetCollectionTableProps } from './config';
import type { QuestionnaireInviteFields } from './types';

const QuestionnairesTab = () => {
  const thirdPartyId = useGetGuidParam('id');

  const { data, loading } = useGetThirdPartyResponsesQuery({
    variables: {},
  });
  const tableProps = useGetCollectionTableProps(
    data?.third_party_response,
    thirdPartyId
  );
  const { t } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_invite',
  });

  const [selectedItems, setSelectedItems] = useState<
    QuestionnaireInviteFields[]
  >([]);

  const [showRecallModal, setShowRecallModal] = useState(false);

  const [recall, { loading: recallLoading }] =
    useRecallQuestionnaireInvitesMutation();

  const navigate = useNavigate();

  const onRecall = () => {
    setShowRecallModal(true);
  };

  return (
    <div>
      <Table
        {...tableProps}
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        trackBy="Id"
        selectionType="multi"
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant={'primary'}
                    onClick={() => navigate('invite')}
                  >
                    {t('actions.plan')}
                  </Button>
                  <Button onClick={onRecall} disabled={!selectedItems.length}>
                    {t('actions.recall')}
                  </Button>
                </SpaceBetween>
              }
            >
              {t('register_title')}
            </TabHeader>
          </SpaceBetween>
        }
        loading={loading}
        variant={'embedded'}
      />
      <RecallModal
        isVisible={showRecallModal}
        loading={recallLoading}
        onDismiss={() => setShowRecallModal(false)}
        onRecall={async (data: RecallSchemaFields) => {
          await recall({
            variables: {
              ThirdPartyId: thirdPartyId,
              ResponseIds: selectedItems.map((item) => item.Id),
              Reason: data.Reason,
              ShareWithRespondents: data.ShareWithRespondents,
            },
          });
        }}
      />
    </div>
  );
};

export default QuestionnairesTab;
