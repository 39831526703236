import type { FC } from 'react';
import { useState } from 'react';

import { Parent_Type_Enum } from '@/generated/graphql';

import type { ObjectWithContributors } from '../../../rbac/Permission';
import type { ResultType } from '../modals/types';
import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import ConnectedDocumentAssessmentResultForm from './ConnectedDocumentAssessmentResultForm';
import ConnectedObligationAssessmentResultForm from './ConnectedObligationAssessmentResultForm';
import ConnectedRiskAssessmentResultForm from './ConnectedRiskAssessmentResultForm';
import TypeSelector from './TypeSelector';

export interface Props {
  readonly: boolean;
  navigateToResults: boolean;
  assessmentMode: AssessmentTypeEnum;
  parentAssessment?: ObjectWithContributors;
  assessedItem?: ObjectWithContributors;
  resultType?: ResultType;
  onDismiss?: (saved: boolean) => void;
  hideTypeSelector?: boolean;
  id?: string;
}

const AssessmentResultForm: FC<Props> = ({
  readonly,
  parentAssessment,
  assessedItem,
  resultType,
  onDismiss,
  hideTypeSelector,
  id,
  navigateToResults,
  assessmentMode,
}) => {
  const [formResultType, setFormResultType] = useState<ResultType>(
    resultType || Parent_Type_Enum.RiskAssessmentResult
  );

  const [assessment] = useState<ObjectWithContributors | undefined>(
    parentAssessment
  );

  const typeSelector = (
    <div className="pb-5">
      <TypeSelector
        testId="Type"
        value={formResultType}
        readOnly={readonly}
        onChange={(val) => {
          setFormResultType(val);
        }}
        parent={parentAssessment}
      />
    </div>
  );

  const beforeFieldsSlot = <>{!hideTypeSelector && typeSelector}</>;

  return (
    <>
      {formResultType === Parent_Type_Enum.RiskAssessmentResult && (
        <ConnectedRiskAssessmentResultForm
          readonly={readonly}
          isModalForm={true}
          assessmentId={assessment?.Id}
          assessedItem={assessedItem}
          onDismiss={onDismiss}
          id={id}
          beforeFieldsSlot={beforeFieldsSlot}
          showAssessmentSelector={!parentAssessment}
          navigateToResults={navigateToResults}
          assessmentMode={assessmentMode}
        />
      )}

      {formResultType === Parent_Type_Enum.DocumentAssessmentResult && (
        <ConnectedDocumentAssessmentResultForm
          readonly={readonly}
          isModalForm={true}
          assessmentId={assessment?.Id}
          assessedItem={assessedItem}
          onDismiss={onDismiss}
          id={id}
          beforeFieldsSlot={beforeFieldsSlot}
          showAssessmentSelector={!parentAssessment}
          navigateToResults={navigateToResults}
          assessmentMode={assessmentMode}
        />
      )}

      {formResultType === Parent_Type_Enum.ObligationAssessmentResult && (
        <ConnectedObligationAssessmentResultForm
          readonly={readonly}
          isModalForm={true}
          assessmentId={assessment?.Id}
          assessedItem={assessedItem}
          onDismiss={onDismiss}
          id={id}
          beforeFieldsSlot={beforeFieldsSlot}
          showAssessmentSelector={!parentAssessment}
          navigateToResults={navigateToResults}
          assessmentMode={assessmentMode}
        />
      )}
    </>
  );
};

export default AssessmentResultForm;
