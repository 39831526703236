
    import './styles.scoped.css';
    export default {
  "wrapper": "awsui_wrapper_z5mul_1h6b3_181",
  "wrapper--fit-height": "awsui_wrapper--fit-height_z5mul_1h6b3_214",
  "inner-wrapper--fit-height": "awsui_inner-wrapper--fit-height_z5mul_1h6b3_219",
  "has-default-filter": "awsui_has-default-filter_z5mul_1h6b3_225",
  "content": "awsui_content_z5mul_1h6b3_229",
  "content--reserve-filter": "awsui_content--reserve-filter_z5mul_1h6b3_236",
  "content--reserve-legend": "awsui_content--reserve-legend_z5mul_1h6b3_240",
  "content--fit-height": "awsui_content--fit-height_z5mul_1h6b3_244",
  "filter-container": "awsui_filter-container_z5mul_1h6b3_248"
};
  