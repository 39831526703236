import {
  useGetGuidParam,
  useGetOptionalGuidParam,
} from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';
import {
  Parent_Type_Enum,
  useGetAssessmentByIdQuery,
  useGetAssessmentResultByIdQuery,
} from '@/generated/graphql';

import { useTabs } from '../../../assessment-results/update/useTabs';

const Page: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'assessmentResults' });
  const title = t('edit_title');
  const optionalAssessmentId = useGetOptionalGuidParam('assessmentId');
  const findingId = useGetGuidParam('findingId');
  const { data: resultData, error: resultError } =
    useGetAssessmentResultByIdQuery({
      variables: { Id: findingId },
      skip: !!optionalAssessmentId,
    });
  if (resultError) {
    throw resultError;
  }
  const resultParentId = resultData?.assessment_result_parent.find(
    (ar) => ar.ParentType == Parent_Type_Enum.Assessment
  )?.ParentId;
  const assessmentId = optionalAssessmentId ?? resultParentId;
  const { data, error } = useGetAssessmentByIdQuery({
    variables: {
      Id: assessmentId!,
    },
    skip: !assessmentId,
  });
  if (error) {
    throw error;
  }

  const assessment = data?.assessment?.[0];
  const tabs = useTabs(
    'rating',
    assessment || undefined,
    'update',
    !optionalAssessmentId,
    !!optionalAssessmentId
  );

  return (
    <PageLayout title={title}>
      <ControlledTabs tabs={tabs} variant="container" />
    </PageLayout>
  );
};

export default Page;
