import type { ModalProps as CFModalProps } from '@cloudscape-design/components/modal';
import Modal from '@risksmart-app/components/Modal';
import type { ReactNode } from 'react';
import { Suspense } from 'react';
import type { FieldValues } from 'react-hook-form';

import { usePopoverHelp } from '@/components/HelpPanel/usePopoverHelp';

import { FormInner } from './FormInner';
import ModalFooter from './ModalFooter';
import styles from './style.module.scss';
import type { FormTemplateProps } from './types';

export type ModalProps = {
  visible: boolean;
  size?: CFModalProps.Size;
  footerDetails?: ReactNode;
  i18n: {
    edit_modal_title?: string;
    create_modal_title?: string;
  };
};

export const ModalWrapper = <TFieldValues extends FieldValues>(
  props: FormTemplateProps<TFieldValues> & ModalProps
) => {
  usePopoverHelp();

  const header =
    props.header || props.values
      ? (props.i18n.edit_modal_title ?? 'Edit')
      : (props.i18n.create_modal_title ?? 'Create');

  const {
    children,
    actions,
    visible,
    formId,
    onDismiss,
    readOnly,
    parentType,
    size,
    footerDetails,
    aside,
  } = props;

  return (
    <Modal
      data-testid={props.testId}
      size={size}
      header={header}
      visible={visible}
      footer={
        <ModalFooter
          actions={actions}
          footerDetails={footerDetails}
          readOnly={readOnly}
          parentType={parentType}
        />
      }
      onDismiss={(event) => {
        // don't close modal on overlay click
        if (event.detail.reason === 'overlay') {
          return;
        }
        onDismiss?.(false);
      }}
    >
      <form id={formId} name={formId} onSubmit={(e) => e.preventDefault()}>
        <Suspense>
          <FormInner>
            <div className="flex gap-5">
              <div className="flex-1">{children}</div>
              {aside ? <div className={styles.formSidebar}>{aside}</div> : null}
            </div>
          </FormInner>
        </Suspense>
      </form>
    </Modal>
  );
};
