import { useContext } from 'react';

import { HelpContext } from './HelpContext';

export const useHelpContent = () => {
  const context = useContext(HelpContext);
  if (context === null) {
    throw new Error('useHelpContent must be used within a HelpProvider');
  }

  return context;
};
