import { useStorage } from 'src/state/useStorage';

import type { TablePreferences, TablePropsWithActions } from '../types';
import { useFiltersFromLocalStorageAndUrlHash } from './useFiltersFromLocalStorageAndUrlHash';
import type { UseGetTablePropsOptions } from './useGetStatelessTableProps';
import { useGetStatelessTableProps } from './useGetStatelessTableProps';

export function useGetTableProps<T extends Record<string, unknown>>(
  options: UseGetTablePropsOptions<T>
): TablePropsWithActions<T> {
  const { sortingState, setSortingState, propertyFilter, setPropertyFilter } =
    useFiltersFromLocalStorageAndUrlHash<T>({
      fields: options.fields,
      tableId: options.tableId,
    });

  const [preferences, setPreferences] = useStorage<
    TablePreferences<T> | undefined
  >(undefined, {
    localStorageKey: options.preferencesStorageKey,
  });

  return useGetStatelessTableProps({
    ...options,
    sortingState,
    setSortingState,
    propertyFilter,
    setPropertyFilter,
    preferences,
    setPreferences,
  });
}
