import { useContext } from 'react';

import { AggregationContext } from './AggregationContext';

export const useAggregation = () => {
  const context = useContext(AggregationContext);
  if (context === null) {
    throw new Error('useAggregation must be used within a AggregationProvider');
  }

  return {
    loading: context.loading,
    riskModel: context.riskScoringModel,
    appetiteAggregation: context.appetiteAggregation,
  };
};
