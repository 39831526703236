import FormField from '@cloudscape-design/components/form-field';
import type { HelpPanelProps } from '@cloudscape-design/components/help-panel';
import HelpPanelDefault from '@cloudscape-design/components/help-panel';
import Input from '@cloudscape-design/components/input';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/useHasPermission';

import Link from '../Link';
import HelpSection from './HelpSection';
import { useHelpContent } from './useHelpContent';

const HelpPanel: FC<HelpPanelProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'help' });
  const canUpdateTaxonomy = useHasPermission('update:taxonomy');
  const {
    summaryHelpContent,
    formFieldHelpContent,
    contentId,
    setContentId,
    translationKey,
  } = useHelpContent();
  const content = contentId ? formFieldHelpContent[contentId] : null;

  return (
    <HelpPanelDefault {...props} header={<h2>{t('title')}</h2>}>
      {canUpdateTaxonomy && (
        <div className="mb-4">
          <FormField label={t('translation_key')}>
            <Input disabled={true} value={translationKey ?? ''} />
          </FormField>
        </div>
      )}
      {!content &&
        summaryHelpContent &&
        summaryHelpContent.map((summary, i) => (
          <HelpSection
            title={summary.title}
            content={summary.content}
            key={i}
          />
        ))}
      {!!content && (
        <>
          <Link onFollow={() => setContentId(null)}>{t('back_button')}</Link>
          <HelpSection
            title={content.title}
            content={content.content}
            key={contentId}
          />
        </>
      )}
      {!content &&
        formFieldHelpContent &&
        Object.keys(formFieldHelpContent).map((fieldId) => (
          <HelpSection
            title={formFieldHelpContent[fieldId].title}
            content={formFieldHelpContent[fieldId].content}
            key={fieldId}
          />
        ))}
    </HelpPanelDefault>
  );
};

export default HelpPanel;
