
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_1mn59_153",
  "warning-icon-shake-wrapper": "awsui_warning-icon-shake-wrapper_14mhv_1mn59_154",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_1mn59_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_1mn59_184",
  "warning-icon-scale-wrapper": "awsui_warning-icon-scale-wrapper_14mhv_1mn59_185",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_1mn59_1",
  "warning": "awsui_warning_14mhv_1mn59_154",
  "error": "awsui_error_14mhv_1mn59_153",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_1mn59_1",
  "root": "awsui_root_14mhv_1mn59_236",
  "label": "awsui_label_14mhv_1mn59_269",
  "info": "awsui_info_14mhv_1mn59_283",
  "description": "awsui_description_14mhv_1mn59_289",
  "constraint": "awsui_constraint_14mhv_1mn59_290",
  "hints": "awsui_hints_14mhv_1mn59_297",
  "constraint-has-validation-text": "awsui_constraint-has-validation-text_14mhv_1mn59_298",
  "secondary-control": "awsui_secondary-control_14mhv_1mn59_302",
  "controls": "awsui_controls_14mhv_1mn59_306",
  "label-hidden": "awsui_label-hidden_14mhv_1mn59_306",
  "label-wrapper": "awsui_label-wrapper_14mhv_1mn59_309",
  "control": "awsui_control_14mhv_1mn59_306",
  "error__message": "awsui_error__message_14mhv_1mn59_336",
  "warning__message": "awsui_warning__message_14mhv_1mn59_337",
  "visually-hidden": "awsui_visually-hidden_14mhv_1mn59_341"
};
  