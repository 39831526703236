import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '../../theme/risksmart-themed/components';
import type { QuestionData } from './form-configs/question';
import { defaultQuestionData } from './form-configs/question';
import { FormQuestionModal } from './FormQuestionModal';
import { FORM_BUILDER_ACTION } from './useFormBuilderActions';
import { useFormBuilderContext } from './useFormBuilderContext';

type FormBuilderAddSectionProps = {
  parentId: string;
};

export const FormBuilderAddQuestion: FC<FormBuilderAddSectionProps> = ({
  parentId,
}) => {
  const [isAddingQuestion, setIsAddingQuestion] = useState<boolean>(false);
  const { uiSchema } = useFormBuilderContext();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formQuestion',
  });

  const [questionData, setQuestionData] =
    useState<QuestionData>(defaultQuestionData);

  return (
    <>
      <div
        className={
          'flex w-max p-3 transition hover:cursor-pointer hover:bg-grey150 rounded-md'
        }
        onClick={() => setIsAddingQuestion(true)}
      >
        <Header variant={'h3'}>{t('addQuestionButtonLabel')}</Header>
      </div>

      <FormQuestionModal
        action={FORM_BUILDER_ACTION.ADD}
        parentId={parentId}
        questionData={questionData}
        setQuestionData={setQuestionData}
        isEditingQuestion={isAddingQuestion}
        setIsEditingQuestion={setIsAddingQuestion}
        initialQuestionData={defaultQuestionData}
        uiSchema={uiSchema}
      />
    </>
  );
};
