import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import AcceptanceFormFields from './AcceptanceFormFields';
import type { AcceptanceFormDataFields } from './acceptanceSchema';
import { AcceptanceSchema, defaultValues } from './acceptanceSchema';

export type Props = Omit<
  FormContextProps<AcceptanceFormDataFields>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'renderTemplate'
  | 'i18n'
  | 'defaultValues'
>;

const AcceptanceForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      i18n={t('acceptances')}
      schema={AcceptanceSchema}
      formId={'acceptance-form'}
      defaultValues={defaultValues}
      parentType={Parent_Type_Enum.Acceptance}
      header={t('details')}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    >
      <AcceptanceFormFields />
    </CustomisableFormContext>
  );
};

export default AcceptanceForm;
