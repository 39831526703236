import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Permission } from 'src/rbac/Permission';

import TabHeader from '@/components/TabHeader';
import {
  useGetQuestionnaireTemplateByIdQuery,
  useGetQuestionnaireTemplateVersionsByQuestionnaireTemplateIdQuery,
} from '@/generated/graphql';

import { useGetCollectionTableProps } from './config';

const Tab: FC = () => {
  const questionnaireTemplateId = useGetGuidParam('questionnaireTemplateId');
  const { addNotification } = useNotifications();

  const navigate = useNavigate();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions',
  });

  const { data: questionnaireTemplate, loading: questionnaireTemplateLoading } =
    useGetQuestionnaireTemplateByIdQuery({
      variables: { Id: questionnaireTemplateId },
    });

  const { data, loading } =
    useGetQuestionnaireTemplateVersionsByQuestionnaireTemplateIdQuery({
      variables: {
        questionnaireTemplateId,
      },
      fetchPolicy: 'no-cache',
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const handleCreateNew = () => {
    navigate('create');
  };

  const tableProps = useGetCollectionTableProps(
    data?.questionnaire_template_version,
    questionnaireTemplate?.questionnaire_template
  );

  return (
    <>
      <Table
        {...tableProps}
        variant={'embedded'}
        loading={loading || questionnaireTemplateLoading}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="insert:questionnaire_template_version"
                    parentObject={questionnaireTemplate?.questionnaire_template}
                    canHaveAccessAsContributor={true}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      iconName="upload"
                      onClick={handleCreateNew}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
      />
    </>
  );
};

export default Tab;
