import { useCollection } from '@cloudscape-design/collection-hooks';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IssueUpdateModal from 'src/pages/issue-updates/IssueUpdateModal';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteIssueUpdatesMutation,
  useGetIssueUpdatesByParentIssueIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import type { IssueUpdateTableFields } from './config';
import { useUpdateColumnDefinitions } from './config';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('issueUpdates.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actionUpdates', // could make this into an "updates" section?
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const issueId = useGetGuidParam('issueId');
  const { addNotification } = useNotifications();
  const [selectedIssueUpdates, setSelectedIssueUpdates] = useState<
    IssueUpdateTableFields[]
  >([]);
  const canDeleteIssueUpdates = useHasPermission('delete:issue_update', parent);

  const [deleteIssueUpdates, deleteResult] = useDeleteIssueUpdatesMutation({
    update: (cache) => evictField(cache, 'issue_update'),
  });

  const [issueUpdateId, setIssueUpdateId] = useState<string | undefined>();

  const { data, loading, refetch } = useGetIssueUpdatesByParentIssueIdQuery({
    variables: {
      _eq: issueId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleIssueUpdateModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleIssueUpdateModalClose = () => {
    setIssueUpdateId(undefined);
    setIsEditOpen(false);
    refetch();
  };

  const updateColumnDefinitions = useUpdateColumnDefinitions((issueUpdate) => {
    setIssueUpdateId(issueUpdate.Id);
    handleIssueUpdateModalOpen();
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteIssueUpdates({
        variables: {
          Ids: selectedIssueUpdates.map((s) => s.Id),
        },
      });
      setSelectedIssueUpdates([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const labelledFields = useMemo<IssueUpdateTableFields[]>(() => {
    return (
      data?.issue_update.map((iu) => ({
        ...iu,
        CreatedByUserName: iu.createdByUser?.FriendlyName ?? null,
      })) || []
    );
  }, [data?.issue_update]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:issue_update" parentObject={parent}>
              <Button formAction="none" onClick={handleIssueUpdateModalOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        // Sort by date, most recent at the top
        sortingColumn: updateColumnDefinitions[3],
        isDescending: true,
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteIssueUpdates ? 'multi' : undefined}
        selectedItems={selectedIssueUpdates}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedIssueUpdates(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:issue_update"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedIssueUpdates.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:issue_update"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleIssueUpdateModalOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={updateColumnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isEditOpen && (
        <IssueUpdateModal
          parent={parent}
          issueUpdateId={issueUpdateId}
          onDismiss={handleIssueUpdateModalClose}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setSelectedIssueUpdates([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
