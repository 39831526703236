import type { TabsProps } from '@cloudscape-design/components/tabs';

import CreateDetailsTab from './tabs/details/Tab';

export const tabs: TabsProps['tabs'] = [
  {
    label: 'Details',
    id: 'details',
    content: <CreateDetailsTab />,
  },
  {
    label: 'Impact',
    id: 'impact',
    content: 'Second tab content area',
    disabled: true,
  },
  {
    label: 'Controls',
    id: 'controls',
    content: 'Third tab content area',
    disabled: true,
  },
  {
    label: 'Performance',
    id: 'performance',
    content: 'Forth tab content area',
    disabled: true,
  },
  {
    label: 'Actions',
    id: 'actions',
    content: 'Fith tab content area',
    disabled: true,
  },
  {
    label: 'Issues',
    id: 'issues',
    content: 'Sixth tab content area',
    disabled: true,
  },
];
