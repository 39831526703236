
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1wp4s_1jls0_185",
  "file-input": "awsui_file-input_1wp4s_1jls0_186",
  "hidden": "awsui_hidden_1wp4s_1jls0_190",
  "file-input-button": "awsui_file-input-button_1wp4s_1jls0_196",
  "force-focus-outline-icon": "awsui_force-focus-outline-icon_1wp4s_1jls0_196",
  "force-focus-outline-button": "awsui_force-focus-outline-button_1wp4s_1jls0_217"
};
  