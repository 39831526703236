
    import './styles.scoped.css';
    export default {
  "trigger-button-styles": "awsui_trigger-button-styles_lpshu_nqw0l_145",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_nqw0l_168",
  "trigger": "awsui_trigger_lpshu_nqw0l_145",
  "selected": "awsui_selected_lpshu_nqw0l_212",
  "badge": "awsui_badge_lpshu_nqw0l_232",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_nqw0l_236",
  "dot": "awsui_dot_lpshu_nqw0l_244"
};
  