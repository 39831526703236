import type { FC, MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/useHasPermission';
import type { Editor as TinyEditor } from 'tinymce';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledLinkInput from '@/components/Form/ControlledLinkInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import Editor from '@/components/Form/Editor';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import {
  Document_File_Type_Enum,
  Version_Status_Enum,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import type { DocumentVersionFormFieldData } from './documentFileSchema';

type DocumentVersionFormFieldsProps = {
  readonly?: boolean;
  isCreatingNewEntity: boolean;
  savedStatus: Version_Status_Enum;
  editorRef: MutableRefObject<TinyEditor | null>;
  parentId: string;
  disableStatus: boolean;
};

const DocumentVersionFormFields: FC<DocumentVersionFormFieldsProps> = ({
  readonly,
  savedStatus,
  editorRef,
  parentId,
  isCreatingNewEntity,
  disableStatus,
}) => {
  const { control, watch } = useFormContext<DocumentVersionFormFieldData>();
  const canUpdateAnyVersion = useHasPermission('update:document_file');
  const { getByValue } = useRating('document_file_status');
  const richTextEnabled = useIsFeatureVisibleToOrg('rich-text');
  const approversEnabled = useIsFeatureVisibleToOrg('approvers');

  // TODO: change based on current status
  const statusOptions = [
    {
      label: getByValue(Version_Status_Enum.Archived)?.label,
      value: Version_Status_Enum.Archived,
    },
    {
      label: getByValue(Version_Status_Enum.Draft)?.label,
      value: Version_Status_Enum.Draft,
    },
    {
      label: getByValue(Version_Status_Enum.Published)?.label,
      value: Version_Status_Enum.Published,
    },
  ];

  const { options: documentFileTypeOptions } = useRating('document_file_type');
  const fileTypeOptions = documentFileTypeOptions
    .filter((c) => c.value !== Document_File_Type_Enum.Html || richTextEnabled)
    .map((option) => ({
      ...option,
      value: String(option.value),
    }));
  const status = watch('Status');
  const type = watch('Type');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.fields',
  });
  const { t } = useTranslation(['common']);

  const reasonForReviewOptions = t('documentFiles.reviewReasons').map((rr) => ({
    value: rr,
  }));
  const isArchived = savedStatus === Version_Status_Enum.Archived;
  const isPublished = savedStatus === Version_Status_Enum.Published;
  const versionContentIsDisabled = readonly || isArchived || isPublished;
  const versionMetaIsDisabled =
    readonly || ((isArchived || isPublished) && !canUpdateAnyVersion);
  const showReviewOptions =
    approversEnabled || status === Version_Status_Enum.Published || isArchived;

  const reviewFieldsDisabled = approversEnabled
    ? versionMetaIsDisabled
    : readonly || (isArchived && !canUpdateAnyVersion);

  return (
    <CustomisableForm readOnly={readonly}>
      <ControlledInput
        key="version"
        name="Version"
        label={st('Version')}
        forceRequired={true}
        description={st('Version_help')}
        control={control}
        placeholder={st('Version_placeholder')}
        testId="version"
        disabled={versionContentIsDisabled}
      />
      <ControlledSelect
        key="versionStatus"
        name={'Status'}
        forceRequired={true}
        label={st('Status')}
        description={st('Status_help')}
        control={control}
        disabled={
          versionContentIsDisabled || !isCreatingNewEntity || disableStatus
        }
        options={statusOptions}
        testId={'status'}
      />
      <ControlledTextarea
        key="summary"
        name="Summary"
        label={st('Summary')}
        description={st('Summary_help')}
        control={control}
        disabled={versionMetaIsDisabled}
        testId="summary"
      />
      <ControlledRadioGroup
        key="type"
        label={st('Type')}
        description={st('Type_help')}
        name="Type"
        testId="type"
        control={control}
        items={fileTypeOptions}
        transform={noTransform}
        disabled={versionContentIsDisabled}
      />

      <ConditionalField
        condition={type === Document_File_Type_Enum.Html}
        key="content"
      >
        <Editor
          label={st('Text')}
          name="Content"
          control={control}
          editorRef={editorRef}
          disabled={versionContentIsDisabled}
          enableComments={!versionContentIsDisabled}
          parentId={parentId}
          // Need to remove comments if we're basing content of a previous version (i.e. not editing existing version)
          // as editing comments in one version would change the other
          removeInitialComments={!isCreatingNewEntity}
        />
      </ConditionalField>

      <ConditionalField
        condition={type === Document_File_Type_Enum.Link}
        key="link"
      >
        <ControlledLinkInput
          name="Link"
          label={st('Link')}
          description={st('Link_help')}
          control={control}
          forceRequired={true}
          testId="link"
          inputMode="url"
          disabled={versionContentIsDisabled}
        />
      </ConditionalField>
      <ConditionalField
        condition={type === Document_File_Type_Enum.File}
        key="files"
      >
        <ControlledFileUpload
          label={st('newFile')}
          description={st('newFile_help')}
          control={control}
          name="newFiles"
          forceRequired={true}
          saveFilesName="files"
          disabled={versionContentIsDisabled}
          multiple={false}
        />
      </ConditionalField>
      <ConditionalField condition={showReviewOptions} key="reasonForReview">
        <ControlledAutosuggest
          name="ReasonForReview"
          testId="reasonForReview"
          label={st('ReasonForReview')}
          description={st('ReasonForReview_help')}
          control={control}
          options={reasonForReviewOptions}
          disabled={reviewFieldsDisabled}
        />
      </ConditionalField>
      <ConditionalField condition={showReviewOptions} key="reviewedBy">
        <ControlledGroupAndUserSelect
          control={control}
          addEmptyOption={true}
          name="ReviewedBy"
          label={st('ReviewedBy')}
          description={st('ReviewedBy_help')}
          testId="reviewedBy"
          disabled={reviewFieldsDisabled}
          includeGroups={false}
        />
      </ConditionalField>
      <ConditionalField condition={showReviewOptions} key="reviewDate">
        <ControlledDatePicker
          name="ReviewDate"
          label={st('ReviewDate')}
          description={st('ReviewDate_help')}
          control={control}
          testId="reviewDate"
          disabled={reviewFieldsDisabled}
        />
      </ConditionalField>

      <ConditionalField condition={showReviewOptions} key="nextReviewDate">
        <ControlledDatePicker
          name="NextReviewDate"
          label={st('NextReviewDate')}
          description={st('NextReviewDate_help')}
          control={control}
          testId="nextReviewDate"
          disabled={reviewFieldsDisabled}
        />
      </ConditionalField>
    </CustomisableForm>
  );
};

export default DocumentVersionFormFields;
