import type { MultiselectProps } from '@cloudscape-design/components/multiselect';
import CSMultiSelect from '@cloudscape-design/components/multiselect';
import { forwardRef } from 'react';

import styles from './style.module.scss';

const Multiselect = forwardRef<MultiselectProps.Ref, MultiselectProps>(
  (props, ref) => {
    return (
      <div className={styles.select}>
        <CSMultiSelect {...props} ref={ref} />
      </div>
    );
  }
);
Multiselect.displayName = 'Multiselect';

export default Multiselect;
