import type { FC, PropsWithChildren } from 'react';

import FormBuilderContextProvider from './FormBuilderContextProviderUnwrapped';

export const withFormBuilderContextProvider = <P extends object>(
  Component: FC<PropsWithChildren<P>>
) => {
  return function ProviderWrapper(props: PropsWithChildren<P>) {
    return (
      <FormBuilderContextProvider>
        <Component {...props} />
      </FormBuilderContextProvider>
    );
  };
};
