import type {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import type { CollectionActions } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import type { CollectionPreferencesProps } from '@cloudscape-design/components/collection-preferences';
import type { PropertyFilterProps } from '@cloudscape-design/components/property-filter';
import type { TableProps } from '@cloudscape-design/components/table';
import type { ParseKeys } from 'i18next/typescript/t';
import type { StorageKeys } from 'src/state/useStorage';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import type { Order_By, Parent_Type_Enum } from '@/generated/graphql';

export type TableRecordColumnWidths<T extends TableRecord> = Record<
  keyof T,
  number | undefined
>;

export type TablePreferences<T extends TableRecord> =
  CollectionPreferencesProps.Preferences<{
    columnWidths?: TableRecordColumnWidths<T>;
  }>;

export type CustomContentDisplayItem = {
  id: string;
  visible: boolean;
  custom: boolean;
};

export type TablePropsWithActions<T extends TableRecord> = TableProps<T> & {
  actions: CollectionActions<T>;
  propertyFilterProps: PropertyFilterProps;
  propertyFilterQuery: PropertyFilterQuery;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
  allItems: readonly T[] | undefined;
  exportToCsvString: () => string;
  exportToCsv: () => void;
  preferenceDetails: {
    preferences: TablePreferences<T>;
    setPreferences: (preferences: TablePreferences<T>) => void;
  };
};

export const emptyFilterQuery = {
  tokens: [],
  operation: 'and',
} as const;

export type TableRecord = Record<string, unknown>;

type CustomFieldConfig<T extends TableRecord> =
  | {
      custom: true;
      customFieldValue: (record: T) => string | null;
    }
  | {
      custom?: false;
    };

export type FieldConfig<T extends TableRecord> = Partial<
  Omit<TableProps.ColumnDefinition<T>, 'header'>
> &
  CustomFieldConfig<T> & {
    header: string;
    filterOptions?: FieldFilterConfig;
    exportVal?: (record: T) => CsvFieldType;
    footerExportVal?: (records: readonly T[]) => CsvFieldType;
    footerVal?: (records: readonly T[]) => CsvFieldType;
    footerLabel?: string;
    sortingDisabled?: boolean;
    fieldType?: 'date' | 'number' | 'string';
    // TODO: remove this when we migrate all registers to the new date format
    _typename?: Parent_Type_Enum;
  };

type WhereFilterPart<T extends TableRecord> = {
  [K in keyof Partial<T>]: T[K] extends string | number | boolean | null
    ? Partial<{
        _eq: T[K] | null;
        _neq: T[K] | null;
        _ilike: T[K] | null;
        _nilike: T[K] | null;
        _lt: T[K] | null;
        _lte: T[K] | null;
        _gt: T[K] | null;
        _gte: T[K] | null;
      }> | null
    : T[K] extends TableRecord
      ? WhereFilterPart<T[K]>
      : never;
};

export type WhereFilter<T extends TableRecord> = WhereFilterPart<T> & {
  _and?: WhereFilter<T> | null;
  _or?: WhereFilter<T> | null;
  _not?: WhereFilter<T> | null;
};

type LazyDatasetOptions<T extends TableRecord> = {
  offset: number;
  limit: number;
  orderBy: Record<keyof T, Order_By>;
  where: WhereFilter<T>;
};

export type LazyDataset<T extends TableRecord> = (
  options: LazyDatasetOptions<T>
) => Promise<{ data: T[]; totalCount?: number }>;

export type Dataset<T extends TableRecord> = T[];

export type RootTablePropsOptions<
  T extends TableRecord,
  TDataset extends Dataset<T> | LazyDataset<T>,
> = {
  // TODO: Got a max of ParseKeys and string usage and the translation keys are working correctly. Need a better solution
  entityLabel: ParseKeys<'common'> | string;
  preferencesStorageKey?: StorageKeys;
  /**
   * Unique Id for the table.
   * Used for storing sort and filter state in db
   */
  tableId?: string;
  fields: TableFields<T>;
  data?: TDataset;
  customAttributeSchema?:
    | CustomAttributeFields
    | CustomAttributeFields[]
    | null;
  emptyCollectionAction?: JSX.Element;
  initialColumns?: (keyof T)[];
  defaultSortingState?: DefaultSortingState<T>;
  enableFiltering?: boolean;
  defaultPreferences?: CollectionPreferencesProps.Preferences;
  extraFilters?: React.ReactNode;
  useRelativeCustomAttributeDates?: boolean;
};

type FieldFilterConfig = {
  filteringProperties?: Partial<PropertyFilterProps.FilteringProperty>;
  filteringOptions?: ReadonlyArray<
    Omit<PropertyFilterProps.FilteringOption, 'propertyKey'>
  >;
};

export type TableFields<T extends TableRecord> = {
  [K in keyof Partial<T>]: FieldConfig<T>;
};

export type DefaultSortingState<T extends TableRecord> = {
  sortingColumn: keyof T;
  sortingDirection: 'asc' | 'desc';
};

export type CsvFieldType = number | string | boolean | null | undefined;
