import type { PieChartProps } from '@cloudscape-design/components/pie-chart';
import PieChart from '@cloudscape-design/components/pie-chart';
import i18n from '@risksmart-app/components/providers/i18n';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useDashboardFilter } from '../../../../../context/useDashboardFilter';
import { NoWidgetData } from '../../../widgets/NoWidgetData';
import { useAggregateCategories } from '../../hooks/useAggregateCategories';
import { useGetWidgetData } from '../../hooks/useGetWidgetData';
import type {
  AggregationType,
  CategoricalGigawidgetCommonProps,
  CategoryType,
  DataSourceItem,
  GigawidgetCommonProps,
  UnratedCategoryType,
  WidgetDataSource,
} from '../../types';
import type { PieSegment } from './hooks/usePieChartData';
import { usePieChartData } from './hooks/usePieChartData';
import styles from './styles.module.scss';

type CountFormatter = (
  datum: PieChartProps.Datum,
  sum: number
) => {
  key: string;
  value: string;
};

export type GigaPieWidgetProps<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
> = GigawidgetCommonProps<TDataSource> &
  CategoricalGigawidgetCommonProps<TDataSource, TCategory> & {
    donut?: boolean;
    countFormatter?: CountFormatter;
    aggregationType?: AggregationType;
    aggregationField?: keyof DataSourceItem<TDataSource>;
  };

export const GigaPieWidget = <
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
>({
  donut,
  dataSource,
  variables,
  categoryGetter,
  dateFilterOptions,
  onClickUrl,
  categoryRatingTranslationKey,
  categoryOverrideFunction,
  countFormatter,
  propertyFilterQuery,
  aggregationType,
  aggregationField,
}: GigaPieWidgetProps<TDataSource, TCategory>) => {
  const { filters } = useDashboardFilter();
  const {
    tableProps: { allItems },
    loading,
  } = useGetWidgetData({
    dataSource,
    variables,
    dateFilterOptions,
    propertyFilterQuery,
  });
  const highlightedSegment = useRef<PieSegment<
    DataSourceItem<TDataSource>,
    TCategory | UnratedCategoryType
  > | null>(null);

  const total = useMemo(
    () => (allItems ? (allItems?.length ?? 0) : 0),
    [allItems]
  );

  const navigate = useNavigate();

  const countedCategories = useAggregateCategories({
    dataSource,
    items: allItems,
    dateFilterOptions,
    categoryGetter,
    aggregationType,
    aggregationField,
  });

  const { t } = useTranslation();

  const pieChartData = usePieChartData(countedCategories, {
    categoryOverrideFunction,
    categoryKey: categoryRatingTranslationKey,
  });

  const description = i18n.format(
    t(dataSource.entityNamePlural),
    'capitalizeAll'
  );

  const handleClick = useCallback(() => {
    if (onClickUrl && highlightedSegment.current) {
      const url = onClickUrl(highlightedSegment.current.category, filters);
      if (url) {
        navigate(url);
      }
    }
  }, [highlightedSegment, navigate, onClickUrl, filters]);

  let dataLabel = t('dashboard.count');
  if (aggregationType) {
    switch (aggregationType) {
      case 'sum':
        dataLabel = 'Sum';
        break;
      case 'mean':
        dataLabel = 'Mean';
        break;
      case 'count':
        dataLabel = t('dashboard.count');
        break;
    }
  }

  return (
    <div
      className={styles.pieChart}
      onClick={handleClick}
      aria-describedby={'awsui-pie-chart'}
    >
      <PieChart
        statusType={loading ? 'loading' : 'finished'}
        variant={donut ? 'donut' : 'pie'}
        hideLegend={true}
        onHighlightChange={(e) =>
          (highlightedSegment.current = e.detail.highlightedSegment)
        }
        hideFilter={true}
        innerMetricValue={total.toString()}
        innerMetricDescription={description}
        data={pieChartData}
        detailPopoverContent={(datum, sum) => [
          countFormatter
            ? countFormatter(datum, sum)
            : { key: dataLabel, value: datum.value },
          {
            key: t('dashboard.percentage'),
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
        segmentDescription={(datum, sum) =>
          countFormatter ? countFormatter(datum, sum).value : `${datum.value}`
        }
        ariaDescription={description}
        empty={<NoWidgetData />}
      />
    </div>
  );
};
