import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ConsequenceFormFields } from 'src/schemas/consequenceSchema';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import { Consequence_Type_Enum, Cost_Type_Enum } from '@/generated/graphql';
import { useLibrary } from '@/hooks/use-library';

type SuggestedTitle = {
  value: string;
  label: string;
  type: 'library';
};

type Props = {
  readOnly?: boolean;
};

const ConsequenceForm: FC<Props> = ({ readOnly }) => {
  const { control, setValue } = useFormContext<ConsequenceFormFields>();

  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences',
  });

  const costTypes = Object.values(Cost_Type_Enum).map((key) => ({
    value: key,
    label: st('costType')[key],
  }));

  const consequencesLibrary = useLibrary('consequences');

  const autoPopulateDescription = (selectedTitleOption?: OptionDefinition) => {
    if (!selectedTitleOption) {
      return;
    }
    const selectedOption = selectedTitleOption as SuggestedTitle;
    const description = consequencesLibrary.find(
      (control) => control.title === selectedOption?.value
    )?.description;
    if (description) {
      setValue('Description', description);
    }
  };

  const consequencesLibraryOptions: SuggestedTitle[] = consequencesLibrary.map(
    (c) => ({
      value: c.title,
      label: c.title,
      type: 'library',
    })
  );

  const types = Object.values(Consequence_Type_Enum).map((value) => ({
    value,
    label: st('types')[value],
  }));

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledAutosuggest
        key="title"
        forceRequired={true}
        name="Title"
        label={st('fields.Title')}
        description={st('fields.Title_help')}
        control={control}
        placeholder={st('fields.Title_placeholder') ?? ''}
        onSelect={(e) => {
          autoPopulateDescription(e.detail.selectedOption);
        }}
        options={[
          {
            value: 'Library',
            label: 'Library',
            options: consequencesLibraryOptions,
          },
        ]}
        disabled={readOnly}
      />
      <ControlledSelect
        key="type"
        addEmptyOption={true}
        control={control}
        name="Type"
        label={st('fields.Type')}
        description={st('fields.Type_help')}
        options={types}
        disabled={readOnly}
      />

      <ControlledRating
        key="criticality"
        filteringType="none"
        label={st('fields.Criticality')}
        name="Criticality"
        placeholder={t('select') ?? ''}
        description={st('fields.Criticality_help')}
        control={control}
        type="criticality"
        disabled={readOnly}
      />

      <ControlledSelect
        key="costType"
        forceRequired={true}
        control={control}
        name="CostType"
        label={st('fields.CostType')}
        description={st('fields.CostType_help')}
        options={costTypes}
        disabled={readOnly}
      />

      <ControlledInput
        key="costValue"
        type="number"
        forceRequired={true}
        name="CostValue"
        label={st('fields.CostValue')}
        control={control}
        placeholder={st('fields.CostValue_placeholder') ?? ''}
        description={st('fields.CostValue_help')}
        disabled={readOnly}
      />

      <ControlledTextarea
        key="description"
        defaultRequired={true}
        name="Description"
        label={st('fields.Description')}
        description={st('fields.Description_help')}
        placeholder={st('fields.Description_placeholder') ?? ''}
        control={control}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default ConsequenceForm;
