
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_14yj0_9i69u_145",
  "no-gutters": "awsui_no-gutters_14yj0_9i69u_181",
  "grid-column": "awsui_grid-column_14yj0_9i69u_186",
  "colspan-1": "awsui_colspan-1_14yj0_9i69u_197",
  "push-1": "awsui_push-1_14yj0_9i69u_201",
  "pull-1": "awsui_pull-1_14yj0_9i69u_204",
  "colspan-2": "awsui_colspan-2_14yj0_9i69u_207",
  "push-2": "awsui_push-2_14yj0_9i69u_211",
  "pull-2": "awsui_pull-2_14yj0_9i69u_214",
  "colspan-3": "awsui_colspan-3_14yj0_9i69u_217",
  "push-3": "awsui_push-3_14yj0_9i69u_221",
  "pull-3": "awsui_pull-3_14yj0_9i69u_224",
  "colspan-4": "awsui_colspan-4_14yj0_9i69u_227",
  "push-4": "awsui_push-4_14yj0_9i69u_231",
  "pull-4": "awsui_pull-4_14yj0_9i69u_234",
  "colspan-5": "awsui_colspan-5_14yj0_9i69u_237",
  "push-5": "awsui_push-5_14yj0_9i69u_241",
  "pull-5": "awsui_pull-5_14yj0_9i69u_244",
  "colspan-6": "awsui_colspan-6_14yj0_9i69u_247",
  "push-6": "awsui_push-6_14yj0_9i69u_251",
  "pull-6": "awsui_pull-6_14yj0_9i69u_254",
  "colspan-7": "awsui_colspan-7_14yj0_9i69u_257",
  "push-7": "awsui_push-7_14yj0_9i69u_261",
  "pull-7": "awsui_pull-7_14yj0_9i69u_264",
  "colspan-8": "awsui_colspan-8_14yj0_9i69u_267",
  "push-8": "awsui_push-8_14yj0_9i69u_271",
  "pull-8": "awsui_pull-8_14yj0_9i69u_274",
  "colspan-9": "awsui_colspan-9_14yj0_9i69u_277",
  "push-9": "awsui_push-9_14yj0_9i69u_281",
  "pull-9": "awsui_pull-9_14yj0_9i69u_284",
  "colspan-10": "awsui_colspan-10_14yj0_9i69u_287",
  "push-10": "awsui_push-10_14yj0_9i69u_291",
  "pull-10": "awsui_pull-10_14yj0_9i69u_294",
  "colspan-11": "awsui_colspan-11_14yj0_9i69u_297",
  "push-11": "awsui_push-11_14yj0_9i69u_301",
  "pull-11": "awsui_pull-11_14yj0_9i69u_304",
  "colspan-12": "awsui_colspan-12_14yj0_9i69u_307",
  "push-12": "awsui_push-12_14yj0_9i69u_311",
  "pull-12": "awsui_pull-12_14yj0_9i69u_314",
  "push-0": "awsui_push-0_14yj0_9i69u_317",
  "pull-0": "awsui_pull-0_14yj0_9i69u_320",
  "offset-1": "awsui_offset-1_14yj0_9i69u_323",
  "offset-2": "awsui_offset-2_14yj0_9i69u_326",
  "offset-3": "awsui_offset-3_14yj0_9i69u_329",
  "offset-4": "awsui_offset-4_14yj0_9i69u_332",
  "offset-5": "awsui_offset-5_14yj0_9i69u_335",
  "offset-6": "awsui_offset-6_14yj0_9i69u_338",
  "offset-7": "awsui_offset-7_14yj0_9i69u_341",
  "offset-8": "awsui_offset-8_14yj0_9i69u_344",
  "offset-9": "awsui_offset-9_14yj0_9i69u_347",
  "offset-10": "awsui_offset-10_14yj0_9i69u_350",
  "offset-11": "awsui_offset-11_14yj0_9i69u_353",
  "restore-pointer-events": "awsui_restore-pointer-events_14yj0_9i69u_357"
};
  