import Link from '@risksmart-app/components/Link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import type { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import type { DataImportFields, DataImportTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<DataImportTableFields> => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { getByValue } = useRating('data_import_status');

  return useMemo(
    () => ({
      Id: {
        header: t('guid'),
        cell: (data) => {
          return <Link href={data.Id}>{data.Id}</Link>;
        },
      },
      StatusLabel: {
        header: t('status'),
        cell: (data) => <SimpleRatingBadge rating={getByValue(data.Status)} />,
      },
      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: t('updated_by_username') },
      CreatedByUserName: { header: t('created_by_username') },
    }),
    [getByValue, t]
  );
};

const useGetDataImportTableProps = (
  records: DataImportFields[] | undefined
): UseGetTablePropsOptions<DataImportTableFields> => {
  const labelledFields = useLabelledFields(records);
  const { t } = useTranslation(['common'], { keyPrefix: 'dataImport' });
  const fields = useGetFieldConfig();

  return {
    tableId: 'dataImportRegister',
    data: labelledFields,
    customAttributeSchema: null,
    entityLabel: t('entity_name'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'DataImportsRegisterTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: [
      'Id',
      'StatusLabel',
      'CreatedByUserName',
      'CreatedAtTimestamp',
    ],
    fields,
  };
};

export const useGetCollectionTableProps = (
  records: DataImportFields[] | undefined
): TablePropsWithActions<DataImportTableFields> => {
  const props = useGetDataImportTableProps(records);

  return useGetTableProps(props);
};
