
    import './styles.scoped.css';
    export default {
  "breadcrumb-group": "awsui_breadcrumb-group_d19fg_jbvwc_145",
  "breadcrumb-group-list": "awsui_breadcrumb-group-list_d19fg_jbvwc_180",
  "ghost": "awsui_ghost_d19fg_jbvwc_191",
  "item": "awsui_item_d19fg_jbvwc_196",
  "ghost-item": "awsui_ghost-item_d19fg_jbvwc_197",
  "ellipsis": "awsui_ellipsis_d19fg_jbvwc_198",
  "hide": "awsui_hide_d19fg_jbvwc_209",
  "visible": "awsui_visible_d19fg_jbvwc_215",
  "icon": "awsui_icon_d19fg_jbvwc_219",
  "breadcrumbs-skeleton": "awsui_breadcrumbs-skeleton_d19fg_jbvwc_225",
  "collapsed-button": "awsui_collapsed-button_d19fg_jbvwc_229",
  "button-icon": "awsui_button-icon_d19fg_jbvwc_311",
  "button-icon-open": "awsui_button-icon-open_d19fg_jbvwc_325",
  "hidden": "awsui_hidden_d19fg_jbvwc_329"
};
  