import Container from '@cloudscape-design/components/container';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { JsonForms } from '@jsonforms/react';
import Button from '@risksmart-app/components/Button';
import { withFormBuilderContextProvider } from '@risksmart-app/components/FormBuilder/contexts/FormBuilderContextProvider';
import { withJsonFormsContextProvider } from '@risksmart-app/components/FormBuilder/contexts/JsonFormsContextProvider';
import Loading from '@risksmart-app/components/Loading';
import renderers from '@risksmart-app/components/renderers/registry';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal';
import {
  Third_Party_Response_Status_Enum,
  useDeleteThirdPartyResponseMutation,
  useGetThirdPartyResponseByIdQuery,
  useUpdateThirdPartyResponseMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { thirdPartyRegisterUrl } from '@/utils/urls';

interface Props {
  activeTabId?:
    | 'details'
    | 'questionnaires'
    | 'controls'
    | 'issues'
    | 'actions'
    | 'linkedItems';
  showDeleteButton?: boolean;
}

const Page: FC<Props> = ({ showDeleteButton }: Props) => {
  const Id = useGetGuidParam('responseId');
  const navigate = useNavigate();
  const { t: st } = useTranslation(['common'], { keyPrefix: 'third_party' });

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { data, loading } = useGetThirdPartyResponseByIdQuery({
    variables: { Id },
  });

  const title =
    data?.third_party_response_by_pk?.questionnaireTemplateVersion?.parent
      ?.Title || '';

  const [update] = useUpdateThirdPartyResponseMutation({
    fetchPolicy: 'no-cache',
  });

  const [mutate, deleteResult] = useDeleteThirdPartyResponseMutation({
    fetchPolicy: 'no-cache',
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!data) {
        return false;
      }
      await mutate({
        variables: {
          Id,
        },
      });
      navigate(thirdPartyRegisterUrl());

      return true;
    },
  });

  if (loading) {
    return (
      <PageLayout title={title}>
        <Loading />
      </PageLayout>
    );
  }

  const status = data?.third_party_response_by_pk?.Status;
  const isDisabled = status !== Third_Party_Response_Status_Enum.AwaitingReview;

  return (
    <PageLayout
      title={title}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          {showDeleteButton && (
            <Permission
              permission="delete:third_party_response"
              parentObject={data?.third_party_response_by_pk?.thirdParty}
            >
              <Button
                variant="normal"
                formAction="none"
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {st('delete_button')}
              </Button>
            </Permission>
          )}
        </SpaceBetween>
      }
    >
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>

      <Container>
        <JsonForms
          schema={
            data?.third_party_response_by_pk?.questionnaireTemplateVersion
              ?.Schema
          }
          uischema={
            data?.third_party_response_by_pk?.questionnaireTemplateVersion
              ?.UISchema
          }
          data={data?.third_party_response_by_pk?.ResponseData}
          renderers={renderers}
          readonly={true}
        />
        <SpaceBetween size={'s'} direction={'horizontal'}>
          <Button
            disabled={isDisabled}
            variant={'primary'}
            onClick={() => {
              update({
                variables: {
                  Id,
                  Status: Third_Party_Response_Status_Enum.Completed,
                },
              });

              navigate(-1);
            }}
          >
            Approve
          </Button>
          <Button
            disabled={isDisabled}
            variant={'normal'}
            onClick={() => {
              update({
                variables: {
                  Id,
                  Status: Third_Party_Response_Status_Enum.Rejected,
                },
              });

              navigate(-1);
            }}
          >
            Reject
          </Button>
          <Button
            disabled={false}
            variant={'normal'}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </SpaceBetween>
      </Container>
    </PageLayout>
  );
};

const PageWrapper = withJsonFormsContextProvider(
  withFormBuilderContextProvider(Page)
);

export default PageWrapper;
