export enum TestIds {
  Datasource = 'dataSource',
  ChartType = 'chartType',
  Filtering = 'filtering',
  Category = 'category',
  SubCategory = 'subCategory',
  DatePrecision = 'datePrecision',
  AggregateFunction = 'aggregateFunction',
  AggregateField = 'aggregateField',
}
