/**
 * Base Config
 * Uses Tailwind ThemeConfig schema
 * Shared between Tailwind and Cloudscape
 */
import { ThemeConfig } from 'tailwindcss/types/config';

const theme = {
  colors: {
    light_blue: '#ECFBFA',
    navy: '#0F0F2D',
    navy_light: '#2D2D53',
    navy_mid: '#14143A',
    teal: '#00DECB',
    dark_green: '#0F4D55',
    teal2: '#15BEB0',
    teal3: '#079589',
    white: '#FFFFFF',
    off_white: '#F6F6FB',
    transparent: 'rgba(0,0,0,0)',
    blue100: '#F2F8FD',
    blue200: '#D9F7F5',
    grey: '#B9B9C6',
    grey150: '#EDEDF2',
    grey200: '#E8E8EC',
    grey250: '#E4E4E8',
    grey300: '#D0D0D9',
    grey500: '#8B8BA0',
    grey550: '#73738C',
    grey600: '#5C5C79',
    grey650: '#454566',
    grey800: '#121233',
    red: '#d91515',
    orange: '#F2A041',
    orange_light: '#FEF6EE',
  },
  fontFamily: {
    sans: ['Sora', 'sans-serif'],
  },
} satisfies Partial<ThemeConfig>;

export default theme;
