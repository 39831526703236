import { useQuery } from '@apollo/client';
import type { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import _ from 'lodash';
import { merge } from 'ts-deepmerge';

import type { StatefulTableOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import type { TablePropsWithActions } from '@/utils/table/types';

import propertyFilter from '../propertyFilter';
import type {
  DataSourceData,
  DataSourceItem,
  DataSourceVariables,
  DateFilterOptions,
  WidgetDataSource,
} from '../types';
import { useDataSourceFilter } from './useDataSourceFilter';

type GetWidgetDataResult<TDataSource extends WidgetDataSource> = {
  loading?: boolean;
  tableProps: TablePropsWithActions<DataSourceItem<TDataSource>>;
  data: DataSourceData<TDataSource> | undefined;
};

type UseGetWidgetDataOptions<TDataSource extends WidgetDataSource> = {
  dataSource: TDataSource;
  disableDashboardFilters?: boolean;
  dateFilterOptions?: DateFilterOptions<TDataSource>;
  variables?: Partial<DataSourceVariables<TDataSource>>;
  propertyFilterQuery?: PropertyFilterQuery;
  cacheOnly?: boolean;
  tableOptions?: Omit<
    StatefulTableOptions<DataSourceVariables<TDataSource>>,
    'propertyFilter'
  >;
};

export const useGetWidgetData = <TDataSource extends WidgetDataSource>(
  options: UseGetWidgetDataOptions<TDataSource>
): GetWidgetDataResult<TDataSource> => {
  const { dataSource, dateFilterOptions, variables } = options;

  const where = useDataSourceFilter(dataSource, dateFilterOptions);

  const { data, loading } = useQuery<
    DataSourceData<TDataSource>,
    DataSourceVariables<TDataSource>
  >(dataSource.documentNode, {
    variables: merge(
      options.disableDashboardFilters ? {} : where,
      variables ?? {},
      options.dataSource.defaultVariables
    ) as DataSourceVariables<TDataSource>,
    fetchPolicy: options.cacheOnly ? 'cache-first' : 'no-cache',
  });

  const tableProps = options.dataSource.useTablePropsHook(data, {
    propertyFilter: options?.propertyFilterQuery,
    sortingState: options.tableOptions?.sortingState,
    setSortingState: options.tableOptions?.setSortingState ?? _.noop,
    preferences: options.tableOptions?.preferences,
    setPreferences: options.tableOptions?.setPreferences ?? _.noop,
    setPropertyFilter: _.noop,
  });

  tableProps.allItems =
    options.propertyFilterQuery && options.propertyFilterQuery.tokens.length > 0
      ? propertyFilter(tableProps.allItems, options.propertyFilterQuery, {
          filteringProperties: tableProps.filteringProperties,
        })
      : tableProps.allItems;

  return { data, tableProps, loading: loading || !data };
};
