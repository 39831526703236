import { createContext } from 'react';

import type {
  Appetite_Model_Enum,
  Risk_Scoring_Model_Enum,
} from '@/generated/graphql';

type AggregationContext = {
  loading: boolean;
  name: string;
  riskScoringModel: Risk_Scoring_Model_Enum;
  appetiteAggregation: Appetite_Model_Enum;
};

export const AggregationContext = createContext<AggregationContext | null>(
  null
);
