import pdfMake from 'pdfmake/build/pdfmake';

import fonts from './fonts';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(pdfMake as any).vfs = fonts;
import type { TDocumentDefinitions } from 'pdfmake/interfaces';

export const download = (doc: TDocumentDefinitions) => {
  const win = window.open('', '_blank');
  pdfMake.createPdf(doc).open({}, win);
};
