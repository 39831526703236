import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TotalsRibbon from '@/components/TotalsRibbon';
import { Cost_Type_Enum } from '@/generated/graphql';

import { calculateCostTotal } from './utils';

type Props = {
  consequences: { CostType: Cost_Type_Enum; CostValue: number }[];
};

const ConsequenceTotalsRibbon: FC<Props> = ({ consequences }) => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences.totals_ribbon',
  });

  return (
    <TotalsRibbon
      items={[
        {
          label: st('cost'),
          value:
            '£' +
            calculateCostTotal(
              consequences,
              Cost_Type_Enum.Pounds
            ).toLocaleString(),
        },
        {
          label: st('customers_impacted'),
          value:
            calculateCostTotal(
              consequences,
              Cost_Type_Enum.CustomersImpacted
            ).toLocaleString() ?? '0',
        },
        {
          label: st('hours'),
          value:
            calculateCostTotal(
              consequences,
              Cost_Type_Enum.Hours
            ).toLocaleString() ?? '0',
        },
      ]}
    />
  );
};

export default ConsequenceTotalsRibbon;
