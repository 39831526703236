import FormField from '@cloudscape-design/components/form-field';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import type { QuestionData } from '../../FormBuilder/form-configs/question';
import { FormQuestionModal } from '../../FormBuilder/FormQuestionModal';
import { FORM_BUILDER_ACTION } from '../../FormBuilder/useFormBuilderActions';
import type {
  CustomSchema,
  CustomUISchemaElement,
} from '../../FormBuilder/useFormBuilderContext';
import { useFormBuilderContext } from '../../FormBuilder/useFormBuilderContext';
import { useJsonFormContext } from '../../FormBuilder/useJsonFormContext';
import FormEditButton from '../../FormEditButton/FormEditButton';

type CustomisableControlProps = {
  label: string;
  errors: string;
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
  children: ReactNode | ReactNode[];
};

const CustomisableControl: FC<CustomisableControlProps> = ({
  children,
  schema,
  uischema,
  errors,
  label,
}) => {
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [isFieldDirty] = useState(false);
  const { isFormDirty } = useJsonFormContext();
  const { isFormCustomisable } = useFormBuilderContext();

  const initialQuestionData: QuestionData = {
    questionTitle: uischema?.label ? `${uischema.label}` : '',
    placeholder: uischema?.options?.placeholder
      ? uischema.options.placeholder
      : '',
    fieldType: uischema?.options?.fieldType || 'text',
    isPropertyRequired: (schema?.minLength && schema.minLength > 0) || false,
  };

  const [questionData, setQuestionData] = useState(initialQuestionData);

  const isCustomisable = isFormCustomisable && schema?.isCustomisable;

  return (
    <>
      <div className={'pb-6'}>
        <FormField
          label={label}
          stretch={true}
          errorText={isFieldDirty || isFormDirty ? errors : ''}
        >
          <div className={isCustomisable ? 'flex gap-x-4' : ''}>
            {children}

            {isCustomisable ? (
              <FormEditButton onClick={() => setIsEditingQuestion(true)} />
            ) : null}
          </div>
        </FormField>
      </div>

      {isCustomisable ? (
        <FormQuestionModal
          action={FORM_BUILDER_ACTION.EDIT}
          parentId={uischema?.parentId || ''}
          questionData={questionData}
          setQuestionData={setQuestionData}
          isEditingQuestion={isEditingQuestion}
          setIsEditingQuestion={setIsEditingQuestion}
          initialQuestionData={questionData}
          uiSchema={uischema}
        />
      ) : null}
    </>
  );
};

export default CustomisableControl;
