import { InternalAuditUpdateBreadcrumb, InternalAuditUpdatePage } from '@pages';
import i18n from '@risksmart-app/components/providers/i18n';
import type { RouteObject } from 'react-router';

import ProtectedRoute from '../rbac/ProtectedRoute';
import { actionRoute } from './actionRoutes.config';
import { internalAuditReportsRoute } from './internalAuditReportRoutes.config';
import { issueRoute } from './issueRoutes.config';

export const internalAuditRoute: RouteObject = {
  path: ':internalAuditId',
  handle: {
    breadcrumb: InternalAuditUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute
          permission="read:internal_audit_entity"
          canHaveAccessAsContributor={true}
        >
          <InternalAuditUpdatePage activeTabId="details" />
        </ProtectedRoute>
      ),
    },
    {
      path: 'reports',
      handle: {
        title: () =>
          i18n.format(i18n.t('internal_audit_report_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission="read:internal_audit_entity"
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId="reports" />
            </ProtectedRoute>
          ),
        },
        internalAuditReportsRoute,
      ],
    },
    {
      path: 'issues',
      handle: {
        title: () => i18n.format(i18n.t('issue_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission="read:internal_audit_entity"
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId="issues" />
            </ProtectedRoute>
          ),
        },
        issueRoute,
      ],
    },
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission="read:internal_audit_entity"
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId="actions" />
            </ProtectedRoute>
          ),
        },
        actionRoute,
      ],
    },
    {
      path: 'risks',
      handle: {
        title: () => i18n.format(i18n.t('risk_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute
              permission="read:internal_audit_entity"
              canHaveAccessAsContributor={true}
            >
              <InternalAuditUpdatePage activeTabId="risks" />
            </ProtectedRoute>
          ),
        },
        internalAuditReportsRoute,
      ],
    },
  ],
};
