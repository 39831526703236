import type { SideNavigationProps } from '@cloudscape-design/components/side-navigation';

import { Item } from './Item';
import type { Icon } from './Navigation';

export interface SectionGroupProps {
  item: SideNavigationProps.SectionGroup & Icon;
}
export const SectionGroup = ({ item }: SectionGroupProps) => {
  return <Item>{item.title}</Item>;
};
