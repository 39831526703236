import { JsonForms } from '@jsonforms/react';
import type { ErrorObject } from 'ajv';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  SpaceBetween,
  TextContent,
} from '../../theme/risksmart-themed/components';
import Button from '../Button';
import DeleteButton from '../DeleteButton';
import DeleteModal from '../DeleteModal';
import rendererRegistry from '../renderers/registry';
import type { QuestionData } from './form-configs/question';
import { questionUISchema, useQuestionSchema } from './form-configs/question';
import {
  FORM_BUILDER_ACTION,
  useFormBuilderActions,
} from './useFormBuilderActions';
import type {
  CustomUISchema,
  CustomUISchemaElement,
} from './useFormBuilderContext';

export type EditFormQuestionModalProps = {
  action: FORM_BUILDER_ACTION;
  parentId: string;
  questionData: QuestionData;
  setQuestionData: (questionData: QuestionData) => void;
  isEditingQuestion: boolean;
  setIsEditingQuestion: (isEditingQuestion: boolean) => void;
  initialQuestionData: QuestionData;
  uiSchema: CustomUISchema | CustomUISchemaElement;
};

export const FormQuestionModal: FC<EditFormQuestionModalProps> = ({
  action,
  parentId,
  questionData,
  setQuestionData,
  isEditingQuestion,
  setIsEditingQuestion,
  initialQuestionData,
  uiSchema,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>(undefined);

  const questionSchema = useQuestionSchema();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formQuestion',
  });

  const {
    isFormCustomisable,
    setIsFormDirtyState,
    addNewQuestion,
    updateQuestion,
    deleteQuestion,
  } = useFormBuilderActions();

  const resetModal = () => {
    setQuestionData(initialQuestionData);
    setIsEditingQuestion(false);
    setIsFormDirtyState(false);
  };

  const handleEditQuestionCancel = () => {
    resetModal();
  };

  const handleAddNewQuestion = () => {
    addNewQuestion(questionData, parentId);
    resetModal();
  };

  const handleUpdateQuestion = () => {
    updateQuestion(questionData, uiSchema, parentId);
    resetModal();
  };

  const handleSaveQuestion = () => {
    if (errors?.length) {
      setIsFormDirtyState(true);

      return;
    }

    if (action === FORM_BUILDER_ACTION.ADD) {
      handleAddNewQuestion();
    }

    if (action === FORM_BUILDER_ACTION.EDIT) {
      handleUpdateQuestion();
    }
  };

  const handleDeleteQuestion = () => {
    deleteQuestion(uiSchema, parentId);
    resetModal();
  };

  return (
    <>
      {isEditingQuestion ? (
        <>
          <Modal
            onDismiss={resetModal}
            visible={isEditingQuestion}
            header={
              action === FORM_BUILDER_ACTION.ADD
                ? t('addQuestionModalTitle')
                : t('editQuestionModalTitle')
            }
            footer={
              <div className={'flex justify-between'}>
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" onClick={handleSaveQuestion}>
                    {t('saveButtonLabel')}
                  </Button>
                  <Button variant="normal" onClick={handleEditQuestionCancel}>
                    {t('cancelButtonLabel')}
                  </Button>
                </SpaceBetween>
                {action === FORM_BUILDER_ACTION.EDIT ? (
                  <DeleteButton onClick={() => setIsDeleteModalVisible(true)}>
                    {t('deleteButtonLabel')}
                  </DeleteButton>
                ) : null}
              </div>
            }
          >
            <JsonForms
              data={questionData}
              readonly={!isFormCustomisable}
              schema={questionSchema}
              uischema={questionUISchema}
              renderers={rendererRegistry}
              onChange={({ data, errors }) => {
                const formattedErrors = errors?.map((error) => ({
                  ...error,
                  dataPath: '',
                })) as ErrorObject[] | undefined;

                setErrors(formattedErrors);
                setQuestionData(data);
              }}
            />
          </Modal>

          <DeleteModal
            loading={false}
            isVisible={isDeleteModalVisible}
            onDelete={handleDeleteQuestion}
            onDismiss={() => setIsDeleteModalVisible(false)}
            header={t('deleteModal.header')}
          >
            <TextContent>
              <p>{t('deleteModal.body1')}</p>
              <p>{t('deleteModal.body2')}</p>
            </TextContent>
          </DeleteModal>
        </>
      ) : null}
    </>
  );
};
