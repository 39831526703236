import ExpandableSection from '@cloudscape-design/components/expandable-section';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import type { GetControlByIdQuery } from '@/generated/graphql';
import { useGetInternalAuditReportTestResultsByControlIdQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import TestResultModal from '../TestResultModal';
import { useGetCollectionTableProps } from './internalAuditPerformanceRatingConfig';

type Props = {
  control: GetControlByIdQuery['control'][number];
};

const InternalAuditPerformanceRatingRegister: FC<Props> = ({ control }) => {
  useI18NSummaryHelpContent('testResults.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'testResults',
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();
  const [openTestResultId, setOpenTestResultId] = useState<
    string | undefined
  >();

  const { data, loading } =
    useGetInternalAuditReportTestResultsByControlIdQuery({
      variables: {
        controlId: control.Id,
      },
      fetchPolicy: 'no-cache',
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const handleTestResultModalClose = () => {
    setOpenTestResultId(undefined);
    setIsEditOpen(false);
  };

  const tableProps = useGetCollectionTableProps(
    (testResult) => {
      setOpenTestResultId(testResult.Id);
      setIsEditOpen(true);
    },
    data?.test_result,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{st('internalAuditRatingSubheading')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <Table
          {...tableProps}
          resizableColumns={true}
          variant="embedded"
          loading={loading}
          loadingText={t('loadingTestResults') ?? ''}
          sortingDisabled={false}
        />
      </ExpandableSection>
      {isEditOpen && control.Id && (
        <TestResultModal
          parentControlId={control.Id}
          Id={openTestResultId}
          onDismiss={handleTestResultModalClose}
          assessmentMode={'internal_audit_report'}
        />
      )}
    </>
  );
};

export default InternalAuditPerformanceRatingRegister;
