import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import type { ToolsContent } from './useTools';
import { ToolsContext } from './useTools';

type Props = {
  children: ReactNode;
};

export const ToolsProvider: FC<Props> = ({ children }) => {
  const state = useState<ToolsContent>(undefined);
  const location = useLocation();
  const [toolsContent, setToolsContent] = state;
  useEffect(() => {
    // Close right panel on content change
    if (toolsContent !== 'notifications') {
      setToolsContent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setToolsContent, location.pathname]);

  return (
    <ToolsContext.Provider value={state}>{children}</ToolsContext.Provider>
  );
};
