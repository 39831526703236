import type { FC } from 'react';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { colours } from '@/utils/colours';

import styles from './style.module.scss';

export interface BadgeListProps {
  badges: string[];
  color?: string;
}

const BadgeList: FC<BadgeListProps> = ({ badges = [], color }) => {
  return badges.length ? (
    <div className={styles.badgeList}>
      {[...badges]
        ?.sort((a, b) => a.localeCompare(b))
        .map((badge, i) => (
          <SimpleRatingBadge
            key={i + badge}
            rating={{
              color: color ?? colours['charts-grey-450'].backgroundColor,
              label: badge || '',
            }}
          >
            {badge}
          </SimpleRatingBadge>
        ))}
    </div>
  ) : (
    false
  );
};

export default BadgeList;
