import type {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import type { ThirdPartyRegisterFields } from './types';

export type ThirdPartyRibbonProps = {
  items: readonly ThirdPartyRegisterFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const ThirdPartyRibbon: FC<ThirdPartyRibbonProps> = ({
  items,
  propertyFilterQuery,
  onFilterQueryChanged,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'third_party.dashboard',
  });
  const { options } = useRating('third_party_status');

  return (
    <RegisterDashboard>
      {options.map(({ value, label }) => (
        <FilterPropertyDashboardItem
          key={value}
          tableFilterQuery={propertyFilterQuery}
          onClick={onFilterQueryChanged}
          itemFilterQuery={{
            tokens: [
              {
                value: label,
                propertyKey: 'StatusLabelled',
                operator: '=',
              },
            ],
            operation: 'and',
          }}
          title={label}
          items={items ?? []}
          filteringProperties={filteringProperties}
        />
      ))}

      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
