import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import InternalAuditFormFields from './InternalAuditFormFields';
import type { InternalAuditFormDataFields } from './internalAuditSchema';
import { defaultValues, InternalAuditFormSchema } from './internalAuditSchema';

export type Props = Omit<
  FormContextProps<InternalAuditFormDataFields>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'defaultValues'
  | 'renderTemplate'
  | 'i18n'
>;

const InternalAuditForm: FC<Props> = (props) => {
  const { user } = useRisksmartUser();
  const { t } = useTranslation(['common']);
  const defaultData: InternalAuditFormDataFields = {
    ...defaultValues,
    Owners: [
      {
        type: 'user',
        value: user!.userId,
      },
    ],
  };

  return (
    <CustomisableFormContext
      {...props}
      header={t('details')}
      schema={InternalAuditFormSchema}
      defaultValues={defaultData}
      i18n={t('internalAudits')}
      formId={'internal-audit-form'}
      parentType={Parent_Type_Enum.InternalAuditEntity}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    >
      <InternalAuditFormFields readOnly={props.readOnly} />
    </CustomisableFormContext>
  );
};

export default InternalAuditForm;
