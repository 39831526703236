import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetAssessmentActivitiesByParentIdLazyQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useAssessmentActivityExportTable = (
  parentId: string
): [() => Promise<ContentTable>, boolean] => {
  const [getAssessmentActivities, getAssessmentActivitiesResult] =
    useGetAssessmentActivitiesByParentIdLazyQuery({
      variables: {
        AssessmentId: parentId,
      },
    });

  const { t: assessmentActivityColumns } = useTranslation(['common'], {
    keyPrefix: 'assessmentActivities.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'assessmentActivities',
  });
  const createExportTable = async () => {
    const { data: assessmentActivityData } = await getAssessmentActivities();
    const status = t('status');
    const type = t('type');
    const assessmentActivityTableData = (
      assessmentActivityData?.assessment_activity ?? []
    ).map((i) => [
      i.ActivityType ? type[i.ActivityType] : '-',
      i.Title!,
      i.Status ? status[i.Status] : '-',
      toLocalDate(i.CompletionDate),
    ]);

    return createTable({
      widths: [70, '*', 50, 70],
      body: [
        tableHeaders([
          assessmentActivityColumns('Type'),
          assessmentActivityColumns('Title'),
          assessmentActivityColumns('Status'),
          assessmentActivityColumns('CompletionDate'),
        ]),
        ...assessmentActivityTableData,
      ],
    });
  };

  return [createExportTable, getAssessmentActivitiesResult.loading];
};

export default useAssessmentActivityExportTable;
