import {
  CustomAttributeDataSchema,
  InheritedContributorSchema,
  NullableStringDateSchema,
  TagsAndDepartmentsSchema,
  UserOrGroupsSchema,
} from 'src/schemas/global';
import { z } from 'zod';

import { Control_Type_Enum, Test_Frequency_Enum } from '@/generated/graphql';

export const ControlFormSchema = z
  .object({
    Title: z.string().min(1, { message: 'Required' }),
    Type: z.nativeEnum(Control_Type_Enum, { invalid_type_error: 'Required' }),
    Description: z.string(),
    TestFrequency: z.nativeEnum(Test_Frequency_Enum).nullish(),
    NextTestDate: NullableStringDateSchema,
    Contributors: UserOrGroupsSchema,
    ancestorContributors: InheritedContributorSchema,
    Owners: UserOrGroupsSchema.min(1, { message: 'Required' }),
  })
  .and(CustomAttributeDataSchema)
  .and(TagsAndDepartmentsSchema)
  .transform((o) => ({ ...o, TestFrequency: o.TestFrequency }));

export type ControlFormFieldData = z.infer<typeof ControlFormSchema>;

export const defaultValues: ControlFormFieldData = {
  Title: '',
  Description: '',
  Type: null as unknown as Control_Type_Enum,
  TagTypeIds: [],
  tags: [],
  departments: [],
  DepartmentTypeIds: [],
  Owners: [],
  ancestorContributors: [],
  Contributors: [],
  TestFrequency: null,
  CustomAttributeData: null,
  NextTestDate: null,
};
