import type { FC } from 'react';
import { useNavigate } from 'react-router';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  Parent_Type_Enum,
  useCreateThirdPartyMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';
import { thirdPartyDetailsUrl } from '@/utils/urls';

import { useMultiParentFileUpdate } from '../../../../data/rest/useFileUpdate';
import { ThirdPartyForm } from '../../forms/ThirdPartyForm';
import type { ThirdPartyFormData } from '../../forms/thirdPartySchema';

const ThirdPartyCreateTab: FC = () => {
  useI18NSummaryHelpContent('third_party.help');
  const navigate = useNavigate();
  const [mutate] = useCreateThirdPartyMutation({
    update: (cache) => {
      evictField(cache, 'third_party');
    },
  });
  const { updateFiles } = useMultiParentFileUpdate();

  const onSave = async (data: ThirdPartyFormData) => {
    const { files, newFiles } = data;
    const { data: result } = await mutate({
      variables: {
        object: {
          Title: data.title,
          Description: data.description,
          CompanyName: data.companyName,
          CompaniesHouseNumber: data.companiesHouseNumber,
          Address: data.address,
          CityTown: data.cityTown,
          Postcode: data.postcode,
          Country: data.country,
          PrimaryContactName: data.primaryContactName,
          ContactName: data.contactName,
          ContactEmail: data.contactEmail,
          CompanyDomain: data.companyDomain,
          Type: data.type,
          Status: data.status,
          Criticality: data.criticality,
          CustomAttributeData: data.CustomAttributeData,
          tags: {
            data: data.tags,
          },
          departments: {
            data: data.departments,
          },
          contributors: {
            data: data.Contributors.filter((c) => c.type === 'user').map(
              (c) => ({ UserId: c.value })
            ),
          },
          contributorGroups: {
            data: data.Contributors.filter((c) => c.type === 'userGroup').map(
              (c) => ({ UserGroupId: c.value })
            ),
          },
          owners: {
            data: data.Owners.filter((c) => c.type === 'user').map((c) => ({
              UserId: c.value,
            })),
          },
          ownerGroups: {
            data: data.Owners.filter((c) => c.type === 'userGroup').map(
              (c) => ({
                UserGroupId: c.value,
              })
            ),
          },
        },
      },
    });

    if (result?.insert_third_party_one?.Id) {
      await updateFiles({
        parentType: Parent_Type_Enum.ThirdParty,
        parentIds: [result.insert_third_party_one.Id],
        newFiles,
        originalFiles: [],
        selectedFiles: files,
      });
      navigate(thirdPartyDetailsUrl(result?.insert_third_party_one?.Id), {
        replace: true,
      });
    }
  };

  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return <ThirdPartyForm onSave={onSave} onDismiss={onDismiss} />;
};

export default ThirdPartyCreateTab;
