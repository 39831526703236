import type { PropertyFilterProps } from '@cloudscape-design/components/property-filter';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import type { FC } from 'react';
import { useMemo } from 'react';

import styles from './style.module.scss';

const PropertyFilterPanel: FC<PropertyFilterProps> = (props) => {
  const query = useMemo(() => {
    const tokens = props.query.tokens.filter((p) => !('isGlobal' in p));

    return {
      ...props.query,
      tokens,
    };
  }, [props.query]);

  return (
    <div className={styles.propertyFilter}>
      <PropertyFilter
        {...props}
        query={query}
        onChange={(e) => {
          props.onChange({
            ...e,
            detail: {
              ...e.detail,
              tokens: [
                ...props.query.tokens.filter((p) => 'isGlobal' in p),
                ...e.detail.tokens,
              ],
            },
          });
        }}
      />
    </div>
  );
};

export default PropertyFilterPanel;
