import type { JsonSchema7 } from '@jsonforms/core';

import type { QuestionData } from './form-configs/question';
import type { SectionData } from './form-configs/section';
import type { CustomSchema, CustomUISchema } from './useFormBuilderContext';
import {
  type CustomUISchemaElement,
  useFormBuilderContext,
} from './useFormBuilderContext';
import { useJsonFormContext } from './useJsonFormContext';

export enum FORM_BUILDER_ACTION {
  ADD = 'add',
  EDIT = 'edit',
}

export const useFormBuilderActions = () => {
  const {
    isFormCustomisable,
    uiSchema: fullUISchema,
    schema,
    setSchema,
    setUISchema,
  } = useFormBuilderContext();

  const { setIsFormDirty } = useJsonFormContext();

  return {
    // CONTEXT ITEMS
    isFormCustomisable,

    // GENERAL ACTIONS
    setIsFormDirtyState: (option: boolean) => {
      setIsFormDirty(option);
    },

    // SECTION ACTIONS
    addNewSection: (sectionData: SectionData) => {
      const uuid = `section_${crypto.randomUUID()}`;

      setUISchema({
        ...fullUISchema,
        elements: [
          ...fullUISchema.elements,
          {
            type: 'Group',
            label: sectionData.sectionTitle,
            id: uuid,
            elements: [],
          },
        ],
      });
    },

    updateSection: (
      sectionData: SectionData,
      currentElementUISchema: CustomUISchema | CustomUISchemaElement
    ) => {
      const modifiedElementsCopy = fullUISchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === currentElementUISchema?.id) {
            return {
              ...element,
              label: sectionData.sectionTitle,
            };
          }

          return element;
        }
      );

      setUISchema({
        ...fullUISchema,
        elements: modifiedElementsCopy,
      });
    },

    deleteSection: (
      currentElementUISchema: CustomUISchema | CustomUISchemaElement
    ) => {
      if (!currentElementUISchema?.id) {
        console.log('No id found in uiSchema');

        return;
      }

      if (!schema?.properties) {
        console.log('No properties found in schema');

        return;
      }

      const modifiedUISchemaElementsCopy = fullUISchema.elements.filter(
        (element: CustomUISchemaElement) =>
          element.id !== currentElementUISchema.id
      );

      setUISchema({
        ...fullUISchema,
        elements: modifiedUISchemaElementsCopy,
      });

      const modifiedSchemaPropertiesCopy = Object.keys(
        schema.properties || {}
      ).reduce(
        (acc, key) => {
          if (schema?.properties === undefined) {
            return acc;
          }

          const entity = schema.properties![key] as {
            [property: string]: JsonSchema7 & { parentId?: string };
          };

          if (entity?.parentId !== currentElementUISchema.id) {
            acc![key] = schema.properties![key];
          }

          return acc;
        },
        {} as JsonSchema7['properties']
      );

      setSchema({
        ...schema,
        properties: { ...modifiedSchemaPropertiesCopy },
      });
    },

    // QUESTION ACTIONS
    addNewQuestion: (questionData: QuestionData, parentId: string) => {
      const uuid = `question_${crypto.randomUUID()}`;

      setSchema({
        ...schema,
        properties: {
          ...schema.properties,
          [uuid]: {
            type: 'string',
            parentId,
            isCustomisable: true,
            minLength: questionData.isPropertyRequired ? 1 : 0,
          },
        },
        required: [
          ...(schema.required || []),
          ...(questionData.isPropertyRequired ? [uuid] : []),
        ],
      } as CustomSchema);

      const modifiedUISchemaElementsCopy = fullUISchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === parentId) {
            return {
              ...element,
              elements: [
                ...(element?.elements || []),
                {
                  type: 'Control',
                  label: questionData.questionTitle,
                  id: uuid,
                  parentId,
                  scope: `#/properties/${uuid}`,
                  options: {
                    placeholder: questionData.placeholder,
                    fieldType: questionData.fieldType,
                  },
                },
              ],
            };
          }

          return element;
        }
      );

      setUISchema({
        ...fullUISchema,
        elements: modifiedUISchemaElementsCopy,
      });
    },

    updateQuestion: (
      questionData: QuestionData,
      currentElementUISchema: CustomUISchema | CustomUISchemaElement,
      parentId: string
    ) => {
      if (!currentElementUISchema?.id) {
        console.log('No id found in uiSchema');

        return;
      }

      if (!schema?.properties) {
        console.log('No properties found in schema');

        return;
      }

      const updatedRequiredList =
        schema.required?.filter(
          (fieldId) => fieldId !== currentElementUISchema.id
        ) || [];

      if (questionData.isPropertyRequired) {
        updatedRequiredList.push(currentElementUISchema.id);
      }

      setSchema({
        ...schema,
        properties: {
          ...schema.properties,
          [currentElementUISchema.id]: {
            ...(schema?.properties &&
              schema.properties[currentElementUISchema.id]),
            minLength: questionData.isPropertyRequired ? 1 : 0,
          },
        },
        required: updatedRequiredList,
      } as CustomSchema);

      const modifiedUISchemaElementsCopy = fullUISchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === parentId) {
            const modifiedElements = element?.elements
              ? element.elements.map((childElement: CustomUISchemaElement) => {
                  if (childElement.id === currentElementUISchema?.id) {
                    return {
                      ...childElement,
                      label: questionData.questionTitle,
                      options: {
                        placeholder: questionData.placeholder,
                        fieldType: questionData.fieldType,
                      },
                    };
                  }

                  return childElement;
                })
              : [];

            return {
              ...element,
              elements: modifiedElements,
            };
          }

          return element;
        }
      );

      setUISchema({
        ...fullUISchema,
        elements: modifiedUISchemaElementsCopy,
      });
    },

    deleteQuestion: (
      currentElementUISchema: CustomUISchema | CustomUISchemaElement,
      parentId: string
    ) => {
      if (!currentElementUISchema?.id) {
        console.log('No id found in uiSchema');

        return;
      }

      if (!schema?.properties) {
        console.log('No properties found in schema');

        return;
      }

      const modifiedUISchemaElementsCopy = fullUISchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === parentId) {
            const filteredElements = element?.elements
              ? element.elements.filter(
                  (elementElement: CustomUISchemaElement) =>
                    elementElement.id !== currentElementUISchema.id
                )
              : [];

            return {
              ...element,
              elements: filteredElements,
            };
          }

          return element;
        }
      );

      setUISchema({
        ...fullUISchema,
        elements: modifiedUISchemaElementsCopy,
      });

      const modifiedSchemaPropertiesCopy = Object.keys(
        schema.properties || {}
      ).reduce(
        (acc, key) => {
          if (key !== currentElementUISchema.id) {
            acc![key] = schema.properties![key];
          }

          return acc;
        },
        {} as JsonSchema7['properties']
      );

      const propertyIds = Object.keys(modifiedSchemaPropertiesCopy || {});

      const updatedRequiredList = schema.required?.filter((fieldId) => {
        if (!propertyIds.includes(fieldId)) {
          // This ensures any required fields that are not in the schema are removed
          return false;
        }

        return fieldId !== currentElementUISchema.id;
      });

      setSchema({
        ...schema,
        properties: { ...modifiedSchemaPropertiesCopy },
        required: updatedRequiredList || [],
      });
    },
  };
};
