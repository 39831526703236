import Button from '@risksmart-app/components/Button';
import { Reorder, useDragControls } from 'framer-motion';
import type { FC, ReactNode } from 'react';

const DraggableItem: FC<{ value: unknown; children: ReactNode }> = ({
  value,
  children,
}) => {
  const controls = useDragControls();

  return (
    <Reorder.Item
      value={value}
      dragListener={false}
      dragControls={controls}
      className="flex items-center"
    >
      <div onPointerDown={(e) => controls.start(e)}>
        <Button variant="icon" iconName="drag-indicator" />
      </div>
      <div className="flex-grow">{children}</div>
    </Reorder.Item>
  );
};

export default DraggableItem;
