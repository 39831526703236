import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Cost_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

import { getAllOwnersCellValue } from '../../rbac/contributorHelper';
import { UNRATED } from '../controls/lookupData';
import { getCost } from '../issues/update/tabs/consequences/utils';
import type { ConsequenceFlatField, ConsequenceRegisterFields } from './types';

export const useLabelledFields = (
  records: ConsequenceFlatField[] | undefined
): ConsequenceRegisterFields[] | undefined => {
  const { getByValue: getCriticalityByValue } = useRating('criticality');
  const { getLabel: getTypeByValue } = useRating('consequences_type');
  const { t } = useTranslation(['common']);
  const { t: tt } = useTranslation(['common'], {
    keyPrefix: 'consequences',
  });

  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');
  const issueTypes = useMemo(
    () =>
      t('issueTypes', {
        returnObjects: true,
      }),
    [t]
  );

  return useMemo<ConsequenceRegisterFields[] | undefined>(() => {
    return records?.map((d): ConsequenceRegisterFields => {
      return {
        ...d,
        ModifiedByUserName: d.modifiedByUser?.FriendlyName || '-',
        CreatedByUserName: d.createdByUser?.FriendlyName || '-',
        CriticalityLabelled: getCriticalityByValue(d.Criticality)?.label ?? '',
        CostTypeLabelled: d.CostType ? tt('costType')[d.CostType] : '-',
        IssueTitle: d.issue?.Title ?? '-',
        IssueSequentialId: d.issue?.SequentialId ?? null,
        IssueType: d.issue?.assessment?.IssueType ?? null,
        IssueTypeLabelled: d.issue?.assessment?.IssueType
          ? issueTypes[d.issue.assessment.IssueType as keyof typeof issueTypes]
          : '-',
        IssueStatus: d.issue?.assessment?.Status ?? null,
        IssueStatusLabelled: status.getLabel(
          d.issue?.assessment?.Status ?? null
        ),
        IssueRaisedDate: d.issue?.CreatedAtTimestamp ?? null,
        IssueClosedDate: d.issue?.assessment?.ActualCloseDate ?? null,
        IssueSeverity: d.issue?.assessment?.Severity ?? null,
        IssueSeverityLabelled:
          severity.getLabel(d.issue?.assessment?.Severity ?? null) ||
          UNRATED.label,
        TypeLabelled: d.Type ? getTypeByValue(d.Type) : '-',
        CostHours: getCost(d, Cost_Type_Enum.Hours),
        CostNumber: getCost(d, Cost_Type_Enum.Number),
        CostPounds: getCost(d, Cost_Type_Enum.Pounds),
        allOwners: d.issue ? getAllOwnersCellValue(d.issue) : [],
        tags: d.issue?.tags ?? [],
        departments: d.issue?.departments ?? [],
      };
    });
  }, [
    getCriticalityByValue,
    getTypeByValue,
    status,
    records,
    tt,
    severity,
    issueTypes,
  ]);
};
