import type { ModalProps } from '@cloudscape-design/components/modal';
import Modal from '@cloudscape-design/components/modal';
import type { FC } from 'react';

import style from './style.module.scss';

const RSModal: FC<ModalProps> = (props) => {
  return <Modal {...props} {...{ className: style.modal }} />;
};

export default RSModal;
