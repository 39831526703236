import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetOptionalGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal';
import {
  Parent_Type_Enum,
  useDeleteAppetitesMutation,
  useGetAppetiteByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import { useTabs } from './useTabs';

const Page = () => {
  const { t: st } = useTranslation('common', { keyPrefix: 'appetites' });
  const { t } = useTranslation('common');
  const parentId = useGetOptionalGuidParam('riskId');
  const appetiteId = useGetOptionalGuidParam('appetiteId');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const tabs = useTabs({ appetiteId, parentId });
  const { data } = useGetAppetiteByIdQuery({
    variables: {
      _eq: appetiteId,
    },
    skip: !appetiteId,
  });
  if (data?.appetite.length === 0) {
    throw new PageNotFound(`Appetite with id ${appetiteId} not found`);
  }
  const appetite = data?.appetite[0];
  const navigate = useNavigate();
  const [deleteAppetites, deleteResult] = useDeleteAppetitesMutation({
    update: (cache) => {
      evictField(cache, 'appetite');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'risk_aggregate');
    },
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteAppetites({ variables: { Ids: [appetite!.Id] } });
      setIsDeleteModalVisible(false);
      navigate('..');

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  return (
    <PageLayout
      helpTranslationKey="appetites.help"
      title={getFriendlyId(Parent_Type_Enum.Appetite, appetite?.SequentialId)}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Permission permission="delete:appetite" parentObject={appetite}>
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {t('delete')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs tabs={tabs} variant="container" />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
