import Button from '@risksmart-app/components/Button';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import Tokens from '@/components/Tokens';
import useEntityInfo from '@/hooks/getEntityInfo';

import type { LinkedItem as ModalLinkedItem } from '../modals/LinkItemModal';
import { LinkItemModal } from '../modals/LinkItemModal';
import type { AssessmentActivityFormDataFields } from './assessmentActivitySchema';

interface Props {
  readOnly?: boolean;
}

enum TestIds {
  ActivityType = 'ActivityType',
  ActivityUser = 'ActivityUser',
  Status = 'Status',
}

const AssessmentActivityFormFields = ({ readOnly }: Props) => {
  const getEntityInfo = useEntityInfo();
  const { control, watch, setValue } =
    useFormContext<AssessmentActivityFormDataFields>();
  const LinkedItemIds = watch('LinkedItemIds');
  const [showLinkModal, setShowLinkModal] = useState(false);

  const { t } = useTranslation(['common'], {
    keyPrefix: 'assessmentActivities',
  });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'assessmentActivities.fields',
  });
  const statusTypes = t('status', { returnObjects: true });
  const statusTypesOptions = Object.keys(statusTypes).map((key) => ({
    value: key,
    label: statusTypes[key as keyof typeof statusTypes],
  }));
  const activityTypes = t('type', { returnObjects: true });
  const activityTypesOptions = Object.keys(activityTypes).map((key) => ({
    value: key,
    label: activityTypes[key as keyof typeof activityTypes],
  }));
  const onSelectLinked = (selected: ModalLinkedItem[]) => {
    const items = LinkedItemIds;
    items.push(
      ...selected.map((c) => ({
        Id: c.Id,
        Type: c.Type,
        Label: c.Label,
      }))
    );
    setValue('LinkedItemIds', items, { shouldDirty: true });
  };
  const onLinkItemClicked = () => {
    setShowLinkModal(true);
  };

  const onLinkItemModalDismiss = () => {
    setShowLinkModal(false);
  };

  const removeToken = (s: string) => {
    const currentItems = LinkedItemIds;
    const items = currentItems.filter((c) => c.Id !== s);

    setValue('LinkedItemIds', items, { shouldDirty: true });
  };

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key="title"
        forceRequired={true}
        disabled={readOnly}
        name="Title"
        label={st('Title')}
        description={st('Title_help')}
        placeholder={st('Title_placeholder')}
        control={control}
      />
      <ControlledSelect
        key="activityType"
        filteringType="auto"
        label={st('ActivityType')}
        description={st('ActivityType_help')}
        name="ActivityType"
        forceRequired={true}
        control={control}
        options={activityTypesOptions}
        disabled={readOnly}
        testId={TestIds.ActivityType}
      />
      <ControlledTextarea
        key="summary"
        disabled={readOnly}
        defaultRequired={true}
        name="Summary"
        label={st('Summary')}
        description={st('Summary_help')}
        placeholder={st('Summary_placeholder')}
        control={control}
      />
      <div key="linked-items">
        <Button
          disabled={readOnly}
          variant="normal"
          onClick={onLinkItemClicked}
        >
          {t('link_item_button')}
        </Button>
        <Tokens
          disabled={readOnly}
          onRemove={removeToken}
          tokens={LinkedItemIds.map((c) => ({
            value: c.Id,
            label: c.Label,
            url: getEntityInfo(c.Type).url(c.Id),
          }))}
        />
      </div>
      <ControlledRadioGroup
        key="status"
        forceRequired={true}
        label={st('Status')}
        description={st('Status_help')}
        name="Status"
        control={control}
        items={statusTypesOptions}
        transform={noTransform}
        disabled={readOnly}
        testId={TestIds.Status}
      />
      <ControlledGroupAndUserSelect
        key="assigned-user"
        disabled={readOnly}
        addEmptyOption={true}
        name="AssignedUser"
        label={st('AssignedUser')}
        description={st('AssignedUser_help')}
        includeGroups={false}
        control={control}
        testId={TestIds.ActivityUser}
      />
      <ControlledDatePicker
        key="completion-date"
        disabled={readOnly}
        name="CompletionDate"
        label={st('CompletionDate')}
        description={st('CompletionDate_help')}
        control={control}
      />
      <ControlledFileUpload
        key="newFiles"
        label={st('NewFiles')}
        control={control}
        name="newFiles"
        saveFilesName="files"
        disabled={readOnly}
      />
      {showLinkModal && (
        <LinkItemModal
          key="link-modal"
          onDismiss={onLinkItemModalDismiss}
          onSelect={onSelectLinked}
          excludeIds={LinkedItemIds.map((c) => c.Id)}
        />
      )}
    </CustomisableForm>
  );
};

export default AssessmentActivityFormFields;
