import type { PropertyFilterQuery } from '@cloudscape-design/collection-hooks/cjs/interfaces';

import styles from './collectionUtils.module.scss';

export const EMPTY_CELL = <span className={styles['empty-cell']}>–</span>;

export type CollectionData<T> = Omit<T, '__typename'>;

export const emptyFilterQuery: PropertyFilterQuery = {
  tokens: [],
  operation: 'and',
};

export const getCounter = (count: number | undefined, loading: boolean) => {
  if (count === undefined || loading) {
    return '';
  }

  return `(${count})`;
};
