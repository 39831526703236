import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetTestResultsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import TestResultModal from '../update/tabs/TestResultModal';
import { useGetCollectionTableProps } from './config';
import { TestSummary } from './TestSummary';
import type { ControlTestFlatFields } from './types';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'testResults' });
  const { addNotification } = useNotifications();
  const { data, loading } = useGetTestResultsQuery({
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState<string | undefined>(
    undefined
  );
  const [selectedTestParentId, setSelectedTestParentId] = useState<
    string | undefined
  >(undefined);

  const handleTestResultModalOpen = (item: ControlTestFlatFields) => {
    setIsEditOpen(true);
    setSelectedTestId(item.Id);
    setSelectedTestParentId(item.ParentControlId);
  };
  const handleTestResultModalClose = () => {
    setIsEditOpen(false);
  };

  const tableProps = useGetCollectionTableProps(
    data?.test_result,
    handleTestResultModalOpen,
    data?.form_configuration?.[0]?.customAttributeSchema
  );
  const counter = getCounter(tableProps.totalItemsCount, loading);
  const title = st('register_title');

  return (
    <PageLayout
      helpTranslationKey="testResults.registerHelp"
      title={title}
      counter={counter}
      actions={
        <Button iconName="download" onClick={tableProps.exportToCsv}>
          {t('export')}
        </Button>
      }
    >
      <TestSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table {...tableProps} loading={loading} />

      {isEditOpen && selectedTestId && (
        <TestResultModal
          parentControlId={selectedTestParentId || ''}
          Id={selectedTestId}
          onDismiss={handleTestResultModalClose}
          assessmentMode={'rating'}
        />
      )}
    </PageLayout>
  );
};

export default Page;
