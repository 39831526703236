
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_w736d_289",
  "arrow-outer": "awsui_arrow-outer_xjuzf_w736d_293",
  "arrow-inner": "awsui_arrow-inner_xjuzf_w736d_293",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_w736d_335",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_w736d_335",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_w736d_338",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_w736d_338",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_w736d_341",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_w736d_341",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_w736d_341",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_w736d_341",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_w736d_344",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_w736d_344",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_w736d_344",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_w736d_344",
  "body": "awsui_body_xjuzf_w736d_492",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_w736d_528",
  "has-dismiss": "awsui_has-dismiss_xjuzf_w736d_532",
  "dismiss": "awsui_dismiss_xjuzf_w736d_537",
  "dismiss-control": "awsui_dismiss-control_xjuzf_w736d_545",
  "header-row": "awsui_header-row_xjuzf_w736d_549",
  "header": "awsui_header_xjuzf_w736d_549",
  "content": "awsui_content_xjuzf_w736d_574",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_w736d_582",
  "container": "awsui_container_xjuzf_w736d_730",
  "container-body": "awsui_container-body_xjuzf_w736d_738",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_w736d_778",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_w736d_783",
  "fixed-width": "awsui_fixed-width_xjuzf_w736d_786",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_w736d_790",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_w736d_797",
  "container-arrow": "awsui_container-arrow_xjuzf_w736d_809",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_w736d_813",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_w736d_813",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_w736d_830",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_w736d_830",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_w736d_847",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_w736d_847",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_w736d_847",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_w736d_847",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_w736d_863",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_w736d_867",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_w736d_871",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_w736d_1",
  "refresh": "awsui_refresh_xjuzf_w736d_1041",
  "root": "awsui_root_xjuzf_w736d_1063",
  "no-wrap": "awsui_no-wrap_xjuzf_w736d_1095",
  "root-filtering-token": "awsui_root-filtering-token_xjuzf_w736d_1099",
  "trigger": "awsui_trigger_xjuzf_w736d_1103",
  "overflow-ellipsis": "awsui_overflow-ellipsis_xjuzf_w736d_1110",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_w736d_1118",
  "trigger-type-filtering-token": "awsui_trigger-type-filtering-token_xjuzf_w736d_1154",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_w736d_1158"
};
  