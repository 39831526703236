import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetPolicyAttestationRecordsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { AttestationsSummary } from './AttestationsSummary';
import { useGetRegisterTableProps } from './config';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'attestations',
  });
  const { t } = useTranslation(['common']);
  const title = st('register_title');
  const { data, loading } = useGetPolicyAttestationRecordsQuery({
    fetchPolicy: 'no-cache',
  });
  const tableProps = useGetRegisterTableProps(data?.attestation_record);

  return (
    <PageLayout
      helpTranslationKey="policy.registerHelp"
      title={title}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <Button iconName="download" onClick={tableProps.exportToCsv}>
          {t('export')}
        </Button>
      }
    >
      <AttestationsSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        filteringProperties={tableProps.filteringProperties}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
