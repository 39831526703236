import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import Select from '@cloudscape-design/components/select';
import type { ControlProps } from '@jsonforms/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  CustomSchema,
  CustomUISchemaElement,
  FieldType,
} from '../../FormBuilder/useFormBuilderContext';
import { useFieldTypeOptions } from '../../FormBuilder/useFormBuilderContext';
import CustomisableControl from './CustomisableControl';
import { getFieldLabel } from './utils';

export type DropdownSelectControlProps = ControlProps & {
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
};

export const DropdownSelectControlUnwrapped: FC<DropdownSelectControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  path,
  config,
}) => {
  const typedData = data as FieldType;
  const fieldTypeOptions = useFieldTypeOptions();
  const [_, setIsFieldDirty] = useState(false);
  const [label, setLabel] = useState<string>(uischema?.label || '');
  const [selectedOption, setSelectedOption] = useState<OptionDefinition>(
    typedData ? fieldTypeOptions[typedData] : fieldTypeOptions['text']
  );

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  useEffect(() => {
    setLabel(getFieldLabel(uischema?.label, schema?.minLength));
  }, [uischema, schema]);

  return (
    <CustomisableControl
      label={label}
      uischema={uischema}
      errors={errors}
      schema={schema}
    >
      <Select
        selectedOption={selectedOption}
        onChange={({ detail }) => {
          setIsFieldDirty(true);
          setSelectedOption(detail.selectedOption);
          handleChange(path, detail.selectedOption.value);
        }}
        options={
          schema?.oneOf?.map((option) => ({
            label: option.title,
            value: option.const,
          })) as OptionDefinition[]
        }
        onBlur={() => setIsFieldDirty(true)}
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={appliedUiSchemaOptions?.placeholder || t('optionSelect')}
      />
    </CustomisableControl>
  );
};
