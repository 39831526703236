import type { ReactNode } from 'react';
import type {
  DefaultValues,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';

import type {
  GetFormFieldOptionsByParentTypeQuery,
  Parent_Type_Enum,
} from '@/generated/graphql';
import type { ObjectWithApprovals } from '@/hooks/useChangeRequests';

export enum ButtonVariant {
  Standard = 'Standard',
  Danger = 'Danger',
}
export type SubmitButtonOptions = {
  variant?: ButtonVariant;
  label: string;
  action: () => Promise<void>;
  loading?: boolean;
};

export type CommonProps<TFieldValues extends FieldValues> = Omit<
  FormContextProps<TFieldValues>,
  'renderTemplate'
>;

export type FormTemplateProps<TFieldValues extends FieldValues> =
  FormContextProps<TFieldValues> & { actions: ReactNode };

export type SaveAction<T> = (data: T) => Promise<void>;

type FormFieldsHook<TFieldValues extends FieldValues> = (
  options: UseFormReturn<TFieldValues>
) => FormFields;

type FormField = {
  component: ReactNode;
  hidden?: boolean;
};

export type FormFieldOptions =
  GetFormFieldOptionsByParentTypeQuery['form_field_configuration'];

type FormFields = Record<string, FormField>;

export type InnerFormContextProps<TFieldValues extends FieldValues> =
  FormContextProps<TFieldValues> & {
    formFieldOptions: FormFieldOptions | undefined;
  };

export type FormContextProps<TFieldValues extends FieldValues> = {
  testId?: string;
  formId: string;
  header?: ReactNode | string;
  defaultValues: DefaultValues<TFieldValues>;
  values?: TFieldValues;
  children?: ReactNode;
  fields?: FormFields | FormFieldsHook<TFieldValues>;
  onDismiss?: (saved: boolean) => void;
  onSave: SaveAction<TFieldValues>;
  onDelete?: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: Zod.Schema<any, any>;
  readOnly?: boolean;
  parentType?: Parent_Type_Enum;
  aside?: ReactNode;
  renderTemplate: (props: FormTemplateProps<TFieldValues>) => ReactNode;
  submitActions?: (Omit<SubmitButtonOptions, 'action'> & {
    action: SaveAction<TFieldValues>;
  })[];
  secondaryActions?: SubmitButtonOptions[];
  approvalConfig?: {
    object?: ObjectWithApprovals;
  };
  i18n: {
    entity_name: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapPreviewedChanges?: (a: TFieldValues | undefined, b: any) => TFieldValues;
};
