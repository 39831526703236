
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_mgja0_29okn_153",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_mgja0_29okn_1",
  "root": "awsui_root_mgja0_29okn_176",
  "wide": "awsui_wide_mgja0_29okn_207",
  "focus-lock": "awsui_focus-lock_mgja0_29okn_214",
  "trigger-flexbox": "awsui_trigger-flexbox_mgja0_29okn_218",
  "calendar-container": "awsui_calendar-container_mgja0_29okn_222",
  "one-grid": "awsui_one-grid_mgja0_29okn_225",
  "calendar-header": "awsui_calendar-header_mgja0_29okn_229",
  "calendar-header-months-wrapper": "awsui_calendar-header-months-wrapper_mgja0_29okn_235",
  "calendar-header-month": "awsui_calendar-header-month_mgja0_29okn_235",
  "calendar-next-month-btn": "awsui_calendar-next-month-btn_mgja0_29okn_254",
  "calendar-prev-month-btn": "awsui_calendar-prev-month-btn_mgja0_29okn_257",
  "first-grid": "awsui_first-grid_mgja0_29okn_261",
  "second-grid": "awsui_second-grid_mgja0_29okn_262",
  "date-and-time-container": "awsui_date-and-time-container_mgja0_29okn_266",
  "date-and-time-wrapper": "awsui_date-and-time-wrapper_mgja0_29okn_272",
  "footer": "awsui_footer_mgja0_29okn_279",
  "has-clear-button": "awsui_has-clear-button_mgja0_29okn_288",
  "footer-button-wrapper": "awsui_footer-button-wrapper_mgja0_29okn_297",
  "icon-wrapper": "awsui_icon-wrapper_mgja0_29okn_304",
  "label": "awsui_label_mgja0_29okn_309",
  "label-enabled": "awsui_label-enabled_mgja0_29okn_314",
  "label-text": "awsui_label-text_mgja0_29okn_318",
  "label-token-nowrap": "awsui_label-token-nowrap_mgja0_29okn_323",
  "mode-switch": "awsui_mode-switch_mgja0_29okn_327",
  "dropdown": "awsui_dropdown_mgja0_29okn_331",
  "dropdown-content": "awsui_dropdown-content_mgja0_29okn_353",
  "start-date-input": "awsui_start-date-input_mgja0_29okn_362",
  "start-time-input": "awsui_start-time-input_mgja0_29okn_363",
  "end-date-input": "awsui_end-date-input_mgja0_29okn_364",
  "end-time-input": "awsui_end-time-input_mgja0_29okn_365",
  "validation-section": "awsui_validation-section_mgja0_29okn_369",
  "validation-error": "awsui_validation-error_mgja0_29okn_373",
  "clear-button": "awsui_clear-button_mgja0_29okn_377",
  "apply-button": "awsui_apply-button_mgja0_29okn_381",
  "cancel-button": "awsui_cancel-button_mgja0_29okn_385",
  "calendar-aria-live": "awsui_calendar-aria-live_mgja0_29okn_389"
};
  