
    import './styles.scoped.css';
    export default {
  "header": "awsui_header_16mm3_13lwx_145",
  "disabled": "awsui_disabled_16mm3_13lwx_160",
  "expandable-header": "awsui_expandable-header_16mm3_13lwx_164",
  "rolled-down": "awsui_rolled-down_16mm3_13lwx_175",
  "highlighted": "awsui_highlighted_16mm3_13lwx_178",
  "is-focused": "awsui_is-focused_16mm3_13lwx_196",
  "category": "awsui_category_16mm3_13lwx_201",
  "expandable": "awsui_expandable_16mm3_13lwx_164",
  "variant-navigation": "awsui_variant-navigation_16mm3_13lwx_216",
  "expand-icon": "awsui_expand-icon_16mm3_13lwx_223",
  "expand-icon-up": "awsui_expand-icon-up_16mm3_13lwx_240",
  "expand-icon-right": "awsui_expand-icon-right_16mm3_13lwx_243",
  "items-list-container": "awsui_items-list-container_16mm3_13lwx_251",
  "in-dropdown": "awsui_in-dropdown_16mm3_13lwx_260"
};
  