import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ControlFormFieldData } from 'src/pages/controls/update/forms/controlSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ownerAndContributorIds } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import type { Query_Root } from '@/generated/graphql';
import {
  useDeleteControlsMutation,
  useGetControlsQuery,
  useInsertChildControlMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';

import { useGetCollectionTableProps } from '../config';
import ControlCreateModal from '../modals/ControlCreateModal';
import type { ControlTableFields } from '../types';
import DeleteControlModal from './DeleteModal/DeleteControlModal';

interface Props {
  parent: ObjectWithContributors;
  deleteCacheInvalidationFields?: (keyof Query_Root)[];
}

const Tab: FC<Props> = ({ deleteCacheInvalidationFields = [], parent }) => {
  useI18NSummaryHelpContent('controls.tabHelp');
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', parent);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const canDeleteControls = useHasPermission('delete:control', parent);
  const [selectedControls, setSelectedControls] = useState<
    ControlTableFields[]
  >([]);
  const { addNotification } = useNotifications();

  const [mutate] = useInsertChildControlMutation({
    update: (cache) => {
      evictField(cache, 'risk');
      evictField(cache, 'control');
      evictField(cache, 'control_aggregate');
      evictField(cache, 'obligation');
      evictField(cache, 'linked_item');
      evictField(cache, 'risk_score');
    },
  });
  const saveControl = async (data: ControlFormFieldData) => {
    await mutate({
      variables: {
        ...data,
        Type: data.Type,
        TestFrequency: data.TestFrequency,
        ParentId: parent.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
      },
    });
  };

  const { data, loading } = useGetControlsQuery({
    variables: {
      where: {
        parents: {
          ParentId: { _eq: parent.Id },
        },
      },
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [deleteControls, deleteResult] = useDeleteControlsMutation({
    update: (cache) => {
      const fields = [
        'control',
        'linked_item',
        ...deleteCacheInvalidationFields,
      ];
      fields.forEach((field) => evictField(cache, field as keyof Query_Root));
    },
  });
  const handleControlCreateOpen = () => {
    setIsCreateOpen(true);
  };
  const handleControlCreateClose = () => {
    setIsCreateOpen(false);
  };

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      await deleteControls({
        variables: { Ids: selectedControls.map((s) => s.Id) },
      });
      setSelectedControls([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    entityName: st('entity_name'),
  });

  const tableProps = useGetCollectionTableProps(
    handleControlCreateOpen,
    data?.control,
    data?.form_configuration?.[0]?.customAttributeSchema,
    <Permission permission="insert:control" parentObject={parent}>
      <Button formAction="none" onClick={handleControlCreateOpen}>
        {st('create_button')}
      </Button>
    </Permission>
  );

  return (
    <>
      <Table
        {...tableProps}
        selectionType={canDeleteControls ? 'multi' : undefined}
        selectedItems={selectedControls}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedControls(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission permission="delete:control" parentObject={parent}>
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedControls.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission permission="insert:control" parentObject={parent}>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleControlCreateOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />

      {isCreateOpen && (
        <ControlCreateModal
          onSave={saveControl}
          onDismiss={handleControlCreateClose}
        />
      )}

      <DeleteControlModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
        showUnlink={!!linkedItemsEnabled && !!canViewLinkedItems}
      />
    </>
  );
};

export default Tab;
