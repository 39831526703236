export enum TestIds {
  Title = 'title',
  Description = 'description',
  Owners = 'owners',
  Contributors = 'contributors',
  Status = 'status',
  Priority = 'priority',
  Tags = 'tags',
  Departments = 'departments',
}
