import type { Filter } from '../../components/CustomisableRibbon/Filter';

export const defaultRibbonFilters: Filter[] = [
  {
    id: crypto.randomUUID(),
    title: 'Open issues',
    itemFilterQuery: {
      tokens: [
        {
          operator: '!=',
          propertyKey: 'StatusLabelled',
          value: 'Closed',
        },
      ],
      operation: 'and',
    },
    itemOrder: 0,
  },
  {
    id: crypto.randomUUID(),
    title: 'Overdue',
    itemFilterQuery: {
      tokens: [
        {
          operator: '!=',
          propertyKey: 'StatusLabelled',
          value: 'Closed',
        },
        {
          operator: '=',
          propertyKey: 'TargetCloseDate',
          value: {
            type: 'relative',
            unit: 'year',
            amount: -3,
          },
        },
      ],
      operation: 'and',
    },
    itemOrder: 1,
  },
  {
    id: crypto.randomUUID(),
    title: 'Without open action',
    itemFilterQuery: {
      tokens: [
        {
          operator: '=',
          propertyKey: 'OpenActions',
          value: '0',
        },
        {
          operator: '!=',
          propertyKey: 'StatusLabelled',
          value: 'Closed',
        },
      ],
      operation: 'and',
    },
    itemOrder: 2,
  },
  {
    id: crypto.randomUUID(),
    title: 'All issues',
    itemFilterQuery: {
      tokens: [],
      operation: 'and',
    },
    itemOrder: 3,
  },
];
