import Box from '@cloudscape-design/components/box';
import Table from '@risksmart-app/components/Table';

import type { TablePropsWithActions, TableRecord } from '@/utils/table/types';

type Props<T extends TableRecord> = {
  loading?: boolean;
  tableProps: TablePropsWithActions<T>;
};

const TableWidget = <T extends TableRecord>({
  tableProps,
  loading,
}: Props<T>) => (
  <Box padding={{ left: 's', right: 's' }}>
    <Table
      {...tableProps}
      loading={loading}
      variant="borderless"
      stickyHeader={true}
    />
  </Box>
);

export default TableWidget;
