import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useControlTypeLabel } from 'src/pages/assessments/forms/useControlTypeLabel';
import { useAggregation } from 'src/providers/useAggregation';

import { useRating } from '@/hooks/use-rating';

import { decorateWithControlType, getParentTitle } from './helpers';
import type {
  InternalAuditReportResultFields,
  InternalAuditReportResultRegisterFields,
} from './types';

export const useLabelledFields = (
  records: InternalAuditReportResultFields[] | undefined
) => {
  const { t: at } = useTranslation('common', {
    keyPrefix: 'assessmentResults',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'internalAuditReports',
  });
  const status = t('status');
  const assessmentResultTypes = at('resultTypes', { returnObjects: true });
  const getControlTypeLabel = useControlTypeLabel();
  const { riskModel } = useAggregation();
  const { getByValue: getByResultValue } = useRating('performance_result');
  const { getByValue: getEffectivenessByValue } = useRating('effectiveness');
  const { getByValue: getLikelihoodByValue } = useRating('likelihood');
  const { getByValue: getImpactByValue } = useRating('impact');
  const { getByValue: getControlledRatingByValue } =
    useRating('risk_controlled');
  const { getByValue: getUncontrolledRatingByValue } =
    useRating('risk_uncontrolled');

  return useMemo<InternalAuditReportResultRegisterFields[] | undefined>(() => {
    const ratingFns = {
      document_assessment_result: (d: InternalAuditReportResultFields) =>
        getByResultValue(d.Rating),
      obligation_assessment_result: (d: InternalAuditReportResultFields) =>
        getByResultValue(d.Rating),
      test_result: (d: InternalAuditReportResultFields) =>
        getEffectivenessByValue(d.OverallEffectiveness),
      risk_assessment_result: (d: InternalAuditReportResultFields) => {
        const rating = d.Rating;
        const controlType = d.ControlType;
        if (
          rating == null ||
          controlType == null ||
          riskModel === 'control_effectiveness_averages'
        ) {
          return;
        }

        return controlType === 'Uncontrolled'
          ? getUncontrolledRatingByValue(rating)
          : getControlledRatingByValue(rating);
      },
    };

    const likelihoodFns = {
      document_assessment_result: (_d: InternalAuditReportResultFields) => null,
      obligation_assessment_result: (_d: InternalAuditReportResultFields) =>
        null,
      test_result: (_d: InternalAuditReportResultFields) => null,
      risk_assessment_result: (d: InternalAuditReportResultFields) =>
        getLikelihoodByValue(d.Likelihood),
    };

    const impactFns = {
      document_assessment_result: (_d: InternalAuditReportResultFields) => null,
      obligation_assessment_result: (_d: InternalAuditReportResultFields) =>
        null,
      test_result: (_d: InternalAuditReportResultFields) => null,
      risk_assessment_result: (d: InternalAuditReportResultFields) =>
        getImpactByValue(d.Impact),
    };

    return records?.map((d) => {
      const auditStatus = d.internalAuditReports.find(
        (a) => a.internalAuditReport
      )?.internalAuditReport?.Status;

      return {
        ...d,
        AuditTitle:
          d.internalAuditReports.find((a) => a.internalAuditReport)
            ?.internalAuditReport?.Title || '-',
        TypeLabelled: decorateWithControlType(
          assessmentResultTypes[
            d.__typename as keyof typeof assessmentResultTypes
          ],
          getControlTypeLabel,
          d
        ),
        ParentTitle: getParentTitle(d) || '-',
        RatingLabelled:
          ratingFns[d.__typename as keyof typeof ratingFns](d)?.label || '-',
        ImpactLabelled:
          impactFns[d.__typename as keyof typeof impactFns](d)?.label || '-',
        LikelihoodLabelled:
          likelihoodFns[d.__typename as keyof typeof likelihoodFns](d)?.label ||
          '-',
        StartDate: d.internalAuditReports.find((a) => a.internalAuditReport)
          ?.internalAuditReport?.StartDate,
        ActualCompletionDate: d.internalAuditReports.find(
          (a) => a.internalAuditReport
        )?.internalAuditReport?.ActualCompletionDate,
        CompletedByUser:
          d.internalAuditReports.find((a) => a.internalAuditReport)
            ?.internalAuditReport?.completedByUser?.FriendlyName || '-',
        Rationale: d.Rationale,
        TestDate: d.TestDate,
        Status: auditStatus ?? '-',
        StatusLabelled: auditStatus ? status[auditStatus] : '-',
        originalResult: d,
      };
    });
  }, [
    records,
    assessmentResultTypes,
    getControlTypeLabel,
    getByResultValue,
    getEffectivenessByValue,
    getLikelihoodByValue,
    getImpactByValue,
    getControlledRatingByValue,
    getUncontrolledRatingByValue,
    riskModel,
    status,
  ]);
};
