import { IndicatorsUpdatePage, IndicatorUpdateBreadcrumb } from '@pages';
import type { RouteObject } from 'react-router';

export const indicatorRoute: RouteObject = {
  path: ':indicatorId',
  handle: {
    breadcrumb: IndicatorUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: <IndicatorsUpdatePage activeTabId="details" />,
    },
    {
      path: 'results',
      handle: {
        // TODO: translation
        title: 'Results',
      },
      element: <IndicatorsUpdatePage activeTabId="results" />,
    },
    {
      path: 'linked-items',
      handle: {
        title: 'Linked items',
      },
      children: [
        {
          path: '',
          element: <IndicatorsUpdatePage activeTabId="linkedItems" />,
        },
      ],
    },
  ],
};
