import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRating } from '@/hooks/use-rating';
import { isPastDate } from '@/utils/dateUtils';

import type { ControlTestFlatFields, ControlTestTableFields } from './types';

export const useLabelledFields = (
  records: ControlTestFlatFields[] | undefined
) => {
  const { t } = useTranslation(['common']);
  const { getLabel: getLabelOverallEffectiveness } = useRating('effectiveness');
  const { getLabel: getLabelDesignEffectiveness } = useRating(
    'design_effectiveness'
  );
  const { getLabel: getLabelPerformanceEffectiveness } = useRating(
    'performance_effectiveness'
  );
  const testTypeLookup = useMemo(() => t('testTypes'), [t]);

  return useMemo<ControlTestTableFields[] | undefined>(() => {
    return records?.map((d) => {
      return {
        ...d,
        ParentTitle: d.parent?.Title ?? null,
        CreatedByUserName: d.createdByUser?.FriendlyName ?? null,
        SubmitterNameLabelled: d.submitter?.FriendlyName ?? null,
        DesignEffectivenessLabelled: getLabelDesignEffectiveness(
          d.DesignEffectiveness
        ),
        PerformanceEffectivenessLabelled: getLabelPerformanceEffectiveness(
          d.PerformanceEffectiveness
        ),
        OverallEffectivenessLabelled: getLabelOverallEffectiveness(
          d.OverallEffectiveness
        ),
        TestType: testTypeLookup[d.TestType as keyof typeof testTypeLookup],
        ControlSequentialId: d.parent?.SequentialId ?? null,
        Overdue: isPastDate(d.parent?.NextTestDate ?? null),
        tags: d.parent?.tags ?? [],
        departments: d.parent?.departments ?? [],
        NextTestDate: d.parent?.NextTestDate ?? null,
        FileCount: d.files_aggregate.aggregate?.count ?? 0,
      };
    });
  }, [
    records,
    getLabelDesignEffectiveness,
    getLabelPerformanceEffectiveness,
    getLabelOverallEffectiveness,
    testTypeLookup,
  ]);
};
