
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_o2h6h_290",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_o2h6h_298",
  "pagination-button": "awsui_pagination-button_14rmt_o2h6h_315",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_o2h6h_322",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_o2h6h_325",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_o2h6h_329",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_o2h6h_332",
  "tabs-tab": "awsui_tabs-tab_14rmt_o2h6h_337",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_o2h6h_346",
  "tabs-tab-header-container": "awsui_tabs-tab-header-container_14rmt_o2h6h_357",
  "tabs-tab-dismiss": "awsui_tabs-tab-dismiss_14rmt_o2h6h_368",
  "tabs-tab-action": "awsui_tabs-tab-action_14rmt_o2h6h_368",
  "refresh": "awsui_refresh_14rmt_o2h6h_372",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_o2h6h_376",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_o2h6h_418",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_o2h6h_494",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_o2h6h_501",
  "tabs-tab-focusable": "awsui_tabs-tab-focusable_14rmt_o2h6h_505",
  "root": "awsui_root_14rmt_o2h6h_509",
  "tabs": "awsui_tabs_14rmt_o2h6h_290",
  "tabs-content": "awsui_tabs-content_14rmt_o2h6h_547",
  "fit-height": "awsui_fit-height_14rmt_o2h6h_551",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_o2h6h_557",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_o2h6h_571",
  "with-paddings": "awsui_with-paddings_14rmt_o2h6h_571",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_o2h6h_582",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_14rmt_o2h6h_593",
  "tabs-tab-focused": "awsui_tabs-tab-focused_14rmt_o2h6h_597"
};
  