import { JsonForms } from '@jsonforms/react';

import rendererRegistry from '../renderers/registry';
import { withJsonFormsContextProvider } from './contexts/JsonFormsContextProvider';
import { FormBuilderAddSection } from './FormBuilderAddSection';
import { useFormBuilderContext } from './useFormBuilderContext';

const FormBuilderUnwrapped = () => {
  const {
    formData,
    schema,
    uiSchema,
    isFormCustomisable,
    setFormData,
    setUISchema,
  } = useFormBuilderContext();

  return (
    <>
      <div key={'form-builder'} className={'flex flex-col gap-y-4 mb-8'}>
        <JsonForms
          // readonly={false} // TODO: Remove debugging readonly state
          readonly={true}
          data={formData}
          schema={schema}
          uischema={uiSchema}
          renderers={rendererRegistry}
          onChange={({ data }) => setFormData(data)}
        />

        {isFormCustomisable ? (
          <FormBuilderAddSection
            schema={schema}
            uiSchema={uiSchema}
            setUISchema={setUISchema}
          />
        ) : null}
      </div>
    </>
  );
};

export const FormBuilder = withJsonFormsContextProvider(FormBuilderUnwrapped);
