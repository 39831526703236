import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImpactRatingModal from 'src/pages/impacts/ratings/ImpactRatingModal';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteImpactRatingsMutation,
  useGetAppetitesByParentIdQuery,
  useGetImpactRatingsByRatedItemIdQuery,
  useInsertChildImpactRatingsMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import InsertMultipleImpactRatingsModal from '../../../../impacts/ratings/InsertMultipleImpactRatingsModal';
import { useGetCollectionTableProps } from './config';
import type { ImpactRatingTableFields } from './types';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('impactRatings.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impactRatings',
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditMultipleOpen, setIsEditMultipleOpen] = useState(false);
  const [ratingId, setRatingId] = useState<string | undefined>(undefined);
  const [selectedRatings, setSelectedRatings] = useState<
    ImpactRatingTableFields[]
  >([]);

  const canDeleteRatings = useHasPermission('delete:impact_rating', parent);

  const { addNotification } = useNotifications();
  const { data, loading, refetch } = useGetImpactRatingsByRatedItemIdQuery({
    variables: {
      ratedItemId: parent.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const { data: appetiteData, loading: loadingAppetites } =
    useGetAppetitesByParentIdQuery({
      variables: {
        parentId: parent.Id,
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const [deleteRatings, deleteResult] = useDeleteImpactRatingsMutation({
    update: (cache) => evictField(cache, 'impact_rating'),
  });

  const handleRatingsOpen = () => {
    setIsEditMultipleOpen(true);
  };

  const handleRatingCreateClose = () => {
    setRatingId(undefined);
    setIsEditOpen(false);
  };

  const handleMultipleRatingsCreateClose = () => {
    setRatingId(undefined);
    setIsEditMultipleOpen(false);
  };

  const [insertRatings] = useInsertChildImpactRatingsMutation({
    update: (cache) => {
      evictField(cache, 'impact_rating');
      refetch();
    },
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteRatings({
        variables: { Ids: selectedRatings.map((s) => s.Id) },
      });
      setSelectedRatings([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const tableProps = useGetCollectionTableProps(
    parent,
    data,
    appetiteData,
    ({ Id }) => {
      setRatingId(Id);
      setIsEditOpen(true);
    },
    handleRatingsOpen
  );

  return (
    <>
      <Table
        {...tableProps}
        selectionType={canDeleteRatings ? 'multi' : undefined}
        selectedItems={selectedRatings}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedRatings(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:impact_rating"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedRatings.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:impact_rating"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleRatingsOpen}
                    >
                      {t('impactRatingsMultiple.create_new_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading || loadingAppetites}
        sortingDisabled={false}
      />
      {isEditOpen && (
        <ImpactRatingModal
          ratedItemId={parent.Id}
          impactRatingId={ratingId}
          onSaving={async (data) => {
            await insertRatings({
              variables: {
                ...data,
                CompletedBy: data.CompletedBy?.value,
                Ratings: [
                  {
                    ImpactId: data.ImpactId,
                    Rating: data.Rating,
                  },
                ],
              },
            });
          }}
          onDismiss={handleRatingCreateClose}
        />
      )}
      {isEditMultipleOpen && (
        <InsertMultipleImpactRatingsModal
          impactRatingId={ratingId}
          onSaving={async (data) => {
            await insertRatings({
              variables: {
                ...data,
                CompletedBy: data.CompletedBy?.value,
                RatedItemId: parent.Id,
                TestDate: data.TestDate,
                CustomAttributeData: data.CustomAttributeData,
                Ratings: data.Ratings,
              },
            });
          }}
          onDismiss={handleMultipleRatingsCreateClose}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
