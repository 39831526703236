import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { FormStateContext } from '../useJsonFormContext';

interface FormStateContextProviderProps {
  children: ReactNode;
}

const JsonFormsContextProvider: FC<FormStateContextProviderProps> = ({
  children,
}) => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <FormStateContext.Provider value={{ isFormDirty, setIsFormDirty }}>
      {children}
    </FormStateContext.Provider>
  );
};

export default JsonFormsContextProvider;
