import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import { useHasPermission } from 'src/rbac/useHasPermission';
import type { ActionUpdatesFields } from 'src/schemas/actionUpdates';
import { ActionUpdatesSchema, defaultValues } from 'src/schemas/actionUpdates';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import type { GetActionByIdQuery } from '@/generated/graphql';
import {
  Parent_Type_Enum,
  useGetActionUpdateByIdQuery,
  useInsertActionUpdateMutation,
  useUpdateActionUpdateMutation,
} from '@/generated/graphql';

import ActionUpdateForm from './forms/ActionUpdateForm';

type Props = {
  onDismiss: () => void;
  actionUpdateId?: string;
  action: GetActionByIdQuery['action'][number];
};

const ActionUpdateModal: FC<Props> = ({
  onDismiss,
  actionUpdateId,
  action,
}) => {
  const { t } = useTranslation('common');
  const { updateFiles } = useFileUpdate();
  const actionId = useGetGuidParam('actionId');

  const { data, loading, error } = useGetActionUpdateByIdQuery({
    variables: {
      _eq: actionUpdateId!,
    },
    skip: !actionUpdateId,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const actionUpdate = data?.action_update[0];
  const canEditActionUpdate = useHasPermission('update:action_update', action);
  const canCreateActionUpdate = useHasPermission(
    'insert:action_update',
    action
  );
  const canModify = actionUpdate ? canEditActionUpdate : canCreateActionUpdate;
  const [insertActionUpdate] = useInsertActionUpdateMutation();
  const [updateActionUpdate] = useUpdateActionUpdateMutation();

  const onSave = async (data: ActionUpdatesFields) => {
    const { newFiles, files, ...rest } = data;
    if (actionUpdate) {
      await updateActionUpdate({
        variables: {
          ...rest,
          CustomAttributeData: rest.CustomAttributeData,
          Id: actionUpdate.Id,
          OriginalTimestamp: actionUpdate.ModifiedAtTimestamp,
        },
      });
    } else {
      const result = await insertActionUpdate({
        variables: {
          ...rest,
          CustomAttributeData: rest.CustomAttributeData,
        },
      });
      actionUpdateId = result.data?.insert_action_update_one?.Id;
    }
    if (!actionUpdateId) {
      throw new Error('Missing action update id');
    }

    await updateFiles({
      parentType: Parent_Type_Enum.ActionUpdate,
      parentId: actionUpdateId,
      newFiles: newFiles,
      originalFiles: actionUpdate?.files,
      selectedFiles: files,
    });
  };

  if (loading) {
    return null;
  }

  return (
    <ModalForm<ActionUpdatesFields>
      values={actionUpdate}
      defaultValues={{
        ...defaultValues,
        ParentActionId: actionId,
      }}
      i18n={t('actionUpdates')}
      schema={ActionUpdatesSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      formId={'action-update-form'}
      visible={true}
      readOnly={!canModify}
      parentType={Parent_Type_Enum.ActionUpdate}
    >
      <ActionUpdateForm readOnly={!canModify} />
    </ModalForm>
  );
};

export default ActionUpdateModal;
