
    import './styles.scoped.css';
    export default {
  "segment": "awsui_segment_8cbea_pghx8_161",
  "disabled": "awsui_disabled_8cbea_pghx8_241",
  "selected": "awsui_selected_8cbea_pghx8_249",
  "icon": "awsui_icon_8cbea_pghx8_270",
  "with-text": "awsui_with-text_8cbea_pghx8_274",
  "with-no-text": "awsui_with-no-text_8cbea_pghx8_280",
  "root": "awsui_root_8cbea_pghx8_285",
  "segment-part": "awsui_segment-part_8cbea_pghx8_317",
  "select": "awsui_select_8cbea_pghx8_249",
  "segment-count-2": "awsui_segment-count-2_8cbea_pghx8_336",
  "segment-count-3": "awsui_segment-count-3_8cbea_pghx8_340",
  "segment-count-4": "awsui_segment-count-4_8cbea_pghx8_344",
  "segment-count-5": "awsui_segment-count-5_8cbea_pghx8_348",
  "segment-count-6": "awsui_segment-count-6_8cbea_pghx8_352",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_8cbea_pghx8_356"
};
  