import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  Parent_Type_Enum,
  useDeleteActionsMutation,
  useGetActionByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  activeTabId: 'details' | 'updates' | 'linkedItems';
  showDeleteButton?: boolean;
};

const Page: FC<Props> = ({ activeTabId, showDeleteButton }) => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'actions' });
  const { t } = useTranslation(['common']);
  const defaultTitle = st('fallback_title');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const actionId = useGetGuidParam('actionId');
  const [exportItem, { loading: exporting }] = useExporter(actionId);
  const parentPath = useGetDetailParentPath(actionId);
  const detailsPath = useGetDetailPath(actionId);

  const { data, error } = useGetActionByIdQuery({
    variables: {
      _eq: actionId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const [deleteActions, deleteResult] = useDeleteActionsMutation({
    update: (cache) => {
      evictField(cache, 'action');
      evictField(cache, 'action_aggregate');
    },
  });

  const action = data?.action?.[0];
  const tabs = useTabs(detailsPath, action);

  if (data?.action.length === 0) {
    throw new PageNotFound(`Action with id ${actionId} not found`);
  }

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!action) {
        return false;
      }
      await deleteActions({
        variables: { Ids: [action.Id] },
      });
      navigate(parentPath);

      return true;
    },
  });

  const title = action?.Title || defaultTitle;
  const counter =
    action &&
    `(${getFriendlyId(Parent_Type_Enum.Action, action.SequentialId)})`;

  return (
    <PageLayout
      title={title}
      meta={{
        title: defaultTitle,
      }}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" disabled={exporting} onClick={exportItem}>
            {t('export')}
          </Button>
          {showDeleteButton && (
            <Permission permission="delete:action" parentObject={action}>
              <Button
                variant="normal"
                formAction="none"
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {st('delete_button')}
              </Button>
            </Permission>
          )}
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
