
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_x7peu_14hcd_145",
  "resize-active-with-focus": "awsui_resize-active-with-focus_x7peu_14hcd_145",
  "divider": "awsui_divider_x7peu_14hcd_150",
  "divider-disabled": "awsui_divider-disabled_x7peu_14hcd_164",
  "divider-active": "awsui_divider-active_x7peu_14hcd_167",
  "resizer": "awsui_resizer_x7peu_14hcd_171",
  "has-focus": "awsui_has-focus_x7peu_14hcd_225",
  "tracker": "awsui_tracker_x7peu_14hcd_250"
};
  