import { useTranslation } from 'react-i18next';

import { FormContext } from '@/components/Form/Form/InnerFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import type { FormContextProps } from '@/components/Form/Form/types';

import { InvitationFormFields } from './InvitationFormFields';
import type { InvitationFields } from './invitationSchema';
import { defaultValues, invitationSchema } from './invitationSchema';

export type Props = Omit<
  FormContextProps<InvitationFields>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'defaultValues'
  | 'renderTemplate'
  | 'i18n'
  | 'mapPreviewedChanges'
>;

export const InvitationForm = ({ ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <FormContext
      {...props}
      values={props.values}
      formId={'questionnaire-invite-form'}
      defaultValues={defaultValues}
      i18n={t('third_party')}
      header={t('plan_questionnaire.form_title')}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      schema={invitationSchema}
    >
      <InvitationFormFields />
    </FormContext>
  );
};
