
    import './styles.scoped.css';
    export default {
  "visible-content": "awsui_visible-content_tc96w_16sjh_289",
  "visible-content-toggle": "awsui_visible-content-toggle_tc96w_16sjh_290",
  "visible-content-groups": "awsui_visible-content-groups_tc96w_16sjh_291",
  "visible-content-group": "awsui_visible-content-group_tc96w_16sjh_291",
  "visible-content-title": "awsui_visible-content-title_tc96w_16sjh_296",
  "visible-content-group-label": "awsui_visible-content-group-label_tc96w_16sjh_306",
  "visible-content-option": "awsui_visible-content-option_tc96w_16sjh_312",
  "visible-content-option-label": "awsui_visible-content-option-label_tc96w_16sjh_325",
  "content-display-option-toggle": "awsui_content-display-option-toggle_tc96w_16sjh_620",
  "content-display-option-content": "awsui_content-display-option-content_tc96w_16sjh_624",
  "content-display-option": "awsui_content-display-option_tc96w_16sjh_620",
  "placeholder": "awsui_placeholder_tc96w_16sjh_671",
  "sorting": "awsui_sorting_tc96w_16sjh_671",
  "content-display-option-label": "awsui_content-display-option-label_tc96w_16sjh_698",
  "content-display": "awsui_content-display_tc96w_16sjh_620",
  "content-display-text-filter": "awsui_content-display-text-filter_tc96w_16sjh_706",
  "content-display-no-match": "awsui_content-display-no-match_tc96w_16sjh_707",
  "content-display-title": "awsui_content-display-title_tc96w_16sjh_711",
  "content-display-description": "awsui_content-display-description_tc96w_16sjh_720",
  "content-display-option-list": "awsui_content-display-option-list_tc96w_16sjh_728",
  "root": "awsui_root_tc96w_16sjh_735",
  "modal-root": "awsui_modal-root_tc96w_16sjh_736",
  "trigger-button": "awsui_trigger-button_tc96w_16sjh_737",
  "cancel-button": "awsui_cancel-button_tc96w_16sjh_738",
  "confirm-button": "awsui_confirm-button_tc96w_16sjh_739",
  "custom": "awsui_custom_tc96w_16sjh_740",
  "second-column-small": "awsui_second-column-small_tc96w_16sjh_744",
  "wrap-lines": "awsui_wrap-lines_tc96w_16sjh_748",
  "striped-rows": "awsui_striped-rows_tc96w_16sjh_749",
  "content-density": "awsui_content-density_tc96w_16sjh_750",
  "page-size": "awsui_page-size_tc96w_16sjh_751",
  "page-size-form-field": "awsui_page-size-form-field_tc96w_16sjh_752",
  "page-size-radio-group": "awsui_page-size-radio-group_tc96w_16sjh_753",
  "sticky-columns": "awsui_sticky-columns_tc96w_16sjh_754",
  "sticky-columns-form-field": "awsui_sticky-columns-form-field_tc96w_16sjh_755",
  "sticky-columns-radio-group": "awsui_sticky-columns-radio-group_tc96w_16sjh_756",
  "sticky-columns-first": "awsui_sticky-columns-first_tc96w_16sjh_757",
  "sticky-columns-last": "awsui_sticky-columns-last_tc96w_16sjh_758"
};
  