import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { ownerUpdateFields } from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import type { GetImpactByIdQuery } from '@/generated/graphql';
import { useUpdateImpactMutation } from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ImpactForm from '../../forms/ImpactForm';
import type { ImpactFormFieldData } from '../../forms/impactFormSchema';
import { defaultValues } from '../../forms/impactFormSchema';

type Props = {
  impact: GetImpactByIdQuery['impact'][number];
};

const Tab: FC<Props> = ({ impact }) => {
  useI18NSummaryHelpContent('impacts.detailsHelp');
  const canUpdateImpact = useHasPermission('update:impact', impact);
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [updateImpact] = useUpdateImpactMutation({
    update: (cache) => {
      evictField(cache, 'impact');
      evictField(cache, 'impact_rating');
    },
  });

  const onSave = async (data: ImpactFormFieldData) => {
    if (!impact) {
      throw new Error('Missing impact data');
    }
    await updateImpact({
      variables: {
        ...data,
        Id: impact.Id,
        ...ownerUpdateFields(data, impact.Id),
      },
    });
  };

  const onDismiss = () => navigate(-1);

  return (
    <ImpactForm
      onDismiss={onDismiss}
      header={t('details')}
      readOnly={!canUpdateImpact}
      onSave={onSave}
      values={{
        ...defaultValues,
        ...impact,
        Owners: getOwners(impact),
      }}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default Tab;
