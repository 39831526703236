import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import {
  Parent_Type_Enum,
  Risk_Assessment_Result_Control_Type_Enum,
} from '@/generated/graphql';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import RiskAssessmentResultFormFields from './RiskAssessmentResultFormFields';
import type { RiskAssessmentResultFormDataFields } from './riskAssessmentResultSchema';
import { RiskAssessmentResultSchema } from './riskAssessmentResultSchema';

type Props = Omit<
  FormContextProps<RiskAssessmentResultFormDataFields>,
  'schema' | 'formId' | 'parentType' | 'i18n'
> & {
  beforeFieldsSlot?: ReactNode;
  showSelector?: AssessmentTypeEnum;
  assessmentMode: AssessmentTypeEnum;
  disableRiskSelector: boolean;
};

const getParentTypeFromControlType = (
  controlType: Risk_Assessment_Result_Control_Type_Enum,
  assessmentMode?: AssessmentTypeEnum
) => {
  if (!assessmentMode || assessmentMode === 'rating') {
    if (controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled) {
      return Parent_Type_Enum.ControlledRiskAssessmentResult;
    }

    return Parent_Type_Enum.UncontrolledRiskAssessmentResult;
  }
  if (assessmentMode === 'compliance_monitoring_assessment') {
    if (controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled) {
      return Parent_Type_Enum.SecondLineAssessmentControlledRiskAssessmentResult;
    }

    return Parent_Type_Enum.SecondLineAssessmentUncontrolledRiskAssessmentResult;
  }
  if (assessmentMode === 'internal_audit_report') {
    if (controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled) {
      return Parent_Type_Enum.InternalAuditReportControlledRiskAssessmentResult;
    }

    return Parent_Type_Enum.InternalAuditReportUncontrolledRiskAssessmentResult;
  }
};

const RiskAssessmentResultForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [controlType, setControlType] =
    useState<Risk_Assessment_Result_Control_Type_Enum>(
      Risk_Assessment_Result_Control_Type_Enum.Uncontrolled
    );

  return (
    <CustomisableFormContext
      {...props}
      schema={RiskAssessmentResultSchema}
      i18n={t('assessmentResults')}
      formId={'assessment-result-form'}
      renderTemplate={props.renderTemplate}
      parentType={getParentTypeFromControlType(
        controlType,
        props.assessmentMode
      )}
    >
      {props.beforeFieldsSlot}
      <RiskAssessmentResultFormFields
        readOnly={props.readOnly}
        onControlTypeChange={setControlType}
        disableRiskSelector={props.disableRiskSelector}
        showSelector={props.showSelector}
      />
    </CustomisableFormContext>
  );
};

export default RiskAssessmentResultForm;
