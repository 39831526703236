import type { MultiselectProps } from '@cloudscape-design/components/multiselect';
import type { FC } from 'react';

import type { HidableOption } from '../ControlledMultiselect/types';
import useFilterHiddenOptions from '../ControlledMultiselect/useFilterHiddenOptions';
import MultiSelect from '.';

const HiddenOptionMultiSelect: FC<MultiselectProps & { testId?: string }> = ({
  options,
  testId,
  ...rest
}) => {
  const filteredOptions = useFilterHiddenOptions(
    options as HidableOption[] | undefined
  );

  return (
    <MultiSelect data-testid={testId} {...rest} options={filteredOptions} />
  );
};

export default HiddenOptionMultiSelect;
