
    import './styles.scoped.css';
    export default {
  "help-panel": "awsui_help-panel_1d237_yvy08_181",
  "loading": "awsui_loading_1d237_yvy08_348",
  "header": "awsui_header_1d237_yvy08_353",
  "with-toolbar": "awsui_with-toolbar_1d237_yvy08_370",
  "content": "awsui_content_1d237_yvy08_388",
  "footer": "awsui_footer_1d237_yvy08_439"
};
  