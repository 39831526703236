import type { AxiosInstance } from 'axios';
import axios from 'axios';

import { useAxios } from '../useAxios';
import { downloadBlob } from './fileUtils';

type FileType = {
  fileId?: string | null;
  fileName: string;
  file?: File;
  openInBrowser?: boolean;
};

const getS3DownloadUrl = async ({ get }: AxiosInstance, fileId: string) =>
  (await get<string>(`/files/${fileId}`)).data;

const getFileFromS3 = async (url: string) =>
  (await axios.create().get<Blob>(url, { responseType: 'blob' })).data;

const getFileBlob = async (axios: AxiosInstance, fileId: string) => {
  const url = await getS3DownloadUrl(axios, fileId);

  return await getFileFromS3(url);
};

export const useFileDownload = () => {
  const axios = useAxios();

  return async (
    { fileId, fileName, file }: FileType,
    downloadFile = true
  ): Promise<Blob> => {
    const blob = file || (fileId && (await getFileBlob(axios, fileId)));
    if (!blob) {
      throw new Error('No file or fileId to download');
    }
    if (downloadFile) {
      downloadBlob(fileName, blob);
    }

    return blob;
  };
};
