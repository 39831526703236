import type {
  OptionDefinition,
  OptionGroup,
} from '@cloudscape-design/components/internal/components/option/interfaces';

export type StatusType = 'pending' | 'loading' | 'finished' | 'error';

export const getSelectedOption = (
  value: string | undefined | null,
  optionItems: (OptionDefinition | OptionGroup)[]
) => {
  if (value === null) {
    return value;
  }

  const matchingItem: OptionDefinition | null = null;
  for (const option of optionItems) {
    if ('value' in option && option.value === value) {
      return option;
    }
    if ('options' in option) {
      for (const childOption of option.options) {
        if ('value' in childOption && childOption.value === value) {
          return childOption;
        }
      }
    }
  }
  if (!matchingItem) {
    // This can be the case for "Standard" role users, where they don't have permission to view the currently
    // selected option e.g. a parent risk
    return {
      value,
    };
  }

  return matchingItem;
};
