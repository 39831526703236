import type { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import type { ObligationFormFieldData } from 'src/pages/compliance/obligations/forms/obligationSchema';
import { defaultValues as defaultInsertValues } from 'src/pages/compliance/obligations/forms/obligationSchema';
import { useHasPermission } from 'src/rbac/useHasPermission';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import type { InsertObligationMutationVariables } from '@/generated/graphql';
import {
  namedOperations,
  Obligation_Type_Enum,
  useInsertObligationMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';
import { obligationDetailsUrl } from '@/utils/urls';

import ObligationDetailsForm from '../../../forms/ObligationDetailsForm';

const Tab: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get('type') || '';
  const canCreateObligation = useHasPermission('insert:obligation');
  const [mutate] = useInsertObligationMutation({
    update: (cache) => evictField(cache, 'obligation'),
    refetchQueries: [
      namedOperations.Query.getObligationById,
      namedOperations.Query.getObligationsByType,
    ],
  });
  const defaultValues: ObligationFormFieldData = {
    ...defaultInsertValues,
    Type: Object.values<string>(Obligation_Type_Enum).includes(typeParam)
      ? (typeParam as Obligation_Type_Enum)
      : defaultInsertValues.Type,
  };

  const onSave = async (data: ObligationFormFieldData) => {
    const saveData: InsertObligationMutationVariables = {
      ...data,
      Tags: tagInsertInputBuilder(data.TagTypeIds),
      Departments: departmentInsertInputBuilder(data.DepartmentTypeIds),
      ...ownerAndContributorInsertFields(data),
      CustomAttributeData: data.CustomAttributeData,
    };
    const { data: resultData } = await mutate({ variables: saveData });
    if (resultData?.insert_obligation?.returning[0]?.Id) {
      navigate(
        obligationDetailsUrl(resultData?.insert_obligation?.returning[0]?.Id),
        { replace: true }
      );
    }
  };
  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return (
    <>
      <ObligationDetailsForm
        values={defaultValues}
        defaultValues={defaultValues}
        onSave={onSave}
        onDismiss={onDismiss}
        readOnly={!canCreateObligation}
      />
    </>
  );
};

export default Tab;
