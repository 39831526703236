import Linkify from 'linkify-react';
import type { FC } from 'react';

type Props = {
  title?: string;
  content: string | { title: string; content: string }[];
};

const HelpSection: FC<Props> = ({ title, content }) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      {typeof content === 'string'
        ? content.split('\n').map((p, i) => (
            <Linkify
              key={i}
              as="p"
              options={{
                target: '_blank',
              }}
            >
              {p}
            </Linkify>
          ))
        : content.map((c, i) => (
            <div key={i}>
              <h4>{c.title}</h4>
              {c.content.split('\n').map((p, i) => (
                <Linkify
                  key={i}
                  as="p"
                  options={{
                    target: '_blank',
                  }}
                >
                  {p}
                </Linkify>
              ))}
            </div>
          ))}
    </>
  );
};

export default HelpSection;
