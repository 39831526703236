import type { MultiselectProps } from '@cloudscape-design/components/multiselect';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { FieldValues, RefCallBack } from 'react-hook-form';

import { FormField } from '@/components/Form/Form/FormField';
import type { Content } from '@/components/HelpPanel/HelpContext';
import HelpLink from '@/components/HelpPanel/HelpLink';
import Tokens from '@/components/Tokens';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import FormRow from '../Form/FormRow';
import Multiselect from '../MultiSelect';
import type { ControlledBaseProps } from '../types';
import type { HidableOption, HidableOptionGroup } from './types';
import useFilterHiddenOptions from './useFilterHiddenOptions';

type Props<T extends FieldValues> = ControlledBaseProps<T> & {
  testId?: string;
  options: ReadonlyArray<HidableOption>;
  onChange?: (options: readonly HidableOption[]) => void;
  renderTokens?: boolean;
  customTokenRender?: (
    options: HidableOption[],
    actions: { removeToken: (value: string) => void }
  ) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewChangesFormatter?: (value: any) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasFieldChanged?: (value: any) => boolean;
} & Omit<MultiselectProps, 'selectedOptions' | 'onChange' | 'options'> & {
    secondaryControl?: ReactNode;
    sideControl?: ReactNode;
  };

interface MultiSelectInputProps extends MultiselectProps {
  label: string;
  innerRef?: RefCallBack;
  errorMessage?: string;
  testId?: string;
  tokenSection: ReactNode;
  description?: Content;
  renderTokens?: boolean;
  secondaryControl?: ReactNode;
  sideControl?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewChangesFormatter?: (value: any) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasFieldChanged?: (value: any) => boolean;
}

export const MultiSelect = ({
  label,
  options,
  testId,
  description,
  secondaryControl,
  sideControl,
  errorMessage,
  tokenSection,
  renderTokens,
  innerRef,
  previewChangesFormatter,
  hasFieldChanged,
  ...props
}: MultiSelectInputProps) => {
  return (
    <FormField
      label={label}
      errorText={errorMessage}
      stretch
      testId={testId}
      info={
        description && (
          <HelpLink title={label} content={description} id={label} />
        )
      }
      secondaryControl={secondaryControl}
      previewChangesFormatter={previewChangesFormatter}
      hasFieldChanged={hasFieldChanged}
    >
      <FormRow>
        <div className="flex flex-row">
          <div className="flex-grow">
            <Multiselect
              virtualScroll={(options || []).length >= 500}
              hideTokens={!!renderTokens}
              ref={innerRef}
              // TODO: translation
              placeholder="Enter value"
              empty="No matches found"
              options={options}
              {...props}
            />
          </div>
          {sideControl}
        </div>
        {tokenSection}
      </FormRow>
    </FormField>
  );
};

export const ControlledMultiselect = <T extends FieldValues>({
  name,
  control,
  label,
  onChange: _onChange,
  options,
  renderTokens,
  customTokenRender,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
  disabled,
  testId,
  description,
  secondaryControl,
  sideControl,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  const filteredOptions = useFilterHiddenOptions(options);

  const getSelectedOptions = (
    selectedOptions?: HidableOption[]
  ): HidableOption[] => {
    const selectedOptionDefinitions: HidableOption[] = [];
    for (const option of options) {
      if ('value' in option) {
        if (selectedOptions?.find((so) => so.value === option.value)) {
          selectedOptionDefinitions?.push(option);
          continue;
        }
      }
      if ('options' in option) {
        for (const childOption of (option as HidableOptionGroup).options) {
          if ('value' in childOption) {
            if (selectedOptions?.find((so) => so.value === childOption.value)) {
              selectedOptionDefinitions?.push(childOption);
              continue;
            }
          }
        }
      }
    }

    return selectedOptionDefinitions;
  };

  // Count the number of options, including nested options
  const optionCount = useMemo(
    () =>
      filteredOptions.reduce((previous, current) => {
        if ('options' in current) {
          previous += (current as HidableOptionGroup).options.length;
        }

        return previous + 1;
      }, 0),
    [filteredOptions]
  );

  return (
    <Controller
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      name={name}
      control={control}
      render={({ field: { ref, onChange, onBlur, value } }) => {
        const selectedValues: MultiselectProps.Option[] | undefined = value;
        const removeToken = (itemValue: string) => {
          const newOptions = (selectedValues ?? []).filter(
            (v) => v.value !== itemValue
          );
          _onChange?.(newOptions);
          onChange(newOptions);
        };
        const currentSelectedOptions = getSelectedOptions(value);
        let tokenSection: ReactNode = <></>;
        if (renderTokens) {
          if (customTokenRender) {
            tokenSection = customTokenRender(currentSelectedOptions, {
              removeToken,
            });
          } else {
            tokenSection = (
              <Tokens
                disabled={disabled || readOnly}
                onRemove={removeToken}
                tokens={currentSelectedOptions.map((o) => ({
                  value: o.value!,
                  label: o.label!,
                }))}
              />
            );
          }
        }

        return (
          <MultiSelect
            secondaryControl={secondaryControl}
            description={description}
            tokenSection={tokenSection}
            sideControl={sideControl}
            disabled={disabled || readOnly}
            virtualScroll={optionCount >= 500}
            hideTokens={!!renderTokens}
            innerRef={ref}
            selectedOptions={currentSelectedOptions}
            onBlur={onBlur}
            onChange={(e) => {
              _onChange?.(e.detail.selectedOptions);
              onChange(e.detail.selectedOptions);
            }}
            options={filteredOptions}
            // TODO: translation
            placeholder="Enter value"
            empty="No matches found"
            testId={testId}
            errorMessage={error?.message}
            label={label}
            renderTokens={renderTokens}
            {...props}
          />
        );
      }}
    />
  );
};
