import type { InputProps } from '@cloudscape-design/components/input';
import { Link01 } from '@untitled-ui/icons-react';
import type { FC } from 'react';

import { Circle } from '@/components/Circle';
import { TextInput } from '@/components/Form/ControlledInput/ControlledInput';
import HelpLink from '@/components/HelpPanel/HelpLink';

import type { CustomAttributeProps } from './CustomAttributeProps';

interface CustomAttributeLinkInputProps extends CustomAttributeProps {
  type?: InputProps.Type;
}

export const CustomAttributeLinkInput: FC<CustomAttributeLinkInputProps> = ({
  value,
  onChange,
  label,
  type,
  error,
  disabled,
  description,
}) => {
  return (
    <TextInput
      info={
        description && (
          <HelpLink id={label} title={label} content={description} />
        )
      }
      label={label}
      disabled={disabled}
      type={type}
      value={value}
      onChange={(val) => onChange(`${val}`)}
      errorMessage={error}
      adornment={
        <a
          target="_blank"
          className="active:text-navy_light text-navy_light ml-3"
          href={value}
          rel="noreferrer"
        >
          <Circle>
            <Link01 transform="scale(0.75)" />
          </Circle>
        </a>
      }
    />
  );
};
