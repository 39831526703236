
    import './styles.scoped.css';
    export default {
  "card-inner": "awsui_card-inner_p8a6i_1l6r7_161",
  "root": "awsui_root_p8a6i_1l6r7_193",
  "header-variant-full-page": "awsui_header-variant-full-page_p8a6i_1l6r7_232",
  "header-refresh": "awsui_header-refresh_p8a6i_1l6r7_232",
  "list": "awsui_list_p8a6i_1l6r7_239",
  "list-grid-1": "awsui_list-grid-1_p8a6i_1l6r7_250",
  "card": "awsui_card_p8a6i_1l6r7_161",
  "list-grid-2": "awsui_list-grid-2_p8a6i_1l6r7_253",
  "list-grid-3": "awsui_list-grid-3_p8a6i_1l6r7_256",
  "list-grid-4": "awsui_list-grid-4_p8a6i_1l6r7_259",
  "list-grid-5": "awsui_list-grid-5_p8a6i_1l6r7_262",
  "list-grid-6": "awsui_list-grid-6_p8a6i_1l6r7_265",
  "list-grid-7": "awsui_list-grid-7_p8a6i_1l6r7_268",
  "list-grid-8": "awsui_list-grid-8_p8a6i_1l6r7_271",
  "list-grid-9": "awsui_list-grid-9_p8a6i_1l6r7_274",
  "list-grid-10": "awsui_list-grid-10_p8a6i_1l6r7_277",
  "list-grid-11": "awsui_list-grid-11_p8a6i_1l6r7_280",
  "list-grid-12": "awsui_list-grid-12_p8a6i_1l6r7_283",
  "list-grid-13": "awsui_list-grid-13_p8a6i_1l6r7_286",
  "list-grid-14": "awsui_list-grid-14_p8a6i_1l6r7_289",
  "list-grid-15": "awsui_list-grid-15_p8a6i_1l6r7_292",
  "list-grid-16": "awsui_list-grid-16_p8a6i_1l6r7_295",
  "list-grid-17": "awsui_list-grid-17_p8a6i_1l6r7_298",
  "list-grid-18": "awsui_list-grid-18_p8a6i_1l6r7_301",
  "list-grid-19": "awsui_list-grid-19_p8a6i_1l6r7_304",
  "list-grid-20": "awsui_list-grid-20_p8a6i_1l6r7_307",
  "selection-control": "awsui_selection-control_p8a6i_1l6r7_311",
  "loading": "awsui_loading_p8a6i_1l6r7_321",
  "empty": "awsui_empty_p8a6i_1l6r7_322",
  "has-header": "awsui_has-header_p8a6i_1l6r7_329",
  "refresh": "awsui_refresh_p8a6i_1l6r7_332",
  "card-header": "awsui_card-header_p8a6i_1l6r7_403",
  "card-header-inner": "awsui_card-header-inner_p8a6i_1l6r7_411",
  "card-selectable": "awsui_card-selectable_p8a6i_1l6r7_415",
  "card-selected": "awsui_card-selected_p8a6i_1l6r7_418",
  "section": "awsui_section_p8a6i_1l6r7_426",
  "section-header": "awsui_section-header_p8a6i_1l6r7_434",
  "section-content": "awsui_section-content_p8a6i_1l6r7_440",
  "footer-pagination": "awsui_footer-pagination_p8a6i_1l6r7_450"
};
  