import type { FC } from 'react';

import { useGetInternalAuditReportByIdQuery } from '@/generated/graphql';

type Props = {
  assessmentId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ assessmentId }) => {
  if (!assessmentId) {
    throw new Error('Missing assessmentId param');
  }
  const { data } = useGetInternalAuditReportByIdQuery({
    variables: {
      Id: assessmentId,
    },
  });

  return <>{data?.internal_audit_report?.[0]?.Title || ''}</>;
};

export default Breadcrumb;
