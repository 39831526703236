export const enum TestIds {
  Type = 'type',
  TargetValueTxt = 'targetValueTxt',
  UpperTolerance = 'upperTolerance',
  LowerTolerance = 'lowerTolerance',
  UpperAppetite = 'upperAppetite',
  LowerAppetite = 'lowerAppetites',
  Files = 'files',
  Owners = 'owners',
}
