import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import { UNRATED } from 'src/pages/controls/lookupData';

import { useGetComplianceMonitoringAssessmentObligationAssessmentResultsByObligationIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useComplianceMonitoringAssessmentsExportTable = (
  obligationId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getStatusLabel } = useRating('assessment_status');
  const { getLabel: getResultLabel } = useRating('performance_result');
  const [getAssessments, getAssessmentsResult] =
    useGetComplianceMonitoringAssessmentObligationAssessmentResultsByObligationIdLazyQuery(
      {
        variables: {
          ObligationId: obligationId,
        },
      }
    );

  const { t: assessmentsColumns } = useTranslation(['common'], {
    keyPrefix: 'complianceMonitoringAssessment.columns',
  });
  const createExportTable = async () => {
    const { data: assessmentsData } = await getAssessments();
    const assessmentsTableData = (
      assessmentsData?.obligation_assessment_result ?? []
    ).map((au) => [
      au.parents[0].complianceMonitoringAssessment?.Title ?? '-',
      getStatusLabel(
        au.parents[0].complianceMonitoringAssessment?.Status ?? '-'
      ),
      getResultLabel(au.Rating ?? UNRATED.value),
      toLocalDate(au.parents[0].complianceMonitoringAssessment?.StartDate),
      toLocalDate(
        au.parents[0].complianceMonitoringAssessment?.ActualCompletionDate
      ),
      au.parents[0].complianceMonitoringAssessment?.completedByUser
        ?.FriendlyName ?? '-',
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          assessmentsColumns('Title'),
          assessmentsColumns('Status'),
          assessmentsColumns('Result'),
          assessmentsColumns('StartDate'),
          assessmentsColumns('CompletionDate'),
          assessmentsColumns('CompletionBy'),
        ]),
        ...assessmentsTableData,
      ],
    });
  };

  return [createExportTable, getAssessmentsResult.loading];
};

export default useComplianceMonitoringAssessmentsExportTable;
