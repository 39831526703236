import { createContext } from 'react';

import type { FieldType } from '../EditFields/NewFieldSchema';

export type CurrentField = {
  Label: string;
  Type: FieldType;
  Options?: string[];
  Required: boolean;
  ReadOnly: boolean;
  Hidden: boolean;
  Description?: string;
};

export type CustomFieldState = {
  currentField: CurrentField;
  fieldPath: string;
};

export const CustomFieldContext = createContext<CustomFieldState | null>(null);
