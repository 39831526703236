import type { FC } from 'react';

interface Props {
  isActive?: boolean;
}

const RadioButton: FC<Props> = ({ isActive }) => {
  const lineStyles = [
    'block',
    'absolute',
    'left-1/2',
    'border-r-0',
    'border-l-2',
    'h-full',
    'border-grey600',
    'border-solid',
    "content-['']",
    'z-0',
  ];

  return (
    <span className={`flex-none  relative w-[24px] justify-center`}>
      <span
        className={`flex-none block rounded-full border-2 bg-navy border-grey600 border-solid m-auto z-10 relative ${
          isActive ? 'bg-teal border-teal' : false
        }`}
        style={{ width: '11px', height: '11px' }}
      />
      <span
        className={`${lineStyles
          .map((x) => x)
          .join(' ')} -top-full group-first:opacity-0`}
      />
      <span
        className={`${lineStyles
          .map((x) => x)
          .join(' ')} -bottom-full group-last:opacity-0`}
      />
    </span>
  );
};

export default RadioButton;
