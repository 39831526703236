import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  GetInternalAuditReportByIdDocument,
  GetInternalAuditReportsDocument,
  Parent_Type_Enum,
  useDeleteInternalAuditReportsMutation,
  useGetInternalAuditReportByIdQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { useTabs } from 'src/pages/assessments/update/useTabs';
import { Permission } from 'src/rbac/Permission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';

type Props = {
  activeTabId:
    | 'details'
    | 'activities'
    | 'findings'
    | 'impacts'
    | 'linkedItems';
  activityMode?: 'add' | 'update' | 'list' | undefined;
};

const Page: FC<Props> = ({ activeTabId, activityMode = undefined }) => {
  const { t } = useTranslation(['common']);
  const internalAuditReportId = useGetGuidParam('assessmentId');
  const [exportItem, { loading: exporting }] = useExporter(
    internalAuditReportId
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const parentUrl = useGetDetailParentPath(internalAuditReportId);
  const detailsPath = useGetDetailPath(internalAuditReportId);
  const [mutate, deleteResult] = useDeleteInternalAuditReportsMutation({
    update: (cache) => {
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'internal_audit_report_aggregate');
    },
    refetchQueries: [
      GetInternalAuditReportByIdDocument,
      GetInternalAuditReportsDocument,
    ],
    fetchPolicy: 'no-cache',
  });

  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'internalAuditReports',
  });
  const { data, loading, error } = useGetInternalAuditReportByIdQuery({
    variables: {
      Id: internalAuditReportId,
    },
  });
  if (error) {
    throw error;
  }

  const internalAuditReport = data?.internal_audit_report[0];
  const tabs = useTabs(
    detailsPath,
    'internal_audit_report',
    internalAuditReport,
    activityMode
  );
  const onDelete = useDeleteResultNotification({
    entityName: st('entity'),
    asyncAction: async () => {
      if (!internalAuditReport) {
        return false;
      }
      await mutate({
        variables: {
          Ids: [internalAuditReport.Id],
        },
      });
      await navigate(parentUrl);

      return true;
    },
  });

  if (!loading && !internalAuditReport?.Id) {
    throw new PageNotFound(
      `Internal audit report with id ${internalAuditReportId} not found`
    );
  }

  const counter =
    internalAuditReport &&
    `(${getFriendlyId(
      Parent_Type_Enum.InternalAuditReport,
      internalAuditReport.SequentialId
    )})`;
  const fallbackTitle = st('fallback_title');

  return (
    <PageLayout
      actions={
        <Permission
          permission={'delete:internal_audit_report'}
          parentObject={internalAuditReport}
        >
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              iconName="download"
              disabled={exporting}
              onClick={exportItem}
            >
              {t('export')}
            </Button>
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button')}
            </Button>
          </SpaceBetween>
        </Permission>
      }
      meta={{
        title: fallbackTitle,
      }}
      title={internalAuditReport?.Title}
      counter={counter}
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={activeTabId}
        variant="container"
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
