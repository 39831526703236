import SpaceBetween from '@cloudscape-design/components/space-between';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { ControlFormFieldData } from 'src/pages/controls/update/forms/controlSchema';
import {
  ControlFormSchema,
  defaultValues,
} from 'src/pages/controls/update/forms/controlSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { contributorUpdateFields, ownerUpdateFields } from '@/components/Form';
import { PageForm } from '@/components/Form/Form/PageForm';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import LatestRatingsPreview from '@/components/LatestRatingsPreview';
import type { ResultProps } from '@/components/LatestRatingsPreview/LatestRatingsPreview';
import type { GetControlByIdQuery } from '@/generated/graphql';
import {
  namedOperations,
  Parent_Type_Enum,
  useGetLatestComplianceMonitoringAssessmentTestResultsByControlIdQuery,
  useGetLatestInternalAuditReportTestResultsByControlIdQuery,
  useGetLatestTestResultsByControlIdQuery,
  useUpdateControlMutation,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../../../../assessments/useAssessmentTypeConfig';
import ControlFormFields from '../../forms/ControlFormFields';
import TestResultModal from '../TestResultModal';

type Props = {
  control: GetControlByIdQuery['control'][0];
};

const Tab: FC<Props> = ({ control }) => {
  useI18NSummaryHelpContent('controls.help');
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const canEditControls = useHasPermission('update:control', control);
  const [mutate] = useUpdateControlMutation({
    update: (cache) => evictField(cache, 'control'),
    refetchQueries: [namedOperations.Query.getControlById],
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [openTestResultId, setOpenTestResultId] = useState<
    string | undefined
  >();
  const [selectedAssessmentMode, setSelectedAssessmentMode] =
    useState<AssessmentTypeEnum>('rating');

  const handleTestResultModalClose = () => {
    setOpenTestResultId(undefined);
    setIsEditOpen(false);
  };

  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    control
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    control
  );
  const skipInternalAudit = !internalAuditEnabled || !canViewInternalAudit;
  const skipComplianceMonitoring =
    !complianceMonitoringEnabled || !canViewCompliance;

  const { data: testResults } = useGetLatestTestResultsByControlIdQuery({
    variables: {
      controlId: control.Id,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: complianceMonitoringResults } =
    useGetLatestComplianceMonitoringAssessmentTestResultsByControlIdQuery({
      variables: {
        controlId: control.Id,
      },
      skip: skipComplianceMonitoring,
      fetchPolicy: 'no-cache',
    });

  const { data: internalAuditResults } =
    useGetLatestInternalAuditReportTestResultsByControlIdQuery({
      variables: {
        controlId: control.Id,
      },
      skip: skipInternalAudit,
      fetchPolicy: 'no-cache',
    });

  const onSave = async (data: ControlFormFieldData) => {
    if (!control) {
      throw new Error('Missing control');
    }

    await mutate({
      variables: {
        ...data,
        OriginalTimestamp: control.ModifiedAtTimestamp,
        Id: control.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...contributorUpdateFields(data, control.Id),
        ...ownerUpdateFields(data, control.Id),
      },
    });
  };

  const onDismiss = () => {
    navigate(-1);
  };

  const formId = 'insert-control-form';

  return (
    <>
      <PageForm
        formId={formId}
        values={{
          ...defaultValues,
          ...control,
          Owners: getOwners(control),
          Contributors: getContributors(control),
          ancestorContributors: control.ancestorContributors ?? [],
        }}
        mapPreviewedChanges={(
          current: ControlFormFieldData | undefined,
          incoming: ControlFormFieldData & {
            Owners: { UserId: string }[];
            OwnerGroups: { UserGroupId: string }[];
            Contributors: { UserId: string }[];
            ContributorGroups: { UserGroupId: string }[];
          }
        ): ControlFormFieldData => {
          return {
            ...defaultValues,
            ...current,
            ...incoming,
            Owners: incoming
              ? getOwners({
                  owners: incoming.Owners,
                  ownerGroups: incoming.OwnerGroups,
                })
              : (current?.Owners ?? []),
            Contributors: incoming
              ? getContributors({
                  contributors: incoming.Contributors,
                  contributorGroups: incoming.ContributorGroups,
                })
              : (current?.Contributors ?? []),
          };
        }}
        defaultValues={defaultValues}
        i18n={t('controls')}
        onDismiss={onDismiss}
        onSave={onSave}
        schema={ControlFormSchema}
        readOnly={!canEditControls}
        header="Details"
        parentType={Parent_Type_Enum.Control}
        approvalConfig={{ object: control }}
        aside={
          <SpaceBetween size="m">
            {testResults?.test_result[0] && (
              <LatestRatingsPreview
                ratingsTitle={t('testResults.performanceRatingSubheading')}
                onClick={(id) => {
                  setSelectedAssessmentMode('rating');
                  setOpenTestResultId(id);
                  setIsEditOpen(true);
                }}
                assessmentResults={[
                  {
                    id: testResults?.test_result[0].Id,
                    rating: testResults?.test_result[0].OverallEffectiveness,
                    completionDate: testResults?.test_result[0].TestDate,
                    title: testResults?.test_result[0].Title ?? '-',
                    ratingType: 'effectiveness',
                  },
                ]}
              />
            )}

            {complianceMonitoringResults &&
              complianceMonitoringResults.test_result.length > 0 && (
                <LatestRatingsPreview
                  ratingsTitle={t(
                    'testResults.complianceMonitoringRatingSubheading'
                  )}
                  assessmentResults={complianceMonitoringResults.test_result.map(
                    (c) =>
                      ({
                        id: c.Id,
                        title: c?.Title ?? '-',
                        rating: c.OverallEffectiveness,
                        ratingType: 'effectiveness',
                        completionDate: c.TestDate,
                      }) as ResultProps
                  )}
                  onClick={(id) => {
                    setSelectedAssessmentMode(
                      'compliance_monitoring_assessment'
                    );
                    setOpenTestResultId(id);
                    setIsEditOpen(true);
                  }}
                />
              )}
            {internalAuditResults &&
              internalAuditResults.test_result.length > 0 && (
                <LatestRatingsPreview
                  ratingsTitle={t('testResults.internalAuditRatingSubheading')}
                  assessmentResults={internalAuditResults.test_result.map(
                    (c) =>
                      ({
                        id: c.Id,
                        title: c?.Title ?? '-',
                        rating: c.OverallEffectiveness,
                        ratingType: 'effectiveness',
                        completionDate: c.TestDate,
                      }) as ResultProps
                  )}
                  onClick={(id) => {
                    setSelectedAssessmentMode('internal_audit_report');
                    setOpenTestResultId(id);
                    setIsEditOpen(true);
                  }}
                />
              )}
          </SpaceBetween>
        }
      >
        <ControlFormFields readOnly={!canEditControls} />
      </PageForm>
      {isEditOpen && control.Id && (
        <TestResultModal
          parentControlId={control.Id}
          Id={openTestResultId}
          onDismiss={handleTestResultModalClose}
          assessmentMode={selectedAssessmentMode}
        />
      )}
    </>
  );
};

export default Tab;
