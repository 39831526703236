import type { FC } from 'react';

import { useGetQuestionnaireTemplateByIdQuery } from '@/generated/graphql';

type Props = {
  questionnaireTemplateId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ questionnaireTemplateId }) => {
  if (!questionnaireTemplateId) {
    throw new Error('Missing questionnaireTemplateId param');
  }
  const { data } = useGetQuestionnaireTemplateByIdQuery({
    variables: {
      Id: questionnaireTemplateId,
    },
  });

  return <>{data?.questionnaire_template?.Title || ''}</>;
};

export default Breadcrumb;
