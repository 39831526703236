import type {
  JsonSchema7,
  Labelable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export type FieldType =
  | 'text'
  | 'textArea'
  | 'number'
  | 'radio'
  | 'url'
  | 'date'
  | 'select'
  | 'dropdown';

type UseFieldTypeOptionsReturnType = {
  [key in FieldType]: { value: FieldType; label: string };
};

export const useFieldTypeOptions = (): UseFieldTypeOptionsReturnType => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.fieldTypes',
  });

  // TODO: Remove this ts-ignore once all field types have been implemented
  // @ts-ignore
  return {
    text: { value: 'text', label: t('text') },
    textArea: {
      value: 'textArea',
      label: t('textArea'),
    },
    number: { value: 'number', label: t('number') },
    url: { value: 'url', label: t('url') },

    // TODO: Implement these
    // date: { value: 'date', label: t('text') },
    // radio: { value: 'radio', label: t('text') },
    // dropdown: { value: 'dropdown', label: t('text') },
    // select: { value: 'select', label: t('text') },
  };
};

export interface CustomSchemaProperties extends JsonSchema7 {
  parentId?: string;
}

export type CustomSchema = JsonSchema7 & {
  properties?: CustomSchemaProperties;
  isCustomisable?: boolean;
  isPropertyRequired?: boolean;
};

export type CustomUISchemaElement = UISchemaElement &
  Labelable & {
    id: string;
    parentId?: string;
    elements?: CustomUISchemaElement[];
  };

export type CustomUISchema = VerticalLayout & {
  id?: string;
  elements: CustomUISchemaElement[];
};

type FormBuilderContextState = {
  formData: unknown;
  schema: CustomSchema;
  uiSchema: CustomUISchema;
  isFormCustomisable: boolean;
  isCustomising: boolean;
  setFormData: (formData: unknown) => void;
  setSchema: (schema: CustomSchema) => void;
  setUISchema: (uiSchema: CustomUISchema) => void;
  setIsFormCustomisable: (isFormCustomisable: boolean) => void;
  setIsCustomising: (isCustomising: boolean) => void;
};

export const FormBuilderContext = createContext<FormBuilderContextState | null>(
  null
);

export const useFormBuilderContext = () => {
  const context = useContext(FormBuilderContext);

  if (!context) {
    throw new Error(
      'useSetFormBuilderContext must be used within a FormBuilderContextProvider'
    );
  }

  const {
    formData,
    schema,
    uiSchema,
    isFormCustomisable,
    isCustomising,
    setFormData,
    setSchema,
    setUISchema,
    setIsFormCustomisable,
    setIsCustomising,
  } = context;

  return {
    formData,
    schema,
    uiSchema,
    isFormCustomisable,
    isCustomising,
    setFormData,
    setSchema,
    setUISchema,
    setIsFormCustomisable,
    setIsCustomising,
  };
};
