import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';

import type { ControlGroupFormFieldData } from './controlGroupSchema';

interface Props {
  readOnly?: boolean;
}

const ControlGroupFormFields: FC<Props> = ({ readOnly }) => {
  const { control } = useFormContext<ControlGroupFormFieldData>();

  const { t: st } = useTranslation(['common'], { keyPrefix: 'controlGroups' });
  const { t } = useTranslation(['common']);

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledInput
        key="title"
        name="Title"
        forceRequired={true}
        label={st('fields.Title')}
        control={control}
        disabled={readOnly}
        description={st('fields.Title_help')}
        placeholder={st('fields.Title_placeholder') ?? ''}
      />

      <ControlledTextarea
        key="description"
        defaultRequired={true}
        name="Description"
        label={t('fields.Description')}
        description={st('fields.Description_help')}
        placeholder={st('fields.Description_placeholder') ?? ''}
        disabled={readOnly}
        control={control}
      />

      <ControlledGroupAndUserSelect
        includeGroups={false}
        forceRequired={true}
        key="owner"
        control={control}
        label={st('fields.Owner')}
        name="Owner"
        disabled={readOnly}
        description={st('fields.Owner_help')}
        placeholder={t('searchForAPerson') ?? ''}
      />
    </CustomisableForm>
  );
};

export default ControlGroupFormFields;
