import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetAppetitesByParentIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useAppetitesExportTable = (
  riskId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getRatingLabel } = useRating('risk_appetite');
  const [getAppetites, getAppetitesResult] = useGetAppetitesByParentIdLazyQuery(
    {
      variables: {
        parentId: riskId,
      },
    }
  );

  const { t: appetitesColumns } = useTranslation(['common'], {
    keyPrefix: 'appetites.columns',
  });
  const createExportTable = async () => {
    const { data: appetiteData } = await getAppetites();
    const appetiteTableData = (appetiteData?.appetite ?? []).map((i) => [
      toLocalDate(i.CreatedAtTimestamp),
      getRatingLabel(i.LowerAppetite),
      getRatingLabel(i.UpperAppetite),
    ]);

    return createTable({
      widths: [80, 80, 80],
      body: [
        tableHeaders([
          appetitesColumns('dateSet'),
          appetitesColumns('lowerAppetite'),
          appetitesColumns('upperAppetite'),
        ]),
        ...appetiteTableData,
      ],
    });
  };

  return [createExportTable, getAppetitesResult.loading];
};

export default useAppetitesExportTable;
