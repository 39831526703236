
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_ytg3m_149",
  "scrolling-background": "awsui_scrolling-background_hyvsj_ytg3m_152",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_ytg3m_163",
  "drawers-container": "awsui_drawers-container_hyvsj_ytg3m_176",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_ytg3m_187",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_ytg3m_208",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_ytg3m_213",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_ytg3m_251",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_ytg3m_261",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_ytg3m_266",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_ytg3m_280",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_ytg3m_266",
  "drawer": "awsui_drawer_hyvsj_ytg3m_176",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_ytg3m_311",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_ytg3m_320",
  "drawer-content": "awsui_drawer-content_hyvsj_ytg3m_311",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_ytg3m_329",
  "drawer-slider": "awsui_drawer-slider_hyvsj_ytg3m_332",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_ytg3m_339",
  "content": "awsui_content_hyvsj_ytg3m_365",
  "layout": "awsui_layout_hyvsj_ytg3m_390",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_ytg3m_462",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_ytg3m_477",
  "content-type-table": "awsui_content-type-table_hyvsj_ytg3m_491",
  "content-type-cards": "awsui_content-type-cards_hyvsj_ytg3m_491",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_ytg3m_497",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_ytg3m_500",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_ytg3m_514",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_ytg3m_517",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_ytg3m_527",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_ytg3m_530",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_ytg3m_530",
  "has-header": "awsui_has-header_hyvsj_ytg3m_530",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_ytg3m_545",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_ytg3m_545",
  "has-left-toggles-gutter": "awsui_has-left-toggles-gutter_hyvsj_ytg3m_565",
  "has-right-toggles-gutter": "awsui_has-right-toggles-gutter_hyvsj_ytg3m_568",
  "has-split-panel": "awsui_has-split-panel_hyvsj_ytg3m_588",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_ytg3m_588",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_ytg3m_596",
  "unfocusable": "awsui_unfocusable_hyvsj_ytg3m_601",
  "container": "awsui_container_hyvsj_ytg3m_611",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_ytg3m_637",
  "is-tools-open": "awsui_is-tools-open_hyvsj_ytg3m_640",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_ytg3m_640",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_ytg3m_640",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_ytg3m_640",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_ytg3m_653",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_hyvsj_ytg3m_670",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_ytg3m_674",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_ytg3m_678",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_ytg3m_682",
  "navigation-container": "awsui_navigation-container_hyvsj_ytg3m_691",
  "show-navigation": "awsui_show-navigation_hyvsj_ytg3m_729",
  "animating": "awsui_animating_hyvsj_ytg3m_752",
  "showButtons": "awsui_showButtons_hyvsj_ytg3m_1",
  "navigation": "awsui_navigation_hyvsj_ytg3m_691",
  "openNavigation": "awsui_openNavigation_hyvsj_ytg3m_1",
  "animated-content": "awsui_animated-content_hyvsj_ytg3m_816",
  "content-container": "awsui_content-container_hyvsj_ytg3m_819",
  "hide-navigation": "awsui_hide-navigation_hyvsj_ytg3m_828",
  "notifications": "awsui_notifications_hyvsj_ytg3m_838",
  "has-notification-content": "awsui_has-notification-content_hyvsj_ytg3m_843",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_ytg3m_847",
  "high-contrast": "awsui_high-contrast_hyvsj_ytg3m_852",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_ytg3m_870",
  "position-bottom": "awsui_position-bottom_hyvsj_ytg3m_917",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_ytg3m_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_ytg3m_946",
  "position-side": "awsui_position-side_hyvsj_ytg3m_959",
  "tools-container": "awsui_tools-container_hyvsj_ytg3m_981",
  "tools": "awsui_tools_hyvsj_ytg3m_981",
  "openTools": "awsui_openTools_hyvsj_ytg3m_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_ytg3m_1070",
  "hide-tools": "awsui_hide-tools_hyvsj_ytg3m_1080",
  "show-tools": "awsui_show-tools_hyvsj_ytg3m_1092",
  "has-tools-form": "awsui_has-tools-form_hyvsj_ytg3m_1070",
  "trigger-button-styles": "awsui_trigger-button-styles_hyvsj_ytg3m_1172",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_ytg3m_1188",
  "trigger": "awsui_trigger_hyvsj_ytg3m_1172",
  "selected": "awsui_selected_hyvsj_ytg3m_1240",
  "badge": "awsui_badge_hyvsj_ytg3m_1260",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_ytg3m_1264",
  "trigger-wrapper-tooltip-visible": "awsui_trigger-wrapper-tooltip-visible_hyvsj_ytg3m_1275",
  "dot": "awsui_dot_hyvsj_ytg3m_1279",
  "trigger-tooltip": "awsui_trigger-tooltip_hyvsj_ytg3m_1292"
};
  