import { JsonForms } from '@jsonforms/react';
import type { ErrorObject } from 'ajv';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  SpaceBetween,
  TextContent,
} from '../../theme/risksmart-themed/components';
import Button from '../Button';
import DeleteButton from '../DeleteButton';
import DeleteModal from '../DeleteModal';
import rendererRegistry from '../renderers/registry';
import type { SectionData } from './form-configs/section';
import { sectionSchema, sectionUISchema } from './form-configs/section';
import {
  FORM_BUILDER_ACTION,
  useFormBuilderActions,
} from './useFormBuilderActions';
import type {
  CustomUISchema,
  CustomUISchemaElement,
} from './useFormBuilderContext';

type EditFormSectionModalProps = {
  action: 'add' | 'edit';
  sectionData: SectionData;
  setSectionData: (sectionData: SectionData) => void;
  isEditingSection: boolean;
  setIsEditingSection: (isEditingSection: boolean) => void;
  initialSectionData: SectionData;
  uiSchema: CustomUISchema | CustomUISchemaElement;
};

export const FormSectionModal: FC<EditFormSectionModalProps> = ({
  action,
  sectionData,
  setSectionData,
  isEditingSection,
  setIsEditingSection,
  initialSectionData,
  uiSchema,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>([]);
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formSection',
  });

  const {
    isFormCustomisable,
    setIsFormDirtyState,
    addNewSection,
    updateSection,
    deleteSection,
  } = useFormBuilderActions();

  const resetModal = () => {
    setSectionData(initialSectionData);
    setIsEditingSection(false);
    setIsFormDirtyState(false);
  };

  const handleEditSectionCancel = () => {
    resetModal();
  };

  const handleAddNewSection = () => {
    addNewSection(sectionData);
    resetModal();
  };

  const handleUpdateSection = () => {
    updateSection(sectionData, uiSchema);
    resetModal();
  };

  const handleSaveSection = () => {
    if (errors?.length) {
      setIsFormDirtyState(true);

      return;
    }

    if (action === FORM_BUILDER_ACTION.ADD) {
      handleAddNewSection();
    }

    if (action === FORM_BUILDER_ACTION.EDIT) {
      handleUpdateSection();
    }
  };

  const handleDeleteSection = () => {
    deleteSection(uiSchema);
    resetModal();
  };

  return (
    <>
      {isEditingSection ? (
        <>
          <Modal
            onDismiss={resetModal}
            visible={isEditingSection}
            header={
              action === FORM_BUILDER_ACTION.ADD
                ? t('addSectionButtonLabel')
                : t('editSectionButtonLabel')
            }
            footer={
              <div className={'flex justify-between'}>
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={handleSaveSection}
                    formAction={'submit'}
                  >
                    {t('saveButtonLabel')}
                  </Button>
                  <Button variant="normal" onClick={handleEditSectionCancel}>
                    {t('cancelButtonLabel')}
                  </Button>
                </SpaceBetween>
                {action === 'edit' ? (
                  <DeleteButton onClick={() => setIsDeleteModalVisible(true)}>
                    {t('deleteButtonLabel')}
                  </DeleteButton>
                ) : null}
              </div>
            }
          >
            <JsonForms
              data={sectionData}
              readonly={!isFormCustomisable}
              schema={sectionSchema}
              uischema={sectionUISchema}
              renderers={rendererRegistry}
              onChange={({ data, errors }) => {
                const formattedErrors = errors?.map((error) => ({
                  ...error,
                  dataPath: '',
                })) as ErrorObject[] | undefined;

                setErrors(formattedErrors);
                setSectionData(data);
              }}
              validationMode={'ValidateAndShow'}
            />
          </Modal>
          <DeleteModal
            loading={false}
            isVisible={isDeleteModalVisible}
            onDelete={handleDeleteSection}
            onDismiss={() => setIsDeleteModalVisible(false)}
            header={t('deleteModal.header')}
          >
            <TextContent>
              <p>{t('deleteModal.body1')}</p>
              <p>{t('deleteModal.body2')}</p>
              <p>{t('deleteModal.body3')}</p>
            </TextContent>
          </DeleteModal>
        </>
      ) : null}
    </>
  );
};
