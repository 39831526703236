
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_12i0j_52umu_189",
  "legacy": "awsui_legacy_12i0j_52umu_204",
  "last-opened": "awsui_last-opened_12i0j_52umu_212",
  "drawer-global": "awsui_drawer-global_12i0j_52umu_217",
  "drawer-hidden": "awsui_drawer-hidden_12i0j_52umu_221",
  "drawer-content-container": "awsui_drawer-content-container_12i0j_52umu_224",
  "drawer-close-button": "awsui_drawer-close-button_12i0j_52umu_232",
  "drawer-content": "awsui_drawer-content_12i0j_52umu_224",
  "drawer-content-hidden": "awsui_drawer-content-hidden_12i0j_52umu_242",
  "drawer-slider": "awsui_drawer-slider_12i0j_52umu_245"
};
  