import Alert from '@cloudscape-design/components/alert';
import ContentLayout from '@cloudscape-design/components/content-layout';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Breadcrumbs from '@risksmart-app/components/Breadcrumbs';
import useBreadcrumbs from '@risksmart-app/components/hooks/use-breadcrumbs';
import PageHeader from '@risksmart-app/components/PageHeader';
import { useTools } from '@risksmart-app/components/Tools/useTools';
import { ErrorBoundary } from '@sentry/react';
import clsx from 'clsx';
import type { ParseKeys } from 'i18next';
import type { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'src/rbac/useHasPermission';
import { useBaseTracking } from 'src/segment';

import { I18nSummaryHelpContent } from '@/components/HelpPanel/SummaryHelpContent';
import { useHelpContent } from '@/components/HelpPanel/useHelpContent';

import { AuthenticatedAppLayout } from './AuthenticatedAppLayout';

type Meta = {
  /** Used to set `window.title` where the page title itself contains sensitive information */
  title?: string;
};
interface Props {
  title?: string;
  /** @deprecated Use {@link Props.meta} instead */
  pageTitle?: string;
  meta?: Meta;
  actions?: ReactNode;
  children?: ReactNode | ReactNode[];
  counter?: string;
  secondary?: ReactNode;
  panelContent?: ReactNode;
  protected?: boolean;
  helpTranslationKey?: ParseKeys<'common'>;
}

const PageLayout: FC<Props> = ({
  title,
  meta,
  pageTitle,
  counter,
  actions,
  secondary,
  panelContent,
  protected: isProtected = true,
  children,
  helpTranslationKey,
}) => {
  useBaseTracking();
  const { showBreadcrumbs } = useBreadcrumbs();
  const [toolsContent, setToolsContent] = useTools();

  const { setContentId, hasHelpContent } = useHelpContent();
  const toggleHelp = () => {
    setContentId(null);
    setToolsContent(toolsContent === 'help' ? undefined : 'help');
  };
  const hasUpdateTaxonomyPermission = useHasPermission('update:taxonomy');
  const visibleTitle = title || meta?.title || pageTitle;
  const metaTitle = meta?.title || pageTitle || title;
  const page = (
    <>
      <Helmet title={metaTitle} />
      {helpTranslationKey && (
        <I18nSummaryHelpContent translationKey={helpTranslationKey} />
      )}

      <ContentLayout
        disableOverlap
        defaultPadding
        headerVariant={'high-contrast'}
        header={
          // 84px is the height of the header minus Cloudscape’s default padding (120px total)
          <div className="print:hidden">
            <div
              className={clsx('flex items-center flex-wrap mx-auto', {
                'min-h-[84px]': showBreadcrumbs,
                'min-h-[64px]': !showBreadcrumbs,
              })}
            >
              <div className="block w-full">
                <Breadcrumbs />
                <SpaceBetween size="m">
                  <PageHeader
                    counter={counter}
                    actions={actions}
                    showHelp={hasHelpContent || hasUpdateTaxonomyPermission}
                    onShowHelpClick={toggleHelp}
                  >
                    {visibleTitle}
                  </PageHeader>
                  {secondary}
                </SpaceBetween>
              </div>
            </div>
          </div>
        }
      >
        <div className="py-5">
          <ErrorBoundary
            onError={(error) => console.error(error)}
            fallback={
              <Alert header="Error" type="error">
                An error has occurred
              </Alert>
            }
          >
            <SpaceBetween size="m">{children}</SpaceBetween>
          </ErrorBoundary>
        </div>
      </ContentLayout>
    </>
  );

  return isProtected ? (
    <AuthenticatedAppLayout panelContent={panelContent}>
      {page}
    </AuthenticatedAppLayout>
  ) : (
    <>{children}</>
  );
};

export default PageLayout;
