import { useAxios } from '../useAxios';

type Response = { returnedText: string };

export const usePostInferenceCommand = () => {
  const axios = useAxios();

  return async (prompt: string, bodyText: string): Promise<Response> => {
    try {
      const { data } = await axios.post<Response>('/bedrock', {
        prompt: prompt,
        bodyText: bodyText,
      });

      if (data.returnedText) {
        return data;
      } else {
        throw new Error('No returned text');
      }
    } catch (error) {
      console.error(error);
      throw new Error('Something went wrong!');
    }
  };
};
