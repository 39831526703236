import _ from 'lodash';
import type { Content } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import {
  Parent_Type_Enum,
  useGetControlsBasicQuery,
  useGetIssueAssessmentByParentIdLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createField, createYesNoField } from '@/utils/pdf/field';
import { createSubHeading2 } from '@/utils/pdf/headings';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import { getAssociatedControlIds } from './tabs/assessments/assessmentUtils';

const useExportAssessmentDetails = (
  issueId: string
): [() => Promise<Content[]>, boolean] => {
  const { data: controls } = useGetControlsBasicQuery({});
  const { getLabel: getStatusLabel } = useRating('issue_assessment_status');
  const { getLabel: getSeverityLabel } = useRating('severity');
  const { t } = useTranslation(['common']);
  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.IssueAssessment);
  const { t: issueAssessmentFields } = useTranslation(['common'], {
    keyPrefix: 'issueAssessment.fields',
  });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'issueAssessment',
  });

  const [getIssueAssessment, getIssueAssessmentResult] =
    useGetIssueAssessmentByParentIdLazyQuery({
      variables: {
        parentIssueId: issueId,
      },
    });

  const createDetails = async () => {
    const issueTypes = t('issueTypes');
    const { data: assessmentData } = await getIssueAssessment();
    const assessment = assessmentData?.issue_assessment?.[0];
    const issue = assessmentData?.issue?.[0];
    if (!issue || !assessment || !controls) {
      return [twoColumns([])];
    }
    const associatedControlIds = getAssociatedControlIds(assessmentData);

    const controlsById = _.keyBy(controls?.control, 'Id');
    const controlNodesById = _.keyBy(controls?.node, 'Id');

    const detailFields = [
      createField(
        issueAssessmentFields('IssueType'),
        assessment?.IssueType
          ? issueTypes[assessment.IssueType as keyof typeof issueTypes]
          : '-'
      ),
      createField(
        issueAssessmentFields('Severity'),
        getSeverityLabel(assessment?.Severity)
      ),
      createField(fields('Status'), getStatusLabel(assessment?.Status)),
      createField(
        issueAssessmentFields('TargetCloseDate'),
        toLocalDate(assessment?.TargetCloseDate)
      ),
      createField(
        issueAssessmentFields('ActualCloseDate'),
        toLocalDate(assessment?.ActualCloseDate)
      ),

      createField(
        issueAssessmentFields('AssociatedControls'),
        associatedControlIds
          .map(
            (id) =>
              controlsById[id].Title ||
              getFriendlyId(
                Parent_Type_Enum.Control,
                controlNodesById[id].SequentialId
              )
          )
          .join(', ')
      ),

      createField(
        issueAssessmentFields('CertifiedIndividual'),
        assessment.certifiedIndividual?.FriendlyName ?? '-'
      ),

      createField(fields('Tags'), getTagsValue(issue)),
      createField(fields('Departments'), getDepartmentsValue(assessment)),
      ...(assessment ? await getCustomAttribute(assessment) : []),
    ];

    const regulationFields = [
      createYesNoField(
        issueAssessmentFields('RegulatoryBreach'),
        assessment?.RegulatoryBreach
      ),
      createField(
        issueAssessmentFields('RegulationsBreached'),
        assessmentData?.issue_parent
          ?.filter((parent) => parent.obligation)
          ?.map((parent) => parent.obligation?.Title)
          .join(', ') ?? '-'
      ),

      createYesNoField(
        issueAssessmentFields('Reportable'),
        assessment?.Reportable
      ),

      createField(issueAssessmentFields('Rationale'), assessment?.Rationale),
    ];
    const thirdPartyFields = [
      createYesNoField(
        issueAssessmentFields('IssueCausedByThirdParty'),
        assessment?.IssueCausedByThirdParty
      ),
      createField(
        issueAssessmentFields('ThirdPartyResponsible'),
        assessment?.ThirdPartyResponsible
      ),
    ];

    const systemFields = [
      createYesNoField(
        issueAssessmentFields('IssueCausedBySystemIssue'),
        assessment?.IssueCausedBySystemIssue
      ),
      createField(
        issueAssessmentFields('SystemResponsible'),
        assessment?.SystemResponsible
      ),
    ];

    const policyFields = [
      createYesNoField(
        issueAssessmentFields('PolicyBreach'),
        assessment?.PolicyBreach
      ),
      createField(
        issueAssessmentFields('PoliciesBreached'),
        assessment?.PoliciesBreached
      ),
      createField(
        issueAssessmentFields('PolicyOwner'),
        assessment?.policyOwner?.FriendlyName ?? '-'
      ),
      createField(
        issueAssessmentFields('PolicyOwnerCommentary'),
        assessment?.PolicyOwnerCommentary
      ),
    ];

    return [
      twoColumns(detailFields),
      createSubHeading2(st('headings.regulation')),
      twoColumns(regulationFields),
      createSubHeading2(st('headings.thirdParty')),
      twoColumns(thirdPartyFields),
      createSubHeading2(st('headings.system')),
      twoColumns(systemFields),
      createSubHeading2(st('headings.policy')),
      twoColumns(policyFields),
    ];
  };

  return [
    createDetails,
    getIssueAssessmentResult.loading || customAttributesLoading,
  ];
};

export default useExportAssessmentDetails;
