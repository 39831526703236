import _ from 'lodash';

import type { GetRiskListQuery } from '@/generated/graphql';
import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

export const getOptions = (
  data: GetRiskListQuery | undefined,
  selectedRiskId: string | undefined
) => {
  const risksById = _.keyBy(data?.risk, 'Id');
  let options: {
    Id: string;
    Title?: string;
    SequentialId?: number | null | undefined;
  }[] = data?.risk ?? [];

  options = options.concat(
    (data?.node ?? []).filter(
      (n) => !risksById[n.Id] && n.Id === selectedRiskId
    )
  );

  return (
    options.map((r) => ({
      value: r.Id,
      label: r.Title ?? getFriendlyId(Parent_Type_Enum.Risk, r.SequentialId),
    })) ?? []
  );
};
