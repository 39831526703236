
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_4yi2u_1secz_145",
  "outline": "awsui_outline_4yi2u_1secz_178",
  "toggle-control": "awsui_toggle-control_4yi2u_1secz_200",
  "toggle-control-checked": "awsui_toggle-control-checked_4yi2u_1secz_213",
  "toggle-control-disabled": "awsui_toggle-control-disabled_4yi2u_1secz_216",
  "toggle-control-readonly": "awsui_toggle-control-readonly_4yi2u_1secz_222",
  "toggle-handle": "awsui_toggle-handle_4yi2u_1secz_226",
  "toggle-handle-checked": "awsui_toggle-handle-checked_4yi2u_1secz_251",
  "toggle-handle-disabled": "awsui_toggle-handle-disabled_4yi2u_1secz_258",
  "toggle-handle-readonly": "awsui_toggle-handle-readonly_4yi2u_1secz_262"
};
  