import type { FC } from 'react';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import ComplianceRatingRegister from './ComplianceRatingRegister';
import InternalAuditRatingRegister from './InternalAuditRatingRegister';
import RiskRatingRegister from './RiskRatingRegister';

interface Props {
  risk: ObjectWithContributors;
}

const Tab: FC<Props> = ({ risk }) => {
  useI18NSummaryHelpContent('assessments.tabHelp');
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    risk
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    risk
  );

  return (
    <>
      <RiskRatingRegister risk={risk} />
      {complianceMonitoringEnabled && canViewCompliance && (
        <ComplianceRatingRegister risk={risk} />
      )}
      {internalAuditEnabled && canViewInternalAudit && (
        <InternalAuditRatingRegister risk={risk} />
      )}
    </>
  );
};

export default Tab;
