import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import { ButtonVariant } from '@/components/Form/Form/types';

import RecallModalForm from './RecallModalForm';
import type { RecallSchemaFields } from './schema';
import RecallSchema from './schema';

interface Props {
  onDismiss: () => void;
  onRecall: (data: RecallSchemaFields) => Promise<void>;
  isVisible: boolean;
  loading?: boolean;
}

const RecallModal: FC<Props> = ({
  onDismiss,
  onRecall,
  isVisible,
  loading,
}) => {
  const { t: rt } = useTranslation('common', {
    keyPrefix: 'third_party_responses.recall',
  });

  const defaultSubmitActions = [
    {
      label: rt('confirm'),
      action: onRecall,
      variant: ButtonVariant.Danger,
      loading,
    },
  ];

  return (
    <ModalForm<RecallSchemaFields>
      onDismiss={onDismiss}
      visible={isVisible}
      size="medium"
      formId="recall-modal"
      defaultValues={{
        Reason: '',
        ShareWithRespondents: false,
      }}
      schema={RecallSchema}
      submitActions={defaultSubmitActions}
      onSave={onRecall}
      header={rt('header')}
      i18n={{
        entity_name: rt('entity_name'),
        edit_modal_title: rt('header'),
      }}
    >
      {rt('recall_confirmation')}
      <RecallModalForm />
    </ModalForm>
  );
};

export default RecallModal;
