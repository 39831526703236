
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_wih1l_ry096_153",
  "tools": "awsui_tools_wih1l_ry096_164",
  "tools-filtering": "awsui_tools-filtering_wih1l_ry096_172",
  "tools-align-right": "awsui_tools-align-right_wih1l_ry096_186",
  "tools-pagination": "awsui_tools-pagination_wih1l_ry096_190",
  "tools-preferences": "awsui_tools-preferences_wih1l_ry096_190",
  "tools-small": "awsui_tools-small_wih1l_ry096_196",
  "table": "awsui_table_wih1l_ry096_202",
  "table-layout-fixed": "awsui_table-layout-fixed_wih1l_ry096_208",
  "wrapper": "awsui_wrapper_wih1l_ry096_212",
  "variant-stacked": "awsui_variant-stacked_wih1l_ry096_219",
  "wrapper-content-measure": "awsui_wrapper-content-measure_wih1l_ry096_219",
  "variant-container": "awsui_variant-container_wih1l_ry096_219",
  "has-footer": "awsui_has-footer_wih1l_ry096_222",
  "has-header": "awsui_has-header_wih1l_ry096_225",
  "cell-merged": "awsui_cell-merged_wih1l_ry096_242",
  "cell-merged-content": "awsui_cell-merged-content_wih1l_ry096_254",
  "empty": "awsui_empty_wih1l_ry096_270",
  "loading": "awsui_loading_wih1l_ry096_274",
  "selection-control": "awsui_selection-control_wih1l_ry096_283",
  "selection-control-header": "awsui_selection-control-header_wih1l_ry096_290",
  "header-secondary": "awsui_header-secondary_wih1l_ry096_296",
  "variant-full-page": "awsui_variant-full-page_wih1l_ry096_308",
  "table-has-header": "awsui_table-has-header_wih1l_ry096_314",
  "header-controls": "awsui_header-controls_wih1l_ry096_325",
  "variant-embedded": "awsui_variant-embedded_wih1l_ry096_334",
  "variant-borderless": "awsui_variant-borderless_wih1l_ry096_334",
  "footer-wrapper": "awsui_footer-wrapper_wih1l_ry096_339",
  "footer": "awsui_footer_wih1l_ry096_339",
  "footer-with-pagination": "awsui_footer-with-pagination_wih1l_ry096_347",
  "footer-pagination": "awsui_footer-pagination_wih1l_ry096_355",
  "thead-active": "awsui_thead-active_wih1l_ry096_359",
  "row": "awsui_row_wih1l_ry096_360",
  "row-selected": "awsui_row-selected_wih1l_ry096_361"
};
  