import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { useIsFeatureVisibleToOrgLazy } from '@/utils/featureFlags';

import type { StoredWidgetPlacement } from '../pages/dashboards/types';
import { getDefaultLayout } from '../pages/dashboards/widgets';
import { useHasPermissionLazy } from '../rbac/useHasPermissionLazy';
import { useStorage } from '../state/useStorage';
import type { DashboardFilter } from './DashboardFilter';
import { DashboardFilterContext } from './DashboardFilterContext';
import { defaultDashboardFilter } from './defaultDashboardFilter';
import { processWidgets } from './processWidgets';
import type { DashboardPreferences } from './types';

interface DashboardFilterProviderProps {
  children: ReactNode;
}

export const DashboardFilterProvider = ({
  children,
}: DashboardFilterProviderProps) => {
  const hasPermission = useHasPermissionLazy();
  const isFeatureVisibleToOrg = useIsFeatureVisibleToOrgLazy();
  const [dashboardPreferences, setDashboardPreferences] =
    useStorage<DashboardPreferences>(
      {
        filters: defaultDashboardFilter,
        widgets: getDefaultLayout(hasPermission, isFeatureVisibleToOrg),
      },
      { localStorageKey: 'Dashboard-PreferencesV3' }
    );

  const processedWidgets = useMemo(() => {
    // ensure that all widgets have a unique ID, and that the widgetType is set
    // this is a migration step for the previous version of the dashboard preferences
    return processWidgets(dashboardPreferences.widgets);
  }, [dashboardPreferences]);

  const setFilters = (filters: DashboardFilter) => {
    setDashboardPreferences({
      ...dashboardPreferences,
      filters,
    });
  };

  const setWidgets = (widgets: StoredWidgetPlacement[]) => {
    setDashboardPreferences({
      ...dashboardPreferences,
      widgets,
    });
  };

  const setId = (id: string) => {
    setDashboardPreferences({
      ...dashboardPreferences,
      id,
    });
  };

  return (
    <DashboardFilterContext.Provider
      value={{
        id: dashboardPreferences.id,
        filters: dashboardPreferences.filters,
        widgets: processedWidgets,
        setFilters,
        setWidgets,
        setDashboardPreferences,
        setId,
      }}
    >
      {children}
    </DashboardFilterContext.Provider>
  );
};
