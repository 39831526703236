import { withJsonFormsControlProps } from '@jsonforms/react';

import { BooleanControlUnwrapped } from './BooleanControl';
import { DropdownSelectControlUnwrapped } from './DropdownSelectControl';
import { TextAreaControlUnwrapped } from './TextAreaControl';
import { TextControlUnwrapped } from './TextControl';

// TODO: Fix the types

// @ts-ignore
export const TextControl = withJsonFormsControlProps(TextControlUnwrapped);
export const TextAreaControl = withJsonFormsControlProps(
  // @ts-ignore
  TextAreaControlUnwrapped
);
export const BooleanControl = withJsonFormsControlProps(
  BooleanControlUnwrapped
);
export const DropdownSelectControl = withJsonFormsControlProps(
  // @ts-ignore
  DropdownSelectControlUnwrapped
);
