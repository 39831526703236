import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import {
  Parent_Type_Enum,
  useInsertAssessmentActivityWithLinkedItemsMutation,
} from 'src/generated/graphql';
import type { AssessmentActivityFormDataFields } from 'src/pages/assessments/forms/AssessmentActivityForm/assessmentActivitySchema';

import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { evictField } from '@/utils/graphqlUtils';

import AssessmentActivityForm from '../../../../forms/AssessmentActivityForm';
import type { AssessmentTypeEnum } from '../../../../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../../../../useAssessmentTypeConfig';

interface Props {
  assessmentMode: AssessmentTypeEnum;
}

const AssessmentActivityCreateTab: FC<Props> = ({ assessmentMode }) => {
  const navigate = useNavigate();
  const assessmentId = useGetGuidParam('assessmentId');
  const { updateFiles } = useFileUpdate();
  const [mutate] = useInsertAssessmentActivityWithLinkedItemsMutation({
    update: (cache) => {
      evictField(cache, 'assessment');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'internal_audit_entity');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'assessment_activity');
    },
  });
  const {
    routing: { activityRegisterUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const onSave = async (variables: AssessmentActivityFormDataFields) => {
    const { files, newFiles } = variables;
    const { data } = await mutate({
      variables: {
        ...variables,
        ParentId: assessmentId,
        LinkedItemIds: variables.LinkedItemIds.map((c) => c.Id),
        CustomAttributeData: variables.CustomAttributeData || undefined,
        AssignedUser: variables.AssignedUser?.value,
      },
    });
    if (!data?.insertAssessmentActivityWithLinkedItems?.Id) {
      throw new Error('Assessment Activity id is missing');
    }
    await updateFiles({
      parentId: data.insertAssessmentActivityWithLinkedItems.Id,
      parentType: Parent_Type_Enum.AssessmentActivity,
      newFiles,
      selectedFiles: files,
      originalFiles: [],
    });
    if (data?.insertAssessmentActivityWithLinkedItems?.Id) {
      navigate(activityRegisterUrl(assessmentId));
    }
  };

  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(activityRegisterUrl(assessmentId));
    }
  };

  return (
    <AssessmentActivityForm
      onSave={onSave}
      onDismiss={onDismiss}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default AssessmentActivityCreateTab;
