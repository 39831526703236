import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import ImpactFormFields from './ImpactFormFields';
import type { ImpactFormFieldData } from './impactFormSchema';
import { defaultValues, ImpactFormSchema } from './impactFormSchema';

export type Props = Omit<
  FormContextProps<ImpactFormFieldData>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
>;

const ImpactForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={ImpactFormSchema}
      defaultValues={defaultValues}
      i18n={t('impacts')}
      formId={'impact-form'}
      parentType={Parent_Type_Enum.Impact}
    >
      <ImpactFormFields readOnly={props.readOnly} />
    </CustomisableFormContext>
  );
};

export default ImpactForm;
