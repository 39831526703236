import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledSwitch } from '@/components/Form/ControlledSwitch/ControlledSwitch';
import ControlledTextarea from '@/components/Form/ControlledTextarea';

import type { RecallSchemaFields } from './schema';

const RecallModalForm: FC = () => {
  const { control } = useFormContext<RecallSchemaFields>();

  return (
    <>
      <ControlledTextarea
        name="Reason"
        label={'Reason'}
        control={control}
        defaultRequired={false}
      />
      <ControlledSwitch
        control={control}
        label="Share with respondents"
        name="ShareWithRespondents"
        defaultRequired={true}
      />
    </>
  );
};

export default RecallModalForm;
