import type { RankedTester } from '@jsonforms/core';
import { and } from '@jsonforms/core';
import {
  isBooleanControl,
  isOneOfControl,
  isStringControl,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';

/*
RATIONALE:
  - `rankWith` expects an integer to determine the priority that each tester is picked with
  - The higher the number, the earlier this tester is used to determine which renderer to use
  - Generally speaking, rules with higher specificity should be given a higher priority
    as lower numbers will be used as fall back renderers
  - The default value used for `rankWith` is 2 so any custom renderers should have a minimum rank of 3
*/

export const dropdownSelectControlTester: RankedTester = rankWith(
  5,
  isOneOfControl
);

export const textAreaControlTester: RankedTester = rankWith(
  4,
  and(isStringControl, (uischema) => {
    return uischema.options?.fieldType === 'textArea';
  })
);

export const verticalLayoutTester: RankedTester = rankWith(
  3,
  uiTypeIs('VerticalLayout')
);

export const groupLayoutTester: RankedTester = rankWith(3, uiTypeIs('Group'));

export const textControlTester: RankedTester = rankWith(3, isStringControl);

export const booleanControlTester: RankedTester = rankWith(3, isBooleanControl);
