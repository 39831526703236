import type {
  PropertyFilterOption,
  PropertyFilterProperty,
  PropertyFilterQuery,
  PropertyFilterToken,
} from '@cloudscape-design/collection-hooks';
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import Container from '@cloudscape-design/components/container';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomisableRibbonForm from '@/components/CustomisableRibbon/CustomisableRibbonForm';
import type { Filter } from '@/components/CustomisableRibbon/Filter';
import { ModalForm } from '@/components/Form/Form/ModalForm';
import type { SaveAction } from '@/components/Form/Form/types';
import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import type { Parent_Type_Enum } from '@/generated/graphql';
import {
  namedOperations,
  useGetRibbonItemsByParentTypeQuery,
  useInsertRibbonItemsByParentTypeMutation,
  useUpdateRibbonItemsByParentTypeMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import { sanitizeTokens } from '../../pages/dashboards/UniversalWidget/sanitizeSettings';
import { useHasPermission } from '../../rbac/useHasPermission';
import type { CustomisableRibbonModalFields } from '../../schemas/customisableRibbonModalSchema';
import { CustomisableRibbonFormSchema } from '../../schemas/customisableRibbonModalSchema';
import styles from './style.module.scss';

export interface Props<T> {
  items: readonly T[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty[];
  filteringOptions: readonly PropertyFilterOption[] | undefined;
  parentType: Parent_Type_Enum;
  defaultFilters: Filter[];
}

// For more information see the docs at: docs/customisable-ribbon.md
const CustomisableRibbon = <T extends object>({
  items,
  propertyFilterQuery,
  onFilterQueryChanged,
  filteringProperties,
  filteringOptions,
  parentType,
  defaultFilters,
}: Props<T>) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customisableRibbons',
  });
  const [showEditRibbonModal, setShowEditRibbonModal] = useState(false);
  const { addNotification } = useNotifications();

  const [insertRibbonItemsByParentType] =
    useInsertRibbonItemsByParentTypeMutation({
      update: (cache) => {
        evictField(cache, 'custom_ribbon');
      },
      refetchQueries: [namedOperations.Query.getRibbonItemsByParentType],
    });

  const [updateRibbonItemsByParentType] =
    useUpdateRibbonItemsByParentTypeMutation({
      update: (cache) => {
        evictField(cache, 'custom_ribbon');
      },
      refetchQueries: [namedOperations.Query.getRibbonItemsByParentType],
    });

  const {
    data: responseData,
    loading,
    error,
  } = useGetRibbonItemsByParentTypeQuery({
    variables: {
      parentType,
    },
  });

  if (error) {
    throw error;
  }

  const userCanEdit = useHasPermission('update:custom_ribbon', undefined);
  const userCanCreate = useHasPermission('insert:custom_ribbon', undefined);

  const filterResult = responseData?.custom_ribbon[0]?.Filters as
    | Filter[]
    | undefined;
  const filters = useMemo(() => {
    if (!filterResult?.length) {
      return defaultFilters;
    }

    return filterResult.map((filter) => {
      return {
        ...filter,
        itemFilterQuery: {
          ...filter.itemFilterQuery,
          tokens: sanitizeTokens(
            filter.itemFilterQuery.tokens as PropertyFilterToken[]
          ),
        },
      };
    });
  }, [defaultFilters, filterResult]);

  const onSave: SaveAction<CustomisableRibbonModalFields> = async (
    dataToSave
  ) => {
    const { Filters } = dataToSave;

    if (filterResult?.length) {
      const result = await updateRibbonItemsByParentType({
        variables: {
          id: responseData?.custom_ribbon[0]?.Id,
          parentType: parentType,
          filters: Filters,
          originalTimestamp:
            responseData?.custom_ribbon[0]?.ModifiedAtTimestamp ||
            'no valid timestamp',
        },
      });

      if (result.data?.update_custom_ribbon?.affected_rows !== 1) {
        addNotification({
          type: 'error',
          content: <>{t('record_updated_by_another_user')}</>,
        });

        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      await insertRibbonItemsByParentType({
        variables: {
          parentType: parentType,
          filters: Filters,
        },
      });
    }
  };

  return (
    <Container data-testid="customisable-ribbon" disableContentPaddings={false}>
      <div className="flex flex-row">
        <div className={styles.container}>
          {filters.map((filter, index) => (
            <div key={index} className={styles.item}>
              <FilterPropertyDashboardItem
                title={filter.title}
                tableFilterQuery={propertyFilterQuery}
                itemFilterQuery={{
                  tokens: [...filter.itemFilterQuery.tokens],
                  operation: filter.itemFilterQuery.operation,
                }}
                items={items ?? []}
                filteringProperties={filteringProperties}
                onClick={onFilterQueryChanged}
              />
              {index !== filters.length - 1 ? (
                <div className={styles.divider} />
              ) : null}
            </div>
          ))}
        </div>
        {userCanEdit && userCanCreate && (
          <ButtonDropdown
            data-testid="edit-ribbon-button"
            expandToViewport
            ariaLabel="Edit Ribbon"
            items={[
              {
                text: t('editRibbon'),
                id: 'edit',
                disabled: false,
              },
            ]}
            variant="icon"
            onItemClick={() => setShowEditRibbonModal(true)}
          />
        )}
      </div>
      {showEditRibbonModal && !loading && (
        <div className={styles.modal}>
          <ModalForm<CustomisableRibbonModalFields>
            formId={'edit-ribbon-form'}
            header={t('editRibbon')}
            size={'max'}
            onDismiss={() => setShowEditRibbonModal(false)}
            onSave={onSave}
            visible={true}
            i18n={{
              edit_modal_title: t('editRibbon'),
              entity_name: 'Ribbon',
            }}
            schema={CustomisableRibbonFormSchema}
            defaultValues={{
              Filters: defaultFilters,
            }}
            values={{ Filters: filters }}
          >
            <CustomisableRibbonForm
              defaultFilters={defaultFilters}
              filteringProperties={filteringProperties}
              filteringOptions={filteringOptions}
              items={items}
            />
          </ModalForm>
        </div>
      )}
    </Container>
  );
};

export default CustomisableRibbon;
