import type { KeyPrefix } from 'i18next';
import i18n from 'i18next';
import type { ParseKeys } from 'i18next/typescript/t';
import { isDate } from 'lodash';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import type { UseRatingResponse } from '@/hooks/use-rating';
import { useRating } from '@/hooks/use-rating';
import type { UseRiskScoreFormattersResponse } from '@/hooks/useRiskScore';
import { useRiskScoreFormatters } from '@/hooks/useRiskScore';
import type { Colour } from '@/utils/colours';
import { colours, genericCategoricalPalette } from '@/utils/colours';

import type {
  Category,
  CategoryType,
  UnratedCategoryType,
} from '../../../types';
import { UNRATED } from '../../../types';
import type { BarSeries } from '../types';

type RatingKeyOptions<T, K extends CategoryType, S extends CategoryType> = {
  entityNamePlural: ParseKeys<'common'>;
  category?: KeyPrefix<'ratings'>;
  subCategory?: KeyPrefix<'ratings'>;
  subCategoryOverrideFunction?: (
    category: Category<T, S | UnratedCategoryType>,
    ratingFns: UseRatingResponse,
    riskFormatters: UseRiskScoreFormattersResponse
  ) => Partial<{
    color: string;
    title: string;
    category: Category<T, S | UnratedCategoryType>;
    value: number;
  }>;
  categoryOverrideFunction?: (
    category: Category<T, K>,
    ratingFns: UseRatingResponse,
    riskFormatters: UseRiskScoreFormattersResponse
  ) => Partial<{
    color: string;
    title: string;
    category: Category<T, K>;
    value: number;
  }>;
};

export const useDataSeries = <
  T,
  K extends CategoryType,
  S extends CategoryType,
>(
  categories: Category<T, K, S | never>[],
  translationOptions: RatingKeyOptions<T, K, S | never>
): BarSeries<K>[] => {
  const { t } = useTranslation();
  const riskFormatters = useRiskScoreFormatters();
  const categoryRatingFns = useRating(translationOptions.category);
  const subCategoryRatingFns = useRating(translationOptions.subCategory);
  const { getByValue } = categoryRatingFns;
  const {
    getByValue: getSubcategoryByValue,
    getColorClass: getSubcategoryColor,
  } = subCategoryRatingFns;

  if (categories.length < 1) {
    return [];
  }
  const data = categories
    .sort((a, b) => (a.sortKey ?? a.label).localeCompare(b.sortKey ?? b.label))
    .map((category) => ({
      x:
        (translationOptions.categoryOverrideFunction?.(
          category,
          categoryRatingFns,
          riskFormatters
        )?.title ?? isDate(category.key))
          ? category.key
          : (getByValue(category.key)?.label ?? category.label),
      y: category.aggregatedValue,
      subCategories: category.subCategories,
    }));

  if (!data.length) {
    return [];
  }

  if (!data.some((d) => d.subCategories)) {
    return [
      {
        // for some reason typescript doesn't think that { x: K, y: number }[] is assignable to
        // K extends unknown ? readonly { x: K, y: number }[] : readonly { x: K, y: number }[]
        // which makes zero sense to me and has caused me a lot of headache, so im giving up
        //
        // please forgive the ts-ignores in this file :(
        // @ts-ignore
        data,
        type: 'bar',
        title: i18n.format(
          t(translationOptions.entityNamePlural),
          'capitalizeAll'
        ),
        color: genericCategoricalPalette(0),
      },
    ];
  }

  const subCategories = data.flatMap((d) => d.subCategories ?? []);
  const uniqueSubCategories = _.uniqBy(subCategories, (c) => c.key);

  const sortedSubCategoryKeys = uniqueSubCategories.sort((a, b) => {
    if (a.key === UNRATED) {
      return 1;
    }
    if (b.key === UNRATED) {
      return -1;
    }

    if (isDate(a.key) && isDate(b.key)) {
      return a.key.getTime() - b.key.getTime();
    }

    return String(a.sortKey ?? a.key).localeCompare(String(b.sortKey ?? b.key));
  });

  // @ts-ignore
  return sortedSubCategoryKeys.map((subCategory, index) => {
    let subCategoryKey: string | number;
    const key = subCategory.key;
    if (isDate(key)) {
      subCategoryKey = String(key);
    } else {
      subCategoryKey = key;
    }

    const color =
      subCategoryKey === UNRATED
        ? colours['light-grey'].backgroundColor
        : (colours[getSubcategoryColor(subCategoryKey) as Colour]
            ?.backgroundColor ??
          getSubcategoryColor(subCategoryKey) ??
          genericCategoricalPalette(index));

    return {
      color,
      data: data.map((d) => ({
        x: d.x,
        y:
          d.subCategories?.find((subCategory) => subCategory.key === key)
            ?.aggregatedValue ?? 0,
      })),
      type: 'bar',
      title:
        getSubcategoryByValue(subCategoryKey)?.label ??
        subCategory?.label ??
        String(key),
      ...(subCategory
        ? translationOptions.subCategoryOverrideFunction?.(
            subCategory,
            categoryRatingFns,
            riskFormatters
          )
        : {}),
    };
  });
};
