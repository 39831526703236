import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetPublicDocumentFilesQuery } from '@/generated/graphql';

import type { View } from '../../../components/ViewSelector';
import ViewSelector from '../../../components/ViewSelector';
import CardView from './CardView';
import { useGetCollectionTableProps } from './config';

const PublicPoliciesPage = () => {
  const { user } = useRisksmartUser();
  const { t } = useTranslation(['common'], { keyPrefix: 'publicPolicies' });
  const { addNotification } = useNotifications();
  const { data, loading } = useGetPublicDocumentFilesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      currentUserId: user?.userId ?? '',
    },
    skip: !user?.userId,
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const collectionProps = useGetCollectionTableProps(data?.document_file);
  const [selectedView, setSelectedView] = useState<View>('table');

  return (
    <PageLayout title={t('register_title')} actions={<></>}>
      {selectedView === 'table' && (
        <Table
          {...collectionProps}
          loading={loading}
          header={
            <ViewSelector
              selectedView={selectedView}
              onSelectedViewChanged={(view) => {
                collectionProps.actions.setSorting?.({
                  isDescending: true,
                  sortingColumn: {
                    sortingField: 'ModifiedAtTimestamp',
                  },
                });
                setSelectedView(view);
              }}
            />
          }
        />
      )}
      {selectedView === 'card' && (
        <CardView
          pagination={collectionProps.pagination}
          items={collectionProps.items}
          empty={collectionProps.empty}
          filter={collectionProps.filter}
          header={
            <ViewSelector
              selectedView={selectedView}
              onSelectedViewChanged={setSelectedView}
            />
          }
        />
      )}
    </PageLayout>
  );
};

export default PublicPoliciesPage;
