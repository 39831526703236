import type { FC, PropsWithChildren } from 'react';

import JsonFormsContextProvider from './JsonFormsContextProviderUnwrapped';

export const withJsonFormsContextProvider = <P extends object>(
  Component: FC<PropsWithChildren<P>>
) => {
  return function ProviderWrapper(props: PropsWithChildren<P>) {
    return (
      <JsonFormsContextProvider>
        <Component {...props} />
      </JsonFormsContextProvider>
    );
  };
};
