import type { FC } from 'react';

import { useGetImpactByIdQuery } from '@/generated/graphql';

type Props = {
  impactId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ impactId }) => {
  if (!impactId) {
    throw new Error('Missing impactId param');
  }
  const { data } = useGetImpactByIdQuery({
    variables: {
      id: impactId,
    },
  });

  return <>{data?.impact?.[0]?.Name || ''}</>;
};

export default Breadcrumb;
