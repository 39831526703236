
    import './styles.scoped.css';
    export default {
  "content-enter": "awsui_content-enter_gwq0h_7r29b_161",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_gwq0h_7r29b_1",
  "trigger-expanded": "awsui_trigger-expanded_gwq0h_7r29b_183",
  "icon": "awsui_icon_gwq0h_7r29b_197",
  "root": "awsui_root_gwq0h_7r29b_211",
  "expand-button": "awsui_expand-button_gwq0h_7r29b_246",
  "expanded": "awsui_expanded_gwq0h_7r29b_254",
  "icon-container": "awsui_icon-container_gwq0h_7r29b_264",
  "icon-container-container": "awsui_icon-container-container_gwq0h_7r29b_268",
  "wrapper": "awsui_wrapper_gwq0h_7r29b_272",
  "wrapper-default": "awsui_wrapper-default_gwq0h_7r29b_280",
  "wrapper-inline": "awsui_wrapper-inline_gwq0h_7r29b_280",
  "wrapper-footer": "awsui_wrapper-footer_gwq0h_7r29b_280",
  "wrapper-navigation": "awsui_wrapper-navigation_gwq0h_7r29b_284",
  "wrapper-container": "awsui_wrapper-container_gwq0h_7r29b_287",
  "wrapper-compact": "awsui_wrapper-compact_gwq0h_7r29b_291",
  "header-deprecated": "awsui_header-deprecated_gwq0h_7r29b_304",
  "wrapper-expanded": "awsui_wrapper-expanded_gwq0h_7r29b_310",
  "header": "awsui_header_gwq0h_7r29b_304",
  "header-wrapper": "awsui_header-wrapper_gwq0h_7r29b_347",
  "header-actions-wrapper": "awsui_header-actions-wrapper_gwq0h_7r29b_359",
  "header-button": "awsui_header-button_gwq0h_7r29b_365",
  "header-container-button": "awsui_header-container-button_gwq0h_7r29b_365",
  "header-container": "awsui_header-container_gwq0h_7r29b_365",
  "header-navigation": "awsui_header-navigation_gwq0h_7r29b_400",
  "header-text": "awsui_header-text_gwq0h_7r29b_437",
  "content": "awsui_content_gwq0h_7r29b_161",
  "content-default": "awsui_content-default_gwq0h_7r29b_450",
  "content-inline": "awsui_content-inline_gwq0h_7r29b_450",
  "content-footer": "awsui_content-footer_gwq0h_7r29b_454",
  "content-expanded": "awsui_content-expanded_gwq0h_7r29b_458",
  "content-compact": "awsui_content-compact_gwq0h_7r29b_461",
  "focusable": "awsui_focusable_gwq0h_7r29b_465",
  "click-target": "awsui_click-target_gwq0h_7r29b_480"
};
  