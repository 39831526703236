
    import './styles.scoped.css';
    export default {
  "expandable-toggle-wrapper": "awsui_expandable-toggle-wrapper_c6tup_1dgzb_145",
  "body-cell": "awsui_body-cell_c6tup_1dgzb_152",
  "body-cell-content": "awsui_body-cell-content_c6tup_1dgzb_160",
  "expandable-level-0": "awsui_expandable-level-0_c6tup_1dgzb_166",
  "expandable-level-1": "awsui_expandable-level-1_c6tup_1dgzb_173",
  "expandable-level-2": "awsui_expandable-level-2_c6tup_1dgzb_180",
  "expandable-level-3": "awsui_expandable-level-3_c6tup_1dgzb_187",
  "expandable-level-4": "awsui_expandable-level-4_c6tup_1dgzb_194",
  "expandable-level-5": "awsui_expandable-level-5_c6tup_1dgzb_201",
  "expandable-level-6": "awsui_expandable-level-6_c6tup_1dgzb_208",
  "expandable-level-7": "awsui_expandable-level-7_c6tup_1dgzb_215",
  "expandable-level-8": "awsui_expandable-level-8_c6tup_1dgzb_222",
  "expandable-level-9": "awsui_expandable-level-9_c6tup_1dgzb_229",
  "expandable-level-next": "awsui_expandable-level-next_c6tup_1dgzb_236",
  "body-cell-wrap": "awsui_body-cell-wrap_c6tup_1dgzb_257",
  "is-visual-refresh": "awsui_is-visual-refresh_c6tup_1dgzb_271",
  "has-striped-rows": "awsui_has-striped-rows_c6tup_1dgzb_283",
  "body-cell-edit-active": "awsui_body-cell-edit-active_c6tup_1dgzb_366",
  "body-cell-editable": "awsui_body-cell-editable_c6tup_1dgzb_366",
  "sticky-cell-pad-inline-start": "awsui_sticky-cell-pad-inline-start_c6tup_1dgzb_449",
  "has-selection": "awsui_has-selection_c6tup_1dgzb_449",
  "body-cell-align-top": "awsui_body-cell-align-top_c6tup_1dgzb_788",
  "body-cell-first-row": "awsui_body-cell-first-row_c6tup_1dgzb_791",
  "body-cell-last-row": "awsui_body-cell-last-row_c6tup_1dgzb_794",
  "body-cell-selected": "awsui_body-cell-selected_c6tup_1dgzb_794",
  "has-footer": "awsui_has-footer_c6tup_1dgzb_794",
  "body-cell-shaded": "awsui_body-cell-shaded_c6tup_1dgzb_804",
  "sticky-cell": "awsui_sticky-cell_c6tup_1dgzb_449",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_c6tup_1dgzb_852",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_c6tup_1dgzb_949",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_c6tup_1dgzb_958",
  "body-cell-next-selected": "awsui_body-cell-next-selected_c6tup_1dgzb_980",
  "body-cell-prev-selected": "awsui_body-cell-prev-selected_c6tup_1dgzb_987",
  "body-cell-editor-wrapper": "awsui_body-cell-editor-wrapper_c6tup_1dgzb_1013",
  "body-cell-success": "awsui_body-cell-success_c6tup_1dgzb_1018",
  "body-cell-editor": "awsui_body-cell-editor_c6tup_1dgzb_1013",
  "body-cell-editor-disabled": "awsui_body-cell-editor-disabled_c6tup_1dgzb_1043",
  "body-cell-editor-form": "awsui_body-cell-editor-form_c6tup_1dgzb_1052",
  "body-cell-editor-row": "awsui_body-cell-editor-row_c6tup_1dgzb_1062",
  "body-cell-editor-controls": "awsui_body-cell-editor-controls_c6tup_1dgzb_1072",
  "body-cell-editor-row-editor": "awsui_body-cell-editor-row-editor_c6tup_1dgzb_1075",
  "body-cell-expandable": "awsui_body-cell-expandable_c6tup_1dgzb_1078",
  "resizable-columns": "awsui_resizable-columns_c6tup_1dgzb_1096",
  "expandable-cell-content": "awsui_expandable-cell-content_c6tup_1dgzb_1099",
  "body-cell-has-success": "awsui_body-cell-has-success_c6tup_1dgzb_1147",
  "body-cell-edit-disabled-popover": "awsui_body-cell-edit-disabled-popover_c6tup_1dgzb_1147"
};
  