import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetIndicatorsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { handleError } from '@/utils/errorUtils';

import { useGetCollectionTableProps } from './config';
import { IndicatorSummary } from './IndicatorSummary';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'indicators',
  });
  const { addNotification } = useNotifications();
  const { data, loading } = useGetIndicatorsQuery({
    onError: (error) => {
      handleError(error);
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.indicator,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <PageLayout
      helpTranslationKey="indicators.registerHelp"
      title={st('register_title')}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <Button iconName="download" onClick={tableProps.exportToCsv}>
          {t('export')}
        </Button>
      }
      meta={{
        title: st('register_title'),
      }}
    >
      <IndicatorSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
