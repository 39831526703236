import Modal from '@risksmart-app/components/Modal';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { InsertAssessmentMutationVariables } from 'src/generated/graphql';
import {
  GetInternalAuditReportByIdDocument,
  GetInternalAuditReportsDocument,
  useInsertInternalAuditReportMutation,
} from 'src/generated/graphql';
import AssessmentForm from 'src/pages/assessments/forms/AssessmentForm/AssessmentForm';
import type { AssessmentFormDataFields } from 'src/pages/assessments/forms/AssessmentForm/assessmentSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';
import { v4 as uuidv4 } from 'uuid';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { ModalBodyWrapper } from '@/components/Form/Form/ModalBodyWrapper';
import { evictField } from '@/utils/graphqlUtils';

interface Props {
  parent: ObjectWithContributors;
  onDismiss: (saved: boolean) => void;
  onSave?: (internalAuditReport: ObjectWithContributors) => void;
}

const InternalAuditReportModal: FC<Props> = ({
  onDismiss,
  parent,
  onSave: parentOnSave,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'internalAuditReports',
  });

  const userCanCreate = useHasPermission(
    'insert:internal_audit_report',
    parent
  );

  const [mutate] = useInsertInternalAuditReportMutation({
    update: (cache) => {
      evictField(cache, 'internal_audit_entity');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'internal_audit_report_aggregate');
    },
    refetchQueries: [
      GetInternalAuditReportByIdDocument,
      GetInternalAuditReportsDocument,
    ],
  });

  const onSave = async (variables: AssessmentFormDataFields) => {
    const Id = uuidv4();
    const data: InsertAssessmentMutationVariables & ObjectWithContributors = {
      ...variables,
      Id,
      CustomAttributeData: variables.CustomAttributeData || undefined,
      ...ownerAndContributorInsertFields(variables, Id),
      Tags: tagInsertInputBuilder(variables.TagTypeIds, Id),
      Departments: departmentInsertInputBuilder(
        variables.DepartmentTypeIds,
        Id
      ),
      OriginatingItemId: parent.Id,
      CompletedByUser: variables.CompletedByUser?.value,
    };
    await mutate({
      variables: data,
    });
    parentOnSave?.(data);
  };

  return (
    <Modal
      header={t('create_title')}
      visible={true}
      onDismiss={(event) => {
        // don't close modal on overlay click
        if (event.detail.reason === 'overlay') {
          return;
        }
        onDismiss(false);
      }}
      disableContentPaddings={true}
    >
      <AssessmentForm
        readOnly={!userCanCreate}
        onSave={onSave}
        assessmentMode={'internal_audit_report'}
        renderTemplate={(renderProps) => <ModalBodyWrapper {...renderProps} />}
        onDismiss={onDismiss}
      />
    </Modal>
  );
};

export default InternalAuditReportModal;
