import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';

import { tabs } from './config';

const Page: FC = () => {
  const { t } = useTranslation();
  const title = t('obligations.create_new_title');

  return (
    <PageLayout title={title}>
      <ControlledTabs tabs={tabs} variant="container" />
    </PageLayout>
  );
};

export default Page;
