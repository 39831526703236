import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetMyItemsQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionTableProps } from './config';
import { MyItemsSummary } from './MyItemsSummary';

const Page: FC = () => {
  const { addNotification } = useNotifications();
  const { user } = useRisksmartUser();

  const { t: st } = useTranslation(['common'], { keyPrefix: 'myItems' });
  const { t } = useTranslation(['common']);
  const { data, loading } = useGetMyItemsQuery({
    variables: {
      userId: user!.userId,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(data);
  const counter = getCounter(tableProps.totalItemsCount, loading);
  const title = st('register_title');

  return (
    <PageLayout
      helpTranslationKey="myItems.help"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <MyItemsSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
