
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_1mfbn_1p467_149",
  "grid-cell": "awsui_grid-cell_1mfbn_1p467_154",
  "day-header": "awsui_day-header_1mfbn_1p467_161",
  "week": "awsui_week_1mfbn_1p467_171",
  "day": "awsui_day_1mfbn_1p467_161",
  "day-inner": "awsui_day-inner_1mfbn_1p467_218",
  "in-first-row": "awsui_in-first-row_1mfbn_1p467_223",
  "in-previous-month": "awsui_in-previous-month_1mfbn_1p467_223",
  "last-day-of-month": "awsui_last-day-of-month_1mfbn_1p467_227",
  "in-next-month": "awsui_in-next-month_1mfbn_1p467_231",
  "in-first-column": "awsui_in-first-column_1mfbn_1p467_235",
  "in-current-month": "awsui_in-current-month_1mfbn_1p467_238",
  "enabled": "awsui_enabled_1mfbn_1p467_242",
  "in-range": "awsui_in-range_1mfbn_1p467_248",
  "end-date": "awsui_end-date_1mfbn_1p467_248",
  "start-date": "awsui_start-date_1mfbn_1p467_248",
  "no-range": "awsui_no-range_1mfbn_1p467_248",
  "selected": "awsui_selected_1mfbn_1p467_258",
  "today": "awsui_today_1mfbn_1p467_263",
  "range-start-date": "awsui_range-start-date_1mfbn_1p467_308",
  "in-range-border-bottom": "awsui_in-range-border-bottom_1mfbn_1p467_311",
  "range-end-date": "awsui_range-end-date_1mfbn_1p467_314",
  "in-range-border-top": "awsui_in-range-border-top_1mfbn_1p467_317",
  "in-range-border-block-start": "awsui_in-range-border-block-start_1mfbn_1p467_330",
  "in-range-border-inline-end": "awsui_in-range-border-inline-end_1mfbn_1p467_333",
  "in-range-border-block-end": "awsui_in-range-border-block-end_1mfbn_1p467_336",
  "in-range-border-inline-start": "awsui_in-range-border-inline-start_1mfbn_1p467_339",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_1mfbn_1p467_355"
};
  