import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import type { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import MultipleImpactRatingsFormFields from './MultipleImpactRatingsFormFields';
import type { ImpactRatingsFormFieldData } from './MultipleImpactRatingsFormSchema';
import { MultipleImpactRatingsFormSchema } from './MultipleImpactRatingsFormSchema';

export type Props = Omit<
  FormContextProps<ImpactRatingsFormFieldData>,
  'schema' | 'formId' | 'parentType' | 'values' | 'i18n'
> & {
  ratedItemId?: string;
  impactId?: string;
  beforeFieldsSlot?: ReactNode;
};

const MultipleImpactRatingsForm: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <CustomisableFormContext
      {...props}
      schema={MultipleImpactRatingsFormSchema}
      i18n={t('impactRatingsMultiple')}
      formId={'impact-ratings-form'}
      parentType={Parent_Type_Enum.ImpactRating}
    >
      {props.beforeFieldsSlot}
      <MultipleImpactRatingsFormFields />
    </CustomisableFormContext>
  );
};

export default MultipleImpactRatingsForm;
