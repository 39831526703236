import type { ReactNode } from 'react';
import { useState } from 'react';

import type { CustomSchema, CustomUISchema } from '../useFormBuilderContext';
import { FormBuilderContext } from '../useFormBuilderContext';

interface FormBuilderContextProviderProps {
  children: ReactNode;
}

const initialFormData = {} as unknown;

const initialFormSchema: CustomSchema = {
  type: 'object',
  properties: {},
  required: [],
};

const initialFormUISchema: CustomUISchema = {
  type: 'VerticalLayout',
  elements: [],
};

const FormBuilderContextProvider = ({
  children,
}: FormBuilderContextProviderProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [schema, setSchema] = useState(initialFormSchema);
  const [uiSchema, setUISchema] = useState(initialFormUISchema);

  const [isFormCustomisable, setIsFormCustomisable] = useState(false);
  const [isCustomising, setIsCustomising] = useState(false);

  return (
    <FormBuilderContext.Provider
      value={{
        formData,
        schema,
        uiSchema,
        isFormCustomisable,
        isCustomising,
        setFormData,
        setSchema,
        setUISchema,
        setIsFormCustomisable,
        setIsCustomising,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

export default FormBuilderContextProvider;
