import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import ImpactRatingForm from 'src/pages/impacts/ratings/forms/ImpactRatingForm';
import type { ImpactRatingFormFieldData } from 'src/pages/impacts/ratings/forms/ImpactRatingForm/impactRatingFormSchema';
import type { ObjectWithContributors } from 'src/rbac/Permission';

import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  useGetImpactRatingByIdQuery,
  useInsertChildImpactRatingsMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../useAssessmentTypeConfig';

type Props = {
  assessmentMode: AssessmentTypeEnum;
  readonly: boolean;
  navigateToResults: boolean;
  assessmentId: string;
  assessedItem?: ObjectWithContributors;
  id?: string;
  onDismiss?: (saved: boolean) => void;
  beforeFieldsSlot?: ReactNode;
  header?: string;
};

const ConnectedImpactRatingResultForm: FC<Props> = ({
  readonly,
  assessmentId,
  assessmentMode,
  id,
  onDismiss,
  beforeFieldsSlot,
  navigateToResults,
  header,
}) => {
  const navigate = useNavigate();
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const { data } = useGetImpactRatingByIdQuery({
    variables: { id: id! },
    skip: !id,
  });
  const impactRating = data?.impact_rating?.[0];

  const onSave = async (values: ImpactRatingFormFieldData) => {
    const result = await insertImpactRating({
      variables: {
        ...values,
        Ratings: [
          {
            ImpactId: values.ImpactId,
            Rating: values.Rating,
          },
        ],
        CustomAttributeData: values.CustomAttributeData ?? null,
        AssessmentId: assessmentMode == 'rating' ? assessmentId : undefined,
        CompletedBy: values.CompletedBy?.value,
        ComplianceMonitoringAssessmentId:
          assessmentMode == 'compliance_monitoring_assessment'
            ? assessmentId
            : undefined,
        InternalAuditReportId:
          assessmentMode == 'internal_audit_report' ? assessmentId : undefined,
      },
    });

    if (!result.data?.insertChildImpactRating?.Ids) {
      throw new Error('Impact rating result id is missing');
    }

    if (navigateToResults) {
      navigate(resultsRegisterUrl(assessmentId));
    }
  };
  const [insertImpactRating] = useInsertChildImpactRatingsMutation({
    update: (cache) => {
      evictField(cache, 'risk_assessment_result');
      evictField(cache, 'assessment');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'risk_assessment_result_aggregate');
    },
  });

  return (
    <div className="pb-5">
      <ImpactRatingForm
        header={header}
        values={
          impactRating
            ? {
                Rating: impactRating.Rating,
                ImpactId: impactRating.ImpactId,
                RatedItemId: impactRating.RatedItemId,
                TestDate: impactRating.TestDate,
                CustomAttributeData: impactRating.CustomAttributeData,
                CompletedBy: impactRating.CompletedBy
                  ? { value: impactRating.CompletedBy, type: 'user' }
                  : null,
              }
            : undefined
        }
        onSave={onSave}
        readOnly={readonly}
        onDismiss={onDismiss}
        renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
        beforeFieldsSlot={beforeFieldsSlot}
      />
    </div>
  );
};

export default ConnectedImpactRatingResultForm;
