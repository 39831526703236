import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import i18next, { ns } from '@risksmart-app/components/providers/i18n';
import type { FC, ReactNode } from 'react';
import { createContext } from 'react';

import Loading from '@/components/Loading';
import { useGetTaxonomyByLocaleAndOrgQuery } from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';

type TaxonomyContext = {
  loading: boolean;
};

const TaxonomyContext = createContext<TaxonomyContext | null>(null);

const TaxonomyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useRisksmartUser();
  const orgKey = user?.orgKey;

  const { data, loading } = useGetTaxonomyByLocaleAndOrgQuery({
    variables: {
      Locale: i18next.language,
      OrgKey: orgKey!,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.warn(
        `Error attempting to load translations for ${orgKey}: ${i18next.language}`
      );
      handleError(error);
    },
  });

  const nonDeepNamespaces = ['ratings', 'library'];
  const taxonomyData = data?.taxonomy_org[0]?.taxonomy;
  if (taxonomyData) {
    for (const namespace of ns) {
      const deep = !nonDeepNamespaces.includes(namespace);
      let namespaceData = {};
      switch (namespace) {
        case 'taxonomy':
          namespaceData = taxonomyData?.Taxonomy || {};
          break;
        case 'ratings':
          namespaceData = taxonomyData?.Rating || {};
          break;
        case 'common':
          namespaceData = taxonomyData?.Common || {};
          break;
        case 'library':
          namespaceData = taxonomyData?.Library || {};
          break;
        default:
          namespaceData = {};
      }
      i18next.addResourceBundle(
        i18next.language,
        namespace,
        namespaceData,
        deep,
        true
      );
    }
  }

  return (
    <TaxonomyContext.Provider
      value={{
        loading,
      }}
    >
      {loading ? <Loading /> : children}
    </TaxonomyContext.Provider>
  );
};

export { TaxonomyProvider };
