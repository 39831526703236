import Textarea from '@cloudscape-design/components/textarea';
import type { ControlProps } from '@jsonforms/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  CustomSchema,
  CustomUISchemaElement,
} from '../../FormBuilder/useFormBuilderContext';
import CustomisableControl from './CustomisableControl';
import styleOverrides from './style.module.scss';
import { getFieldLabel } from './utils';

type TextAreaControlProps = ControlProps & {
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
};

export const TextAreaControlUnwrapped: FC<TextAreaControlProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  id,
  path,
  config,
}) => {
  const [_, setIsFieldDirty] = useState(false);
  const [label, setLabel] = useState<string>(uischema?.label || '');

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.placeholders',
  });

  useEffect(() => {
    setLabel(getFieldLabel(uischema?.label, schema?.minLength));
  }, [uischema, schema]);

  return (
    <CustomisableControl
      label={label}
      uischema={uischema}
      errors={errors}
      schema={schema}
    >
      <Textarea
        {...{
          className: `grow w-max-content ${styleOverrides.textArea}`,
        }}
        value={data || ''}
        onChange={(event) => {
          setIsFieldDirty(true);
          handleChange(path, event.detail.value);
        }}
        onBlur={() => setIsFieldDirty(true)}
        name={id}
        disabled={!enabled}
        autoFocus={appliedUiSchemaOptions.focus}
        placeholder={appliedUiSchemaOptions?.placeholder || t('textArea')}
      />
    </CustomisableControl>
  );
};
