import type { TableProps } from '@cloudscape-design/components/table';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { GetCausesByParentIssueIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

export type CausesFields = GetCausesByParentIssueIdQuery['cause'][0];

export function useCausesColumnDefinitions(
  onEdit: (consequences: CausesFields) => void
) {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'causes.columns',
  });
  const { getByValue } = useRating('significance');
  const causeColumnDefinitions: TableProps<CausesFields>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit(item)}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      {
        id: 'significance',
        header: st('significance'),
        cell: (item) => {
          return <SimpleRatingBadge rating={getByValue(item.Significance)} />;
        },
        sortingField: 'Significance',
        maxWidth: MAX_COL_WIDTH,
      },
      {
        id: 'description',
        header: st('description'),
        cell: (item) => item.Description,
        sortingField: 'Description',
        maxWidth: MAX_COL_WIDTH,
      },
    ];

  return causeColumnDefinitions;
}
