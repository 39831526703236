import type {
  JsonSchema,
  Labelable,
  Scopable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';

import type { FieldType } from '../useFormBuilderContext';
import { useFieldTypeOptions } from '../useFormBuilderContext';

export interface QuestionData {
  questionTitle: string;
  placeholder?: string;
  fieldType: FieldType;
  isPropertyRequired: boolean;
}

export const defaultQuestionData: QuestionData = {
  questionTitle: '',
  placeholder: '',
  fieldType: 'text',
  isPropertyRequired: true,
};

export const useQuestionSchema = (): JsonSchema => {
  const fieldTypeOptions = useFieldTypeOptions();

  return {
    type: 'object',
    properties: {
      questionTitle: { type: 'string', minLength: 1 },
      placeholder: { type: 'string' },
      fieldType: {
        type: 'string',
        oneOf: Object.entries(fieldTypeOptions).map(([_, option]) => {
          return { const: option.value, title: option.label };
        }),
        minLength: 1,
      },
      isPropertyRequired: { type: 'boolean' },
    },
    required: ['questionTitle', 'fieldType', 'isPropertyRequired'],
  };
};

interface VerticalLayoutWithLabelables extends VerticalLayout {
  elements: (UISchemaElement & Labelable & Scopable)[];
}

export const questionUISchema: VerticalLayoutWithLabelables = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      label: 'Question Title',
      scope: '#/properties/questionTitle',
      options: {
        placeholder: 'Enter question title here...',
      },
    },
    {
      type: 'Control',
      label: 'Placeholder Text',
      scope: '#/properties/placeholder',
      options: {
        placeholder: 'Enter placeholder text here...',
      },
    },
    {
      type: 'Control',
      label: 'Field Type',
      scope: '#/properties/fieldType',
    },
    {
      type: 'Control',
      label: 'Response Required',
      scope: '#/properties/isPropertyRequired',
    },
  ],
};
