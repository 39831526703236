import { useCallback, useMemo } from 'react';
import { UNRATED } from 'src/pages/controls/lookupData';
import { useAggregation } from 'src/providers/useAggregation';
import type { RatingOption } from 'src/ratings/ratings';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  useGetRiskScoresByRiskIdQuery,
  useGetRiskScoresSubscription,
} from '@/generated/graphql';

import { useRating } from './use-rating';

export type RiskScore = {
  id: string;
  inherentRating: number | null;
  inherentScore: number | null;
  inherentLikelihood?: number | null;
  inherentImpact?: number | null;
  inherentRatingId: string | undefined;
  residualRating: number | null;
  residualScore: number | null;
  residualLikelihood?: number | null;
  residualImpact?: number | null;
  inherentCompletionDate: string | null | undefined;
  residualCompletionDate: string | null | undefined;
  residualRatingId: string | undefined;
};

type RiskScoreMeta = {
  loading: boolean;
  showScore?: boolean;
};

const getRiskScoreBadge = (
  rating: number | null | undefined,
  getLabelByFn: (
    r: number | string | null | undefined
  ) => RatingOption | undefined,
  showScore?: boolean
) => {
  if (!rating) {
    return <SimpleRatingBadge rating={UNRATED} />;
  }

  let ratingOption = getLabelByFn(rating) ?? UNRATED;

  if (showScore && ratingOption.label !== 'Unrated') {
    ratingOption = {
      ...ratingOption,
      label: rating?.toFixed(1),
    };
  }

  return <SimpleRatingBadge rating={ratingOption} />;
};

export const useRiskScoreFormatters = () => {
  const { riskModel } = useAggregation();
  const { getByRange: getByRangeControlled, getByValue: getByValueControlled } =
    useRating('risk_controlled');
  const {
    getByRange: getByRangeUncontrolled,
    getByValue: getByValueUncontrolled,
  } = useRating('risk_uncontrolled');

  const showScore = riskModel !== 'default';

  return useCallback(
    (score: Partial<RiskScore> | undefined) => ({
      getInherent: () =>
        showScore
          ? getByRangeUncontrolled(score?.inherentScore)
          : getByValueUncontrolled(score?.inherentScore),
      getResidual: () =>
        showScore
          ? getByRangeControlled(score?.residualScore)
          : getByValueControlled(score?.residualScore),
      getInherentLabel: () => {
        if (!score?.inherentRating) {
          return UNRATED.label;
        }

        return showScore
          ? getByRangeUncontrolled(score?.inherentRating)?.label
          : (getByValueUncontrolled(score?.inherentRating)?.label ??
              UNRATED.label);
      },
      getResidualLabel: () => {
        if (!score?.residualRating) {
          return UNRATED.label;
        }

        return showScore
          ? getByRangeControlled(score?.residualRating)?.label
          : (getByValueControlled(score?.residualRating)?.label ??
              UNRATED.label);
      },
      getInherentRatingBadge: () =>
        getRiskScoreBadge(
          score?.inherentRating,
          showScore ? getByRangeUncontrolled : getByValueUncontrolled
        ),
      getResidualRatingBadge: () =>
        getRiskScoreBadge(
          score?.residualRating,
          showScore ? getByRangeControlled : getByValueControlled
        ),
      getInherentScoreBadge: () =>
        getRiskScoreBadge(score?.inherentScore, getByRangeUncontrolled, true),
      getResidualScoreBadge: () =>
        getRiskScoreBadge(score?.residualScore, getByRangeControlled, true),
    }),
    [
      getByRangeControlled,
      getByRangeUncontrolled,
      getByValueControlled,
      getByValueUncontrolled,
      showScore,
    ]
  );
};

export type UseRiskScoreFormattersResponse = ReturnType<
  typeof useRiskScoreFormatters
>;

export const useRiskScore = (riskId: string): RiskScore & RiskScoreMeta => {
  const { riskModel } = useAggregation();

  const { data, loading } = useGetRiskScoresByRiskIdQuery({
    variables: {
      RiskId: riskId!,
    },
  });
  const showScore = riskModel !== 'default';

  const latestUncontrolled = data?.uncontrolled[0];
  const latestControlled = data?.controlled[0];

  const scores: RiskScore = {
    id: riskId,
    inherentRating: !showScore
      ? latestUncontrolled?.Rating
      : data?.riskScores[0]?.InherentScore,
    inherentScore: !showScore
      ? (latestUncontrolled?.Likelihood ?? 0) *
        (latestUncontrolled?.Impact ?? 0)
      : data?.riskScores[0]?.InherentScore,
    inherentImpact: latestUncontrolled?.Impact,
    inherentLikelihood: latestUncontrolled?.Likelihood,
    inherentCompletionDate: !showScore
      ? latestUncontrolled?.TestDate
      : data?.riskScores[0]?.ModifiedAtTimestamp,
    inherentRatingId: !showScore ? latestUncontrolled?.Id : undefined,
    residualRating: !showScore
      ? latestControlled?.Rating
      : data?.riskScores[0]?.ResidualScore,
    residualScore: !showScore
      ? (latestControlled?.Likelihood ?? 0) * (latestControlled?.Impact ?? 0)
      : data?.riskScores[0]?.ResidualScore,
    residualImpact: latestControlled?.Impact,
    residualLikelihood: latestControlled?.Likelihood,

    residualCompletionDate: !showScore
      ? latestControlled?.TestDate
      : data?.riskScores[0]?.ModifiedAtTimestamp,
    residualRatingId: !showScore ? latestControlled?.Id : undefined,
  };

  return {
    loading,
    showScore,
    ...scores,
  };
};

export const useRiskScores = (): {
  loading: boolean;
  showScore: boolean;
  scores: RiskScore[] | undefined;
} => {
  const { data, loading } = useGetRiskScoresSubscription();
  const { riskModel } = useAggregation();

  const showScore = riskModel !== 'default';

  return useMemo(
    () => ({
      loading,
      showScore,
      scores: data?.risk.map((r) => {
        const latestUncontrolled = r?.uncontrolled[0]?.riskAssessmentResult;
        const latestControlled = r?.controlled[0]?.riskAssessmentResult;

        return {
          id: r.Id,
          inherentRating: !showScore
            ? latestUncontrolled?.Rating
            : r?.riskScore?.InherentScore,
          inherentScore: !showScore
            ? (latestUncontrolled?.Likelihood ?? 0) *
              (latestUncontrolled?.Impact ?? 0)
            : r?.riskScore?.InherentScore,
          inherentImpact: latestUncontrolled?.Impact,
          inherentLikelihood: latestUncontrolled?.Likelihood,
          residualRating: !showScore
            ? latestControlled?.Rating
            : r?.riskScore?.ResidualScore,
          residualScore: !showScore
            ? (latestControlled?.Likelihood ?? 0) *
              (latestControlled?.Impact ?? 0)
            : r?.riskScore?.ResidualScore,
          residualImpact: latestControlled?.Impact,
          residualLikelihood: latestControlled?.Likelihood,
          inherentRatingId: !showScore ? latestUncontrolled?.Id : undefined,
          inherentCompletionDate: !showScore
            ? latestUncontrolled?.TestDate
            : r?.riskScore?.ModifiedAtTimestamp,
          residualCompletionDate: !showScore
            ? latestControlled?.TestDate
            : r?.riskScore?.ModifiedAtTimestamp,
          residualRatingId: !showScore ? latestControlled?.Id : undefined,
        };
      }),
    }),
    [data?.risk, loading, showScore]
  );
};
