import ExpandableSection from '@cloudscape-design/components/expandable-section';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Parent_Type_Enum,
  useGetInternalAuditReportDocumentAssessmentResultsByDocumentIdQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import type { ObjectWithContributors } from '../../../../../rbac/Permission';
import AssessmentResultModal from '../../../../assessments/modals/AssessmentResultModal';
import { useGetCollectionTableProps } from './internalAuditDocumentRatingConfig';

interface Props {
  parent: ObjectWithContributors;
}

const InternalAuditDocumentAssessmentResultRegister: FC<Props> = ({
  parent,
}) => {
  const { t: st } = useTranslation(['common']);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();

  const [openAssessmentResultId, setOpenAssessmentResultId] = useState<
    string | undefined
  >();

  const { data, loading } =
    useGetInternalAuditReportDocumentAssessmentResultsByDocumentIdQuery({
      variables: {
        ParentId: parent.Id,
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
      fetchPolicy: 'no-cache',
    });

  const handleAssessmentResultModalClose = () => {
    setOpenAssessmentResultId(undefined);
    setIsEditOpen(false);
  };

  const onOpenResult = (id?: string) => {
    setOpenAssessmentResultId(id);
    setIsEditOpen(true);
  };

  const tableProps = useGetCollectionTableProps(
    data?.document_assessment_result,
    onOpenResult,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>
              {st('documentAssessments.internalAuditRatingSubheading')}
            </span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <Table
          {...tableProps}
          resizableColumns={true}
          variant="embedded"
          loading={loading}
          data-testid="internal-audit-document-rating-table"
        />
      </ExpandableSection>
      {isEditOpen && (
        <AssessmentResultModal
          id={openAssessmentResultId}
          onDismiss={handleAssessmentResultModalClose}
          assessedItem={parent}
          resultType={Parent_Type_Enum.DocumentAssessmentResult}
          hideTypeSelector={true}
          i18n={st('ratings')}
          assessmentMode={'internal_audit_report'}
        />
      )}
    </>
  );
};

export default InternalAuditDocumentAssessmentResultRegister;
