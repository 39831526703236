import type { PropertyFilterOperator } from '@cloudscape-design/collection-hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import BadgeList from '@/components/BadgeList';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { GetChangeRequestsQuery } from '@/generated/graphql';
import { Parent_Type_Enum } from '@/generated/graphql.typed';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetApproversFieldConfig } from '@/utils/table/hooks/useGetApproversFieldConfig';
import { useGetCurrentApproversFieldConfig } from '@/utils/table/hooks/useGetCurrentApproversFieldConfig';
import { useGetNextApproversFieldConfig } from '@/utils/table/hooks/useGetNextApproversFieldConfig';
import { useGetRequestersFieldConfig } from '@/utils/table/hooks/useGetRequestersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { policyFileDetailsUrl } from '@/utils/urls';

import type { ChangeRequestRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetChangeRequestParentUrl = () => {
  const navigate = useNavigate();
  const getEntityInfo = useEntityInfo();
  const gotoParentUrl = async (changeRequest: ChangeRequestRegisterFields) => {
    let url = '';
    if (changeRequest.parent?.ObjectType) {
      if (changeRequest.parent.ObjectType === 'document_file') {
        if (changeRequest.parent.documentFile?.parent) {
          url = policyFileDetailsUrl(
            changeRequest.parent.documentFile.parent.Id,
            changeRequest.parent.Id
          );
        }
      } else if (changeRequest.parent.ObjectType === 'issue_assessment') {
        const entityInfo = getEntityInfo(changeRequest.parent.ObjectType);
        url = entityInfo.url(
          changeRequest.parent.issue_assessment?.parent?.Id ?? '#'
        );
      } else {
        const entityInfo = getEntityInfo(changeRequest.parent.ObjectType);
        url = entityInfo.url(changeRequest.parent.Id);
      }
    }

    navigate(`${url}?showRequest=true&requestId=${changeRequest.Id}`);
  };

  return gotoParentUrl;
};

const useRequestsFields = () => {
  const { t } = useTranslation(['common']);
  const { getByValue } = useRating('approval_status');
  const gotoParentUrl = useGetChangeRequestParentUrl();
  const approversField = useGetApproversFieldConfig();
  const requestersField = useGetRequestersFieldConfig();
  const currentApproversField = useGetCurrentApproversFieldConfig();
  const nextApproversField = useGetNextApproversFieldConfig();

  const fields: TableFields<ChangeRequestRegisterFields> = {
    SequentialId: {
      id: 'sequentialId',
      header: t('columns.id'),
      cell: (item) =>
        getFriendlyId(Parent_Type_Enum.ChangeRequest, item.SequentialId),
      exportVal: (item) =>
        getFriendlyId(Parent_Type_Enum.ChangeRequest, item.SequentialId),
    },
    ParentSequentialId: {
      id: 'parentSeqId',
      header: t('columns.parentId'),
    },
    ParentName: {
      id: 'parentName',
      header: t('columns.parentName'),
      cell: (item) =>
        item.parent ? (
          <Link
            variant="secondary"
            href={'#'}
            onFollow={() => gotoParentUrl(item)}
          >
            {item.ParentName}
          </Link>
        ) : (
          item.ParentName
        ),
    },
    ParentType: {
      id: 'parentType',
      header: t('columns.parentType'),
    },
    allApprovers: approversField,
    allRequesters: requestersField,
    currentApprovers: currentApproversField,
    nextApprovers: nextApproversField,
    RequiresAction: {
      id: 'requiresAction', // TODO: translation
      header: 'Requires Action',
      // TODO: translation
      cell: (item) => (item.RequiresAction ? 'Yes' : 'No'),
    },
    StatusLabelled: {
      id: 'status',
      header: t('approvals.requestsRegister.columns.status'),
      cell: (item) => (
        <SimpleRatingBadge rating={getByValue(item.ChangeRequestStatus)} />
      ),
    },

    ParentId: {
      id: 'ParentId',
      header: t('approvals.requestsRegister.columns.parentGuid'),
    },
    Workflow: {
      id: 'workflow',
      header: t('approvals.requestsRegister.columns.workflow'),
    },
    approvalConfig: {
      id: 'approvalConfig',
      header: t('approvals.requestsRegister.columns.approvalConfig'),
      cell: (item) => <BadgeList badges={item.approvalConfig} />,
      filterOptions: {
        filteringProperties: {
          operators: (['=', ':'] as PropertyFilterOperator[]).map(
            (operator) => ({
              operator,
              match: (ids: unknown, id: string) => {
                return (ids as string[]).includes(id);
              },
            })
          ),
        },
        filteringOptions: [],
      },
    },
    CreatedAtTimestamp: dateColumn(
      t('approvals.requestsRegister.columns.dateOpened'),
      'CreatedAtTimestamp',
      undefined,
      true
    ),
    DateLastActioned: dateColumn(
      t('approvals.requestsRegister.columns.dateLastActioned'),
      'DateLastActioned',
      undefined,
      true
    ),
    DateClosed: dateColumn(
      t('approvals.requestsRegister.columns.dateClosed'),
      'DateClosed',
      undefined,
      true
    ),
    CurrentStage: {
      id: 'currentStage',
      header: t('approvals.requestsRegister.columns.currentStage'),
    },
  };

  return fields;
};

export const useRequestsTableProps = (data?: GetChangeRequestsQuery) => {
  const fields = useRequestsFields();
  const labelledFields = useLabelledFields(data);

  return useGetTableProps({
    fields,
    tableId: 'requestRegister',
    data: labelledFields,
    entityLabel: 'request',
    initialColumns: [
      'SequentialId',
      'ParentName',
      'Workflow',
      'StatusLabelled',
      'CreatedAtTimestamp',
      'DateLastActioned',
      'DateClosed',
    ],
    preferencesStorageKey: 'RequestsRegisterTable-PreferencesV1',
  });
};
