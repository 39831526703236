import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetIndicatorsByParentIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

import { conformanceRatingFromResults } from '../calculateConformanceRating';
import { latestResultValueFromData } from '../latestResultValueFromData';

const useIndicatorExportTable = (
  parentId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getTestFreqLabel } = useRating('frequency');
  const { getLabel: getConformanceLabel } = useRating(
    'indicator_conformance_status'
  );
  const [getIndicators, getIndicatorsResult] =
    useGetIndicatorsByParentIdLazyQuery({
      variables: {
        parentId,
      },
    });

  const { t: indicatorsColumns } = useTranslation(['common'], {
    keyPrefix: 'indicators.columns',
  });
  const createIndicatorTable = async () => {
    const { data: indicatorsData } = await getIndicators();
    const indicatorTableData = (indicatorsData?.indicator ?? []).map(
      (indicator) => [
        indicator.Title,
        getTestFreqLabel(indicator.TestFrequency),
        latestResultValueFromData(indicator),
        getConformanceLabel(conformanceRatingFromResults(indicator)),
        toLocalDate(indicator.latestResults[0]?.ResultDate),
      ]
    );

    return createTable({
      widths: ['*', 50, 50, 70, 70, 50, 70],
      body: [
        tableHeaders([
          indicatorsColumns('title'),
          indicatorsColumns('test_frequency'),
          indicatorsColumns('latest_result'),
          indicatorsColumns('conformance'),
          indicatorsColumns('latest_result_date'),
        ]),
        ...indicatorTableData,
      ],
    });
  };

  return [createIndicatorTable, getIndicatorsResult.loading];
};

export default useIndicatorExportTable;
