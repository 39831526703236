import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import {
  GetInternalAuditByIdDocument,
  GetInternalAuditsDocument,
  useGetBusinessAreasQuery,
  useGetInternalAuditByIdQuery,
  useUpdateInternalAuditMutation,
} from 'src/generated/graphql';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';
import { useGetDetailParentPath } from 'src/routes/useGetDetailParentPath';

import { ownerAndContributorIds } from '@/components/Form';
import { evictField } from '@/utils/graphqlUtils';

import InternalAuditForm from '../../../forms/InternalAuditForm';
import type { InternalAuditFormDataFields } from '../../../forms/internalAuditSchema';
import { defaultValues } from '../../../forms/internalAuditSchema';

const Tab: FC = () => {
  const internalAuditId = useGetGuidParam('internalAuditId');
  const navigate = useNavigate();
  const { data, error } = useGetInternalAuditByIdQuery({
    variables: { id: internalAuditId },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  const { data: businessAreasData } = useGetBusinessAreasQuery();

  const internalAudit = data?.internal_audit_entity[0];
  const canEdit = useHasPermission(
    'update:internal_audit_entity',
    internalAudit
  );
  const parentPath = useGetDetailParentPath(internalAuditId);
  const [updateMutation] = useUpdateInternalAuditMutation({
    update: (cache) => {
      evictField(cache, 'internal_audit_entity');
      evictField(cache, 'business_area');
    },
    refetchQueries: [GetInternalAuditByIdDocument, GetInternalAuditsDocument],
  });

  const onSave = async (data: InternalAuditFormDataFields) => {
    if (!internalAudit) {
      throw new Error('Missing internal audit');
    }
    const businessAreaChanged =
      internalAudit.businessArea?.Title !== data.BusinessArea;

    let businessAreaId = internalAudit.businessArea!.Id;
    if (businessAreaChanged) {
      const existingBusinessAreaId = businessAreasData?.business_area?.find(
        (c) => c.Title === data.BusinessArea
      )?.Id;
      if (existingBusinessAreaId) {
        businessAreaId = existingBusinessAreaId;
      } else {
        businessAreaId = crypto.randomUUID();
      }
    }

    await updateMutation({
      variables: {
        Input: {
          Id: internalAudit.Id,
          BusinessArea: data.BusinessArea,
          DepartmentTypeIds: data.DepartmentTypeIds,
          TagTypeIds: data.TagTypeIds,
          Title: data.Title,
          Description: data.Description,
          CustomAttributeData: data.CustomAttributeData || undefined,
          OriginalTimestamp: internalAudit.ModifiedAtTimestamp,
          BusinessAreaId: businessAreaId,
          ...ownerAndContributorIds(data),
        },
      },
    });
  };
  const onDismiss = () => navigate(parentPath);

  return (
    <InternalAuditForm
      readOnly={!canEdit}
      values={{
        ...defaultValues,
        ...internalAudit,
        Title: internalAudit?.Title ?? '',
        Description: internalAudit?.Description ?? '',
        BusinessArea: internalAudit?.businessArea?.Title ?? '',
        Owners: getOwners(internalAudit),
        Contributors: getContributors(internalAudit),
      }}
      onSave={onSave}
      onDismiss={onDismiss}
    />
  );
};

export default Tab;
