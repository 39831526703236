import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledRating from '@/components/Form/ControlledRating';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import type {
  InsertObligationImpactMutationVariables,
  UpdateObligationImpactMutationVariables,
} from '@/generated/graphql';

interface Props {
  readOnly?: boolean;
}

const ImpactDetailsForm: FC<Props> = ({ readOnly }) => {
  const { control } = useFormContext<
    | UpdateObligationImpactMutationVariables
    | InsertObligationImpactMutationVariables
  >();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'impacts',
  });

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledTextarea
        key="description"
        forceRequired={true}
        name="Description"
        label={t('fields.Description')}
        placeholder={t('placeholders.Description')}
        control={control}
        disabled={readOnly}
      />
      <ControlledRating
        forceRequired={true}
        key="rating"
        name="ImpactRating"
        label={t('fields.ImpactRating')}
        type="impact"
        control={control}
        addEmptyOption={true}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default ImpactDetailsForm;
