
    import './styles.scoped.css';
    export default {
  "column-layout": "awsui_column-layout_vvxn7_5yw5x_181",
  "grid": "awsui_grid_vvxn7_5yw5x_213",
  "grid-no-gutters": "awsui_grid-no-gutters_vvxn7_5yw5x_219",
  "grid-variant-text-grid": "awsui_grid-variant-text-grid_vvxn7_5yw5x_223",
  "grid-breakpoint-default": "awsui_grid-breakpoint-default_vvxn7_5yw5x_231",
  "grid-columns-1": "awsui_grid-columns-1_vvxn7_5yw5x_234",
  "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vvxn7_5yw5x_234",
  "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vvxn7_5yw5x_237",
  "grid-columns-2": "awsui_grid-columns-2_vvxn7_5yw5x_240",
  "grid-columns-3": "awsui_grid-columns-3_vvxn7_5yw5x_246",
  "grid-columns-4": "awsui_grid-columns-4_vvxn7_5yw5x_252",
  "grid-vertical-borders": "awsui_grid-vertical-borders_vvxn7_5yw5x_268",
  "grid-horizontal-borders": "awsui_grid-horizontal-borders_vvxn7_5yw5x_301"
};
  