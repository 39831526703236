import type {
  ChannelTypes,
  PreferenceCategory,
  PreferencesChannel,
  PreferencesSet,
} from '@risksmart-app/shared/knock/schemas';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import NotificationChannelSwitchesRow from '@/components/NotificationSettingsModal/NotificationChannelSwitchesRow';
import style from '@/components/NotificationSettingsModal/style.module.scss';
import type { WorkflowTemplate } from '@/components/NotificationSettingsModal/util';

type Props = {
  category: PreferenceCategory;
  workflows: WorkflowTemplate[];
  channels: readonly PreferencesChannel[];
  disabledChannels: readonly PreferencesChannel[];
};

export const CategorySwitchesSection = ({
  category,
  workflows,
  channels,
  disabledChannels,
}: Props) => {
  const { watch } = useFormContext<PreferencesSet>();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'notification_settings.categories',
  });

  const categoryChannels: ChannelTypes = watch(
    `categories.${category}.channel_types`
  );

  const disabledCategoryChannels = useMemo(() => {
    if (!categoryChannels) {
      return [];
    }

    return channels.filter((channel) => !(categoryChannels ?? {})[channel]);
  }, [categoryChannels, channels]);

  return (
    <>
      <NotificationChannelSwitchesRow
        label={t(category)}
        variant={'header'}
        channelTypes={channels}
        name={`categories.${category}.channel_types`}
        disabledChannels={disabledChannels}
      />
      {workflows.map((workflow, i) => (
        <div key={workflow.key} className={i > 0 ? style.borderedRow : ''}>
          <NotificationChannelSwitchesRow
            label={workflow.label}
            channelTypes={channels}
            name={`workflows.${workflow.key}.channel_types`}
            disabledChannels={[
              ...disabledChannels,
              ...disabledCategoryChannels,
            ]}
          />
        </div>
      ))}
    </>
  );
};
