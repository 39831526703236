import type { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetCausesByParentIssueIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useCausesExportTable = (
  issueId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getSignificanceLabel } = useRating('significance');
  const [getCauses, getCausesResult] = useGetCausesByParentIssueIdLazyQuery({
    variables: {
      _eq: issueId,
    },
  });

  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: causesColumns } = useTranslation(['common'], {
    keyPrefix: 'causes.columns',
  });
  const createExportTable = async () => {
    const { data: causesData } = await getCauses();
    const causesTableData = (causesData?.cause ?? []).map((i) => [
      i.Title,
      getSignificanceLabel(i.Significance),
      i.Description,
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          causesColumns('title'),
          causesColumns('significance'),
          columns('description'),
        ]),
        ...causesTableData,
      ],
    });
  };

  return [createExportTable, getCausesResult.loading];
};

export default useCausesExportTable;
