import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { useInsertInternalAuditMutation } from 'src/generated/graphql';

import { ownerAndContributorIds } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import { evictField } from '@/utils/graphqlUtils';
import {
  internalAuditDetailsUrl,
  internalAuditRegisterUrl,
} from '@/utils/urls';

import InternalAuditForm from '../../forms/InternalAuditForm';
import type { InternalAuditFormDataFields } from '../../forms/internalAuditSchema';

const InternalAuditCreateTab: FC = () => {
  useI18NSummaryHelpContent('internalAudits.help');
  const navigate = useNavigate();

  const [mutate] = useInsertInternalAuditMutation({
    update: (cache) => {
      evictField(cache, 'internal_audit_entity');
      evictField(cache, 'internal_audit_entity_aggregate');
      evictField(cache, 'business_area');
    },
  });

  const onSave = async (variables: InternalAuditFormDataFields) => {
    const { data } = await mutate({
      variables: {
        Input: {
          Title: variables.Title,
          Description: variables.Description ?? '',
          BusinessArea: variables.BusinessArea,
          CustomAttributeData: variables.CustomAttributeData || undefined,
          ...ownerAndContributorIds(variables),
          TagTypeIds: variables.TagTypeIds,
          DepartmentTypeIds: variables.DepartmentTypeIds,
        },
      },
    });
    if (data?.insertInternalAudit?.Id) {
      navigate(internalAuditDetailsUrl(data?.insertInternalAudit?.Id));
    }
  };

  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(internalAuditRegisterUrl());
    }
  };

  return (
    <>
      <InternalAuditForm onSave={onSave} onDismiss={onDismiss} />
    </>
  );
};

export default InternalAuditCreateTab;
