import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import _ from 'lodash';
import type { FieldValues } from 'react-hook-form';

import Tokens from '@/components/Tokens';
import { useGetObligationListQuery } from '@/generated/graphql';
import { obligationDetailsUrl } from '@/utils/urls';

import ControlledMultiselect from '../ControlledMultiselect';
import type { ControlledBaseProps } from '../types';
import { getOptions } from './selectUtils';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  excludedIds?: string[];
}

export const ControlledObligationMultiselect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();

  const defaultValues: { value: string }[] =
    props.control._defaultValues[props.name] ?? [];
  const { data: obligations, loading } = useGetObligationListQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  return (
    <ControlledMultiselect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      hideTokens={true}
      options={getOptions(obligations, defaultValues, props.excludedIds)}
      renderTokens={true}
      customTokenRender={(options, actions) => (
        <Tokens
          onRemove={actions.removeToken}
          tokens={options.map((o) => ({
            value: o.value!,
            url: obligationDetailsUrl(o.value!),
            label: o.label!,
          }))}
        />
      )}
    />
  );
};
