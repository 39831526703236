import clsx from 'clsx';
import type { FC } from 'react';

import Logo from './Logo';
import type { Props } from './Navigation';
import { NavItems } from './NavItems';
import UserMenu from './UserMenu';

const NavigationCollapsed: FC<Props> = ({
  setNavigationOpen,
  navigationOpen,
  navItems,
  logoutUrl,
  renderCount,
  showUserMenu,
  customLogoUrl,
  altTheme,
  authContext,
}) => {
  return (
    <div
      className={clsx(
        'w-[68px] h-screen text-center overflow-hidden  flex flex-col relative',
        { 'bg-navy_mid': !altTheme, 'bg-dark_green': altTheme }
      )}
    >
      <div className="py-5 border-0 border-b border-solid  border-navy_light h-[32px]">
        <button
          className="bg-transparent border-0 text-grey500 h-[24px] w-auto align-middle text-center p-1 rounded-md cursor-pointer"
          onClick={() => setNavigationOpen(!navigationOpen)}
        >
          <Logo small={true} />
        </button>
      </div>
      <div className="grow overflow-y-auto no-scrollbar">
        <div className="py-3">
          <NavItems
            items={navItems}
            collapsed={true}
            renderCount={renderCount}
          />
        </div>
      </div>
      {showUserMenu && (
        <UserMenu
          collapsed={true}
          logoutUrl={logoutUrl}
          customLogoUrl={customLogoUrl}
          authContext={authContext}
        />
      )}
    </div>
  );
};

export default NavigationCollapsed;
