import type { TableProps } from '@cloudscape-design/components/table';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import Link from '@/components/Link';
import type { GetActionUpdatesByParentActionIdQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';

type ActionUpdateFields =
  GetActionUpdatesByParentActionIdQuery['action_update'][0];

export type ActionUpdateTableFields = ActionUpdateFields & {
  CreatedByUserName: string | null;
};

export function useUpdateColumnDefinitions(
  onEdit: (actionUpdate: ActionUpdateFields) => void
) {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actionUpdates.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const updateColumnDefinitions: TableProps<ActionUpdateTableFields>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit(item)}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      {
        id: 'description',
        header: st('description'),
        cell: (item) => item.Description,
        sortingField: 'Description',
        maxWidth: MAX_COL_WIDTH,
      },
      {
        id: 'createdBy',
        header: t('created_by_username'),
        cell: (item) => item.CreatedByUserName || '-',
        sortingField: 'CreatedByUserName',
      },
      {
        id: 'date',
        header: st('date'),
        cell: (item) =>
          item.CreatedAtTimestamp ? toLocalDate(item.CreatedAtTimestamp) : '-',
        sortingField: 'CreatedAtTimestamp',
      },
    ];

  return updateColumnDefinitions;
}
