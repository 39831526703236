import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  Parent_Type_Enum,
  useDeleteDocumentMutation,
  useGetDocumentByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  activeTabId:
    | 'details'
    | 'files'
    | 'ratings'
    | 'actions'
    | 'issues'
    | 'approvals'
    | 'linkedItems';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const documentId = useGetGuidParam('documentId');
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const detailsPath = useGetDetailPath(documentId);
  const parentUrl = useGetDetailParentPath(documentId);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy' });
  const defaultTitle = st('fallback_title');

  const { data, error } = useGetDocumentByIdQuery({
    variables: {
      id: documentId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  const document = data?.document?.[0];
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    document
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    document
  );
  const [exportItem, { loading: exporting }] = useExporter(
    documentId,
    internalAuditEnabled && canViewInternalAudit,
    complianceMonitoringEnabled && canViewCompliance
  );

  const tabs = useTabs('update', detailsPath, document);
  const [deleteDocuments, deleteResult] = useDeleteDocumentMutation({
    update: (cache) => evictField(cache, 'document'),
  });

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      if (!document) {
        throw new Error('Document not selected');
      }

      await deleteDocuments({
        variables: {
          id: document.Id,
        },
      });

      await navigate(parentUrl);
      setIsDeleteModalVisible(false);

      return true;
    },
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  if (data?.document.length === 0) {
    throw new PageNotFound(`Document with id ${documentId} not found`);
  }

  const title = document?.Title;
  const counter =
    document &&
    `(${getFriendlyId(Parent_Type_Enum.Document, document.SequentialId)})`;

  return (
    <PageLayout
      title={title}
      meta={{ title: defaultTitle }}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" disabled={exporting} onClick={exportItem}>
            {t('export')}
          </Button>
          <Permission permission="delete:document" parentObject={document}>
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
