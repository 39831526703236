import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { addThirdPartyUrl, thirdPartyDetailsUrl } from '@/utils/urls';

import { Permission } from '../../rbac/Permission';
import type { ThirdPartyFields, ThirdPartyRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ThirdPartyRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party.columns',
  });
  const ownersField = useGetOwnersFieldConfig<ThirdPartyRegisterFields>();
  const contributorsField =
    useGetContributorsFieldConfig<ThirdPartyRegisterFields>();
  const tagField = useGetTagFieldConfig<ThirdPartyRegisterFields>();
  const departmentField =
    useGetDepartmentFieldConfig<ThirdPartyRegisterFields>();

  const getStatus = useRating('third_party_status');

  return {
    SequentialId: {
      header: t('id'),
      cell: (item) =>
        item.SequentialId
          ? getFriendlyId(Parent_Type_Enum.ThirdParty, item.SequentialId)
          : '-',
      exportVal: (item) =>
        item.SequentialId
          ? getFriendlyId(Parent_Type_Enum.ThirdParty, item.SequentialId)
          : '-',
    },
    Title: {
      header: st('title'),
      cell: (item) => (
        <Link variant="secondary" href={thirdPartyDetailsUrl(item.Id)}>
          {item.Title}
        </Link>
      ),
    },
    allOwners: ownersField,
    allContributors: contributorsField,
    Description: {
      header: st('description'),
    },
    CompanyName: {
      header: st('companyName'),
    },
    CompaniesHouseNumber: {
      header: st('companiesHouseNumber'),
    },
    Address: {
      header: st('address'),
    },
    CityTown: {
      header: st('cityTown'),
    },
    Postcode: {
      header: st('postcode'),
    },
    Country: {
      header: st('country'),
    },
    PrimaryContactName: {
      header: st('primaryContactName'),
    },
    ContactName: {
      header: st('contactName'),
    },
    ContactEmail: {
      header: st('contactEmail'),
    },
    CompanyDomain: {
      header: st('companyDomain'),
    },
    TypeLabelled: {
      header: t('type'),
    },
    StatusLabelled: {
      header: t('status'),
      cell: (item) => (
        <SimpleRatingBadge rating={getStatus.getByValue(item.Status)} />
      ),
    },
    CriticalityLabelled: {
      header: st('criticality'),
    },
    tags: tagField,
    departments: departmentField,
    CreatedByUser: {
      header: t('created_by_id'),
    },
    ModifiedByUser: {
      header: t('updated_by_id'),
    },
  };
};

export const useGetThirdPartyTableProps = (
  records: ThirdPartyFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ThirdPartyRegisterFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'third_party' });
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(() => {
    return {
      tableId: 'thirdPartyRegister',
      data,
      fields,
      customAttributeSchema,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:third_party">
          <Button href={addThirdPartyUrl()}>{st('create_new_button')}</Button>
        </Permission>
      ),
      enableFiltering: true,
      initialColumns: [
        'Title',
        'CompanyName',
        'Description',
        'allOwners',
        'TypeLabelled',
        'StatusLabelled',
        'CriticalityLabelled',
        'tags',
        'departments',
      ],
      preferencesStorageKey: 'ThirdPartyTable-PreferencesV1',
    };
  }, [st, data, fields, customAttributeSchema]);
};

export const useGetCollectionTableProps = (
  records: ThirdPartyFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ThirdPartyRegisterFields> => {
  const props = useGetThirdPartyTableProps(records, customAttributeSchema);

  return useGetTableProps(props);
};

export const useGetThirdPartySmartWidgetTableProps = (
  records: ThirdPartyFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ThirdPartyRegisterFields>
): TablePropsWithActions<ThirdPartyRegisterFields> => {
  const props = useGetThirdPartyTableProps(records, customAttributeSchema);

  return useGetStatelessTableProps<ThirdPartyRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
