
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1i0s3_43648_145",
  "header": "awsui_header_1i0s3_43648_177",
  "content": "awsui_content_1i0s3_43648_181",
  "error": "awsui_error_1i0s3_43648_185",
  "footer": "awsui_footer_1i0s3_43648_189",
  "actions-section": "awsui_actions-section_1i0s3_43648_193",
  "secondary-actions": "awsui_secondary-actions_1i0s3_43648_202",
  "actions": "awsui_actions_1i0s3_43648_193"
};
  