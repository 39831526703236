import SpaceBetween from '@cloudscape-design/components/space-between';
import type { PropsWithChildren } from 'react';

import FormErrors from '@/components/FormErrors';

export const FormInner = ({ children }: PropsWithChildren) => (
  <SpaceBetween direction="vertical" size="l">
    <FormErrors />
    <div>{children}</div>
  </SpaceBetween>
);
