import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

export type ToolsContent =
  | 'help'
  | 'notifications'
  | 'page-content'
  | `change-request:${string}`
  | undefined;

export const ToolsContext = createContext<
  [ToolsContent, Dispatch<SetStateAction<ToolsContent>>]
>([undefined, () => null]);

export const useTools = () => useContext(ToolsContext);
