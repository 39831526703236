export enum RiskAssessmentResultTestIds {
  Risk = 'risk',
  Assessment = 'assessment',
  ComplianceMonitoringAssessment = 'complianceMonitoringAssessment',
  InternalAuditReport = 'internalAuditReport',
  ControlType = 'controlType',
  Likelihood = 'likelihood',
  Impact = 'impact',
  Rating = 'rating',
}
