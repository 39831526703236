import type { TabsProps } from '@cloudscape-design/components/tabs';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import { ApprovalsTab } from './tabs/approvals/Tab';
import AuditTab from './tabs/audit/Tab';
import DataImportTab from './tabs/dataImport/Tab';
import DepartmentsTab from './tabs/departments/Tab';
import GroupsTab from './tabs/groups/Tab';
import TagsTab from './tabs/tags/Tab';
import TaxonomyTab from './tabs/taxonomy/Tab';
import UsersTab from './tabs/users/Tab';

export const useTabs = (): TabsProps['tabs'] => {
  const { t } = useTranslation(['common']);
  const settingsEnabled = useIsFeatureVisibleToOrg('settings');
  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');
  const canUpdateTaxonomy = useHasPermission('update:taxonomy');
  const canUseDataImport = useHasPermission('insert:data_import');
  const tabs = [];

  if (settingsEnabled) {
    tabs.push({
      label: t('userSettings.usersTableTitle'),
      id: 'users',
      href: '/settings/users',
      content: <UsersTab />,
    });
    tabs.push({
      label: t('userGroups.groupsTableTitle'),
      id: 'groups',
      href: '/settings/groups',
      content: <GroupsTab />,
    });
  }
  if (canUpdateTaxonomy) {
    tabs.push({
      label: t('taxonomy.taxonomyTableTitle'),
      id: 'taxonomy',
      href: '/settings/taxonomy',
      content: <TaxonomyTab />,
    });
  }

  if (settingsEnabled) {
    tabs.push({
      label: t('tags.tagsTableTitle'),
      id: 'tags',
      href: '/settings/tags',
      content: <TagsTab />,
    });
    tabs.push({
      label: t('departments.departmentsTableTitle'),
      id: 'departments',
      href: '/settings/departments',
      content: <DepartmentsTab />,
    });
  }

  if (approvalsEnabled) {
    tabs.push({
      label: t('approvals.approvalTableTitle'),
      id: 'approvals',
      href: '/settings/approvals',
      content: <ApprovalsTab />,
    });
  }

  tabs.push({
    label: t('auditLog.auditTableTitle'),
    id: 'audit',
    href: '/settings/audit',
    content: <AuditTab />,
  });

  if (canUseDataImport) {
    tabs.push({
      label: t('dataImport.tabTitle'),
      id: 'dataImport',
      href: '/settings/data-import',
      content: <DataImportTab />,
    });
  }

  return filterEmptyTabs(tabs);
};
